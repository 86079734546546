import { FC } from 'react';
import AppleLogin, { AppleLoginProps } from 'react-apple-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import GoogleLogin, { GoogleLoginResponse, GoogleLoginResponseOffline } from 'react-google-login';

import {
  ENV_APPLE_APP_ID,
  ENV_APPLE_REDIRECT_URL,
  ENV_FACEBOOK_APP_ID,
  ENV_GOOGLE_APP_ID,
} from '@lib/core/service/consts';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import { ILocaleText } from '@lib/tools/locale/views/LocaleFragment';
import { APPLE, EMAIL, FACEBOOK, GOOGLE } from '@lib/tools/shared/helpers/consts';
import { ISocialLoginFacebook } from '@lib/tools/views/web/interfaces/loginSocial';

import AuthorizationButton from '@components/web/src/atoms/Buttons/SocialButtons/AuthorizationButton/AuthorizationButton';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Text } from '@components/web/src/foundations/Text/Text';
import * as S from '@components/web/src/organisms/Authentication/SocialConnector/styles';

interface IProps {
  title: ILocaleText;
  isEmailAuthorization: boolean;
  isRegistrationPage?: boolean;
  isSocialAuthTesting?: boolean;
  handleEmailAuthButtonClick: () => void;
  responseFacebook: (response: ISocialLoginFacebook) => void;
  responseApple: (response: AppleLoginProps) => void;
  responseGoogle: (response: GoogleLoginResponse | GoogleLoginResponseOffline) => void;
  error?: {
    type: typeof GOOGLE | typeof APPLE | typeof FACEBOOK;
    message: string;
  };
}

const SocialConnector: FC<IProps> = ({
  title,
  isEmailAuthorization,
  isRegistrationPage = false,
  error,
  responseFacebook,
  responseApple,
  isSocialAuthTesting,
  responseGoogle,
  handleEmailAuthButtonClick,
}) => {
  const { authOptions } = localeCommon;
  return (
    <S.AuthorizationGroup data-testid="SocialConnector">
      <Text color={STATIC_COLORS.base.black} size="body1" text={title} />

      <S.GroupWrapper $errorType={error?.type}>
        <FacebookLogin
          disableMobileRedirect
          appId={ENV_FACEBOOK_APP_ID}
          autoLoad={false}
          callback={responseFacebook}
          data-use-continue-as="true"
          fields="name,email,picture"
          render={({ onClick }) => (
            <AuthorizationButton
              key={FACEBOOK}
              handleClick={onClick}
              isDisabled={isEmailAuthorization}
              isHidden={!isSocialAuthTesting}
              text={isRegistrationPage ? authOptions.facebookSignup : authOptions.facebookLogIn}
              type={FACEBOOK}
              variant="secondary"
            />
          )}
        />
        <GoogleLogin
          autoLoad={false}
          clientId={ENV_GOOGLE_APP_ID}
          render={({ onClick }) => (
            <AuthorizationButton
              key={GOOGLE}
              handleClick={onClick}
              isDisabled={isEmailAuthorization}
              text={isRegistrationPage ? authOptions.googleSignup : authOptions.googleLogIn}
              type={GOOGLE}
              variant="secondary"
            />
          )}
          onFailure={responseGoogle}
          onSuccess={responseGoogle}
        />
        <AppleLogin
          callback={responseApple}
          clientId={ENV_APPLE_APP_ID}
          redirectURI={ENV_APPLE_REDIRECT_URL}
          responseMode="query"
          scope="email"
          state="state"
          render={({ onClick }) => (
            <AuthorizationButton
              key={APPLE}
              handleClick={onClick}
              isHidden={!isSocialAuthTesting}
              type={APPLE}
              variant="secondary"
            />
          )}
        />
        <Text color={STATIC_COLORS.base.black} size="body1" text={authOptions.orText} weight="normal" />
        <AuthorizationButton
          key={EMAIL}
          handleClick={handleEmailAuthButtonClick}
          text={isRegistrationPage ? authOptions.emailSignup : authOptions.emailLogIn}
          type={EMAIL}
          variant="secondary"
        />
      </S.GroupWrapper>
      {error?.message && <Text color={STATIC_COLORS.error[600]} size="body1" text={error?.message} />}
    </S.AuthorizationGroup>
  );
};

export default SocialConnector;
