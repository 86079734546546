import { styled } from 'styled-components';

export const FooterWidgetAnonymousContainer = styled.div`
  position: sticky !important;
  bottom: 0;
  left: 0;
  z-index: 9998;
  width: 100%;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: center;
  width: 100%;
  padding: 8px 16px;
  margin: 0 auto;
  background-color: var(--color-primary-800);
`;

export const LinksWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 375px;
  height: 20px;
`;

export const Link = styled.button<{ $isHidden?: boolean }>`
  all: unset;
  display: ${({ $isHidden }) => ($isHidden ? 'none' : 'flex')};
  align-items: center;
  cursor: pointer;
`;
