import { FC } from 'react';

import LocaleFragment, { ILocaleText } from '@lib/tools/locale/views/LocaleFragment';

import { Image } from '@components/web/src/foundations/Image/Image';

export type IPreparationItem = {
  title: ILocaleText;
  subtitle: ILocaleText;
  description: ILocaleText;
  image: string;
};

const PreparationCard: FC<IPreparationItem> = ({ image, title, subtitle, description }) => {
  return (
    <div className="preparation-card-container">
      <Image alt={`preparation_${title}`} height="120px" objectFit="cover" src={image} width="100%" />
      <div className="content-container">
        <p className="subtitle">
          <LocaleFragment message={subtitle} />
        </p>
        <p className="title">
          <LocaleFragment message={title} />
        </p>
        <p className="description">
          <LocaleFragment message={description} />
        </p>
      </div>
    </div>
  );
};

export default PreparationCard;
