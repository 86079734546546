import { createSlice } from '@reduxjs/toolkit';

import { TProductCategory } from '@lib/core/products/types';
import { ILocaleText } from '@lib/tools/locale/views/LocaleFragment';

import { TSwiperData } from '@components/web/src/organisms/ExtraInfoSwiper/ExtraInfoSwiper';

const REGISTER_PAGE_SCENARIO_CHARACTER = 'character';
const REGISTER_PAGE_SCENARIO_ADJECTIVE = 'adjective';
const REGISTER_PAGE_SCENARIO_QUIZ_ACTION = 'quiz';
const REGISTER_PAGE_SCENARIO_WISHLIST_ACTION = 'wishlist';
const REGISTER_PAGE_SCENARIO_BANNER_ACTION = 'banner';

export enum RegisterPageScenarios {
  CharacterClick = REGISTER_PAGE_SCENARIO_CHARACTER,
  AdjectiveClick = REGISTER_PAGE_SCENARIO_ADJECTIVE,
  QuizAnswerClick = REGISTER_PAGE_SCENARIO_QUIZ_ACTION,
  WishlistClick = REGISTER_PAGE_SCENARIO_WISHLIST_ACTION,
  BannerClick = REGISTER_PAGE_SCENARIO_BANNER_ACTION,
}

export type TRegisterPageScenarioState = {
  description?: ILocaleText;
  extraDescription?: ILocaleText;
  locale?: Record<'localeIndex' | 'localeVariables', Record<string, any>>;
  scenario?:
    | RegisterPageScenarios.AdjectiveClick
    | RegisterPageScenarios.CharacterClick
    | RegisterPageScenarios.QuizAnswerClick
    | RegisterPageScenarios.WishlistClick
    | RegisterPageScenarios.BannerClick
    | undefined;
  showAnimation?: boolean;
  swiperData?: TSwiperData[];
  title?: ILocaleText;
  productCategory?: TProductCategory;
};

const initialState: TRegisterPageScenarioState = {
  description: '',
  extraDescription: '',
  locale: {
    localeIndex: {},
    localeVariables: {},
  },
  productCategory: '',
  scenario: undefined,
  showAnimation: false,
  swiperData: [],
  title: '',
};

const resultsSlice = createSlice({
  initialState,
  name: 'registerPageScenarioData',
  reducers: {
    actionResetRegisterPageScenarioData: () => {
      return { ...initialState };
    },

    actionSetRegisterPageScenarioData: (_, action) => {
      return { ...action.payload };
    },
  },
});

export default resultsSlice.reducer;

export const { actionResetRegisterPageScenarioData, actionSetRegisterPageScenarioData } = resultsSlice.actions;
