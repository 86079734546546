import cn from 'classnames';
import React, { FC, Fragment } from 'react';

import { IPreferenceExposure } from '@app/native/src/models/UserFoodPreferences.model';

import { TLanguage } from '@lib/core/retailers/types';
import { ICountrySelectOption } from '@lib/core/service/slices/technical/countries';
import { localeApp } from '@lib/tools/locale/source/web/app';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import { languages } from '@lib/tools/locale/utils/consts';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';

import Button from '@components/web/src/atoms/Buttons/Button';
import ProfileHintHeader from '@components/web/src/atoms/Headers/ProfileHintHeader/ProfileHintHeader';
import ProfileSectionHeader from '@components/web/src/atoms/Headers/ProfileSectionHeader/ProfileSectionHeader';
import ToggleButton from '@components/web/src/atoms/ToggleButton/ToggleButton';
import BackButton from '@components/web/src/components/Button/BackButton/BackButton';
import ProfilePictureSelection from '@components/web/src/templates/TasteId/TasteIdSettingsPage/ProfilePictureSelection/ProfilePictureSelection';
import UserInfoForm, { IProfileFormData } from '@components/web/src/templates/TasteId/TasteIdSettingsPage/UserInfoForm';

interface IProps {
  countryOptions: ICountrySelectOption[];
  currentLanguage: string;
  foodPreferencesState: IPreferenceExposure;
  profileFormData: IProfileFormData;
  profilePicture: string;
  profilePictureUpdateError: string;
  supportedLanguages: TLanguage[];
  shouldShowManageSection?: boolean;
  onToggleClick: (optionKey: string) => void;
  onExitBtnClick: () => void;
  onManageBtnClick: () => void;
  onPreferencesHintBtnClick: () => void;
  handleLanguageChange: (IB2CSupportedLanguages) => void;
  handleProfileFormSubmit: (data: IProfileFormData) => void;
  handleProfilePictureUpdateError: React.Dispatch<React.SetStateAction<string>>;
  handleProfilePictureUpdate: (base64ImageData: string) => void;
  handleBackBtnClick?: () => void;
}

const TasteIdSettingsPage: FC<IProps> = ({
  countryOptions,
  currentLanguage = languages.ENGLISH,
  foodPreferencesState,
  profileFormData,
  profilePicture,
  profilePictureUpdateError = '',
  supportedLanguages,
  shouldShowManageSection = false,
  onToggleClick,
  onExitBtnClick,
  onManageBtnClick,
  onPreferencesHintBtnClick,
  handleLanguageChange,
  handleProfileFormSubmit,
  handleProfilePictureUpdateError,
  handleProfilePictureUpdate,
  handleBackBtnClick,
}) => {
  const {
    settings,
    languageBtnGroup: { languageTitle },
  } = localeApp;

  const { back } = localeCommon.commonMessages;

  const {
    settingsTitle,
    settingsSubTitle,
    foodPreferencesTitle,
    foodPreferencesSubTitle,
    accountTitle,
    accountSubTitle,
    manageTextDescription,
    manageBtnText,
    exitBtnText,
  } = settings;

  return (
    <div className="settings-tab-container">
      <BackButton arrowSize="short" handleClick={handleBackBtnClick} text={back} />
      <ProfileSectionHeader description={settingsSubTitle} title={settingsTitle} />
      <div className="divider invisible" />
      <ProfilePictureSelection
        handleProfilePictureUpdate={handleProfilePictureUpdate}
        handleProfilePictureUpdateError={handleProfilePictureUpdateError}
        profilePicture={profilePicture}
        profilePictureUpdateError={profilePictureUpdateError}
      />
      <div className="divider" />
      <UserInfoForm
        countryOptions={countryOptions}
        handleProfileFormSubmit={handleProfileFormSubmit}
        profileFormData={profileFormData}
      />
      <div className="divider" />
      {supportedLanguages?.length >= 2 && (
        <>
          <section className="language-section">
            <p className="language-title">
              <LocaleFragment message={languageTitle} />
            </p>
            <div className="language-buttons">
              {supportedLanguages.map(supportedLang => {
                const isActive = currentLanguage === supportedLang.code;
                return (
                  <button
                    key={supportedLang.code}
                    className={cn('language-btn', { isActive })}
                    onClick={() => handleLanguageChange(supportedLang.code)}
                  >
                    {supportedLang.language}
                  </button>
                );
              })}
            </div>
          </section>
          <div className="divider" />
        </>
      )}
      <section className="food-preferences-section">
        <ProfileHintHeader
          subTitle={foodPreferencesSubTitle}
          title={foodPreferencesTitle}
          onHintBtnClick={onPreferencesHintBtnClick}
        />
        <div className="food-preferences-toggles">
          {Object.keys(foodPreferencesState)?.map((optionKey, index) => {
            const shouldDisplayDivider = Object.keys(foodPreferencesState).length === index + 1;
            return (
              <Fragment key={optionKey}>
                {shouldDisplayDivider && <div className="divider invisible" />}
                <ToggleButton
                  isToggled={foodPreferencesState[optionKey].isEnabled}
                  text={foodPreferencesState[optionKey].name}
                  onToggle={() => onToggleClick(optionKey)}
                />
              </Fragment>
            );
          })}
        </div>
      </section>
      <div className="divider" />
      <section className="account-settings-section">
        <div>
          <ProfileHintHeader subTitle={accountSubTitle} title={accountTitle} />
          <Button size="sm" text={exitBtnText} onClick={onExitBtnClick} />
        </div>
        {shouldShowManageSection && (
          <div>
            <ProfileHintHeader subTitle={manageTextDescription} />
            <Button size="sm" text={manageBtnText} onClick={onManageBtnClick} />
          </div>
        )}
      </section>
    </div>
  );
};

export default TasteIdSettingsPage;
