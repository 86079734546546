import { FC, useState } from 'react';

import { useRetailer } from '@lib/core/retailers/hooks/retailer';
import { useUser } from '@lib/core/users/hooks';
import UserUtils from '@lib/core/users/utils/users';
import RouteUtils from '@lib/tools/routes';
import { PAGES } from '@lib/tools/views/urls';

import BackgroundImage from '@components/web/src/assets/legacy/stairs.jpeg';
import ServiceInstance from '@components/web/src/atoms/ServiceInstance/ServiceInstance';
import { ExitModal } from '@components/web/src/templates/Modals/ExitModal/ExitModal';

interface IProps {
  isEnableRedirectionToApp: boolean;
}

const ServiceInstanceContainer: FC<IProps> = ({ isEnableRedirectionToApp }) => {
  const [openModal, setOpenModal] = useState(false);

  const { retailerName, retailerTags } = useRetailer();

  const retailerLogo = retailerTags?.headerLogo;

  const handleOnClose = () => {
    if (isEnableRedirectionToApp) {
      setOpenModal(true);
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const { isUserAuthenticated } = useUser();

  const navigateToApp = () => {
    let page = '';
    if (!UserUtils.isUserHasCharactersForEachRetailerLocationCategories()) {
      page = PAGES.vinhood.start;
    } else if (UserUtils.isUserHasCharactersForEachRetailerLocationCategories() && !isUserAuthenticated) {
      page = PAGES.vinhood.registration;
    } else {
      page = PAGES.vinhood.home;
    }

    RouteUtils.navigateToAppServiceInstance(page);
  };

  return (
    <>
      <ServiceInstance
        handleOnClose={handleOnClose}
        isEnableRedirectionToApp={isEnableRedirectionToApp}
        retailerLogo={retailerLogo}
      />
      {openModal && (
        <ExitModal
          backgroundImage={BackgroundImage}
          hideModal={handleCloseModal}
          isModalOpen={openModal}
          retailerName={retailerName}
          onClose={handleCloseModal}
          onHandleExit={navigateToApp}
        />
      )}
    </>
  );
};

export default ServiceInstanceContainer;
