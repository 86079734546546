import { FC, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { BANNER_POSITION_PRODUCT_DETAILS } from '@lib/core/banners/consts';
import { useBanners } from '@lib/core/banners/hooks';
import { useCharacters } from '@lib/core/characters/hooks';
import { parseCharacterDetails } from '@lib/core/characters/utils';
import { isCharacterByIdentifiers } from '@lib/core/characters/utils/filters';
import { useRecipes } from '@lib/core/productAttributes/hooks';
import { parseProductIdentities, parseProductInstance, parseProductPreparations } from '@lib/core/products/utils';
import { IS_FROM_PRODUCT_DETAILS_PAGE } from '@lib/core/service/consts';
import { useApp } from '@lib/core/service/hooks';
import { isShowPromoLabelFilter, prependBasename } from '@lib/core/service/utils';
import { useFeedback, useUser, useWishlist } from '@lib/core/users/hooks';
import { feedbackFilter, isProductInWishlistFilter } from '@lib/core/users/utils/filters';
import useDiscoveryQuizData from '@lib/tools/discoveryQuiz/hooks';
import { localeApp } from '@lib/tools/locale/source/web/app';
import { setUrlParams } from '@lib/tools/shared/helpers';
import { PRODUCT_CATEGORY_URL_PARAM } from '@lib/tools/shared/helpers/consts';
import { useSustainability } from '@lib/tools/sustainability/hooks';
import useGetProductInstance from '@lib/tools/views/hooks/useGetProductInstance';
import { PAGES } from '@lib/tools/views/urls';

import CharacteristicsPage from '@components/web/src/app/ProductDetails/Characteristics/Characteristics';
import TastingPage from '@components/web/src/app/ProductDetails/Tasting/Tasting';
import Spinner from '@components/web/src/components/GenericSpinner/GenericSpinner';
import ProductPage from '@components/web/src/pages/app/Product/AppProductPage';

const ProductPageContainer: FC = () => {
  const navigate = useNavigate();
  const { state: locationState } = useLocation();

  const { tabCharacteristicsText, tabTastingText } = localeApp.productPage;

  const { locale } = useApp();
  const { isUserAuthenticated, isUserHasAnyCharacter } = useUser();
  const { productInstanceData, isProductInstanceDataLoading } = useGetProductInstance();
  const { productCharacterId, productCategory, productBadges, productPerfectFor, productFormatIdentifier } =
    parseProductInstance({
      locale,
      productInstanceData,
    });

  const { recipesData, isRecipesDataLoading } = useRecipes(productCharacterId, productCategory);
  const { sustainabilityData, isSustainabilityDataLoading } = useSustainability(
    productCategory,
    productFormatIdentifier,
  );

  const { characters } = useCharacters();
  const translatedUserCharacterData = characters.find(character =>
    isCharacterByIdentifiers(character, [productCharacterId]),
  );

  const { sortedRelatedCharacterEssences } = parseCharacterDetails({ character: translatedUserCharacterData });

  const { feedbackData, handleUpdateFeedback } = useFeedback();
  // TODO: replace useWishlist to useProductList after full start using useProductList
  const { wishlistProductIds, handleUpdateWishlistProductList, isProductListLoading } = useWishlist();

  const { discoveryQuiz } = useDiscoveryQuizData();

  const preparationsData = useMemo(
    () =>
      productInstanceData &&
      parseProductPreparations({
        productInstanceData,
      }),
    [productInstanceData],
  );

  const productIdentitiesData = useMemo(
    () =>
      productInstanceData &&
      parseProductIdentities({
        locale,
        productInstanceData,
      }),
    [productInstanceData, locale],
  );

  const productDetailsBanners = useBanners({
    bannerPosition: BANNER_POSITION_PRODUCT_DETAILS,
    randomNumberOfBanners: 1,
  });

  const navigateToQuizSelectionPage = () => navigate(prependBasename(PAGES.vinhood.quiz.chooseTaste));

  const tabsData = {
    active: 'characteristics',
    tabs: [
      {
        children: (
          <CharacteristicsPage
            essencesData={sortedRelatedCharacterEssences}
            productCategory={productCategory}
            productDetailsBanners={productDetailsBanners}
            productIdentitiesData={productIdentitiesData}
            tagsData={productBadges}
          />
        ),
        tabCode: 'characteristics',
        tabText: tabCharacteristicsText,
      },
      {
        children: (
          <TastingPage
            perfectForData={productPerfectFor}
            preparationsData={preparationsData}
            productCategory={productCategory}
            recipesData={recipesData}
            sustainabilityData={sustainabilityData}
          />
        ),
        tabCode: 'tasting',
        tabText: tabTastingText,
      },
    ],
  };

  const onBackButtonClick = () => {
    navigate(locationState?.from);
    setUrlParams([
      { key: IS_FROM_PRODUCT_DETAILS_PAGE, value: 'true' },
      { key: PRODUCT_CATEGORY_URL_PARAM, value: productCategory },
    ]);
  };

  const isLoading =
    !productInstanceData ||
    isProductInstanceDataLoading ||
    !recipesData ||
    isRecipesDataLoading ||
    isSustainabilityDataLoading;

  return isLoading ? (
    <Spinner />
  ) : (
    <ProductPage
      shouldDisplayContactProducerBtn
      discoveryQuiz={discoveryQuiz}
      feedback={feedbackFilter(feedbackData, productInstanceData)}
      handleUpdateFeedback={handleUpdateFeedback}
      handleUpdateWishlistProductList={handleUpdateWishlistProductList}
      isFeedbackEnabled={isUserAuthenticated}
      isProductInWishlist={isProductInWishlistFilter(wishlistProductIds, productInstanceData)}
      isProductListLoading={isProductListLoading}
      isShowPromoLabel={isShowPromoLabelFilter(productInstanceData)}
      isUserHasAnyCharacter={isUserHasAnyCharacter}
      isWishlistEnabled={isUserAuthenticated}
      locale={locale}
      navigateToQuizSelectionPage={navigateToQuizSelectionPage}
      productInstanceData={productInstanceData}
      tabsProps={tabsData}
      onBackButtonClick={onBackButtonClick}
    />
  );
};

export default ProductPageContainer;
