import { FC, useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import QuizUtils from '@lib/core/quizzes/utils';
import {
  DISABLE_USER_CHARACTER_TOGGLE_URL_PARAM,
  IS_FROM_SITUATIONAL_TEST_URL_PARAM,
  isApplicationKiosk,
} from '@lib/core/service/consts';
import { useApp } from '@lib/core/service/hooks';
import { useProductPreferences } from '@lib/core/service/hooks/useProductPreferences';
import { prependBasename } from '@lib/core/service/utils';
import { useUser } from '@lib/core/users/hooks';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import {
  IS_FROM_CATALOG_PAGE_URL_PARAM,
  PREFERENCES_URL_PARAM,
  PRODUCT_CATEGORY_URL_PARAM,
  USER_NO_FOOD_PREFERENCES_DATA,
  USER_PRODUCT_PREFERENCES,
  USER_PRODUCT_PREFERENCES_FOOD_DATA,
} from '@lib/tools/shared/helpers/consts';
import {
  QUIZ_SLUG_NO_DIARY_PREFERENCES,
  QUIZ_SLUG_NO_GLUTEN_PREFERENCES,
  QUIZ_SLUG_NO_MEAT_PREFERENCES,
  QUIZ_SLUG_NO_PREFERENCES,
} from '@lib/tools/shared/utils/quizzes/consts';
import { useAddons, useRetailerDesignSet } from '@lib/tools/views/hooks';
import { useCustomStyles } from '@lib/tools/views/hooks/useCustomStyles';
import { PAGES } from '@lib/tools/views/urls';

import sketches_restrictions_dairy from '@components/web/src/assets/legacy/b2c/sketches_restrictions_dairy.png';
import sketches_restrictions_gluten from '@components/web/src/assets/legacy/b2c/sketches_restrictions_gluten.png';
import sketches_restrictions_meat from '@components/web/src/assets/legacy/b2c/sketches_restrictions_meat.png';
import sketches_restrictions_no_preferences from '@components/web/src/assets/legacy/b2c/sketches_restrictions_no.png';
import FoodHabits from '@components/web/src/templates/TestFlowsPages/FoodHabitsPage/FoodHabits';

const QuizFoodHabitsContainer: FC = () => {
  const { userProductsFoodPreferences, isUserProductPreferencesDataLoading } = useProductPreferences();
  const { isDevToolsEnabled } = useApp();
  const { customQuizBackgroundColor, customTextColor, customSecondaryColor } = useCustomStyles();

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { isDisableQuizNoGluten } = useAddons();

  const { isUserAuthenticated } = useUser();
  const { isDesignSetVinhoodApp, isDesignSetVinhoodExperience } = useRetailerDesignSet();
  const isFromSituationalTest = searchParams.get(IS_FROM_SITUATIONAL_TEST_URL_PARAM);
  const disableUserCharacterUrlParam = searchParams.get(DISABLE_USER_CHARACTER_TOGGLE_URL_PARAM);

  const { foodHabitsContainer } = localeCommon;

  const { foodPreferencesData: foodPreferencesDataFromLocalStorage } =
    JSON.parse(localStorage.getItem(USER_PRODUCT_PREFERENCES)) || {};

  let preferences = [];

  if (foodPreferencesDataFromLocalStorage?.length) {
    preferences = foodPreferencesDataFromLocalStorage;
  } else if (userProductsFoodPreferences?.length) {
    preferences = userProductsFoodPreferences;
  }

  const foodHabitsOptions = [
    {
      image: sketches_restrictions_no_preferences,
      isDisabled:
        preferences.includes(QUIZ_SLUG_NO_GLUTEN_PREFERENCES) ||
        preferences.includes(QUIZ_SLUG_NO_MEAT_PREFERENCES) ||
        preferences.includes(QUIZ_SLUG_NO_DIARY_PREFERENCES),
      isSelected: preferences.includes(USER_NO_FOOD_PREFERENCES_DATA),
      isVisible: true,
      slug: QUIZ_SLUG_NO_PREFERENCES,
      text: foodHabitsContainer.noPreference,
    },
    {
      image: sketches_restrictions_gluten,
      isDisabled: preferences.includes(USER_NO_FOOD_PREFERENCES_DATA),
      isSelected: preferences.includes(QUIZ_SLUG_NO_GLUTEN_PREFERENCES),
      isVisible: !isDisableQuizNoGluten,
      slug: QUIZ_SLUG_NO_GLUTEN_PREFERENCES,
      text: foodHabitsContainer.avoidGluten,
    },
    {
      image: sketches_restrictions_meat,
      isDisabled: preferences.includes(USER_NO_FOOD_PREFERENCES_DATA),
      isSelected: preferences.includes(QUIZ_SLUG_NO_MEAT_PREFERENCES),
      isVisible: true,
      slug: QUIZ_SLUG_NO_MEAT_PREFERENCES,
      text: foodHabitsContainer.avoidMeat,
    },
    {
      image: sketches_restrictions_dairy,
      isDisabled: preferences.includes(USER_NO_FOOD_PREFERENCES_DATA),
      isSelected: preferences.includes(QUIZ_SLUG_NO_DIARY_PREFERENCES),
      isVisible: true,
      slug: QUIZ_SLUG_NO_DIARY_PREFERENCES,
      text: foodHabitsContainer.avoidDairy,
    },
  ];

  const [answers, setAnswers] = useState(foodHabitsOptions);
  const isDisabledBtn = useMemo(() => !answers.some(answer => answer.isSelected), [answers]);

  const onAnswerSelectHandler = (id: string) => {
    if (id === QUIZ_SLUG_NO_PREFERENCES) {
      setAnswers(
        answers.map(answer =>
          answer.slug === id
            ? { ...answer, isDisabled: false, isSelected: true }
            : { ...answer, isDisabled: true, isSelected: false },
        ),
      );
    } else {
      setAnswers(
        answers
          .map(answer =>
            answer.slug === id
              ? { ...answer, isDisabled: false, isSelected: !answer.isSelected }
              : { ...answer, isDisabled: false },
          )
          .map(answer =>
            answer.slug === QUIZ_SLUG_NO_PREFERENCES ? { ...answer, isDisabled: true, isSelected: false } : answer,
          ),
      );
    }
  };

  useEffect(() => {
    const isAllAnswersNotSelected =
      answers.filter(answer => !answer.isDisabled && !answer.isSelected).length === answers.length - 1;

    if (isAllAnswersNotSelected) setAnswers(answers.map(answer => ({ ...answer, isDisabled: false })));
  }, [answers]);

  useEffect(() => {
    setAnswers(foodHabitsOptions);
  }, [isDisableQuizNoGluten]);

  const getUserPreferences = () => {
    const choices = answers.filter(answer => answer.isSelected).map(item => item.slug);
    const preferredQuizSlug = choices.length > 0 ? QuizUtils.getPreferredQuizSlug(choices) : QUIZ_SLUG_NO_PREFERENCES;

    if (preferredQuizSlug) {
      localStorage.setItem(
        USER_PRODUCT_PREFERENCES,
        JSON.stringify({
          [USER_PRODUCT_PREFERENCES_FOOD_DATA]:
            choices[0] === QUIZ_SLUG_NO_PREFERENCES ? [USER_NO_FOOD_PREFERENCES_DATA] : choices,
        }),
      );

      return navigate(
        prependBasename(
          `${
            isDesignSetVinhoodApp ? PAGES.vinhood.quiz.multiProduct : PAGES.vinhood.quiz.taste
          }?${PREFERENCES_URL_PARAM}=${preferredQuizSlug}`,
        ),
      );
    }
    return null;
  };

  useEffect(() => {
    if (userProductsFoodPreferences.length && isDesignSetVinhoodApp) {
      const userChoiceSlug = QuizUtils.getPreferredQuizSlug(userProductsFoodPreferences);
      const isFromCatalogPage = searchParams.get(IS_FROM_CATALOG_PAGE_URL_PARAM);
      const catalogPageProductCategory = searchParams.get(PRODUCT_CATEGORY_URL_PARAM);

      const queryParamsForCatalogPage = {
        [DISABLE_USER_CHARACTER_TOGGLE_URL_PARAM]: disableUserCharacterUrlParam ? true : '',
        [IS_FROM_CATALOG_PAGE_URL_PARAM]: isFromCatalogPage,
        [PRODUCT_CATEGORY_URL_PARAM]: catalogPageProductCategory,
      };

      const queryStringForCatalogPage = Object.entries(queryParamsForCatalogPage)
        .filter(([_, value]) => !!value)
        .map(([key, value]) => `${key}=${value}`)
        .join('&');

      navigate(
        `${prependBasename(PAGES.vinhood.quiz.multiProduct)}?${PREFERENCES_URL_PARAM}=${userChoiceSlug}&${
          isFromCatalogPage || isFromSituationalTest ? queryStringForCatalogPage : ''
        }`,
      );
    }
  }, [userProductsFoodPreferences]);

  const onGoBackClick = () => {
    let link;

    if (isDesignSetVinhoodApp) {
      link = PAGES.vinhood.start;
    } else if (isFromSituationalTest) {
      link = PAGES.vinhood.quiz.situational;
    } else {
      link = PAGES.vinhood.home;
    }

    navigate(prependBasename(link));
  };

  if (isUserAuthenticated && userProductsFoodPreferences.length > 0 && isUserProductPreferencesDataLoading) return null;

  return (
    <FoodHabits
      answers={answers}
      customColors={{ customQuizBackgroundColor, customSecondaryColor, customTextColor }}
      goBack={onGoBackClick}
      handleUserPreferences={getUserPreferences}
      isApplicationKiosk={isApplicationKiosk}
      isDesignSetVinhoodExperience={isDesignSetVinhoodExperience}
      isDevToolsEnabled={isDevToolsEnabled}
      isDisabledBtn={isDisabledBtn}
      onAnswerSelectHandler={onAnswerSelectHandler}
    />
  );
};

export default QuizFoodHabitsContainer;
