import styled from 'styled-components';

export const LearnMoreSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 32px 16px;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding: 16px 0;
`;

export const ListItem = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 8px 0;
`;
