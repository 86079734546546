import classNames from 'classnames';
import { FC } from 'react';

import { TCharacter } from '@lib/core/characters/types';
import { TProductCategory } from '@lib/core/products/types';
import { localeApp } from '@lib/tools/locale/source/web/app';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';
import {
  PRODUCT_CATEGORY_BEER,
  PRODUCT_CATEGORY_COFFEE,
  PRODUCT_CATEGORY_WINE,
} from '@lib/tools/shared/helpers/consts';
import { useDisclosure } from '@lib/tools/views/hooks';

import { ReactComponent as CollapseIcon } from '@components/web/src/assets/legacy/b2c/icons/icon_character_collapse.svg';
import { ReactComponent as ExpandIcon } from '@components/web/src/assets/legacy/b2c/icons/icon_character_expand.svg';
import UserCharacterInfoImage from '@components/web/src/organisms/Cards/MyTasteProfileCard/UserCharacterInfoImage';
import UserCharacterInfoItemSection from '@components/web/src/organisms/Cards/MyTasteProfileCard/UserCharacterInfoItemSection/UserCharacterInfoItemSection';
import UserCharacterPersonalizeSection from '@components/web/src/organisms/Cards/MyTasteProfileCard/UserCharacterPersonalizeSection';

type Props = {
  progress: number;
  characters: Record<TProductCategory, TCharacter> | Record<string, never>;
  onCharacterItemSectionClick: () => void;
  onPersonalizeSectionClick: () => void;
};

const MyTasteProfileCard: FC<Props> = ({
  progress,
  characters,
  onCharacterItemSectionClick,
  onPersonalizeSectionClick,
}) => {
  const [isOpen, { toggle }] = useDisclosure({ defaultIsOpen: true });
  const ToggleIcon = isOpen ? CollapseIcon : ExpandIcon;

  const { userCharacterInfo } = localeApp;

  return (
    <div className={classNames('my-taste-profile-card-container', { isOpen })}>
      <div aria-hidden className="header-container" onClick={toggle}>
        <p className="title">
          <LocaleFragment message={userCharacterInfo.tasteIdentityText} />
        </p>
        <div className="header-character-images">
          <UserCharacterInfoImage image={characters?.wine?.image} productCategory={PRODUCT_CATEGORY_WINE} />
          <UserCharacterInfoImage image={characters?.beer?.image} productCategory={PRODUCT_CATEGORY_BEER} />
          <UserCharacterInfoImage image={characters?.coffee?.image} productCategory={PRODUCT_CATEGORY_COFFEE} />
        </div>
        <ToggleIcon />
      </div>
      <div className="divider" />
      <div className="content">
        <UserCharacterInfoItemSection characters={characters} onClick={onCharacterItemSectionClick} />
        {progress < 100 ? (
          <>
            <div className="divider" />
            <UserCharacterPersonalizeSection progress={progress} onClick={onPersonalizeSectionClick} />
          </>
        ) : null}
      </div>
    </div>
  );
};

export default MyTasteProfileCard;
