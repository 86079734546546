import { FC, useMemo } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { useCharacters } from '@lib/core/characters/hooks';
import { parseCharacterDetails } from '@lib/core/characters/utils';
import { isCharacterByIdentifiers } from '@lib/core/characters/utils/filters';
import { useRecipes } from '@lib/core/productAttributes/hooks';
import { parseProductIdentities, parseProductInstance, parseProductPreparations } from '@lib/core/products/utils';
import { useRetailer } from '@lib/core/retailers/hooks/retailer';
import { IS_FROM_PRODUCT_DETAILS_PAGE, isApplicationKiosk } from '@lib/core/service/consts';
import { useApp } from '@lib/core/service/hooks';
import { createMobileKioskQRUrl, isShowPromoLabelFilter, prependBasename } from '@lib/core/service/utils';
import { useFeedback, useProductList, useUser, useWishlist } from '@lib/core/users/hooks';
import { useFidelityCard } from '@lib/core/users/hooks/useFidelityCard';
import { feedbackFilter, isProductInWishlistFilter } from '@lib/core/users/utils/filters';
import useDiscoveryQuizData from '@lib/tools/discoveryQuiz/hooks';
import { REGISTRATION_URL_PARAM } from '@lib/tools/shared/auths/helpers/consts';
import {
  CHARACTERS_URL_PARAM,
  IS_FROM_KIOSK_QR_URL_PARAM,
  PRODUCT_CATEGORY_WINE,
} from '@lib/tools/shared/helpers/consts';
import { useSustainability } from '@lib/tools/sustainability/hooks';
import { useAddons, useRetailerDesignSet } from '@lib/tools/views/hooks';
import useGetProductInstance from '@lib/tools/views/hooks/useGetProductInstance';
import { PAGES } from '@lib/tools/views/urls';

import Spinner from '@components/web/src/components/GenericSpinner/GenericSpinner';
import ProductPage from '@components/web/src/pages/ProductPage/ProductPage';

const ProductPageContainer: FC = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { state: locationState } = useLocation();

  const { locale } = useApp();
  const {
    isEnableLocationMapAddon,
    isEnableVusionAddon,
    isShowProductLocationAddon,
    isEnableTastePathAddon,
    isProductPDFAddon,
  } = useAddons();
  const { isDesignSetVinhoodExperience } = useRetailerDesignSet();
  const { isUserAuthenticated, isUserHasAnyCharacter } = useUser();

  const { isAuthenticatedRetailerAccess } = useRetailer();
  const isFromKioskQR = !!searchParams.get(IS_FROM_KIOSK_QR_URL_PARAM);
  const { isFidelityCardScanned } = useFidelityCard();

  const { productInstanceData, isProductInstanceDataLoading } = useGetProductInstance();
  const { productCharacterId, productCategory, productPerfectFor, productFormatIdentifier } = parseProductInstance({
    locale,
    productInstanceData,
  });

  const { recipesData, isRecipesDataLoading } = useRecipes(productCharacterId, productCategory);
  const { sustainabilityData, isSustainabilityDataLoading } = useSustainability(
    productCategory,
    productFormatIdentifier,
  );

  const { feedbackData, handleUpdateFeedback } = useFeedback();
  // TODO: remove useWishlist and useProductList after full start using useProductList
  const {
    wishlistProductIds: wishlistIds,
    handleUpdateWishlistProductList: handleUpdateWishlist,
    isProductListLoading: isWishlistLoading,
  } = useWishlist();
  const {
    wishlistProductIds: productListIds,
    handleUpdateWishlistProductList: handleUpdateProductList,
    isProductListLoading: isProductListWishlistLoading,
  } = useProductList();

  const wishlistProductIds = isApplicationKiosk ? productListIds : wishlistIds;
  const isProductListLoading = isApplicationKiosk ? isProductListWishlistLoading : isWishlistLoading;
  const handleUpdateWishlistProductList = isApplicationKiosk ? handleUpdateProductList : handleUpdateWishlist;

  const { discoveryQuiz } = useDiscoveryQuizData();

  const { characters } = useCharacters();
  const translatedUserCharacterData = characters.find(character =>
    isCharacterByIdentifiers(character, [productCharacterId]),
  );

  const navigateToQuizSelectionPage = () => navigate(prependBasename(PAGES.vinhood.quiz.chooseTaste));

  const navigateToRegistrationPage = (param?: string) =>
    navigate(
      prependBasename(PAGES.vinhood.registration, {
        ...(param && { [REGISTRATION_URL_PARAM]: param }),
      }),
      {
        state: { isFromResultPage: true },
      },
    );

  const { sortedRelatedCharacterEssences } = parseCharacterDetails({ character: translatedUserCharacterData });

  const mobileKioskQRUrl = createMobileKioskQRUrl();
  const shouldDisplayWidgetRegistrationButton =
    isAuthenticatedRetailerAccess && isDesignSetVinhoodExperience && !isUserAuthenticated;

  const preparationsData = useMemo(
    () =>
      productInstanceData &&
      parseProductPreparations({
        productInstanceData,
      }),
    [productInstanceData],
  );

  const productIdentitiesData = useMemo(
    () =>
      productInstanceData &&
      parseProductIdentities({
        locale,
        productInstanceData,
      }),
    [productInstanceData, locale],
  );

  const onBackButtonClick = () => {
    const paramSeparator = locationState?.from?.includes('?') ? '&' : '?';
    const url = `${locationState?.from}${paramSeparator}${IS_FROM_PRODUCT_DETAILS_PAGE}=true`;

    return isFromKioskQR || !locationState?.from ? navigate(prependBasename(PAGES.vinhood.catalog)) : navigate(url);
  };

  const navigateToCatalog = () =>
    navigate(prependBasename(PAGES.vinhood.catalog, { [CHARACTERS_URL_PARAM]: productCharacterId }));

  const isLoading =
    !productInstanceData ||
    isProductInstanceDataLoading ||
    !recipesData ||
    isRecipesDataLoading ||
    isSustainabilityDataLoading;

  return isLoading ? (
    <Spinner />
  ) : (
    <ProductPage
      discoveryQuiz={discoveryQuiz}
      essencesData={sortedRelatedCharacterEssences}
      feedback={feedbackFilter(feedbackData, productInstanceData)}
      handleUpdateFeedback={handleUpdateFeedback}
      handleUpdateWishlistProductList={handleUpdateWishlistProductList}
      isApplicationKiosk={isApplicationKiosk}
      isEnableLocationMapAddon={isEnableLocationMapAddon}
      isEnableVusionAddon={isEnableVusionAddon}
      isFeedbackEnabled={isEnableTastePathAddon}
      isProductInWishlist={isProductInWishlistFilter(wishlistProductIds, productInstanceData)}
      isProductListLoading={isProductListLoading}
      isProductPDFDownload={isProductPDFAddon && productCategory === PRODUCT_CATEGORY_WINE}
      isShowProductLocationAddon={isShowProductLocationAddon}
      isShowPromoLabel={isShowPromoLabelFilter(productInstanceData)}
      isUserHasAnyCharacter={isUserHasAnyCharacter}
      isWishlistEnabled={(isApplicationKiosk && isFidelityCardScanned) || (isUserAuthenticated && !isApplicationKiosk)}
      locale={locale}
      mobileKioskQRUrl={mobileKioskQRUrl}
      navigateToCatalog={navigateToCatalog}
      navigateToQuizSelectionPage={navigateToQuizSelectionPage}
      navigateToRegistrationPage={navigateToRegistrationPage}
      perfectForData={productPerfectFor}
      preparationsData={preparationsData}
      productCategory={productCategory}
      productIdentitiesData={productIdentitiesData}
      productInstanceData={productInstanceData}
      recipesData={recipesData}
      shouldDisplayWidgetRegistrationButton={shouldDisplayWidgetRegistrationButton}
      sustainabilityData={sustainabilityData}
      onBackButtonClick={onBackButtonClick}
    />
  );
};

export default ProductPageContainer;
