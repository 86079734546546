import { styled } from 'styled-components';

import { STATIC_SHADOWS } from '@components/web/src/foundations';

export const HeaderFooterKioskContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
  overflow-y: scroll;
  scrollbar-width: none;
  background-color: var(--color-secondary-100);
  box-shadow: ${STATIC_SHADOWS.xl};

  &::-webkit-scrollbar {
    width: 0;
  }
`;

export const BodyContainer = styled.div`
  display: flex;
  flex: 1 1;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
`;
