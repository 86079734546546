import { FC } from 'react';

import { TProductCategory, TProductInstance } from '@lib/core/products/types';
import { TRetailerLocationStoreType } from '@lib/core/retailers/types';
import {
  RegisterPageScenarios,
  TRegisterPageScenarioState,
} from '@lib/core/service/slices/technical/registerPageScenario';
import { MP_POSITION_CONTEXT } from '@lib/tools/dat/mixpanel/consts';
import { localeApp } from '@lib/tools/locale/source/web/app';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import { PRODUCT_CARD_VARIANTS } from '@lib/tools/shared/helpers/consts';

import { STATIC_COLORS } from '@components/web/src/foundations';
import { Text } from '@components/web/src/foundations/Text/Text';
import ProductCard from '@components/web/src/organisms/Cards/ProductCard/ProductCard';
import * as S from '@components/web/src/organisms/TestResult/ProductSection/styles';

interface IProps {
  locale: string;
  storeType: TRetailerLocationStoreType;
  productInstanceData: TProductInstance;
  productCategory: TProductCategory;
  retailerLocationLogo?: string;
  handleWishlistClick?: (payload: TRegisterPageScenarioState) => void;
}

const ProductSection: FC<IProps> = ({
  locale,
  storeType,
  productInstanceData,
  productCategory,
  retailerLocationLogo,
  handleWishlistClick,
}) => {
  const {
    productSectionDescription,
    productSectionModalTitle,
    productSectionModalExtraDescription,
    productSectionTitle,
  } = localeApp.testResultPage;

  const { productCategories, productCategoriesPlural } = localeCommon;

  const wishListScenarioResultPageData: TRegisterPageScenarioState = {
    description: productSectionDescription,
    extraDescription: productSectionModalExtraDescription,
    locale: {
      localeIndex: { productCategory },
      localeVariables: {
        productCategoryPluralText: productCategoriesPlural[productCategory],
        productCategoryText: productCategories[productCategory],
      },
    },
    scenario: RegisterPageScenarios.WishlistClick,
    showAnimation: false,
    title: productSectionModalTitle,
  };

  return (
    <S.ProductsSection data-testid={`ProductsSection-${productCategory}-${storeType}`}>
      <S.AnimatedWrapper>
        {retailerLocationLogo && <img alt="retailer logo" src={retailerLocationLogo} />}
        <Text
          color={STATIC_COLORS.base.black}
          fontFamily="Fraunces"
          localeIndex={{ productCategory }}
          localeVariables={{ productCategoryText: productCategories[productCategory] }}
          size="h5"
          text={productSectionTitle}
          weight="semibold"
        />
      </S.AnimatedWrapper>
      <ProductCard
        isProductCardClickDisabled
        isResponsive
        isWishlistEnabled
        handleUpdateWishlistProductList={() => handleWishlistClick(wishListScenarioResultPageData)}
        isLoading={false}
        isProductInWishlist={false}
        locale={locale}
        mixpanelPositionContext={MP_POSITION_CONTEXT.TEST_RESULT_PAGE}
        productInstanceData={productInstanceData}
        storeType={storeType}
        variant={PRODUCT_CARD_VARIANTS.COMPACT}
      />
    </S.ProductsSection>
  );
};

export default ProductSection;
