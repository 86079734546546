import { FC } from 'react';

import { TCharacter } from '@lib/core/characters/types';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';

import CharacterMapCard from '@components/web/src/components/Character/CharactersMap/CharacterMapCard';

interface IProps {
  lang?: string;
  characterSubCategoryText: string;
  characters: TCharacter[];
}

const CharactersMap: FC<IProps> = ({ characterSubCategoryText, characters, lang }) => {
  return (
    <div className="characters-map-container">
      <div className="characters-header">
        <LocaleFragment message={characterSubCategoryText} />
      </div>
      <div className="characters-wrapper">
        {characters.map((item, idx) => (
          <CharacterMapCard {...item} key={idx} lang={lang} />
        ))}
      </div>
    </div>
  );
};

export default CharactersMap;
