import styled from 'styled-components';

import CloseButton from '@components/web/src/components/Button/CloseButton/CloseButton';
import { STATIC_COLORS } from '@components/web/src/foundations';

export const ServiceInstanceContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 500px;
  height: 50px;
  padding: 10px 16px;
  background-color: var(--color-secondary-200);
  border-top: 1px solid ${STATIC_COLORS.warmGray['600']};
  border-bottom: 1px solid ${STATIC_COLORS.warmGray['600']};
`;

export const StyledCloseButton = styled(CloseButton)`
  width: 24px;
  height: 24px;
  cursor: pointer;
`;
