import { css, styled } from 'styled-components';

import { ROOT_ELEMENT_KIOSK } from '@components/web/src/foundations/consts';

export const NoProductsAvailableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
  background: ${({ theme }) => theme?.colors?.secondaryColor?.['-100']};
  border-radius: 16px 16px 0 0;
  gap: ${({ theme }) => (theme?.rootElementType === ROOT_ELEMENT_KIOSK ? '80px' : '32px')};
`;

export const Title = styled.p`
  margin: 0px;
  text-align: center;
`;

export const ImageWrapper = styled.div`
  background-repeat: no-repeat;
  background-position: center;
  background-size: 150% 150%;

  ${({ theme }) => {
    if (theme?.rootElementType === ROOT_ELEMENT_KIOSK) {
      return css`
        width: 430px;
        height: 453px;
      `;
    }

    return css`
      width: 187px;
      height: 197px;
    `;
  }}
`;
