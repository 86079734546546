import classNames from 'classnames';
import { ReactNode, useEffect, useRef } from 'react';

import Portal from '@lib/tools/views/web/components/Portal';

type ModalProps = {
  isOpen: boolean;
  isApplicationKiosk?: boolean;
  children: ReactNode;
  modalClassName?: string;
  portalClassName?: string;
  hideModal?: () => void;
  fullWidthModal?: boolean;
  isAllowContentScrolling?: boolean;
  centerContent?: boolean;
};

const ModalWrapper = ({
  isOpen = true,
  isApplicationKiosk = false,
  children,
  hideModal,
  centerContent = true,
  modalClassName,
  portalClassName,
  fullWidthModal = false,
  isAllowContentScrolling = false,
}: ModalProps) => {
  const modalRef = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    const rootElement = document.querySelector('#root') as HTMLDivElement;
    if (isOpen && rootElement && !isAllowContentScrolling) {
      rootElement.style.overflowY = 'hidden';
      rootElement.style.height = '100vh';
    }
    return () => {
      if (rootElement) {
        rootElement.style.overflowY = 'unset';
        rootElement.style.height = '100%';
      }
    };
  }, [isOpen]);

  if (!isOpen) {
    return null;
  }

  const onModalClickHandler = e => {
    e.stopPropagation();

    const checkDiv = document.getElementById('modal-wrap');
    const closeModal = e.target === checkDiv;
    // closemodal if backdrop is clicked
    if (closeModal) hideModal();
  };

  return (
    <Portal className={classNames('modal-overlay-container', portalClassName)}>
      <div
        ref={modalRef}
        id="modal-wrap"
        role="presentation"
        className={classNames('modal-overlay-wrapper', modalClassName, {
          isKiosk: isApplicationKiosk,

          'modal-center-position': centerContent,
          'modal-width-full': fullWidthModal,
        })}
        onClick={e => onModalClickHandler(e)}
        onKeyDown={onModalClickHandler}
      >
        <div className="modal-wrapper">{children}</div>
      </div>
    </Portal>
  );
};

export default ModalWrapper;
