/* eslint-disable no-plusplus */
import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { useCharacters } from '@lib/core/characters/hooks';
import { charactersByProductCategory } from '@lib/core/characters/utils';
import { useQuiz, useUserQuiz, useUserQuizType } from '@lib/core/quizzes/hooks';
import { actionPatchUserQuizAssociateUser, actionResetUserQuiz } from '@lib/core/quizzes/slices';
import { useRetailerLocation } from '@lib/core/retailers/hooks/retailerLocation';
import { isApplicationKiosk } from '@lib/core/service/consts';
import { useApp } from '@lib/core/service/hooks';
import { useServiceInstance } from '@lib/core/service/hooks/useServiceInstance';
import { selectAppAgreements } from '@lib/core/service/selectors/technical';
import { setDisplayTastePathStartPage, setIsTasteIdGuideShown } from '@lib/core/service/slices';
import {
  actionFetchSelectedUserProductPreferences,
  actionUpdateUserProductPreferences,
} from '@lib/core/service/slices/productPreferencesSlice';
import { resetTastePathData } from '@lib/core/service/slices/tastePathProductsSlice';
import { useUser } from '@lib/core/users/hooks';
import { useFidelityCard } from '@lib/core/users/hooks/useFidelityCard';
import { actionGetFeedbacks } from '@lib/core/users/slices/feedbacks';
import { actionGetProductList } from '@lib/core/users/slices/productList';
import { actionGenerateUserSession, actionGetUserData, actionSetUserCharacters } from '@lib/core/users/slices/user';
import { fetchAllWishlist } from '@lib/core/users/slices/wishlist';
import MixpanelTracker from '@lib/tools/dat/mixpanel';
import RouteUtils from '@lib/tools/routes';
import {
  CHARACTERS_URL_PARAM,
  EXPOSURE_PREFERENCES,
  IS_FROM_KIOSK_QR_URL_PARAM,
  PRODUCT_LIST_WISHLIST,
  USER_PRODUCT_PREFERENCES,
} from '@lib/tools/shared/helpers/consts';
import { useAddons, useEffectSkipFirst, useRetailerDesignSet } from '@lib/tools/views/hooks';
import { useTypedDispatch } from '@lib/tools/views/hooks/useTypedDispatch';
import { PAGES } from '@lib/tools/views/urls';

const UserProvider = ({ children }) => {
  const dispatch = useTypedDispatch();
  const [searchParams] = useSearchParams();

  const {
    handleResetUserData,
    isUserAuthenticated,
    isUserKioskAdmin: userIsKioskAdmin,
    userId,
    userCharacters,
    userSessionId,
    isUserFullUserDataLoadedTemporaryHint,
    isUserHasAnyCharacter,
    isUserHasCharacterForCurrentPC,
  } = useUser();
  const userCharactersIndexedByProductCategory = charactersByProductCategory(userCharacters);
  const { isQuizFetching } = useQuiz();
  const { isUserQuizComplete } = useUserQuiz();
  const { isUserQuizTypeSituational } = useUserQuizType();
  const { isDesignSetVinhoodExperience } = useRetailerDesignSet();
  const { locale } = useApp();
  const { retailerLocationId } = useRetailerLocation();
  const { characters } = useCharacters();
  const { isEnableTastePathAddon } = useAddons();
  const { isServiceInstanceFeatureEnabled } = useServiceInstance();
  const agreements = useSelector(selectAppAgreements);
  const { fidelityCardId } = useFidelityCard();

  const { foodPreferencesData } = JSON.parse(localStorage.getItem(USER_PRODUCT_PREFERENCES)) || {};

  const kioskUserCharacterForAssociatingWithMobileKiosk = useMemo(
    () => characters.find(character => character.identifier === searchParams.get(CHARACTERS_URL_PARAM)),
    [characters],
  );

  const shouldAssociateKioskCharacterWithMobileKiosk =
    !!searchParams.get(IS_FROM_KIOSK_QR_URL_PARAM) &&
    searchParams.get(CHARACTERS_URL_PARAM) &&
    !isUserHasCharacterForCurrentPC &&
    kioskUserCharacterForAssociatingWithMobileKiosk;

  // const shouldAssociateKioskFidelityCardWithMobileKiosk =
  //   !!searchParams.get(IS_FROM_KIOSK_QR_URL_PARAM) && searchParams.get(FIDELITY_ID_URL_PARAM);

  const hideTasteIdGuide = () => dispatch(setIsTasteIdGuideShown(true));

  // this function is used to fetch the user related data
  const fetchUserRelatedData = () => {
    /** We use Wishlist feature for:
     *  Widget and App applications - for authenticated users */
    dispatch(fetchAllWishlist()).then(res => {
      const { payload = [] } = res;
      if (payload.length > 0) {
        hideTasteIdGuide();
      }
    });

    /** We use Feedback feature for:
     *  Taste Path application - for all users.
     *  Widget and App applications - for authenticated users */
    dispatch(actionGetFeedbacks()).then(res => {
      const { payload: { results = [] } = {} } = res;
      if (results.length > 0) {
        hideTasteIdGuide();
      }
    });
  };

  useEffect(() => {
    if (isApplicationKiosk && retailerLocationId && locale && fidelityCardId) {
      /** We use Product list feature for:
       *  Kiosk - for fidelity card users */
      dispatch(actionGetProductList({ listName: PRODUCT_LIST_WISHLIST }));
    }
  }, [retailerLocationId, locale, fidelityCardId]);

  // * this use Effect runs only once after the first render of the component
  // * it is used to fetch the user data and the wishlist data
  useEffect(() => {
    if (isUserAuthenticated && !isApplicationKiosk) {
      dispatch(actionGetUserData()).then(() => fetchUserRelatedData());
    }

    if (!userSessionId) {
      dispatch(actionGenerateUserSession());
    }
  }, []);

  // * this use Effect runs only once after the first render of the component
  // * and skip the first render to avoid to make conflict with above useEffect
  // * it is used to fetch the user data and the wishlist data after the user make the login
  useEffectSkipFirst(() => {
    if (isUserAuthenticated && !isApplicationKiosk) {
      dispatch(actionGetUserData()).then(() => {
        MixpanelTracker.events.userLogin(false, false);
        if (!isApplicationKiosk) {
          fetchUserRelatedData();
          if (isEnableTastePathAddon) dispatch(setDisplayTastePathStartPage(true));
        }
      });

      if (isUserQuizComplete && !isQuizFetching && !isUserQuizTypeSituational) {
        dispatch(actionGetUserData());
      }
    } else {
      handleResetUserData();
      dispatch(actionResetUserQuiz());
    }
  }, [isUserAuthenticated]);

  useEffectSkipFirst(() => {
    dispatch(actionGenerateUserSession());
  }, [fidelityCardId]);

  // Login / signup
  useEffect(() => {
    if (isApplicationKiosk) return;

    if (isUserFullUserDataLoadedTemporaryHint && isUserQuizComplete) {
      (async () => {
        if (!isUserHasAnyCharacter) {
          await dispatch(actionPatchUserQuizAssociateUser());
        }

        // setting food preferences on user registration
        // userSessionId is available only after a login and not a registration
        if (foodPreferencesData) {
          dispatch(
            actionUpdateUserProductPreferences({
              exposure: EXPOSURE_PREFERENCES,
              isEnabled: true,
              isRegistrationAction: false,
              isWidgetUpdating: isDesignSetVinhoodExperience,
              slug: foodPreferencesData,
            }),
          ).then(() => {
            localStorage.setItem(USER_PRODUCT_PREFERENCES, JSON.stringify({ foodPreferencesData: undefined }));
            return dispatch(actionFetchSelectedUserProductPreferences(userSessionId));
          });
        }
      })();
    }
  }, [userId, isUserFullUserDataLoadedTemporaryHint]);

  useEffectSkipFirst(() => {
    if (isUserAuthenticated && !userIsKioskAdmin) {
      dispatch(actionGetUserData()).then(() => {
        /** Navigation for the Service Instance feature after authenticating into the account and receiving all data */
        if (isServiceInstanceFeatureEnabled) {
          RouteUtils.navigateToWidgetServiceInstance(PAGES.vinhood.legal.privacy);
        }
      });
    }
  }, [agreements]);

  useEffect(() => {
    if (userSessionId) {
      dispatch(resetTastePathData());
      dispatch(setDisplayTastePathStartPage(true));

      const isNonKioskUserOverKioskEnv = isApplicationKiosk && !userIsKioskAdmin;
      const isKioskUserOverNonKioskEnv = !isApplicationKiosk && userIsKioskAdmin;
      if ((userId && isNonKioskUserOverKioskEnv) || isKioskUserOverNonKioskEnv) {
        handleResetUserData();
      }
    }
  }, [userIsKioskAdmin, userSessionId]);

  useEffect(() => {
    if (shouldAssociateKioskCharacterWithMobileKiosk) {
      dispatch(actionSetUserCharacters([kioskUserCharacterForAssociatingWithMobileKiosk]));
    }
  }, [shouldAssociateKioskCharacterWithMobileKiosk]);

  // TODO refactor this commented code to support the fidelity card into the mobile kiosk
  // useEffect(() => {
  //   if (shouldAssociateKioskFidelityCardWithMobileKiosk) {
  //     dispatch(scanCode(String(searchParams.get(FIDELITY_ID_URL_PARAM))));
  //   }
  // }, [shouldAssociateKioskFidelityCardWithMobileKiosk]);

  useEffect(() => {
    const translatedUserCharacters = { ...userCharactersIndexedByProductCategory };

    let charactersShouldBeTranslated = false;
    userCharacters.forEach(character => {
      const characterName = character?.name || '';
      const characterId = character?.identifier || '';
      const characterProductCategory = character?.product_category || '';

      // It means that current characters data doesn't match expected for current language
      if (characterName && characters[characterId]?.name && characterName !== characters[characterId]?.name) {
        charactersShouldBeTranslated = true;
        translatedUserCharacters[characterProductCategory] = characters[characterId];
      }
    });

    if (charactersShouldBeTranslated) {
      dispatch(actionSetUserCharacters([translatedUserCharacters]));
    }
  }, [locale, characters, isUserFullUserDataLoadedTemporaryHint]);

  return children;
};

export default UserProvider;
