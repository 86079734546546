import { PayloadAction, createSlice, isAnyOf } from '@reduxjs/toolkit';

import { TProductInstance } from '@lib/core/products/types';
import { IRequestParams, getProductsListData } from '@lib/core/products/utils';
import { selectAppliedAddOns } from '@lib/core/retailers/selectors/retailerLocation';
import { GPRL_CHARACTER_QUERY, GPRL_PROMOTIONS_QUERY } from '@lib/core/service/consts';
import { createTypedAsyncThunk } from '@lib/core/service/createTypedAsyncThunk';
import { shuffleProductsInsideRankGroups } from '@lib/core/service/utils';
import { FILTER_TYPE_ORDERING, ORDERING_TYPE_RANK, PROMOTION_FEATURED_SLUG } from '@lib/tools/shared/helpers/consts';
import { parseThunkError } from '@lib/tools/shared/helpers/utils';

import { KIOSK_RESULT_PAGE_MAX_PRODUCTS } from '@components/web/src/kiosk/consts';

interface IFetchSituationalResultPageProducts {
  charactersIdentifiers: string[];
  params: IRequestParams;
}

interface IKioskRootState {
  isAnonymousPreference: boolean | null;
  resultPageProducts: {
    isProductsLoading: boolean;
    products: TProductInstance[][];
    requestError: string;
  };
}

const initialState: IKioskRootState = {
  isAnonymousPreference: null,
  resultPageProducts: {
    isProductsLoading: false,
    products: [],
    requestError: '',
  },
};

export const fetchProducts = async (
  params: IRequestParams,
  isFeatureProductsAddon: boolean,
  uniqueResultNumber: number = KIOSK_RESULT_PAGE_MAX_PRODUCTS,
) => {
  params[FILTER_TYPE_ORDERING] = ORDERING_TYPE_RANK;
  const { results: products = [] } = await getProductsListData({
    ...params,
    ...(isFeatureProductsAddon && { [GPRL_PROMOTIONS_QUERY]: PROMOTION_FEATURED_SLUG }),
  });
  const featuredShuffledRankProducts = shuffleProductsInsideRankGroups(products)?.slice(0, uniqueResultNumber);

  const missedProductsQuantity = uniqueResultNumber - featuredShuffledRankProducts.length;

  if (!missedProductsQuantity || !isFeatureProductsAddon) {
    return featuredShuffledRankProducts;
  }

  const randomProductsIDs = featuredShuffledRankProducts.map(product => product.identifier);

  const { results: missedProducts = [] } = await getProductsListData({
    ...params,
  });
  const filteredMissedProducts = missedProducts.filter(product => !randomProductsIDs.includes(product.identifier));
  const missedShuffledRankedProducts = [...shuffleProductsInsideRankGroups(filteredMissedProducts)]?.slice(
    0,
    missedProductsQuantity,
  );

  return [...featuredShuffledRankProducts, ...missedShuffledRankedProducts];
};

export const fetchResultPageProducts = createTypedAsyncThunk(
  'kioskRoot/fetchResultPageProducts',
  async (params: IRequestParams, thunkAPI) => {
    const { isFeatureProductsAddon } = selectAppliedAddOns(thunkAPI.getState());

    const products = (await fetchProducts(params, isFeatureProductsAddon)) as TProductInstance[];
    return [products];
  },
);

export const fetchSituationalResultPageProducts = createTypedAsyncThunk(
  'kioskRoot/fetchSituationalResultPageProducts',
  async ({ charactersIdentifiers, params }: IFetchSituationalResultPageProducts, thunkAPI) => {
    const { isFeatureProductsAddon } = selectAppliedAddOns(thunkAPI.getState());

    const productsPromises = charactersIdentifiers.map(characterId =>
      fetchProducts({ ...params, [GPRL_CHARACTER_QUERY]: characterId }, isFeatureProductsAddon),
    );
    const products: TProductInstance[][] = await Promise.all(productsPromises);
    return products;
  },
);

const kioskRootSlice = createSlice({
  extraReducers: builder => {
    builder
      .addMatcher(isAnyOf(fetchResultPageProducts.pending, fetchSituationalResultPageProducts.pending), state => {
        state.resultPageProducts = { ...state.resultPageProducts, isProductsLoading: true };
      })
      .addMatcher(
        isAnyOf(fetchResultPageProducts.fulfilled, fetchSituationalResultPageProducts.fulfilled),
        (state, action) => {
          state.resultPageProducts = {
            ...state.resultPageProducts,
            isProductsLoading: false,
            products: action.payload,
          };
        },
      )
      .addMatcher(
        isAnyOf(fetchResultPageProducts.rejected, fetchSituationalResultPageProducts.rejected),
        (state, action: any) => {
          state.resultPageProducts = {
            ...state.resultPageProducts,
            isProductsLoading: false,
            requestError: parseThunkError(action),
          };
        },
      );
  },
  initialState,
  name: 'kioskApp',
  reducers: {
    resetResultPageProducts(state) {
      state.resultPageProducts = initialState.resultPageProducts;
    },
    setIsAnonymousPreference(state, action: PayloadAction<boolean>) {
      state.isAnonymousPreference = action.payload;
    },
  },
});

export const { setIsAnonymousPreference, resetResultPageProducts } = kioskRootSlice.actions;
export default kioskRootSlice.reducer;
