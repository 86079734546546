import styled from 'styled-components';

import { STATIC_COLORS } from '@components/web/src/foundations';

export const RegistrationBanner = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  width: 100%;
  text-align: center;
  background: ${STATIC_COLORS.base.white};
  box-shadow: 0px -8px 18px 0px rgba(16, 24, 40, 0.15);
`;
