import classNames from 'classnames';
import { ComponentType, FC, SVGProps } from 'react';
import QRCode from 'react-qr-code';

import { ModalTypes, isApplicationKiosk } from '@lib/core/service/consts';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import { ILocaleText } from '@lib/tools/locale/views/LocaleFragment';
import { VH_VARIANTS } from '@lib/tools/shared/helpers/consts';

import { ReactComponent as FindItModalIcon } from '@components/web/src/assets/legacy/icons/findIt_modal_icon.svg';
import { ReactComponent as sketchLocationModalIcon } from '@components/web/src/assets/legacy/icons/sketch_location.svg';
import { ReactComponent as sketchTimesLargeIcon } from '@components/web/src/assets/legacy/icons/sketch_timer.svg';
import { ReactComponent as sketchBottles } from '@components/web/src/assets/legacy/icons/sketches_bottles_large.svg';
import { ReactComponent as curiousModalIcon } from '@components/web/src/assets/legacy/icons/sketches_curious.svg';
import { ReactComponent as GiftModalIcon } from '@components/web/src/assets/legacy/icons/sketches_gift.svg';
import { ReactComponent as occasionModalIcon } from '@components/web/src/assets/legacy/icons/sketches_occasion.svg';
import { ReactComponent as questionModalIcon } from '@components/web/src/assets/legacy/icons/sketches_question.svg';
import Button from '@components/web/src/atoms/Buttons/Button';
import CloseButton from '@components/web/src/components/Button/CloseButton/CloseButton';
import { STATIC_COLORS } from '@components/web/src/foundations';
import * as S from '@components/web/src/templates/Modals/Events/EventsModal/styles';
import ModalWrapper from '@components/web/src/templates/Modals/Events/ModalWrapper';

export type IconType = 'none' | 'Location' | 'Time' | 'Occasion' | 'Curious' | 'Question' | 'Bottles' | 'Gift';
export type ModalType = (typeof ModalTypes)[keyof typeof ModalTypes];
interface EventsModalProps {
  icon?: IconType;
  title?: ILocaleText;
  titleTextLeftAlignGreen?: boolean;
  descriptionTextLeftAlign?: boolean;
  description?: ILocaleText;
  locationImageUrl?: string;
  qrCodeUrl?: string;
  hideModal: () => void;
  handleClick?: () => void;
  isOpen: boolean;
  withButton?: boolean;
  buttonText?: ILocaleText;
  modalType: ModalType;
  creditTitle?: string;
  titleLocaleIndex?: Record<string, string>;
}

const iconComponents: Record<IconType, ComponentType<SVGProps<SVGSVGElement>>> = {
  Bottles: sketchBottles,
  Curious: curiousModalIcon,
  Gift: GiftModalIcon,
  Location: sketchLocationModalIcon,
  Occasion: occasionModalIcon,
  Question: questionModalIcon,
  Time: sketchTimesLargeIcon,
  none: () => null,
};

const EventsModal: FC<EventsModalProps> = ({
  isOpen,
  title,
  icon = 'none',
  description,
  descriptionTextLeftAlign = false,
  titleTextLeftAlignGreen = false,
  locationImageUrl,
  qrCodeUrl,
  buttonText,
  withButton = true,
  hideModal,
  handleClick,
  modalType,
  creditTitle,
  titleLocaleIndex,
}) => {
  const { commonMessages, findItModal } = localeCommon;

  const IconComponent = iconComponents[icon];

  const renderModalContent = () => {
    switch (modalType) {
      case ModalTypes.InformationModal:
        return (
          <>
            {creditTitle && <S.CreditTitleText size="body2" text={creditTitle} weight="medium" />}
            {title && (
              <S.TitleText
                $isLeftAlignedGreen={titleTextLeftAlignGreen}
                localeIndex={titleLocaleIndex}
                size="h6"
                text={title}
                weight="semibold"
              />
            )}
            {description && (
              <S.DescriptionText
                $isLeftAlignedDescription={descriptionTextLeftAlign}
                size={descriptionTextLeftAlign ? 'body1' : 'subtitle1'}
                text={description}
                weight={descriptionTextLeftAlign ? 'normal' : 'medium'}
              />
            )}
            {withButton && (
              <Button
                className="modal-close-btn"
                fontSize="body1"
                size="sm"
                text={buttonText || commonMessages.close}
                textWeight="medium"
                variant={VH_VARIANTS.LIGHT}
                onClick={handleClick || hideModal}
              />
            )}
          </>
        );
      case ModalTypes.LocationMapModal:
        return (
          <>
            {title && <S.TitleText size="h6" text={title} weight="semibold" />}
            {description && (
              <S.DescriptionText
                $isLocationMap
                $isLeftAlignedDescription={descriptionTextLeftAlign}
                size={descriptionTextLeftAlign ? 'body1' : 'subtitle1'}
                text={description}
                weight={descriptionTextLeftAlign ? 'normal' : 'medium'}
              />
            )}
            {locationImageUrl && (
              <div className="product-location-map">
                <img alt="centerImage" src={locationImageUrl} />
              </div>
            )}
            {withButton && (
              <Button
                className="modal-close-btn"
                fontSize="body1"
                text={buttonText || commonMessages.close}
                textWeight="medium"
                variant={VH_VARIANTS.PRIMARY}
                onClick={handleClick || hideModal}
              />
            )}
          </>
        );
      case ModalTypes.FindMeModal:
        return (
          <div className="content-wrapper">
            <S.ContentHeaderSection>
              <S.TitleText
                $noMargin
                fontFamily="Fraunces"
                size="h5"
                text={title || findItModal.titleText}
                weight="semibold"
              />
              <FindItModalIcon className="icon" />
            </S.ContentHeaderSection>
            <S.ContentBodySection>
              {description && <S.BodyTextTitle size="subtitle1" text={description} weight="medium" />}
            </S.ContentBodySection>
            <div className="btn-wrapper">
              <Button
                className="find-it-btn"
                size="sm"
                text={buttonText || commonMessages.close}
                variant={VH_VARIANTS.PRIMARY}
                onClick={handleClick || hideModal}
              />
            </div>
          </div>
        );
      case ModalTypes.PromoQrModal:
        return (
          <>
            {IconComponent && (
              <IconComponent className={classNames('modal-icon', { 'bottles-icon': icon === 'Bottles' })} />
            )}
            {title && <S.TitleText size="h6" text={title} weight="semibold" />}
            {description && (
              <S.DescriptionText
                color={icon === 'Bottles' ? STATIC_COLORS.base.black : 'inherit'}
                size={descriptionTextLeftAlign ? 'body1' : 'subtitle1'}
                text={description}
                weight={descriptionTextLeftAlign ? 'normal' : 'medium'}
              />
            )}
            {qrCodeUrl && <QRCode className="qrcode" size={200} value={qrCodeUrl} />}
            {withButton && (
              <Button
                className="modal-close-btn"
                fontSize="body1"
                text={buttonText || commonMessages.close}
                textWeight="medium"
                variant={VH_VARIANTS.PRIMARY}
                onClick={handleClick || hideModal}
              />
            )}
          </>
        );
      case ModalTypes.ProductScanFound:
        return (
          <>
            {IconComponent && <IconComponent className="modal-icon" />}
            {title && <S.TitleText size="h6" text={title} weight="semibold" />}
            {description && (
              <S.DescriptionText
                size={descriptionTextLeftAlign ? 'body1' : 'subtitle1'}
                text={description}
                weight={descriptionTextLeftAlign ? 'normal' : 'medium'}
              />
            )}
            {withButton && (
              <Button
                className="modal-close-btn"
                fontSize="body1"
                text={buttonText || commonMessages.close}
                textWeight="medium"
                variant={VH_VARIANTS.PRIMARY}
                onClick={handleClick || hideModal}
              />
            )}
          </>
        );
      case ModalTypes.ProductScanNotFound:
        return (
          <>
            {IconComponent && <IconComponent className="modal-icon" />}
            {description && (
              <S.DescriptionText
                size={descriptionTextLeftAlign ? 'body1' : 'subtitle1'}
                text={description}
                weight={descriptionTextLeftAlign ? 'normal' : 'medium'}
              />
            )}
            {withButton && (
              <Button
                className="modal-close-btn"
                fontSize="body1"
                text={buttonText || commonMessages.close}
                textWeight="medium"
                variant={VH_VARIANTS.PRIMARY}
                onClick={handleClick || hideModal}
              />
            )}
          </>
        );
      default:
        return null;
    }
  };
  return (
    <>
      <ModalWrapper
        hideModal={hideModal}
        isApplicationKiosk={isApplicationKiosk}
        isOpen={isOpen}
        modalClassName={classNames('events-modal-wrapper', {
          informationModal: modalType === ModalTypes.InformationModal,
        })}
      >
        <S.EventsModalContainer
          $modalType={modalType}
          data-testid={`EventsModalContainer-${modalType}`}
          className={classNames('events-modal-container', {
            informationModal: modalType === ModalTypes.InformationModal,
            'no-padding': modalType === ModalTypes.FindMeModal,
            'no-top': modalType === ModalTypes.LocationMapModal,
          })}
        >
          <CloseButton className="modal-close" handleClick={hideModal} />
          {renderModalContent()}
        </S.EventsModalContainer>
      </ModalWrapper>
    </>
  );
};

export default EventsModal;
