import { css, styled } from 'styled-components';

import { STATIC_COLORS, STATIC_GRADIENTS, STATIC_SHADOWS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Image } from '@components/web/src/foundations/Image/Image';
import { Text } from '@components/web/src/foundations/Text/Text';

export const ProductCardContainer = styled.div<{ $isResponsive: boolean }>`
  position: relative;
  width: 312px;
  overflow: hidden;
  cursor: pointer;
  background: ${STATIC_COLORS.base.white};
  border-radius: 8px;
  box-shadow: ${STATIC_SHADOWS.lg};

  ${({ $isResponsive }) => $isResponsive && `width: 100%`}

  ${({ theme, $isResponsive }) =>
    theme?.isKioskRootElementType &&
    css`
      max-width: ${$isResponsive ? '100%' : '328px'};
      width: ${$isResponsive ? '100%' : '328px'};
    `}
`;

export const ProductImageSection = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 210px;
  overflow: hidden;
  background: ${STATIC_GRADIENTS.warmGradientReversed()};

  .product-image {
    display: block;
    height: 85%;
    object-fit: contain;
  }
`;

export const PromoIcon = styled.div`
  position: absolute;
  top: 10px;
  left: -48px;
  z-index: 1;
  width: 140px;
  height: 20px;
  padding: 3px 0;
  background-color: #de5242;
  transform: rotate(-45deg);
  text-align: center;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ProductTags = styled.div`
  position: absolute;
  left: 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: flex-end;
  height: 100%;
  padding: 16px 0;
  margin-left: 16px;
`;

export const ProductImageCtaContainer = styled(Flexbox)`
  position: absolute;
  right: 0;
  height: 100%;
  padding: 14px 0;
  margin-right: 16px;
`;

export const ProductContentSection = styled.div<{ $isCompactVariant: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding: 16px;

  ${({ $isCompactVariant }) =>
    !$isCompactVariant &&
    css`
      min-height: 246px;
      max-height: 246px;
    `}

  ${({ theme }) =>
    theme?.isKioskRootElementType &&
    css`
      min-height: 300px;
      max-height: 300px;
    `}
`;

export const StyledProductName = styled(Text)`
  min-height: 60px;
  max-height: 60px;
  margin-top: auto;

  ${({ theme }) =>
    theme?.isKioskRootElementType &&
    css`
      min-height: 64px;
      max-height: 64px;
    `}
`;

export const ProducerName = styled(Text)`
  min-height: 18px;
`;
export const ProductRegionText = styled(Text)`
  min-height: 18px;
`;

export const StyledProductDescription = styled(Text)`
  margin: 8px 0;
  min-height: 32px;
  max-height: 32px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;

  ${({ theme }) =>
    theme?.isKioskRootElementType &&
    css`
      -webkit-line-clamp: 3;
      min-height: 60px;
      max-height: 60px;
    `}
`;

export const ProductPriceFormatContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 24px;
  margin-top: auto;
  width: 100%;

  & > div {
    margin-left: auto;
  }

  ${({ theme }) =>
    theme?.isKioskRootElementType &&
    css`
      height: 30px;
    `};
`;

export const StyledProductFormatText = styled(Text)`
  flex-grow: 1;
  flex-wrap: wrap;
  color: ${STATIC_COLORS.base.black};
`;

export const ProductPriceContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0 8px;
  align-items: center;
  justify-content: flex-end;
`;

export const StyledProductPriceText = styled(Text)`
  white-space: nowrap;
  color: ${STATIC_COLORS.base.black};
`;

export const StyledProductDashedPriceText = styled(Text)`
  color: ${STATIC_COLORS.gray['400']};
  text-decoration-line: line-through;
  white-space: nowrap;
`;

export const ProductCTASection = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 4px 4px;

  .give-it-button {
    height: 38px;
    text-decoration-line: underline;
  }

  .find-product {
    min-height: 52px;
    padding-top: 12px;

    .find-product-button {
      width: 100%;
      height: 40px;
    }
  }
`;

export const StyledBadgesWrapper = styled.div`
  margin: 4px -4px -4px;
`;

export const ProductImage = styled(Image)`
  display: block;
`;
