// eslint-disable-next-line import/no-extraneous-dependencies
import { BEER_ALCOHOL_ESSENCE_ID, HARDCODED_ESSENCE_DATA } from '@app/web/src/helpers/consts';

import { TCharacter } from '@lib/core/characters/types';
import { TProductCategory } from '@lib/core/products/types';
import { localeApp } from '@lib/tools/locale/source/web/app';

import { ICharacterAdjectiveData } from '@components/web/src/organisms/CharacterAdjectivesGroup/CharacterAdjectivesGroup';

export const parseCharacterAdjectiveData = (
  translatedUserCharacters: Record<string, TCharacter>,
  locale: string,
  productCategories: TProductCategory[],
): Record<string, ICharacterAdjectiveData> => {
  if (!translatedUserCharacters || !locale) return null;

  const {
    characterAdjectiveAverage,
    characterAdjectiveExcellent,
    characterAdjectiveFair,
    characterAdjectiveGood,
    characterAdjectiveNone,
    characterAdjectivePoor,
  } = localeApp.testResultPage;

  const CHARACTER_ADJECTIVE_TITLE = {
    0: characterAdjectiveNone,
    1: characterAdjectivePoor,
    2: characterAdjectiveFair,
    3: characterAdjectiveAverage,
    4: characterAdjectiveGood,
    5: characterAdjectiveExcellent,
  };

  const parseCharacterAdjectiveDataData = (character: TCharacter) =>
    character?.related_essences?.map(essence => {
      const { identifier, name } = essence;
      const isAlcoholEssence = essence.identifier === BEER_ALCOHOL_ESSENCE_ID;
      const title = isAlcoholEssence ? `${name.split(' ')[0]} %` : name;

      return {
        identifier,
        preTitle: CHARACTER_ADJECTIVE_TITLE[essence.score],
        score: essence.score,
        title,
      };
    });

  const parseCharacterAdjectiveModalData = (character: TCharacter, productCategory: TProductCategory) =>
    character?.related_essences?.map(essence => {
      const { identifier, score, name } = essence;

      const attribute = HARDCODED_ESSENCE_DATA[identifier].slug[locale];
      const isAlcoholEssence = essence.identifier === BEER_ALCOHOL_ESSENCE_ID;
      const titleName = isAlcoholEssence ? `${name.split(' ')[0]} %` : name;

      return {
        description: character?.attributes[attribute],
        identifier,
        image: HARDCODED_ESSENCE_DATA[identifier].icon[score],
        name: titleName,
        productCategory,
        title: CHARACTER_ADJECTIVE_TITLE[score],
      };
    });

  return productCategories.reduce((acc, category) => {
    acc[category] = {
      characterAdjectiveItemsData: parseCharacterAdjectiveDataData(translatedUserCharacters[category]),
      characterAdjectiveModalData: parseCharacterAdjectiveModalData(translatedUserCharacters[category], category),
    };
    return acc;
  }, {});
};
