import { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import useTestResultData from '@app/web/src/hooks/useTestResultData';

import { useRetailerLocation } from '@lib/core/retailers/hooks/retailerLocation';
import { useApp } from '@lib/core/service/hooks';
import {
  TRegisterPageScenarioState,
  actionSetRegisterPageScenarioData,
} from '@lib/core/service/slices/technical/registerPageScenario';
import { prependBasename } from '@lib/core/service/utils';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import { PRODUCT_CATEGORY_URL_PARAM } from '@lib/tools/shared/helpers/consts';
import { useRetailerDesignSet } from '@lib/tools/views/hooks';
import { PAGES } from '@lib/tools/views/urls';

import { TTabIcon } from '@components/web/src/atoms/Tabs/TabButton/TabButton';
import Tabs, { ITabs } from '@components/web/src/atoms/Tabs/Tabs/Tabs';
import LoadingPage from '@components/web/src/templates/Loading/LoadingPage';
import TestResultPage from '@components/web/src/templates/ResultPages/TestResultPage/TestResultPage';

const TestResultPageContainer: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const { locale } = useApp();
  const { retailerStoreType, retailerLocationLogo } = useRetailerLocation();
  const { isDesignSetVinhoodExperience } = useRetailerDesignSet();

  const { productCategories: localeProductCategories } = localeCommon;

  const [activeTab, setActiveTab] = useState(searchParams.get(PRODUCT_CATEGORY_URL_PARAM));

  const {
    productCategories,
    translatedUserCharacters,
    quizData,
    isTestResultDataLoading,
    charactersModalData,
    characterAdjectiveData,
    resultProductInstanceData,
    isLoadingProduct,
  } = useTestResultData();

  useEffect(() => {
    if (activeTab) {
      searchParams.delete(PRODUCT_CATEGORY_URL_PARAM);
      setSearchParams(searchParams);
    }
  }, [activeTab]);

  const handleSetResultPageState = (payload: TRegisterPageScenarioState) => {
    dispatch(actionSetRegisterPageScenarioData(payload));
    navigate(prependBasename(PAGES.vinhood.registration));
  };

  const tabsData: ITabs = useMemo(
    () => ({
      active: activeTab || productCategories[0],
      tabs: productCategories.map(category => ({
        children: (
          <TestResultPage
            key={category}
            characterAdjectiveData={characterAdjectiveData && characterAdjectiveData[category]}
            characterData={translatedUserCharacters && translatedUserCharacters[category]}
            charactersModalData={charactersModalData && charactersModalData[category]}
            handleSetResultPageState={handleSetResultPageState}
            isDesignSetVinhoodExperience={isDesignSetVinhoodExperience}
            isLoadingProduct={isLoadingProduct}
            locale={locale}
            productCategory={category}
            productInstanceData={resultProductInstanceData?.[category]}
            quizData={quizData && quizData[category]}
            retailerLocationLogo={retailerLocationLogo}
            storeType={retailerStoreType}
          />
        ),
        tabCode: category,
        tabIcon: category as TTabIcon,
        tabText: localeProductCategories[category],
      })),
    }),
    [isTestResultDataLoading, resultProductInstanceData, isLoadingProduct],
  );

  return (
    <>
      {isTestResultDataLoading ? (
        <LoadingPage />
      ) : (
        <Tabs
          isAutoSpacedTabs
          active={tabsData.active}
          gradientVariant="custom-down"
          isHiddenTabs={isDesignSetVinhoodExperience && productCategories.length === 1}
          tabs={tabsData.tabs}
          onTabClick={tabCode => setActiveTab(tabCode)}
        />
      )}
    </>
  );
};

export default TestResultPageContainer;
