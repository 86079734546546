import cn from 'classnames';
import { FC } from 'react';
import { useDispatch } from 'react-redux';

import { updateShowOnlyFilterValue } from '@lib/tools/filterManager/slices/showOnlyFilterSlice';
import LocaleFragment, { ILocaleText } from '@lib/tools/locale/views/LocaleFragment';
import { FILTER_TYPE_WISHLIST } from '@lib/tools/shared/helpers/consts';
import { useTypedSelector } from '@lib/tools/views/hooks';

import wishlistIcon from '@components/web/src/assets/legacy/icons/filter_wishlist_icon.svg';
import wishlistIconActive from '@components/web/src/assets/legacy/icons/filter_wishlist_icon_active.svg';
import { Image } from '@components/web/src/foundations/Image/Image';

interface IProps {
  title: ILocaleText;
  filterType: typeof FILTER_TYPE_WISHLIST;
}

const ShowOnly: FC<IProps> = ({ title, filterType }) => {
  const dispatch = useDispatch();
  const { isWishlistToggleActive } = useTypedSelector(state => state.productFilters.showOnly);

  const getCurrentIcon = iconName => {
    let currentIcon;

    switch (iconName) {
      case FILTER_TYPE_WISHLIST:
        currentIcon = isWishlistToggleActive ? wishlistIconActive : wishlistIcon;
        break;
      default:
    }
    return currentIcon;
  };

  return (
    <button
      className={cn('filter-show-only', { isActive: isWishlistToggleActive })}
      type="button"
      onClick={() => dispatch(updateShowOnlyFilterValue(filterType))}
    >
      <Image alt="icon" height="40px" src={getCurrentIcon(filterType)} width="40px" />
      <span>
        <LocaleFragment message={title} />
      </span>
    </button>
  );
};

export default ShowOnly;
