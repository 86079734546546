import { FC } from 'react';

import { TCharacterEssences } from '@lib/core/characters/types';
import { TProductCategory } from '@lib/core/products/types';

import ProgressBar, { IProgressBarVariant } from '@components/web/src/components/ProgressBar/ProgressBar';
import { TOTAL_INDICATOR_AMOUNT } from '@components/web/src/foundations/consts';
import { Text } from '@components/web/src/foundations/Text/Text';

export interface IEssenceProps extends TCharacterEssences {
  productCategory?: TProductCategory;
}

const EssenceCard: FC<IEssenceProps> = ({ name, description, productCategory, score }) => {
  return (
    <div className="essence-card-container">
      <div>
        <p className="essence-title">
          <Text size="body1" text={name} weight="semibold" />
        </p>
        <p className="essence-description">{description}</p>
      </div>
      <div className="essence-details-container">
        <div className="essence-item-container">
          <div className="essence-item-header">
            <span className="essence-item-title">
              <Text size="body2" text={name} />
            </span>
            <span className="essence-item-rating">
              {score} / {TOTAL_INDICATOR_AMOUNT}
            </span>
          </div>
          <ProgressBar
            className="no-padding"
            progressPercentage={(score / TOTAL_INDICATOR_AMOUNT) * 100}
            variant={productCategory as IProgressBarVariant}
          />
        </div>
      </div>
    </div>
  );
};

export default EssenceCard;
