import { FC } from 'react';

import { localeApp } from '@lib/tools/locale/source/web/app';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';
import { VH_VARIANTS } from '@lib/tools/shared/helpers/consts';

import { ReactComponent as ArrowIcon } from '@components/web/src/assets/legacy/b2c/icons/bannerdropdownwhite.svg';
import ProgressBar from '@components/web/src/components/ProgressBar/ProgressBar';

type Props = {
  progress: number;
  onClick: () => void;
};

const UserCharacterPersonalizeSection: FC<Props> = ({ progress, onClick }) => {
  const { userCharacterPersonalizeSection } = localeApp;
  return (
    <div aria-hidden className="user-character-personal-section-container" onClick={onClick}>
      <div className="left-section">
        <div className="title">
          <LocaleFragment message={userCharacterPersonalizeSection.personalizeExperienceText} />
        </div>
        <div>
          <ProgressBar className="no-padding" progressPercentage={progress} variant={VH_VARIANTS.LIGHT} />
        </div>
        <div className="description">
          <LocaleFragment message={userCharacterPersonalizeSection.personalizeExperienceDesc} />
        </div>
      </div>
      <div className="right-section">
        <ArrowIcon />
      </div>
    </div>
  );
};

export default UserCharacterPersonalizeSection;
