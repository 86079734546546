import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';
import { MAP_SUB_CATEGORY } from '@lib/tools/shared/helpers/consts';

import { Image } from '@components/web/src/foundations/Image/Image';

const CharacterMapCard = ({ lang = 'en', ...rest }) => {
  const { image: characterImage, name: characterName, character_caption: characterCaption, attributes = {} } = rest;
  const characteSubCategory = attributes[`${MAP_SUB_CATEGORY}${lang}`] || '';
  return (
    <div className="character-map-card-container" data-itemid={characterName}>
      <div className="character-image-container">
        <Image alt="characters product" objectFit="contain" src={characterImage} />
      </div>
      <div className="character-info-details">
        <p className="character-type">
          <LocaleFragment message={characteSubCategory} />
        </p>
        <p className="character-name">
          <LocaleFragment message={characterName} />
        </p>
        <p className="character-description">
          <LocaleFragment message={characterCaption} />
        </p>
      </div>
    </div>
  );
};

export default CharacterMapCard;
