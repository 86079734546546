import { FC, useEffect } from 'react';

import MixpanelTracker from '@lib/tools/dat/mixpanel';
import { localeApp } from '@lib/tools/locale/source/web/app';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';

import wineTasteIcon from '@components/web/src/assets/legacy/b2b/kiosk/sketches_tastetest_wine.png';
import Button from '@components/web/src/atoms/Buttons/Button';

interface IProps {
  handleTakeTestBtn: () => void;
  handleSignInBtn: () => void;
}

const StartPage: FC<IProps> = ({ handleTakeTestBtn, handleSignInBtn }) => {
  useEffect(() => {
    MixpanelTracker.events.startPageView();
  }, []);

  const { title, subtitleText, btnTakeTest, loginLinkText } = localeApp.startPage;
  const { loginTitle } = localeCommon.registrationPage;

  return (
    <div className="app-start-page-container">
      <div className="image-wrapper">
        <img alt="start-page-background" src={wineTasteIcon} />
      </div>
      <p className="title">
        <LocaleFragment message={title} />
      </p>
      <p className="subtitle">
        <LocaleFragment message={subtitleText} />
      </p>
      <Button className="start-btn" handleClick={handleTakeTestBtn} size="sm" text={btnTakeTest} />
      <button className="login-btn" onClick={handleSignInBtn}>
        <LocaleFragment message={loginTitle} />{' '}
        <span className="link">
          <LocaleFragment message={loginLinkText} />
        </span>
      </button>
    </div>
  );
};

export default StartPage;
