import { css, styled } from 'styled-components';

import { VARIANT_SET_EMBEDDED, VARIANT_SET_FULLPAGE, VARIANT_SET_JOURNAL } from '@lib/tools/shared/helpers/consts';

import { TFilterSectionVariant } from '@components/web/src/components/Catalog/Filter/FilterSection';
import { STATIC_COLORS, STATIC_SHADOWS } from '@components/web/src/foundations';
import { ROOT_ELEMENT_KIOSK } from '@components/web/src/foundations/consts';

export const FilterSection = styled.div<{
  $variant: TFilterSectionVariant;
  $isFilterOpened: boolean;
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
  width: 100%;
  padding: 16px;

  ${({ theme, $variant, $isFilterOpened }) =>
    $variant === VARIANT_SET_EMBEDDED &&
    theme.rootElementType === ROOT_ELEMENT_KIOSK &&
    css`
      padding: 16px 32px 32px;
      background-color: ${STATIC_COLORS.base.white};
      box-shadow: ${$isFilterOpened ? 'none' : `${STATIC_SHADOWS.lg}`};
    `}

  ${({ $variant }) =>
    $variant === VARIANT_SET_JOURNAL &&
    css`
      padding: 16px 16px 16px 22px;
    `}
`;

export const FilterButtonSection = styled.div<{
  $variant: TFilterSectionVariant;
}>`
  display: flex;
  flex-direction: column;

  gap: 16px;
  width: 100%;
  ${({ theme, $variant }) =>
    $variant === VARIANT_SET_EMBEDDED &&
    theme.rootElementType === ROOT_ELEMENT_KIOSK &&
    css`
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    `}
`;

export const AppliedFiltersContainer = styled.div<{
  $variant: TFilterSectionVariant;
}>`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 8px 0 16px;
  ${({ $variant }) =>
    ($variant === VARIANT_SET_FULLPAGE || $variant === VARIANT_SET_JOURNAL) &&
    css`
      .badge-container {
        background-color: ${STATIC_COLORS.warmGray['300']};
      }
    `}

  ${({ theme, $variant }) =>
    $variant === VARIANT_SET_EMBEDDED &&
    theme.rootElementType === ROOT_ELEMENT_KIOSK &&
    css`
      padding: 0;
      margin-top: 0;
    `}
`;

export const InputSearchWrapper = styled.div<{ $hideElement?: boolean }>`
  width: 100%;

  ${({ theme }) =>
    theme?.rootElementType === ROOT_ELEMENT_KIOSK &&
    css`
      display: flex;
      justify-content: center;

      .mp-input {
        max-width: 600px;
      }
    `}

  ${({ $hideElement }) =>
    $hideElement &&
    css`
      display: none;
    `}
`;
