import { DetailedHTMLProps, HTMLAttributes, ReactNode, forwardRef } from 'react';
import { styled } from 'styled-components';

type TDirection = 'row' | 'column' | 'row-reverse' | 'column-reverse';
type TJustify = 'center' | 'flex-start' | 'flex-end' | 'space-between' | 'space-around' | 'space-evenly';
type TAlign = 'center' | 'flex-start' | 'flex-end' | 'stretch' | 'baseline';
type TAlignContent = 'center' | 'flex-start' | 'flex-end' | 'space-between' | 'space-around' | 'stretch';
type TWrap = 'nowrap' | 'wrap' | 'wrap-reverse';

interface IStyleProps {
  $direction?: TDirection;
  $justify?: TJustify;
  $align?: TAlign;
  $alignContent?: TAlignContent;
  $wrap?: TWrap;
  $gap?: number;
  $padding?: number;
}

/**
 * All the default values are the default CSS styled applied when using
 * "display:flex"
 */
export const FlexboxContainer = styled.div<IStyleProps>`
  display: flex;
  flex-direction: ${({ $direction = 'row' }) => $direction};
  justify-content: ${({ $justify = 'flex-start' }) => $justify};
  align-items: ${({ $align = 'stretch' }) => $align};
  align-content: ${({ $alignContent = 'normal' }) => $alignContent};
  flex-wrap: ${({ $wrap = 'nowrap' }) => $wrap};
  gap: ${({ $gap = 12 }) => `${$gap}px`};
  padding: ${({ $padding }) => `${$padding}px`};
`;

interface IProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  direction?: TDirection;
  justify?: TJustify;
  align?: TAlign;
  alignContent?: TAlignContent;
  wrap?: TWrap;
  gap?: number;
  padding?: number;
  children: ReactNode;
}

export const Flexbox = forwardRef<HTMLDivElement, IProps>(
  ({ children, direction, justify, align, wrap, alignContent, gap, padding, ...rest }, ref) => (
    <FlexboxContainer
      ref={ref}
      $align={align}
      $alignContent={alignContent}
      $direction={direction}
      $gap={gap}
      $justify={justify}
      $padding={padding}
      $wrap={wrap}
      {...rest}
    >
      {children}
    </FlexboxContainer>
  ),
);
