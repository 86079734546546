import { FC, useState } from 'react';
import { useSelector } from 'react-redux';

import B2CProductCatalogContainer from '@app/web/src/containers/app/Catalog/ProductCatalogContainer';
import WidgetCatalogContainerHOC from '@app/web/src/containers/Catalog/CatalogContainerHOC';
import WidgetExtendedCatalogContainer from '@app/web/src/containers/Catalog/ExtendedCatalogContainer';
import WidgetLimitedCatalogContainer from '@app/web/src/containers/Catalog/LimitedCatalogContainer';

import { selectRetailerSlug } from '@lib/core/retailers/selectors/retailer';
import {
  selectRetailerCatalogType,
  selectRetailerPhysicalAddress,
} from '@lib/core/retailers/selectors/retailerLocation';
import {
  ACTION_PERFORMED_URL_PARAM,
  MIXPANEL_EVENT_SLUG_URL_PARAM,
  ModalTypes,
  REDIRECT_URL_PARAM,
} from '@lib/core/service/consts';
import { useFidelityCard } from '@lib/core/users/hooks/useFidelityCard';
import { MP_ACTION_PERFORMED_CONTEXT, MP_EVENTS } from '@lib/tools/dat/mixpanel/consts';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import RouteUtils from '@lib/tools/routes';
import { EXTENSIVE_CATALOG, FIDELITY_ID_URL_PARAM, LIMITED_CATALOG } from '@lib/tools/shared/helpers/consts';
import { useAddons, useRetailerDesignSet } from '@lib/tools/views/hooks';

import CatalogTabs from '@components/web/src/pages/widget/Catalog/CatalogTabs';
import EventsModal from '@components/web/src/templates/Modals/Events/EventsModal/EventsModal';

const ProductCatalogContainer: FC = () => {
  const { productCatalog } = localeCommon;

  const { isEnableKioskFidelityPromotionAddon, isEnableTastePathAddon } = useAddons();
  const { isDesignSetVinhoodExperience, isDesignSetVinhoodApp } = useRetailerDesignSet();

  const retailerCatalogType = useSelector(selectRetailerCatalogType);
  const retailerPhysicalAddress = useSelector(selectRetailerPhysicalAddress);
  const retailerSlug = useSelector(selectRetailerSlug);

  const { fidelityCardId } = useFidelityCard();
  const [isPromotionModalOpen, setIsPromotionModalOpen] = useState(
    !!fidelityCardId && isEnableKioskFidelityPromotionAddon && !!retailerPhysicalAddress,
  );

  const promotionQrCodeURL = RouteUtils.generateRedirectURL({
    [ACTION_PERFORMED_URL_PARAM]: MP_ACTION_PERFORMED_CONTEXT.SCAN,
    [FIDELITY_ID_URL_PARAM]: `${retailerSlug}_${fidelityCardId}`,
    [MIXPANEL_EVENT_SLUG_URL_PARAM]: MP_EVENTS.SPECIAL_PROMO_DISPLAYED.SLUG,
    [REDIRECT_URL_PARAM]: retailerPhysicalAddress,
  });

  // should be updated for opening Promotion coupon on mobile kiosk
  const openMobileKioskPromotion = () => window.open(promotionQrCodeURL, '_blank');

  const isLimitedCatalog = retailerCatalogType === LIMITED_CATALOG;
  const isExtendedCatalog = retailerCatalogType === EXTENSIVE_CATALOG;

  if (isDesignSetVinhoodApp) {
    return <B2CProductCatalogContainer />;
  }

  if (isDesignSetVinhoodExperience) {
    const catalogContainer =
      (isLimitedCatalog && <WidgetLimitedCatalogContainer />) ||
      (isExtendedCatalog && <WidgetExtendedCatalogContainer />) ||
      null;

    return (
      <>
        <WidgetCatalogContainerHOC>
          {isEnableTastePathAddon ? <CatalogTabs catalogContainer={catalogContainer} /> : catalogContainer}
        </WidgetCatalogContainerHOC>

        {isPromotionModalOpen && (
          <EventsModal
            withButton
            buttonText={productCatalog.modalBtnShowCoupon}
            description={productCatalog.mobileModalDescription}
            handleClick={openMobileKioskPromotion}
            hideModal={() => setIsPromotionModalOpen(false)}
            icon="Bottles"
            isOpen={isPromotionModalOpen}
            modalType={ModalTypes.PromoQrModal}
            title={productCatalog.modalTitle}
          />
        )}
      </>
    );
  }

  return null;
};

export default ProductCatalogContainer;
