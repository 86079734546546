import { ToastPosition, toast } from 'react-toastify';

import LocaleFragment, { ILocaleText } from '@lib/tools/locale/views/LocaleFragment';

import { ReactComponent as RateIcon } from '@components/web/src/assets/legacy/icons/icon_rate.svg';
import { ReactComponent as ToastCloseIcon } from '@components/web/src/assets/legacy/icons/toast/icon_close.svg';
import { ReactComponent as DangerRedIcon } from '@components/web/src/assets/legacy/icons/toast/icon_danger_toast.svg';
import { ReactComponent as InfoIcon } from '@components/web/src/assets/legacy/icons/toast/icon_info_toast.svg';
import { ReactComponent as SuccessGreenTickIcon } from '@components/web/src/assets/legacy/icons/toast/icon_success_toast.svg';
import { ReactComponent as WarningExclamationIcon } from '@components/web/src/assets/legacy/icons/toast/icon_warning_toast.svg';

export type IToastType = 'success' | 'warning' | 'error' | 'info';
export type IToastPlacement = 'top' | 'bottom';
export type ICustomIcon = 'rate'; // Add here new custom icon

interface IToastMessageProps {
  type?: IToastType;
  title?: string;
  message?: ILocaleText;
  closeButton?: boolean;
  position?: IToastPlacement;
  closeTime?: number | false;
  customIcon?: ICustomIcon;
}

const customIconVariants: Record<ICustomIcon, JSX.Element> = {
  rate: <RateIcon />,
};

const CloseIcon = () => (
  <span className="vh-toast-close-container">
    <ToastCloseIcon />
  </span>
);

const mapToastPlacementToToastPosition: Record<IToastPlacement, ToastPosition> = {
  bottom: 'bottom-center',
  top: 'top-center',
};

export const toastMessage = ({
  type = 'success',
  title,
  message,
  closeButton = true,
  position = 'top',
  closeTime = 5000,
  customIcon,
}: IToastMessageProps) => {
  const defaultIcons = {
    error: <DangerRedIcon />,
    info: <InfoIcon />,
    success: <SuccessGreenTickIcon />,
    warning: <WarningExclamationIcon />,
  };

  const ToastTextComponent = () => (
    <div className={`toast-text-wrapper ${title ? 'font-changed' : ''}`}>
      {title && (
        <span className="title">
          <LocaleFragment message={title} />
        </span>
      )}
      <span className="subtitle">
        <LocaleFragment message={message} />
      </span>
    </div>
  );
  const toastProps = {
    autoClose: closeTime || (false as const),
    bodyClassName: `vh-toast-body ${type}`,
    className: `vh-toast`,
    closeButton: closeButton ? <CloseIcon /> : false,
    icon: customIcon ? customIconVariants[customIcon] : defaultIcons[type],
    position: mapToastPlacementToToastPosition[position],
    progressClassName: 'vh-toast-progress',
    type,
  };

  toast(ToastTextComponent, toastProps);
};
