import cn from 'classnames';
import { FC } from 'react';

import { TProductCategory } from '@lib/core/products/types';
import { localeApp } from '@lib/tools/locale/source/web/app';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';
import { VH_VARIANTS } from '@lib/tools/shared/helpers/consts';

import Button from '@components/web/src/atoms/Buttons/Button';
import ModalWrapper from '@components/web/src/templates/Modals/ModalWrapper/ModalWrapper';

type Props = {
  isModalOpen: boolean;
  productCategory?: TProductCategory;
  hideModal: () => void;
  handleConfirm: () => void;
  handleManagePreferences: () => void;
  handleShowCookie?: () => void;
};

const CookieModal: FC<Props> = ({
  isModalOpen,
  productCategory,
  hideModal,
  handleConfirm,
  handleManagePreferences,
  handleShowCookie,
}) => {
  const { cookieModal } = localeApp;
  const { productCategoriesPlural } = localeCommon;

  const cookieText = (
    <LocaleFragment
      message={cookieModal.cookieText}
      insertIntlJSXPartOptions={{
        className: 'cookie-policy',
        linkId: 'cookie-page',
        linkRender: text => (
          <span aria-hidden className="decorated-text" onClick={handleShowCookie}>
            <p>{text}</p>
          </span>
        ),
      }}
    />
  );
  return (
    <ModalWrapper hideModal={hideModal} isOpen={isModalOpen} modalClassName="cookie-modal-container">
      <p className="pre-title">
        <LocaleFragment
          message={cookieModal.preTitle}
          options={{
            productCategory: productCategory || '',
          }}
          variables={{
            productCategoryPluralText: productCategory ? productCategoriesPlural[productCategory] : '',
          }}
        />
      </p>
      <div className="header-container">
        <p className={cn('title')}>
          <LocaleFragment message={cookieModal.title} />
        </p>
        <Button handleClick={hideModal} size="sm" text={cookieModal.closeText} variant={VH_VARIANTS.LIGHT} />
      </div>
      <div className="description-container">
        <p>
          <LocaleFragment message={cookieModal.descriptionShort} />
        </p>
        {cookieText}
      </div>
      <div className="cta-container">
        <Button
          className="confirm-btn"
          handleClick={handleConfirm}
          size="sm"
          text={cookieModal.confirmBtn}
          variant={VH_VARIANTS.PRIMARY}
        />
        <Button
          handleClick={handleManagePreferences}
          size="sm"
          text={cookieModal.managePreferencesBtnText}
          variant={VH_VARIANTS.LIGHT}
        />
      </div>
    </ModalWrapper>
  );
};

export default CookieModal;
