import { useDispatch } from 'react-redux';

import { B2C_MODALS } from '@lib/core/service/consts';
import { selectModalState } from '@lib/tools/modals/selector';
import { IModalTypes, actionModalClose, actionModalOpen } from '@lib/tools/modals/slice';
import { MODALS } from '@lib/tools/shared/helpers/consts';
import { useTypedSelector } from '@lib/tools/views/hooks';

export const useModals = () => {
  const dispatch = useDispatch();

  const isCookieModalOpened = useTypedSelector(selectModalState(MODALS.COOKIE_MODAL));
  const isPermissionModalOpened = useTypedSelector(selectModalState(MODALS.PERMISSION_MODAL));
  const isCookiePolicyModalOpened = useTypedSelector(selectModalState(B2C_MODALS.COOKIE_POLICY_MODAL));
  const isInterestsModalOpened = useTypedSelector(selectModalState(B2C_MODALS.INTERESTS_MODAL));
  const isLegalPolicyModalOpened = useTypedSelector(selectModalState(B2C_MODALS.LEGAL_POLICY_MODAL));
  const isFoodPreferencesModalOpened = useTypedSelector(selectModalState(B2C_MODALS.FOOD_PREFERENCES_MODAL));
  const isManageModalOpened = useTypedSelector(selectModalState(B2C_MODALS.MANAGE_MODAL));
  const isPrivacyPolicyModalOpened = useTypedSelector(selectModalState(B2C_MODALS.PRIVACY_POLICY_MODAL));

  const openModal = (modalName: IModalTypes) => dispatch(actionModalOpen({ modalName }));
  const closeAllModals = () => dispatch(actionModalClose());

  return {
    closeAllModals,
    isCookieModalOpened,
    isCookiePolicyModalOpened,
    isFoodPreferencesModalOpened,
    isInterestsModalOpened,
    isLegalPolicyModalOpened,
    isManageModalOpened,
    isPermissionModalOpened,
    isPrivacyPolicyModalOpened,
    openModal,
  };
};
