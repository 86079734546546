import { FC } from 'react';

import { AuthBannerTypes } from '@lib/core/banners/consts';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';

import { ReactComponent as LeadGenerationIcon } from '@components/web/src/assets/legacy/b2b/icons/lead_generation_icon.svg';
import { ReactComponent as Bullseye } from '@components/web/src/assets/legacy/b2c/icons/bullseye.svg';

type TAuthBanner = (typeof AuthBannerTypes)[keyof typeof AuthBannerTypes];

interface IProps {
  title?: string;
  bannerType: TAuthBanner;
}

const AuthBanner: FC<IProps> = ({ title, bannerType }) => {
  const { registrationPage, loginPage } = localeCommon;

  const renderHeaderContent = () => {
    switch (bannerType) {
      case AuthBannerTypes.app:
        return (
          <div className="app-wrapper">
            <p>
              <LocaleFragment message={title || registrationPage.headerTitleApp} />
            </p>
            <Bullseye />
          </div>
        );
      case AuthBannerTypes.experience:
        return (
          <div className="experience-wrapper">
            <p>
              <LocaleFragment message={title || registrationPage.headerTitle} />
            </p>
            <LeadGenerationIcon />
          </div>
        );
      default:
        return (
          <p>
            <LocaleFragment message={title || loginPage.title} />
          </p>
        );
    }
  };
  return <div className="auth-banner-container"> {renderHeaderContent()}</div>;
};

export default AuthBanner;
