import { zodResolver } from '@hookform/resolvers/zod';
import { FC, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { ZodType, z } from 'zod';

import { localeApp } from '@lib/tools/locale/source/web/app';
import { LocaleUtils } from '@lib/tools/locale/utils';
import { VH_VARIANTS } from '@lib/tools/shared/helpers/consts';

import Button from '@components/web/src/atoms/Buttons/Button';
import InputField from '@components/web/src/components/Input/InputFields';
import CountrySelection, {
  ICountryOption,
} from '@components/web/src/templates/TasteId/TasteIdSettingsPage/CountrySelection/CountrySelection';

export interface IProfileFormData {
  firstName?: string;
  lastName?: string;
  email?: string;
  country?: string;
}

interface IProps {
  countryOptions: ICountryOption[];
  profileFormData: IProfileFormData;
  handleProfileFormSubmit: (data: IProfileFormData) => void;
}

const UserInfoForm: FC<IProps> = ({ countryOptions, profileFormData, handleProfileFormSubmit }) => {
  const {
    userInfoForm: {
      saveBtnText,
      cancelBtnText,
      nameFieldTitle,
      lastNameFieldTitle,
      emailFieldTitle,
      countryFieldTitle,
      countryValidationText,
      nameValidationText,
      lastNameValidationText,
      emailValidationText,
    },
  } = localeApp;

  const { publishedTerms } = LocaleUtils;

  const localeCountryValidationText = publishedTerms[countryValidationText?.id];
  const localeNameValidationText = publishedTerms[nameValidationText?.id];
  const localeLastNameValidationText = publishedTerms[lastNameValidationText?.id];
  const localeEmailValidationText = publishedTerms[emailValidationText.id];

  const validationSchema: ZodType<IProfileFormData> = useMemo(
    () =>
      z.object({
        country: z.string().nonempty(localeCountryValidationText),
        email: z.string().nonempty(localeEmailValidationText).email({ message: localeEmailValidationText }),
        firstName: z.string().min(2, { message: localeNameValidationText }),
        lastName: z.string().min(2, { message: localeLastNameValidationText }),
      }),
    [],
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm<IProfileFormData>({
    defaultValues: profileFormData,
    mode: 'onBlur',
    resolver: zodResolver(validationSchema),
  });

  const submitHandler = (formData: IProfileFormData) => {
    handleProfileFormSubmit(formData);
  };

  const cancelHandler = () => {
    reset(profileFormData);
  };

  return (
    <form noValidate autoComplete="off" className="profile-form" onSubmit={handleSubmit(submitHandler)}>
      <InputField label={nameFieldTitle} {...register('firstName')} errors={errors} />
      <InputField label={lastNameFieldTitle} {...register('lastName')} errors={errors} />
      <InputField label={emailFieldTitle} {...register('email')} errors={errors} />
      <Controller
        control={control}
        name="country"
        render={({ field }) => (
          <CountrySelection
            isSearchable
            errors={errors}
            labelTitle={countryFieldTitle}
            name={field.name}
            options={countryOptions}
            value={field.value}
            onChange={changedValue => field.onChange(changedValue)}
          />
        )}
      />
      <div className="form-actions">
        <Button size="sm" text={cancelBtnText} variant={VH_VARIANTS.LIGHT} onClick={cancelHandler} />
        <Button size="sm" text={saveBtnText} type="submit" />
      </div>
    </form>
  );
};

export default UserInfoForm;
