import { FC } from 'react';

import LocaleFragment, { ILocaleText } from '@lib/tools/locale/views/LocaleFragment';

import { Image } from '@components/web/src/foundations/Image/Image';

export interface IRecipe {
  title: ILocaleText;
  subtitle: ILocaleText;
  image: string;
}

const ChefRecommendsCard: FC<IRecipe> = ({ title, subtitle, image }) => {
  return (
    <div className="chef-recommends-card-container">
      <div className="image-container">
        <Image alt={`recipe ${title}`} height="240px" objectFit="cover" src={image} width="100%" />
      </div>
      <div className="content-container">
        <p className="subtitle">
          <LocaleFragment message={subtitle} />
        </p>
        <p className="title">
          <LocaleFragment message={title} />
        </p>
      </div>
    </div>
  );
};

export default ChefRecommendsCard;
