import { FC } from 'react';

import { TJournalPanelFilter, TProductCategory } from '@lib/core/products/types';
import { FILTER_TYPE_RATING, FILTER_TYPE_SHOW_FAVORITES } from '@lib/tools/shared/helpers/consts';

import { ReactComponent as TabIcon } from '@components/web/src/assets/svg/journal_tab.svg';
import {
  JournalPageBookmarkButton,
  JournalPageRateButton,
} from '@components/web/src/templates/TasteId/TasteIdJournalPage/styles';
import * as S from '@components/web/src/templates/TasteId/TasteIdJournalPage/styles';

interface IProps {
  productCategory: TProductCategory;
  activeJournalPanelFilter: TJournalPanelFilter;
  handlePanelFilterChange: (value: TJournalPanelFilter) => void;
}

export const JournalPanel: FC<IProps> = ({ activeJournalPanelFilter, productCategory, handlePanelFilterChange }) => (
  <S.JournalPageLeftPanel data-testid="JournalPanel">
    <S.JournalPageLeftPanelButtonsWrapper>
      <S.JournalPageLeftPanelButton
        $isActive={activeJournalPanelFilter === FILTER_TYPE_SHOW_FAVORITES}
        $productCategory={productCategory}
        onClick={() => handlePanelFilterChange(FILTER_TYPE_SHOW_FAVORITES)}
      >
        <JournalPageBookmarkButton $isActive={activeJournalPanelFilter === FILTER_TYPE_SHOW_FAVORITES} />
        <TabIcon />
      </S.JournalPageLeftPanelButton>
      <S.JournalPageLeftPanelButton
        $isActive={activeJournalPanelFilter === FILTER_TYPE_RATING}
        $productCategory={productCategory}
        onClick={() => handlePanelFilterChange(FILTER_TYPE_RATING)}
      >
        <JournalPageRateButton
          $isActive={activeJournalPanelFilter === FILTER_TYPE_RATING}
          $productCategory={productCategory}
        />
        <TabIcon />
      </S.JournalPageLeftPanelButton>
    </S.JournalPageLeftPanelButtonsWrapper>
  </S.JournalPageLeftPanel>
);
