/**
 * Retrieves the profile ID from the user state.
 * If the user profile ID is not available, it returns the provided defaultValue.
 *
 * @param state - The user state object.
 * @param defaultValue - The default value to return if the profile ID is not available. Default is an empty string.
 * @returns The profile ID from the user state or the defaultValue.
 */
export const selectUserProfileId = (state, defaultValue = ''): string =>
  state.user.profile.userProfileId || defaultValue;
