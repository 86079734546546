import { ReactComponent as CheckedIcon } from '@components/web/src/assets/legacy/b2c/icons/filter_item_checked.svg';
import { ReactComponent as NotCheckedIcon } from '@components/web/src/assets/legacy/b2c/icons/filter_item_not_checked.svg';
import * as S from '@components/web/src/components/Catalog/Filter/ListItem/Sublist/styles';
import { STATIC_COLORS } from '@components/web/src/foundations';

const SublistItemsList = ({ listContent, selectedNamesObj, onItemClick, isUpperCase, variant = 'plain' }) => (
  <S.SublistItemsList $isUpperCase={isUpperCase} data-testid="SublistItemsList">
    {listContent.map(item => {
      const isActive = selectedNamesObj[item.name]?.isActive || false;

      return (
        <S.SublistItem
          key={item.name}
          aria-hidden
          $isActive={isActive}
          $variant={variant}
          data-testid={`SublistItem-${variant}-${isActive}`}
          onClick={() => onItemClick(item)}
        >
          <S.TitleText
            color={STATIC_COLORS.base.black}
            size="subtitle2"
            text={item.name || item.value}
            weight={isActive ? 'semibold' : 'normal'}
          />
          {selectedNamesObj[item.name]?.isActive ? <CheckedIcon /> : <NotCheckedIcon className="unchecked" />}
        </S.SublistItem>
      );
    })}
  </S.SublistItemsList>
);

export default SublistItemsList;
