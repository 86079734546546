import { FC } from 'react';

import { IPreference } from '@app/native/src/models/UserFoodPreferences.model';

import { TProductCategory } from '@lib/core/products/types';
import { localeCommon } from '@lib/tools/locale/source/web/common';

import PlusIcon from '@components/web/src/assets/icons/plus_icon.svg';
import Badge from '@components/web/src/atoms/Badge/Badge';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Text } from '@components/web/src/foundations/Text/Text';
import * as S from '@components/web/src/templates/TasteId/TasteIdPage/BuyerPrinciples/styles';

type IProps = {
  userPreferences: IPreference[];
  productCategory?: TProductCategory;
  navigateToTagsPage?: () => void;
};

const BuyerPrinciples: FC<IProps> = ({ userPreferences, productCategory, navigateToTagsPage }) => {
  const { buyerPrinciplesTitle, buyerPrinciplesDescription } = localeCommon.tasteId;

  return (
    <Flexbox
      align="flex-start"
      data-testid={`BuyerPrinciples-${productCategory}`}
      direction="column"
      gap={16}
      padding={16}
    >
      <S.StyledText
        color={STATIC_COLORS.base.black}
        fontFamily="Fraunces"
        size="h5"
        text={buyerPrinciplesTitle}
        weight="semibold"
      />
      <Text size="body1" text={buyerPrinciplesDescription} />
      <Flexbox align="center" gap={8} wrap="wrap">
        {userPreferences?.length > 0 ? (
          <>
            {userPreferences?.map(preference => (
              <Badge key={preference.slug} productCategory={productCategory} text={preference.name} />
            ))}
            <S.StyledBadge
              handleBadgeClick={navigateToTagsPage}
              icon={PlusIcon}
              isProductCategoryType={false}
              productCategory={productCategory}
            />
          </>
        ) : (
          <Badge isProductCategoryType handleBadgeClick={navigateToTagsPage} productCategory={productCategory} />
        )}
      </Flexbox>
    </Flexbox>
  );
};

export default BuyerPrinciples;
