import { FC } from 'react';

import { TProductInstance } from '@lib/core/products/types';
import { TFeedback } from '@lib/core/users/slices/feedbacks';
import { localeCommon } from '@lib/tools/locale/source/web/common';

import CTAButton from '@components/web/src/app/CTAIconBtn/CTAButton/CTAButton';
import Tabs from '@components/web/src/atoms/Tabs/Tabs/Tabs';
import ProductDetails from '@components/web/src/organisms/Cards/ProductDetails/ProductDetails';
import { IDiscoveryQuiz } from '@components/web/src/templates/Modals/DiscoveryQuizModal/DiscoveryQuizModal';

type Props = {
  locale: string;
  tabsProps: { active: string; tabs: any[] };
  productInstanceData: TProductInstance;
  isShowPromoLabel?: boolean;
  isFeedbackEnabled?: boolean;
  feedback?: TFeedback;
  isWishlistEnabled?: boolean;
  isProductListLoading?: boolean;
  isProductInWishlist?: boolean;
  isUserHasAnyCharacter: boolean;
  shouldDisplayContactProducerBtn?: boolean;
  discoveryQuiz: IDiscoveryQuiz;
  navigateToQuizSelectionPage: () => void;
  onBackButtonClick: () => void;
  handleUpdateWishlistProductList?: (productId: string) => void;
  handleUpdateFeedback?: (feedback: TFeedback, productId: string) => void;
};

const AppProductPage: FC<Props> = ({
  productInstanceData,
  locale,
  tabsProps,
  isShowPromoLabel = false,
  isFeedbackEnabled = false,
  feedback,
  isWishlistEnabled = false,
  isProductInWishlist = false,
  isProductListLoading = false,
  shouldDisplayContactProducerBtn,
  discoveryQuiz,
  isUserHasAnyCharacter,
  onBackButtonClick,
  handleUpdateWishlistProductList,
  handleUpdateFeedback,
  navigateToQuizSelectionPage,
}) => {
  return (
    <div className="app-product-page-container">
      <div className="btn-container">
        <CTAButton btnIcon="leftarrow" btnText={localeCommon.commonMessages.back} handleBtnClick={onBackButtonClick} />
      </div>
      <ProductDetails
        discoveryQuiz={discoveryQuiz}
        feedback={feedback}
        handleUpdateFeedback={handleUpdateFeedback}
        handleUpdateWishlistProductList={handleUpdateWishlistProductList}
        isFeedbackEnabled={isFeedbackEnabled}
        isProductInWishlist={isProductInWishlist}
        isProductListLoading={isProductListLoading}
        isShowPromoLabel={isShowPromoLabel}
        isUserHasAnyCharacter={isUserHasAnyCharacter}
        isWishlistEnabled={isWishlistEnabled}
        locale={locale}
        navigateToQuizSelectionPage={navigateToQuizSelectionPage}
        productInstanceData={productInstanceData}
        shouldDisplayContactProducerBtn={shouldDisplayContactProducerBtn}
      />
      <Tabs {...tabsProps} gradientVariant="default-down" />
    </div>
  );
};

export default AppProductPage;
