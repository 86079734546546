import { useNavigate } from 'react-router-dom';

import { useCharacters } from '@lib/core/characters/hooks';
import { isCharacterByIdentifiers } from '@lib/core/characters/utils/filters';
import { useApp } from '@lib/core/service/hooks';
import { prependBasename } from '@lib/core/service/utils';
import { useUser } from '@lib/core/users/hooks';
import { REGISTRATION_URL_PARAM } from '@lib/tools/shared/auths/helpers/consts';
import { PAGES } from '@lib/tools/views/urls';

import RegistrationBanner from '@components/web/src/components/Authentication/RegistrationBanner/RegistrationBanner';
import CharacterDetailsComponent from '@components/web/src/components/CharacterDetails/CharacterDetails';
import CharacterDetailsSkeleton from '@components/web/src/components/Skeleton/CharacterDetailsSkeleton/CharacterDetailsSkeleton';
import * as S from '@components/web/src/pages/widget/Catalog/styles';

const CatalogContainerHOC = ({ children }) => {
  const { isUserAuthenticated, isFetchingUserData } = useUser();

  const { locale } = useApp();
  const { productCategory } = useApp();
  const navigate = useNavigate();

  const { characters } = useCharacters();
  const translatedUserCharacterData = characters.find(character => isCharacterByIdentifiers(character));

  const navigateToRegistrationPage = (param?: string) =>
    navigate(
      prependBasename(PAGES.vinhood.registration, {
        ...(param && { [REGISTRATION_URL_PARAM]: param }),
      }),
      {
        state: { isFromResultPage: true },
      },
    );

  return (
    <S.CatalogContainerHoc data-testid={`CatalogContainerHOC-${productCategory}`}>
      {isFetchingUserData && <CharacterDetailsSkeleton productCategory={productCategory} />}
      {!isFetchingUserData && translatedUserCharacterData && (
        <CharacterDetailsComponent character={translatedUserCharacterData} locale={locale} />
      )}
      {children}
      {!isUserAuthenticated && (
        <S.RegistrationBannerWrapper>
          <RegistrationBanner handleNavigateToRegistrationPage={navigateToRegistrationPage} />
        </S.RegistrationBannerWrapper>
      )}
    </S.CatalogContainerHoc>
  );
};

export default CatalogContainerHOC;
