import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';

import { TProductInstance } from '@lib/core/products/types';
import { parseProductInstance } from '@lib/core/products/utils';
import { HARDCODED_FEEDBACK_DATA, isApplicationKiosk } from '@lib/core/service/consts';
import { TFeedback } from '@lib/core/users/slices/feedbacks';
import MixpanelTracker from '@lib/tools/dat/mixpanel';
import { fetchDiscoveryQuizProducts, setDiscoveryQuizProductInstance } from '@lib/tools/discoveryQuiz/slices';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';

import FeedbackListItem from '@components/web/src/atoms/Feedback/FeedbackListItem/FeedbackListItem';
import CloseButton from '@components/web/src/components/Button/CloseButton/CloseButton';
import ModalWrapper from '@components/web/src/templates/Modals/Events/ModalWrapper';

interface IProps {
  isModalOpen: boolean;
  feedback: TFeedback;
  productInstanceData: TProductInstance;
  hideModal: () => void;
  handleOpenDiscoveryQuizModal: () => void;
  handleUpdateFeedback: (feedback: TFeedback, productId: string) => void;
}

const FeedbackModal: FC<IProps> = ({
  isModalOpen,
  feedback,
  productInstanceData,
  hideModal,
  handleOpenDiscoveryQuizModal,
  handleUpdateFeedback,
}) => {
  const dispatch = useDispatch();

  const { ratingModal } = localeCommon;
  const { titleText, descriptionText } = ratingModal;

  const { productName, productId, productProducerName } = parseProductInstance({ productInstanceData });

  const [activeFeedback, setActiveFeedback] = useState(feedback);

  const orderedFeedbacks = Object.values(HARDCODED_FEEDBACK_DATA).sort((a, b) => b.value - a.value);

  return (
    <ModalWrapper
      hideModal={hideModal}
      isApplicationKiosk={isApplicationKiosk}
      isOpen={isModalOpen}
      modalClassName="feedback-modal-wrapper"
    >
      <div className="feedback-modal-container">
        <CloseButton className="close-button" handleClick={hideModal} />
        <div className="content-wrapper">
          <div className="title-wrapper">
            {productName && <p className="product-title">{productName}</p>}
            {productProducerName && <p className="producer-name">{productProducerName}</p>}
          </div>
          <div className="text-wrapper">
            <p className="title">
              <LocaleFragment message={titleText} />
            </p>
            <p className="description">
              <LocaleFragment message={descriptionText} />
            </p>
          </div>
          <div className="feedback-list">
            {orderedFeedbacks.map((data, index) => (
              <FeedbackListItem
                key={index}
                feedback={data.value}
                isActive={data.value === activeFeedback}
                onHandleClick={async () => {
                  setActiveFeedback(data.value);
                  await handleUpdateFeedback(data.value, productId);

                  if (!feedback) {
                    dispatch(fetchDiscoveryQuizProducts(productInstanceData));
                    dispatch(setDiscoveryQuizProductInstance(productInstanceData));
                    handleOpenDiscoveryQuizModal();
                  }

                  MixpanelTracker.events.productFeedback(productInstanceData, data.value);

                  hideModal();
                }}
              />
            ))}
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default FeedbackModal;
