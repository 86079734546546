import { useSelector } from 'react-redux';

import { selectCommsHandshake, selectCommsHostCustomId, selectCommsHostRedirectUrl } from '@lib/tools/comms/selectors';
import { IComms } from '@lib/tools/comms/slices';

export const useComms = (): IComms => {
  return {
    hostCustomId: useSelector(selectCommsHostCustomId),
    hostRedirectUrl: useSelector(selectCommsHostRedirectUrl),
    isHostHandshakeApproved: useSelector(selectCommsHandshake),
  };
};
