import { B2C_MODALS } from '@lib/core/service/consts';
import { localeApp } from '@lib/tools/locale/source/web/app';
import { LocaleUtils } from '@lib/tools/locale/utils';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';
import { useModals } from '@lib/tools/modals/hooks';

import Checkbox from '@components/web/src/components/Checkbox/Checkbox';

const PrivacyPolicyAcceptance = ({
  isPrivacyProfilingPolicyChecked,
  isOptionalEmailPolicyChecked,
  setIsPrivacyProfilingPolicyChecked,
  setIsOptionalEmailPolicyChecked,
}) => {
  const { openModal } = useModals();

  const { privacyPolicyAcceptance } = localeApp;

  const { publishedTerms } = LocaleUtils;

  const emailPolicyCheckboxText = publishedTerms[privacyPolicyAcceptance.emailPolicyCheckboxText.id];

  const replacedText = (
    <LocaleFragment
      message={privacyPolicyAcceptance.privacyPolicyCheckboxText}
      insertIntlJSXPartOptions={{
        className: 'link-text-wrapper',
        linkId: 'privacy',
        linkRender: text => (
          <span
            aria-hidden
            className="link-text-wrapper"
            style={{ cursor: 'pointer', textDecoration: 'underline' }}
            onClick={e => {
              e.preventDefault();
              openModal(B2C_MODALS.PRIVACY_POLICY_MODAL);
            }}
          >
            <p className="no-margin d-inline">{text}</p>
          </span>
        ),
      }}
    />
  );
  return (
    <div className="privacy-policy-checkbox-container">
      <Checkbox
        id="privacyPolicyCheckbox"
        isSelected={isPrivacyProfilingPolicyChecked}
        label={replacedText}
        // text={privacyPolicyCheckboxText}
        onChange={() => setIsPrivacyProfilingPolicyChecked(curr => !curr)}
      />
      <Checkbox
        id="optionalEmailPolicyCheckbox"
        isDisabled={!isPrivacyProfilingPolicyChecked}
        isSelected={isOptionalEmailPolicyChecked}
        text={emailPolicyCheckboxText}
        onChange={() => setIsOptionalEmailPolicyChecked(curr => !curr)}
      />
    </div>
  );
};

export default PrivacyPolicyAcceptance;
