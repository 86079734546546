import { useSelector } from 'react-redux';

import { useCharacters } from '@lib/core/characters/hooks';
import { getCharactersBySubCategory } from '@lib/core/characters/utils';
import { selectLocale } from '@lib/core/service/selectors';

import CharactersMapScroller from '@components/web/src/components/Character/CharactersMapScroller/CharactersMapScroller';

const CharacterMapScrollerContainer = ({ productCategory, characterName }) => {
  const { characters } = useCharacters();
  const charactersBySubCategory = getCharactersBySubCategory(productCategory, characters);

  const charactersCount = Object.values(charactersBySubCategory).reduce((sum, currentArray) => {
    return sum + (Array.isArray(currentArray) ? currentArray.length : 0);
  }, 0);

  const lang = useSelector(selectLocale);

  return (
    <CharactersMapScroller
      charactersBySubCategory={charactersBySubCategory}
      charactersCount={charactersCount}
      groupCount={Object.keys(charactersBySubCategory).length}
      lang={lang}
      productCategory={productCategory}
      userCharacterName={characterName}
    />
  );
};

export default CharacterMapScrollerContainer;
