import { useEffect, useState } from 'react';
import { ThemeProvider as ThemeProviderInstance } from 'styled-components';

import { useTheme } from '@lib/tools/views/hooks/useTheme';

import { ROOT_ELEMENT_KIOSK } from '@components/web/src/foundations/consts';
import { STATIC_COLORS, deviceSize } from '@components/web/src/foundations/index';

export const ThemeProvider = ({ children }) => {
  const { primary, secondary } = useTheme();
  const [rootElementType, setRootElementType] = useState(null);

  const setThemeColors = (primaryColors, secondaryColors) => {
    Object.keys(primaryColors).forEach(variant => {
      const primaryVarName = `--color-primary${variant}`;
      const secondaryVarName = `--color-secondary${variant}`;

      document.documentElement.style.setProperty(primaryVarName, primaryColors[variant]);
      document.documentElement.style.setProperty(secondaryVarName, secondaryColors[variant]);
    });
  };

  useEffect(() => {
    const rootElement = document.getElementById('root-element');
    if (rootElement) {
      setRootElementType(rootElement.className);
    }
    setThemeColors(primary, secondary);
  }, [primary, secondary]);

  const theme = {
    breakpoints: { ...deviceSize },
    colors: {
      primaryColor: primary,
      secondaryColor: secondary,
      staticColors: { ...STATIC_COLORS },
    },
    isKioskRootElementType: rootElementType === ROOT_ELEMENT_KIOSK,
    rootElementType,
  };
  return <ThemeProviderInstance theme={theme}>{children}</ThemeProviderInstance>;
};
