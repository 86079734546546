import cn from 'classnames';
import { FC } from 'react';

import { TLanguage } from '@lib/core/retailers/types';
import { useDisclosure } from '@lib/tools/views/hooks/useDisclosure';

import { ReactComponent as BellIcon } from '@components/web/src/assets/legacy/b2c/icons/bell-icon.svg';
import { ReactComponent as SearchIcon } from '@components/web/src/assets/legacy/b2c/icons/search-icon.svg';
import { ReactComponent as HeaderLogo } from '@components/web/src/assets/legacy/b2c/icons/vh-logo.svg';
import LanguageSwitcher from '@components/web/src/components/LanguageSwitcher/LanguageSwitcher';
import SearchModal from '@components/web/src/templates/Modals/SearchModal/SearchModal';

export type HeaderAppProps = {
  is_notification_enabled?: boolean;
  is_search_enabled?: boolean;
  is_show_language?: boolean;
  onHeaderIconClick?: () => void;
  isHeaderShown?: boolean;
  className?: string;
  retailerLanguages: TLanguage[];
  locale: string;
};
const HeaderApp: FC<HeaderAppProps> = ({
  is_notification_enabled = false,
  is_search_enabled = false,
  is_show_language = false,
  onHeaderIconClick,
  isHeaderShown = true,
  className,
  retailerLanguages,
  locale,
}) => {
  const [isOpenSearchModal, { open: openSearchModal, close: closeSearchModal }] = useDisclosure();

  const handleSearchClick = () => openSearchModal();

  return isHeaderShown ? (
    <>
      <div className={cn('header-app-container', className, { 'over-all': is_show_language })}>
        <HeaderLogo onClick={onHeaderIconClick} />
        <div className="header-app-menu">
          {is_notification_enabled && <BellIcon />}
          {is_search_enabled && <SearchIcon onClick={handleSearchClick} />}
          {is_show_language && (
            <LanguageSwitcher languageSwitcherType="select" locale={locale} retailerLanguages={retailerLanguages} />
          )}
        </div>
      </div>
      <SearchModal hideModal={closeSearchModal} isModalOpen={isOpenSearchModal} />
    </>
  ) : null;
};

export default HeaderApp;
