import cn from 'classnames';
import { FC } from 'react';

interface IProps {
  handleClick: () => void;
  className?: string;
  mode?: 'dark' | 'light';
  type?: 'regular' | 'bold';
}

const CloseButton: FC<IProps> = ({ handleClick, className, mode = 'dark', type = 'regular' }) => (
  <button className={cn(className, `close-button`, mode, type)} type="button" onClick={handleClick} />
);

export default CloseButton;
