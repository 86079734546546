import cn from 'classnames';
import { Range as LibRange, createSliderWithTooltip } from 'rc-slider';
import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';

import { setPriceRangeValues } from '@lib/tools/filterManager/slices/rangeFilterSlice';
import LocaleFragment, { ILocaleText } from '@lib/tools/locale/views/LocaleFragment';
import { useTypedSelector } from '@lib/tools/views/hooks';

import { ReactComponent as MinusIcon } from '@components/web/src/assets/legacy/b2c/icons/filter_minus.svg';
import { ReactComponent as PlusIcon } from '@components/web/src/assets/legacy/b2c/icons/filter_plus.svg';
import { ReactComponent as PriceIcon } from '@components/web/src/assets/legacy/b2c/icons/filter_price.svg';

const RangeWithTooltip = createSliderWithTooltip(LibRange);

type Props = {
  title: ILocaleText;
};

const Range = ({ min, max, values, setValues }) => {
  const handleStyles = [
    {
      borderColor: 'transparent',
      boxShadow: 'unset',
      marginTop: -10,
    },
    {
      borderColor: 'transparent',
      boxShadow: 'unset',
      marginTop: -10,
    },
  ];

  const railStyle = { backgroundColor: '#EAE9E3', borderRadius: '100vmax', height: 8 };

  return (
    <RangeWithTooltip
      draggableTrack
      allowCross={false}
      className="range-slider-vinhood"
      dotStyle={{ display: 'none' }}
      handleStyle={handleStyles}
      max={max}
      min={min}
      pushable={1}
      railStyle={railStyle}
      step={1}
      trackStyle={[{ borderRadius: 0, cursor: 'grab', height: 8 }]}
      value={values}
      tipProps={{
        prefixCls: 'slider-tooltip',
        visible: false,
      }}
      onChange={setValues}
    />
  );
};

const PriceRange: FC<Props> = ({ title }) => {
  const dispatch = useDispatch();
  const {
    range: { newMax, newMin, defaultMax, defaultMin },
  } = useTypedSelector(state => state.productFilters);
  const [isOpened, setIsOpened] = useState(false);

  const isChecked = defaultMax !== newMax || defaultMin !== newMin;

  const setValues = e => {
    const [newMinValue, newMaxValue] = e;
    dispatch(setPriceRangeValues({ newMax: newMaxValue, newMin: newMinValue }));
  };
  return (
    <div className="filter-price-range">
      <div aria-hidden className={cn('main-container', { isOpened })} onClick={() => setIsOpened(prev => !prev)}>
        <div className="wrapper">
          <div className={cn('icon', { isChecked })}>
            <PriceIcon />
          </div>
          <p className={cn('title', { isChecked })}>
            <LocaleFragment message={title} />
          </p>
        </div>
        <button className="button" type="button">
          {isOpened ? <MinusIcon /> : <PlusIcon />}
        </button>
      </div>
      {isOpened && (
        <div className="progressbar-container">
          <p className="price">
            <span className="price-value">{newMin}</span>
            <span className="price-currency">€</span> - <span className="price-value">{newMax}</span>
            <span className="price-currency">€</span>
          </p>
          <div className="range-wrapper">
            <Range
              max={Math.round(defaultMax)}
              min={Math.floor(defaultMin)}
              setValues={setValues}
              values={[newMin, newMax]}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default PriceRange;
