import { useDispatch, useSelector } from 'react-redux';

import {
  selectFeedbackData,
  selectIsFeedbackLoading,
  selectLastUpdatedFeedbackId,
} from '@lib/core/users/selectors/feedback';
import { TFeedback, actionUpdateFeedback } from '@lib/core/users/slices/feedbacks';

export const useFeedback = () => {
  const dispatch = useDispatch();

  const feedbackData = useSelector(selectFeedbackData);
  const isFeedbackLoading = useSelector(selectIsFeedbackLoading);
  const lastUpdatedFeedbackId = useSelector(selectLastUpdatedFeedbackId);

  const handleUpdateFeedback = (feedback: TFeedback, productId: string) =>
    dispatch(actionUpdateFeedback({ feedback, productId }));

  return { feedbackData, handleUpdateFeedback, isFeedbackLoading, lastUpdatedFeedbackId };
};
