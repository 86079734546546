import CaretDown from '@components/web/src/shared/Icons/CaretDown';

type Props = {
  activeLanguage: string;
  showLanguageList: boolean;
  isBlackVariant: boolean;
};
const SelectedLanguage = ({ activeLanguage, showLanguageList, isBlackVariant }: Props) => {
  return (
    <div className="selected-language-container">
      <span className={!showLanguageList && isBlackVariant ? 'white-language-text' : 'black-language-text'}>
        {activeLanguage.toUpperCase()}
      </span>
      <CaretDown fillColor={!showLanguageList && isBlackVariant ? 'white' : 'black'} />
    </div>
  );
};

export default SelectedLanguage;
