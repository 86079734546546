import cn from 'classnames';
import { FC, useMemo } from 'react';
import { FieldErrors } from 'react-hook-form';
import Select, { StylesConfig, components } from 'react-select';

import LocaleFragment, { ILocaleText } from '@lib/tools/locale/views/LocaleFragment';

const { SingleValue, Option } = components;

export type ICountryOption = {
  name: string;
  flag: string;
  code: string;
};

interface IProps {
  labelTitle: ILocaleText;
  name: string;
  className?: string;
  errors?: FieldErrors;
  value: string;
  options: ICountryOption[];
  isSearchable: boolean;
  onChange: (value: string) => void;
}

const customStyles: StylesConfig<ICountryOption, false> = {
  control: defaultStyles => ({
    ...defaultStyles,
    border: 'none',
    borderRadius: 8,
    height: '100%',
    width: '100%',
  }),
  input: defaultStyles => ({
    ...defaultStyles,
    margin: 0,
    padding: 0,
    paddingLeft: 16,
  }),
  menu: defaultStyles => ({
    ...defaultStyles,
    borderBottomLeftRadius: 12,
    borderBottomRightRadius: 12,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    marginBottom: 0,
    marginTop: 1,
    overflow: 'hidden',
    paddingTop: 4,
  }),
  option: (defaultStyles, props) => {
    return {
      ...defaultStyles,
      backgroundColor: props.isSelected ? '#1E8851' : 'white',
      color: props.isSelected ? 'white' : '#101828',
      margin: 0,
      paddingBottom: 16,
      paddingTop: 16,
    };
  },
  placeholder: defaultStyles => ({
    ...defaultStyles,
    marginLeft: 16,
  }),
  singleValue: defaultStyles => ({
    ...defaultStyles,
    margin: 0,
  }),
  valueContainer: defaultStyles => ({
    ...defaultStyles,
    padding: 0,
  }),
};

export const IconSingleValue = props => {
  const { data } = props;
  return (
    <SingleValue {...props} className="countrySelect-option">
      <img alt="" className="countrySelect-flagIcon" src={data.flag} />
      <div className="label">{data.name}</div>
    </SingleValue>
  );
};

export const IconOption = props => {
  const { data } = props;
  return (
    <Option {...props} className="countrySelect-option">
      <img alt="" className="countrySelect-flagIcon" src={data.flag} />
      <div className="label">{data.name}</div>
    </Option>
  );
};

const CountrySelection: FC<IProps> = ({
  className,
  errors,
  name,
  onChange,
  value,
  labelTitle,
  options,
  isSearchable,
}) => {
  const error = !!errors && !!errors[name] && errors[name].message;
  const getOptionValue = option => option.code;
  const getOptionLabel = option => option.name;
  const selectedValue = useMemo(() => options.find(current => current.code === value), [options, value]);

  return (
    <label
      className={cn('country-selection-container', className, { 'b2c-settings-countrySelect-invalid': !!error })}
      htmlFor="country"
    >
      <div className="title">
        <LocaleFragment message={labelTitle} />
      </div>
      <Select
        className="customCountrySelect"
        components={{ IndicatorSeparator: null, Option: IconOption, SingleValue: IconSingleValue }}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        isSearchable={isSearchable}
        options={options}
        placeholder=""
        styles={customStyles}
        value={selectedValue}
        onChange={selectedItem => onChange(selectedItem.code)}
      />
      <div
        className={cn('info', {
          'info-error': error,
        })}
      >
        {JSON.stringify(error)}
      </div>
    </label>
  );
};

export default CountrySelection;
