/* eslint-disable import/no-extraneous-dependencies */
import { Document, Image, PDFDownloadLink, Page, Text, View } from '@react-pdf/renderer';
import { toDataURL } from 'qrcode';
import { Fragment, useEffect, useRef } from 'react';
import QRCode from 'react-qr-code';

import { TProductInstance } from '@lib/core/products/types';

import HeaderLogoUrl from '@components/web/src/assets/png/vinhood_logo_png.png';
import DownloadButton from '@components/web/src/components/Button/DownloadButton/DownloadButton';
import { typographyFontsForPDF } from '@components/web/src/foundations/ReactPDF/styles';
import {
  borderLineStyles,
  featureItemStyles,
  productDetailsPdfStyles,
  progressBarStyles,
  recommendationListItemStyles,
  tagItemStyles,
} from '@components/web/src/templates/ProductDetailsPdfPage/styles';

export type IStaticTextsPDF = {
  perfectSituationText: string;
  poweredByText: string;
  recommendedTitleText: string;
  scanDescriptionText: string;
  scanHereText: string;
  uniqueFeatureText: string;
  whenToDrink: string;
};

export type TProductDetailsPdfProps = {
  product: TProductInstance;
  productTags: { tagName: string }[];
  productIdentities: { description: string; title: string }[];
  recommendationList: { image: string; title: string; subtitle: string }[];
  qrDataURL: string;
  progressBarData: { title: string; progress: number }[];
  retailerLogo: string;
  retailerName: string;
  staticTexts: IStaticTextsPDF;
};

export type TProductDetailsPdfPageProps = {
  productDetails: TProductDetailsPdfProps;
  fileName: string;
  handleDownloadClick?: () => void;
  handleQRDataURLChange: (dataURL: string) => void;
  urlToGenerateQR: string;
};

const PdfProgressBar = ({ progress = 20, title = '' }) => (
  <View style={progressBarStyles.container}>
    <View style={progressBarStyles.progressBarTextContainer}>
      <Text style={typographyFontsForPDF.mpBody2Medium}>{title}</Text>
    </View>
    <View style={progressBarStyles.progressBarContainer}>
      <View style={{ ...progressBarStyles.progressBar, width: `${progress}%` }} />
    </View>
  </View>
);

const RecommendationListItem = ({ src = '', title = '', subtitle = ' ' }) => {
  return (
    <View style={recommendationListItemStyles.recommendedWrapper}>
      <Image cache={false} src={src} style={recommendationListItemStyles.recommendedImageSection} />
      <View style={recommendationListItemStyles.recommendedContent}>
        <Text style={typographyFontsForPDF.mpBody2Regular}>{subtitle}</Text>
        <Text style={typographyFontsForPDF.mpBody2Semibold}>{title}</Text>
      </View>
    </View>
  );
};

const BorderLine = () => <View style={borderLineStyles.borderLine} />;

const FeatureItem = ({ description, title }: { description: string; title: string }) => {
  return (
    <View style={featureItemStyles.featureItemWrapper}>
      <Text style={typographyFontsForPDF.mpBody3Regular}>{title}:</Text>
      <Text style={typographyFontsForPDF.mpBody3Medium}>{description}</Text>
    </View>
  );
};

const TagItem = ({ tagName }) => (
  <View style={tagItemStyles.tagItemContainer}>
    <Text style={tagItemStyles.tagText}>{tagName}</Text>
  </View>
);

const QRCodeGeneratorForPDF = ({ value, onChange }) => {
  const qrCodeCanvasRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const generateQRCode = async () => {
      try {
        const dataURL = await toDataURL(value);
        onChange(dataURL);
      } catch (error) {
        console.error('Error generating QR code', error);
      }
    };
    generateQRCode();
  }, [value, onChange]);

  return (
    <div ref={qrCodeCanvasRef} style={{ display: 'none' }}>
      <QRCode value={value} />
    </div>
  );
};

const ProductDetailsPdf = ({
  product,
  productTags,
  productIdentities,
  recommendationList,
  qrDataURL,
  progressBarData,
  retailerLogo,
  retailerName,
  staticTexts,
}: TProductDetailsPdfProps) => {
  const { product: productDetails, character } = product || {};

  const { image: productImage, region, name: productName } = productDetails || {};

  const { name: characterName, techCaption } = character || {};

  return (
    <Document>
      <Page orientation="portrait" size="A4" style={productDetailsPdfStyles.page} wrap={false}>
        <View style={productDetailsPdfStyles.productOwnerImageWrapper}>
          {retailerLogo && <Image cache={false} src={retailerLogo} style={productDetailsPdfStyles.productOwnerImage} />}
        </View>
        <View style={productDetailsPdfStyles.flexRow}>
          <View style={productDetailsPdfStyles.productImageSection}>
            {productImage && <Image cache={false} src={productImage} style={productDetailsPdfStyles.productImage} />}
            <View style={productDetailsPdfStyles.tagsContainer}>
              {productTags?.map(item => <TagItem key={item.tagName} tagName={item.tagName} />)}
            </View>
          </View>
          <View style={productDetailsPdfStyles.flexColumn}>
            <Text style={productDetailsPdfStyles.retailerName}>{retailerName}</Text>
            <Text style={typographyFontsForPDF.frauncesSubtitle1Bold}>{productName}</Text>
            <Text style={typographyFontsForPDF.mpBody1Regular}>
              {region?.[0]?.name} {region?.[0]?.country}
            </Text>
            <Text style={typographyFontsForPDF.mpBody2Medium}>{`#${characterName} . ${techCaption}`}</Text>
            {progressBarData?.map(progressBarItem => (
              <PdfProgressBar key={progressBarItem.title} {...progressBarItem} />
            ))}
          </View>
        </View>
        <View style={productDetailsPdfStyles.productDetailsSection}>
          <View style={productDetailsPdfStyles.productDetailsLeftSection}>
            <View style={productDetailsPdfStyles.perfectSituationFlexWrapper}>
              <Text style={typographyFontsForPDF.frauncesSubtitle2Bold}>{staticTexts.perfectSituationText}</Text>
              <Text style={typographyFontsForPDF.mpBody2Medium}>{staticTexts.whenToDrink}</Text>
            </View>
            <View style={productDetailsPdfStyles.featuresListWrapper}>
              <Text style={typographyFontsForPDF.frauncesSubtitle2Bold}>{staticTexts.uniqueFeatureText}</Text>
              <View style={productDetailsPdfStyles.featuresListContainer}>
                {productIdentities?.map((productIdentity, idx) => {
                  if (productIdentity?.description) {
                    return (
                      <Fragment key={productIdentity?.description}>
                        <FeatureItem description={productIdentity?.description} title={productIdentity?.title} />
                        {idx !== productIdentities.length - 1 && <BorderLine />}
                      </Fragment>
                    );
                  }
                  return null;
                })}
              </View>
            </View>
          </View>
          <View style={productDetailsPdfStyles.flexColumn}>
            <Text style={typographyFontsForPDF.frauncesSubtitle2Bold}>{staticTexts.recommendedTitleText}</Text>
            <View style={productDetailsPdfStyles.rightSideDetailsSection}>
              <View style={productDetailsPdfStyles.recommendedSection}>
                {recommendationList?.map(recommendation => (
                  <RecommendationListItem
                    key={recommendation.title}
                    src={recommendation.image}
                    subtitle={recommendation.subtitle}
                    title={recommendation.title}
                  />
                ))}
              </View>
              <View style={productDetailsPdfStyles.qrContentWrapper}>
                <View style={productDetailsPdfStyles.qrCodeSection}>
                  {qrDataURL && <Image src={qrDataURL} style={productDetailsPdfStyles.qrImageSection} />}
                  <Text style={productDetailsPdfStyles.scanInfoText}>{staticTexts.scanHereText}</Text>
                </View>
                <View style={productDetailsPdfStyles.scanTextSection}>
                  <Text style={typographyFontsForPDF.frauncesBody1Bold}>{staticTexts.scanDescriptionText}</Text>
                  <View style={productDetailsPdfStyles.scanLogoSection}>
                    <Text style={typographyFontsForPDF.mpBody3Medium}>{staticTexts.poweredByText}</Text>
                    {HeaderLogoUrl && <Image src={HeaderLogoUrl} style={productDetailsPdfStyles.appLogoImage} />}
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

const ProductDetailsPdfPage = ({
  urlToGenerateQR,
  handleQRDataURLChange,
  productDetails,
  fileName,
  handleDownloadClick,
}: TProductDetailsPdfPageProps) => {
  return (
    <>
      <QRCodeGeneratorForPDF value={urlToGenerateQR} onChange={handleQRDataURLChange} />
      <PDFDownloadLink
        document={<ProductDetailsPdf {...productDetails} />}
        fileName={fileName}
        style={productDetailsPdfStyles.pdfWrapper}
      >
        {({ url, loading }) => url && !loading && <DownloadButton handleDownloadClick={handleDownloadClick} />}
      </PDFDownloadLink>
    </>
  );
};

export default ProductDetailsPdfPage;
