import cn from 'classnames';
import { FC } from 'react';

import { ReactComponent as WishlistIcon } from '@components/web/src/assets/legacy/icons/wishlist.svg';
import { ReactComponent as WishlistFill } from '@components/web/src/assets/legacy/icons/wishlist_fill.svg';
import { ReactComponent as WishlistSplashIcon } from '@components/web/src/assets/legacy/icons/wishlist_splash.svg';

interface IProps {
  isActive: boolean;
  isAnimationEnded?: boolean;
  isAnimationNotStarted?: boolean;
}

const WishlistAnimation: FC<IProps> = ({ isActive, isAnimationEnded = false, isAnimationNotStarted = false }) => {
  return (
    <div className="wishlist-animation-container">
      {isAnimationNotStarted && <WishlistIcon />}
      {isActive && (
        <>
          <WishlistIcon className={cn({ 'rotate-and-scale': isActive })} />
          <WishlistFill className={cn('hidden-absolute', { 'fade-in': isActive })} />
          <WishlistSplashIcon className={cn('hidden-absolute', { 'scale-and-splash': isActive })} />
        </>
      )}
      {isAnimationEnded && <WishlistFill />}
    </div>
  );
};

export default WishlistAnimation;
