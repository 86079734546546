import { FC } from 'react';

import { ReactComponent as ArrowRightCircleIcon } from '@components/web/src/assets/legacy/icons/btn_arrow_right_circle_icon.svg';
import { ReactComponent as CircleIcon } from '@components/web/src/assets/legacy/icons/btn_circle_icon.svg';
import { ReactComponent as FilterIcon } from '@components/web/src/assets/legacy/icons/btn_filter_icon.svg';
import { ReactComponent as RocketIcon } from '@components/web/src/assets/legacy/icons/btn_rocket_icon.svg';
import * as S from '@components/web/src/atoms/Buttons/styles';
import { IButtonProps, TButtonIconVariants } from '@components/web/src/atoms/Buttons/types';
import { Text } from '@components/web/src/foundations/Text/Text';
import LocationIcon from '@components/web/src/shared/Icons/LocationIcon';
import StackIcon from '@components/web/src/shared/Icons/StackIcon';

const Button: FC<IButtonProps> = ({
  disabled = false,
  size = 'sm',
  fontSize = 'body1',
  text,
  variant = 'primary',
  iconVariant = 'none',
  handleClick,
  icon,
  type = 'button',
  iconPosition = 'left',
  textWeight = 'medium',
  localeOptions,
  ...rest
}) => {
  const icons: TButtonIconVariants = {
    arrow: <ArrowRightCircleIcon className="mp-btn-icon" />,
    circle: <CircleIcon />,
    filter: <FilterIcon className="mp-btn-icon" />,
    location: <LocationIcon className="mp-btn-icon" variant={variant} />,
    none: null,
    rocket: <RocketIcon className="mp-btn-icon" />,
    stack: <StackIcon className="mp-btn-icon" variant={variant} />,
  };

  const renderedIcon = icon || icons[iconVariant];

  return (
    <S.Button
      $hasText={!!text}
      $iconPosition={iconPosition}
      $size={size}
      $variant={variant}
      data-testid={`Button-${variant}-${size}-${disabled}-${iconPosition}`}
      disabled={disabled}
      type={type}
      onClick={handleClick}
      {...rest}
    >
      {renderedIcon && <S.Icon $iconPosition={iconPosition}>{renderedIcon}</S.Icon>}
      <Text localeOptions={localeOptions} size={fontSize} text={text} weight={textWeight} />
    </S.Button>
  );
};

export default Button;
