import { FC } from 'react';

import { TCharacter } from '@lib/core/characters/types';
import { parseCharacterDetails } from '@lib/core/characters/utils';
import { useApp } from '@lib/core/service/hooks';
import { useUser } from '@lib/core/users/hooks';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';
import { useDisclosure } from '@lib/tools/views/hooks/useDisclosure';

import CharacterModal from '@components/web/src/templates/Modals/CharacterModal/CharacterModal';

export type ICharacterDetailsProps = {
  locale?: string;
  character?: TCharacter;
};

const CharacterDetails: FC<ICharacterDetailsProps> = ({ locale, character }) => {
  const { isUserProfiled, userFirstName, isUserAuthenticated } = useUser();
  const { productCategory } = useApp();
  const isProfiledUser = isUserProfiled?.[productCategory];
  const isAuthenticatedProfiledUser = isProfiledUser && isUserAuthenticated;

  const { characterName, characterCaption, characterDescription, characterProductCategory } = parseCharacterDetails({
    character,
  });
  const { characterDetailMessages, productCategories, productCard } = localeCommon;
  const messages = characterDetailMessages;

  const currentProductTitleText = isAuthenticatedProfiledUser ? (
    <LocaleFragment
      message={messages.userCharacterTitleTextB2B}
      options={{
        userFirstName,
      }}
      variables={{
        productCategory: productCategories[productCategory],
      }}
    />
  ) : (
    <LocaleFragment
      message={messages.characterTitleTextB2B}
      variables={{
        productCategory: productCategories[productCategory],
      }}
    />
  );

  const [isCharacterModalOpen, { open: openCharacterModal, close: closeCharacterModal }] = useDisclosure({
    defaultIsOpen: false,
  });

  return (
    <div className={`character-details-wrapper bg-color-${characterProductCategory}`}>
      <div className="product-summary">
        <span className="product-summary-title">{currentProductTitleText}</span>
        <p className="product-character">#{characterName}</p>
        <p className="product-character-caption">{characterCaption}</p>
      </div>
      <p className="product-character-description">{characterDescription}</p>
      <button className="cta" type="button" onClick={() => openCharacterModal()}>
        <span>
          <LocaleFragment message={productCard.ctaGiveItBtn} />
        </span>
      </button>
      {isCharacterModalOpen && (
        <CharacterModal
          character={character}
          hideModal={closeCharacterModal}
          isModalOpen={isCharacterModalOpen}
          locale={locale}
        />
      )}
    </div>
  );
};

export default CharacterDetails;
