import { FC } from 'react';

import { HARDCODED_FEEDBACK_DATA } from '@lib/core/service/consts';
import { TFeedback } from '@lib/core/users/slices/feedbacks';
import { IFilterItem } from '@lib/tools/filterManager/slices/sublistFilterSlice';

import * as S from '@components/web/src/components/Catalog/Filter/ListItem/Sublist/styles';
import { ISublistItem } from '@components/web/src/components/Catalog/Filter/ListItem/Sublist/Sublist';
import { Image } from '@components/web/src/foundations/Image/Image';

interface IProps {
  listContent: ISublistItem[];
  selectedNamesObj: Record<string, IFilterItem>;
  onItemClick: (item: ISublistItem) => void;
}

const SublistRatingsList: FC<IProps> = ({ listContent, selectedNamesObj, onItemClick }) => (
  <S.SublistRatingList data-testid="SublistRatingList">
    {listContent.map((item, idx) => {
      const itemValue = Number(item.value) as TFeedback;

      const ratingIcon = selectedNamesObj[itemValue]?.isActive
        ? HARDCODED_FEEDBACK_DATA[itemValue]?.icon.activeIcon
        : HARDCODED_FEEDBACK_DATA[itemValue]?.icon.inactiveIcon;

      return (
        <S.SublistItem key={idx} $isRatingItem aria-hidden onClick={() => onItemClick(item)}>
          <Image alt="rating icon" height="48px" src={ratingIcon} width="48px" />
        </S.SublistItem>
      );
    })}
  </S.SublistRatingList>
);

export default SublistRatingsList;
