import { Fragment, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { selectRetailerAgreementsArray } from '@lib/core/retailers/selectors/retailer';
import { TRetailerAgreementItem } from '@lib/core/retailers/types';
import { selectAppAgreements } from '@lib/core/service/selectors/technical';
import { actionChangeAgreements, hardcodedListOfAgreementsIDs } from '@lib/core/service/slices/technical/agreements';
import { selectUserAgreementsArray } from '@lib/core/users/selectors/user';
import { useDisclosure } from '@lib/tools/views/hooks';
import { useTypedDispatch } from '@lib/tools/views/hooks/useTypedDispatch';

import { LEGAL_MODAL_TYPES } from '@components/web/src/foundations/consts';
import CookiePolicyModal from '@components/web/src/templates/Modals/CookiePolicyModal/CookiePolicyModal';
import PreferencesModal from '@components/web/src/templates/Modals/PreferencesModal/PreferencesModal';
import PrivacyPolicyModal from '@components/web/src/templates/Modals/PrivacyPolicyModal/PrivacyPolicyModal';
import TermsConditionsModal from '@components/web/src/templates/Modals/TermsConditionsModal/TermsConditionsModal';
import LegalPage from '@components/web/src/templates/TasteId/TasteIdLegalPage/TasteIdLegalPage';

type IModalTypes = 'privacy' | 'cookie' | 'termsAndConditions' | 'preferences';

interface ExtendedRetailerAgreementItem extends Partial<TRetailerAgreementItem> {
  description?: string;
}

const TasteIdLegalPageContainer = () => {
  const dispatch = useTypedDispatch();

  const navigate = useNavigate();

  const retailerAgreements = useSelector(selectRetailerAgreementsArray);
  const userAgreements = useSelector(selectUserAgreementsArray);
  const appAgreements = useSelector(selectAppAgreements);

  // This is also weird but i have no time to decrypt this further for now (logic should be completely refactored)
  const currentParsedAgreements = useMemo(() => {
    const availableAgreements: Record<string, ExtendedRetailerAgreementItem> = {};
    const allAgreements: Partial<ExtendedRetailerAgreementItem>[] = [...userAgreements, ...retailerAgreements];

    allAgreements.forEach(agreement => {
      const agreementId = hardcodedListOfAgreementsIDs[agreement.privacy_id];
      const existingAgreement = availableAgreements[agreementId];

      availableAgreements[agreementId] = {
        ...existingAgreement,
        ...agreement,
        description: agreement.text || existingAgreement?.description,
        isEnabled: appAgreements[agreementId],
      } as Partial<TRetailerAgreementItem>; // Use type assertion here
    });

    return availableAgreements;
  }, [userAgreements, retailerAgreements, appAgreements]);

  const [isPrivacyModalOpen, { open: openPrivacyModal, close: closePrivacyModal }] = useDisclosure({
    defaultIsOpen: false,
  });
  const [isCookieModalOpen, { open: openCookieModal, close: closeCookieModal }] = useDisclosure({
    defaultIsOpen: false,
  });
  const [isTermsConditionsModalOpen, { open: openTermsConditionsModal, close: closeTermsConditionsModal }] =
    useDisclosure({
      defaultIsOpen: false,
    });

  const [isPreferenceModalOpen, { open: openPreferenceModal, close: closePreferenceModal }] = useDisclosure({
    defaultIsOpen: false,
  });

  const onHandleModal = (modalType: IModalTypes) => {
    switch (modalType) {
      case LEGAL_MODAL_TYPES.PRIVACY:
        openPrivacyModal();
        break;
      case LEGAL_MODAL_TYPES.COOKIE:
        openCookieModal();
        break;
      case LEGAL_MODAL_TYPES.TERMS_AND_CONDITIONS:
        openTermsConditionsModal();
        break;
      case LEGAL_MODAL_TYPES.PREFERENCES:
        openPreferenceModal();
        break;
      default:
        break;
    }
  };

  const handleSavePreferences = preferencesOptions => {
    const parsedOptionsToSimpleObject = {};
    Object.keys(preferencesOptions).forEach(key => {
      parsedOptionsToSimpleObject[key] = preferencesOptions[key].isEnabled;
    });
    dispatch(actionChangeAgreements(parsedOptionsToSimpleObject)).then(() => {
      closePreferenceModal();
    });
  };

  const handleBackBtnClick = () => {
    navigate(-1);
  };

  return (
    <Fragment>
      <LegalPage handleBackBtnClick={handleBackBtnClick} onHandleModal={onHandleModal} />
      <PrivacyPolicyModal
        handleShowCookieModal={openCookieModal}
        hideModal={closePrivacyModal}
        isModalOpen={isPrivacyModalOpen}
      />
      <CookiePolicyModal hideModal={closeCookieModal} isModalOpen={isCookieModalOpen} />
      <TermsConditionsModal hideModal={closeTermsConditionsModal} isModalOpen={isTermsConditionsModalOpen} />
      <PreferencesModal
        agreements={currentParsedAgreements}
        handleSavePreferences={handleSavePreferences}
        hideModal={closePreferenceModal}
        isModalOpen={isPreferenceModalOpen}
      />
    </Fragment>
  );
};

export default TasteIdLegalPageContainer;
