import { FC } from 'react';

import { TProductCategory, TProductInstance } from '@lib/core/products/types';
import { TRetailerLocationStoreType } from '@lib/core/retailers/types';
import { IFeedbackData, TFeedback } from '@lib/core/users/slices/feedbacks';
import { feedbackFilter, isProductInWishlistFilter } from '@lib/core/users/utils/filters';
import { MP_POSITION_CONTEXT } from '@lib/tools/dat/mixpanel/consts';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import { PRODUCT_CARD_VARIANTS } from '@lib/tools/shared/helpers/consts';
import { useEqualElementsHeight } from '@lib/tools/views/hooks';

import { ReactComponent as ButtonArrow } from '@components/web/src/assets/icons/Product-journal-arror.svg';
import RatedProductsIcon from '@components/web/src/assets/icons/rated-products-icon.svg';
import SwiperHOC from '@components/web/src/components/Swiper/SwiperHOC';
import { Text } from '@components/web/src/foundations/Text/Text';
import ProductCard from '@components/web/src/organisms/Cards/ProductCard/ProductCard';
import * as S from '@components/web/src/templates/TasteId/TasteIdPage/ProductJournalSwiper/styles';

interface IProductJournalSwiperProps {
  isProductInWishlist: boolean;
  isFeedbackEnabled: boolean;
  wishlistProductIds: string[];
  feedbackData: IFeedbackData[];
  locale: string;
  productCategory?: TProductCategory;
  isWishlistEnabled: boolean;
  isProductListLoading: boolean;
  storeType: TRetailerLocationStoreType;
  productSwiperData: TProductInstance[];
  handleNavigateToTasteIdJournal: () => void;
  handleProductClick?: (link: string, params?: { [key: string]: string }) => void;
  handleUpdateWishlistProductList: (productId: string) => void;
  handleUpdateFeedback: (feedback: TFeedback, productId: string) => void;
}

const ProductJournalSwiper: FC<IProductJournalSwiperProps> = ({
  wishlistProductIds,
  feedbackData,
  isFeedbackEnabled,
  isProductListLoading = false,
  isWishlistEnabled = false,
  productSwiperData,
  productCategory,
  storeType,
  locale,
  handleUpdateFeedback,
  handleUpdateWishlistProductList,
  handleNavigateToTasteIdJournal,
}) => {
  const { productCategoriesPlural } = localeCommon;
  const { journalTitle, journalSubtitle, viewAllCTA } = localeCommon.tasteId;
  useEqualElementsHeight(['product-card']);

  return (
    <S.ProductJournalSwiper $isProductsSwiperAvailable={!!productSwiperData} data-testid="ProductJournalSwiper">
      <S.HeaderWrapper>
        <S.TitleWrapper>
          <Text
            fontFamily="Fraunces"
            localeOptions={{ productCategory }}
            size="h5"
            text={journalTitle}
            weight="semibold"
          />
          {productSwiperData?.length > 3 && (
            <S.StyledButton
              handleClick={handleNavigateToTasteIdJournal}
              icon={<ButtonArrow />}
              iconPosition="right"
              text={viewAllCTA}
              textWeight="medium"
              variant="secondary"
            />
          )}
        </S.TitleWrapper>
        {!productSwiperData && (
          <S.SubtitleWrapper>
            <img alt="rated-products-icon" src={RatedProductsIcon} />
            <Text
              localeIndex={{ productCategory }}
              localeVariables={{ productCategoryPluralText: productCategoriesPlural[productCategory] }}
              size="subtitle2"
              text={journalSubtitle}
            />
          </S.SubtitleWrapper>
        )}
      </S.HeaderWrapper>

      {!!productSwiperData && (
        <SwiperHOC spaceBetween={20}>
          {productSwiperData.slice(0, 3).map((productInstanceData, index) => (
            <ProductCard
              key={productInstanceData?.identifier}
              className="product-card"
              feedback={feedbackFilter(feedbackData, productInstanceData)}
              handleUpdateFeedback={handleUpdateFeedback}
              handleUpdateWishlistProductList={handleUpdateWishlistProductList}
              isFeedbackEnabled={isFeedbackEnabled}
              isLoading={false}
              isProductInWishlist={isProductInWishlistFilter(wishlistProductIds, productInstanceData)}
              isProductListLoading={isProductListLoading}
              isWishlistEnabled={isWishlistEnabled}
              locale={locale}
              mixpanelIndex={index}
              mixpanelPositionContext={MP_POSITION_CONTEXT.SWIPER}
              productInstanceData={productInstanceData}
              storeType={storeType}
              variant={PRODUCT_CARD_VARIANTS.COMPACT}
            />
          ))}
          {productSwiperData?.length > 3 && (
            <S.ButtonWrapper>
              <S.StyledButton
                handleClick={handleNavigateToTasteIdJournal}
                icon={<ButtonArrow />}
                iconPosition="right"
                text={viewAllCTA}
                textWeight="medium"
                variant="secondary"
              />
            </S.ButtonWrapper>
          )}
        </SwiperHOC>
      )}
    </S.ProductJournalSwiper>
  );
};

export default ProductJournalSwiper;
