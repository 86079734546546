import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { useRetailerLocation } from '@lib/core/retailers/hooks/retailerLocation';
import { GPRL_CHARACTER_QUERY } from '@lib/core/service/consts';
import { useApp } from '@lib/core/service/hooks';
import { fetchProductsListsLimited, resetProductCatalogState } from '@lib/core/service/slices/productCatalogSlice';
import { shuffleArray } from '@lib/core/service/utils';
import { useFeedback, useUser, useWishlist } from '@lib/core/users/hooks';
import MixpanelTracker from '@lib/tools/dat/mixpanel';
import useDiscoveryQuizData from '@lib/tools/discoveryQuiz/hooks';
import { actionResetCatalogFilters } from '@lib/tools/filterManager/slices/productFilter';
import { parseLimitedCatalogProduct } from '@lib/tools/shared/helpers';
import { CHARACTERS_URL_PARAM, LIMITED_CATALOG } from '@lib/tools/shared/helpers/consts';
import { useAddons, useTypedSelector } from '@lib/tools/views/hooks';
import { PAGES } from '@lib/tools/views/urls';

import LimitedCatalog, { ILimitedCatalogProps } from '@components/web/src/pages/widget/Catalog/LimitedCatalog';

const LimitedCatalogContainer = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const { isEnableLocationMapAddon, isEnableVusionAddon, isShowProductLocationAddon } = useAddons();

  const characterStringFromUrl = searchParams.get(CHARACTERS_URL_PARAM) ?? '';

  const { locale, productCategory } = useApp();

  const { retailerStoreType } = useRetailerLocation();
  const { userCharacterForSinglePCRetailer, isUserAuthenticated } = useUser();
  const currentCharacterID = characterStringFromUrl || userCharacterForSinglePCRetailer?.identifier;

  const {
    lastDataLoadedTime,
    isProductsRequestLoading,
    productsDataLimited: {
      first_priority: firstPriorityProducts = [],
      second_priority: secondPriorityProducts = [],
      third_priority: thirdPriorityProducts = [],
    },
  } = useTypedSelector(state => state.productCatalog);

  const handleProductsRequest = () => {
    if (!lastDataLoadedTime) {
      dispatch(fetchProductsListsLimited({ params: { [GPRL_CHARACTER_QUERY]: currentCharacterID } }));
    }
  };

  useEffect(() => {
    return () => {
      if (!window.location.pathname?.includes(PAGES.vinhood.product)) {
        dispatch(actionResetCatalogFilters());
        dispatch(resetProductCatalogState());
      }
    };
  }, []);
  const groupByIdentifier = <T extends { [key: string]: any }>(array: T[]): { [key: string]: T[] }[] => {
    const identifiers: { [key: string]: T[] } = {};

    array.forEach(item => {
      const { identifier } = item.product.character;
      if (identifier) {
        if (!identifiers[identifier]) {
          identifiers[identifier] = [];
        }
        identifiers[identifier].push(item);
      }
    });

    const allProductsArray: T[] = [];
    Object.values(identifiers).forEach(items => {
      allProductsArray.push(...shuffleArray(items));
    });

    return allProductsArray;
  };

  const { matchedProducts, listProducts } = useMemo(() => {
    const shuffledFirstPriorityProducts = shuffleArray(firstPriorityProducts);
    const shuffledSecondPriorityProducts = groupByIdentifier(secondPriorityProducts);
    const shuffledThirdPriorityProducts = groupByIdentifier(thirdPriorityProducts);
    const allProducts = [
      ...shuffledFirstPriorityProducts,
      ...shuffledSecondPriorityProducts,
      ...shuffledThirdPriorityProducts,
    ].map(parseLimitedCatalogProduct);

    const firstPriorityProductsParsed = shuffledFirstPriorityProducts.map(parseLimitedCatalogProduct);

    if (shuffledFirstPriorityProducts.length > 3) {
      return { listProducts: allProducts.slice(3, allProducts.length), matchedProducts: allProducts.slice(0, 3) };
    }
    if (shuffledFirstPriorityProducts.length >= 1 && shuffledFirstPriorityProducts.length < 4) {
      return {
        listProducts: allProducts.slice(shuffledFirstPriorityProducts.length, allProducts.length),
        matchedProducts: firstPriorityProductsParsed,
      };
    }
    return { listProducts: allProducts, matchedProducts: [] };
  }, [firstPriorityProducts, secondPriorityProducts, thirdPriorityProducts]);

  useEffect(() => {
    if (currentCharacterID) {
      handleProductsRequest();
    }
  }, [currentCharacterID]);

  useEffect(() => {
    MixpanelTracker.events.productCatalogView(productCategory);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { handleUpdateFeedback, feedbackData } = useFeedback();
  // TODO: replace useWishlist to useProductList after full start using useProductList
  const { wishlistProductIds, handleUpdateWishlistProductList, isProductListLoading } = useWishlist();

  const { discoveryQuiz } = useDiscoveryQuizData();

  const catalogProps: ILimitedCatalogProps = useMemo(
    () => ({
      catalogType: LIMITED_CATALOG,
      discoveryQuiz,
      feedbackData,
      handleProductsRequest,
      handleUpdateFeedback,
      handleUpdateWishlistProductList,
      isEnableLocationMapAddon,
      isEnableVusionAddon,
      isFeedbackEnabled: isUserAuthenticated,
      isProductListLoading,
      isProductsLoaded: !!lastDataLoadedTime,
      isProductsRequestLoading,
      isShowProductLocationAddon,
      isWishlistEnabled: isUserAuthenticated,
      locale,
      matchedProducts: matchedProducts || [],
      productCategory,
      products: listProducts,
      storeType: retailerStoreType,
      wishlistProductIds,
    }),
    [
      discoveryQuiz,
      feedbackData,
      isEnableLocationMapAddon,
      isEnableVusionAddon,
      isUserAuthenticated,
      isProductListLoading,
      isProductsRequestLoading,
      isShowProductLocationAddon,
      locale,
      matchedProducts,
      productCategory,
      listProducts,
      retailerStoreType,
      wishlistProductIds,
    ],
  );

  return <LimitedCatalog {...catalogProps} />;
};

export default LimitedCatalogContainer;
