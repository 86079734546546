import { FC } from 'react';

import { localeCommon } from '@lib/tools/locale/source/web/common';
import LocaleFragment, { ILocaleText } from '@lib/tools/locale/views/LocaleFragment';

import BottomOverlay from '@components/web/src/app/OverlayBackground/BottomOverlay';
import defaultBackgroundImage from '@components/web/src/assets/legacy/backgrounds/result_loading_background_image.jpeg';
import { FooterAppAnonymous } from '@components/web/src/atoms/Footers';

type IProps = {
  backgroundImage?: string;
  titleText?: ILocaleText;
  shouldDisplayFooter?: boolean;
};

const ResultsLoadingScreen: FC<IProps> = ({ backgroundImage, titleText, shouldDisplayFooter = false }) => {
  const { resultsLoadingScreen } = localeCommon;
  const defaultTitleText = resultsLoadingScreen.titleText;

  return (
    <div className="result-loading-screen-container">
      <div className="image-wrapper">
        <BottomOverlay backgroundImage={backgroundImage || defaultBackgroundImage} className="overlay-style" />
      </div>
      <div className="content">
        <div className="loader-wrapper">
          <div className="loader" />
        </div>
        <p className="title">
          <LocaleFragment message={titleText || defaultTitleText} />
        </p>
      </div>
      {shouldDisplayFooter && <FooterAppAnonymous shouldOpenModalsUnderHeader showGearIcon />}
    </div>
  );
};

export default ResultsLoadingScreen;
