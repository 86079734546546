import styled, { keyframes } from 'styled-components';

import { STATIC_COLORS, STATIC_SHADOWS } from '@components/web/src/foundations';

const fadeIn = (value: string) => keyframes`
  from {
    opacity: 0;
    transform: translateY(${value});
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const floating = (value: string) => keyframes`
	0% {
		transform: translateY(0px);
	}
	50% {
    transform: translateY(${value});
	}
	100% {
		transform: translateY(0px);
	}
`;

export const CharacterAdjectivesGroupContainer = styled.div`
  overflow: hidden;
`;

export const CharacterAdjectivesGroup = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px 16px 0;
  margin-bottom: 50px;
  background-color: ${STATIC_COLORS.base.white};

  &::after {
    position: absolute;
    content: '';
    bottom: -30px;
    left: 50%;
    z-index: 1;
    width: 1150px;
    height: 600px;
    overflow: hidden;
    background-color: #fff;
    border-radius: 50%;
    transform: translate(-50%, 0);
  }
`;

export const TextWrapper = styled.div`
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const CharacterImage = styled.img<{ $isTestResultPage: boolean }>`
  z-index: 2;
  margin: 16px auto 60px;
  height: 225px;
  width: auto;
  ${({ $isTestResultPage }) => $isTestResultPage && `margin-bottom: 100px;`}
`;

export const CharacterAdjectivesWrapper = styled.div`
  position: absolute;
  top: 130px;
  width: 100%;
  z-index: 20;

  & > * {
    position: absolute;
    opacity: 0;
    box-shadow: ${STATIC_SHADOWS.md};
  }

  & > *:nth-child(1) {
    top: 0;
    left: 15px;
    animation:
      ${fadeIn('100px')} 1000ms cubic-bezier(0.68, -0.4, 0.27, 1.55) 100ms forwards,
      ${floating('4px')} 6s ease-in-out 1100ms infinite;
  }

  & > *:nth-child(2) {
    top: 50px;
    right: 41px;
    animation:
      ${fadeIn('100px')} 1000ms cubic-bezier(0.68, -0.4, 0.36, 1.6) 500ms forwards,
      ${floating('12px')} 6s ease-in-out 1500ms infinite;
  }

  & > *:nth-child(3) {
    top: 150px;
    left: 25px;
    animation:
      ${fadeIn('100px')} 1000ms cubic-bezier(0.68, -0.4, 0.36, 1.45) 300ms forwards,
      ${floating('9px')} 6s ease-in-out 1300ms infinite;
  }

  & > *:nth-child(4) {
    top: 170px;
    right: 31px;
    animation:
      ${fadeIn('100px')} 1000ms cubic-bezier(0.68, -0.4, 0.36, 1.3) 800ms forwards,
      ${floating('8px')} 6s ease-in-out 1800ms infinite;
  }
`;
