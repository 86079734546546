import { FC } from 'react';

import * as S from '@components/web/src/atoms/ServiceInstance/styles';
import { Image } from '@components/web/src/foundations/Image/Image';

interface IProps {
  retailerLogo: string;
  isEnableRedirectionToApp: boolean;
  handleOnClose: () => void;
}

const ServiceInstance: FC<IProps> = ({ retailerLogo, handleOnClose, isEnableRedirectionToApp }) => {
  return (
    <S.ServiceInstanceContainer data-testid="ServiceInstanceContainer">
      <Image alt="Retailer Logo" height="100%" maxWidth="50px" objectFit="contain" src={retailerLogo} />
      {isEnableRedirectionToApp && <S.StyledCloseButton handleClick={handleOnClose} mode="dark" type="bold" />}
    </S.ServiceInstanceContainer>
  );
};

export default ServiceInstance;
