import { FC } from 'react';

import { localeApp } from '@lib/tools/locale/source/web/app';
import { VH_VARIANTS } from '@lib/tools/shared/helpers/consts';

import CookieGif from '@components/web/src/assets/legacy/b2c/images/biscuit.gif';
import Button from '@components/web/src/atoms/Buttons/Button';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Text } from '@components/web/src/foundations/Text/Text';
import ModalWrapper from '@components/web/src/templates/Modals/ModalWrapper/ModalWrapper';

interface IProps {
  isModalOpen: boolean;
  hideModal: () => void;
  handleConfirm: () => void;
  handleManagePreferences: () => void;
}

const AppCookieModal: FC<IProps> = ({ isModalOpen, hideModal, handleConfirm, handleManagePreferences }) => {
  const { AppCookieModalText } = localeApp;

  return (
    <ModalWrapper hideModal={hideModal} isOpen={isModalOpen} modalClassName="app-cookie-modal-container">
      <div className="description-container">
        <Text color={STATIC_COLORS.base.black} size="body1" text={AppCookieModalText.descriptionText} />

        <div className="img-wrapper">
          <img alt="cookie-gif" src={CookieGif} />
        </div>
      </div>
      <Button
        fontSize="subtitle2"
        handleClick={handleConfirm}
        size="lg"
        text={AppCookieModalText.acceptAllBtn}
        textWeight="medium"
        variant={VH_VARIANTS.PRIMARY}
      />
      <div className="cta-btn-wrapper">
        <Button
          fontSize="body1"
          handleClick={handleManagePreferences}
          size="sm"
          text={AppCookieModalText.manageBtn}
          textWeight="medium"
          variant={VH_VARIANTS.LIGHT}
        />
        <Button
          fontSize="body1"
          handleClick={hideModal}
          size="sm"
          text={AppCookieModalText.rejectBtn}
          textWeight="medium"
          variant={VH_VARIANTS.LIGHT}
        />
      </div>
    </ModalWrapper>
  );
};

export default AppCookieModal;
