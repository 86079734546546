import RegisterExtraInfoSwiper, {
  TExtraInfoSwiperVariant,
  TSwiperData,
} from '@components/web/src/organisms/ExtraInfoSwiper/ExtraInfoSwiper';
import * as S from '@components/web/src/templates/Modals/ExtraInfoModal/styles';
import ModalWrapper from '@components/web/src/templates/Modals/ModalWrapper/ModalWrapper';

type Props = {
  hideModal: () => void;
  isModalOpen: boolean;
  swiperData: TSwiperData[];
  variant: TExtraInfoSwiperVariant;
};

const ExtraInfoModal = ({ hideModal, isModalOpen, variant, swiperData }: Props) => {
  return (
    <ModalWrapper defaultPadding maxContentHeight hideModal={hideModal} isOpen={isModalOpen}>
      <S.SignUpExtraInfoModalWrapper data-testid={`SignupExtraInfoModalWrapper-${variant}`}>
        <S.CloseIcon handleClick={hideModal} mode="light" />
        <RegisterExtraInfoSwiper withRoundedCorners swiperData={swiperData} variant={variant} />
      </S.SignUpExtraInfoModalWrapper>
    </ModalWrapper>
  );
};

export default ExtraInfoModal;
