import { FC, Fragment } from 'react';

import { IPreferenceExposure } from '@app/native/src/models/UserFoodPreferences.model';

import { localeApp } from '@lib/tools/locale/source/web/app';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';

import { ReactComponent as QuestionMarkIcon } from '@components/web/src/assets/legacy/b2c/icons/icon_questionmark.svg';
import ProfileSectionHeader from '@components/web/src/atoms/Headers/ProfileSectionHeader/ProfileSectionHeader';
import ToggleButton from '@components/web/src/atoms/ToggleButton/ToggleButton';
import BackButton from '@components/web/src/components/Button/BackButton/BackButton';

interface IProps {
  onTagsInfoClick: () => void;
  onToggleClick: (optionKey: string) => void;
  tagsPreferencesState: IPreferenceExposure;
  handleBackBtnClick?: () => void;
}

const TasteIdTagsPage: FC<IProps> = ({ tagsPreferencesState, onToggleClick, onTagsInfoClick, handleBackBtnClick }) => {
  const {
    tagsTabContainer: { headingTitle, headingDescription, subHeadingTitle, subHeadingDescription },
  } = localeApp;

  const { back } = localeCommon.commonMessages;

  return (
    <div className="tags-tab-container">
      <BackButton arrowSize="short" handleClick={handleBackBtnClick} size="sm" text={back} />
      <ProfileSectionHeader description={headingDescription} title={headingTitle} />
      <div className="text-wrapper">
        <div>
          <div className="title">
            <LocaleFragment message={subHeadingTitle} />
          </div>
          <div className="description">
            <LocaleFragment message={subHeadingDescription} />
          </div>
        </div>
        <QuestionMarkIcon className="cursor-pointer" onClick={onTagsInfoClick} />
      </div>
      <div className="tags-toggle-section">
        {Object.keys(tagsPreferencesState)?.map((optionKey, index) => {
          const shouldDisplayDivider = Object.keys(tagsPreferencesState).length === index + 1;
          return (
            <Fragment key={optionKey}>
              {shouldDisplayDivider && <div className="divider" />}
              <ToggleButton
                isToggled={tagsPreferencesState[optionKey].isEnabled}
                text={tagsPreferencesState[optionKey].name}
                onToggle={() => onToggleClick(optionKey as string)}
              />
            </Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default TasteIdTagsPage;
