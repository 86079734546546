import { FC, useEffect, useMemo, useState } from 'react';
import { animated, useSpring } from 'react-spring';

import { TProductCategory, TProductInstance } from '@lib/core/products/types';
import { IFeedbackData, TFeedback } from '@lib/core/users/slices/feedbacks';
import { feedbackFilter, isProductInWishlistFilter } from '@lib/core/users/utils/filters';
import MixpanelTracker from '@lib/tools/dat/mixpanel';
import { MP_POSITION_CONTEXT } from '@lib/tools/dat/mixpanel/consts';
import { localeWidget } from '@lib/tools/locale/source/web/widget';
import { LocaleUtils } from '@lib/tools/locale/utils';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';
import { PRODUCT_CATEGORY_COFFEE, VH_VARIANTS } from '@lib/tools/shared/helpers/consts';

import infoIcon from '@components/web/src/assets/legacy/b2b/icons/info_icon.svg';
import backgroundImage from '@components/web/src/assets/legacy/backgrounds/taste_path_result_loading_background_image.jpeg';
import Button from '@components/web/src/atoms/Buttons/Button';
import TasteJourneyMap, {
  IJourneyStepsProps,
} from '@components/web/src/components/TastePath/TasteJourneyMap/TasteJourneyMap';
import TastePathEnd from '@components/web/src/components/TastePath/TastePathEnd/TastePathEnd';
import TastePathProductItem from '@components/web/src/components/TastePath/TastePathProductItem/TastePathProductItem';
import Tooltip from '@components/web/src/components/Tooltip/Tooltip';
import ProductCard from '@components/web/src/organisms/Cards/ProductCard/ProductCard';
import { IDiscoveryQuiz } from '@components/web/src/templates/Modals/DiscoveryQuizModal/DiscoveryQuizModal';
import ResultsLoadingScreen from '@components/web/src/templates/ResultPages/LoadingScreen/ResultsLoadingScreen';

interface IProps {
  locale: string;
  productInstanceData: TProductInstance;
  productCategory: TProductCategory;
  isTastePathProductsLoading: boolean;
  isTastePathDone: boolean;
  tasteJourneyData: IJourneyStepsProps[];
  nextTastePathCategory: string;
  wishlistProductIds?: string[];
  isProductListLoading?: boolean;
  isFeedbackEnabled: boolean;
  isWishlistEnabled: boolean;
  feedbackData: IFeedbackData[];
  lastUpdatedFeedbackId: string;
  isEnableLocationMapAddon: boolean;
  isEnableVusionAddon: boolean;
  discoveryQuiz: IDiscoveryQuiz;
  isShowProductLocationAddon: boolean;
  shouldHideTastePathTooltipModal: boolean;
  shouldDisplaySkipCategoryBtn: boolean;
  isLoadingScreenOpen: boolean;
  navigateToTastePathStartPage: () => void;
  handleSkipTastePathCategory: () => void;
  handleCloseTooltip: () => void;
  handleUpdateWishlistProductList?: (productId: string) => void;
  handleUpdateFeedback: (feedback: TFeedback, productId: string) => void;
}

const TastePathPage: FC<IProps> = ({
  locale,
  productInstanceData,
  productCategory,
  isTastePathProductsLoading,
  isTastePathDone,
  tasteJourneyData,
  nextTastePathCategory,
  isWishlistEnabled,
  wishlistProductIds,
  isProductListLoading,
  isFeedbackEnabled,
  feedbackData,
  lastUpdatedFeedbackId,
  isEnableLocationMapAddon,
  isEnableVusionAddon,
  isShowProductLocationAddon,
  shouldHideTastePathTooltipModal,
  isLoadingScreenOpen = false,
  shouldDisplaySkipCategoryBtn = false,
  discoveryQuiz,
  navigateToTastePathStartPage,
  handleSkipTastePathCategory,
  handleCloseTooltip,
  handleUpdateFeedback,
  handleUpdateWishlistProductList,
}) => {
  const { character } = productInstanceData || {};
  const technicalCaption = character?.techCaption || '';
  const productStyle = character?.type?.identifier || '';

  const { tastePathPage, tastingPathFeedbackLoadingPage } = localeWidget;

  const { btnText, alreadyTasted, tooltipModalTitle, tooltipModalSubTitle } = tastePathPage;

  const { publishedTerms } = LocaleUtils;

  const buttonText = `${publishedTerms[btnText.id]} ${nextTastePathCategory}`;

  const randomDescriptionText = useMemo(() => {
    if (productStyle && productCategory && productCategory !== PRODUCT_CATEGORY_COFFEE) {
      const randomIndex = Math.floor(Math.random() * 3) + 1;
      return publishedTerms[tastePathPage[`description${productStyle}${productCategory}${randomIndex}`]?.id];
    }
    return '';
  }, [productStyle, productInstanceData, productCategory]);

  const randomLoadingScreenTitleText = useMemo(() => {
    const randomIndex = Math.floor(Math.random() * 8);
    return publishedTerms[tastingPathFeedbackLoadingPage[`titleText${randomIndex + 1}`]?.id];
  }, [productInstanceData]);

  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    if (nextTastePathCategory) {
      setAnimate(true);
    }
  }, [nextTastePathCategory]);

  const cardAnimation = useSpring({
    config: {
      friction: 30,
      tension: 300,
    },
    onRest: () => setAnimate(false),
    opacity: animate ? 0 : 1,
    transform: `translateY(${animate ? 35 : 0}px)`,
  });

  // without delaying displaying the product we break animation
  const [delayedProduct, setDelayedProduct] = useState(productInstanceData);
  useEffect(() => {
    const timer = setTimeout(() => {
      if (productInstanceData) {
        setDelayedProduct(productInstanceData);
      }
    }, 400);

    return () => clearTimeout(timer);
  }, [productInstanceData]);

  return (
    <>
      {isLoadingScreenOpen && (
        <div className="result-loading-screen-wrapper">
          <ResultsLoadingScreen backgroundImage={backgroundImage} titleText={randomLoadingScreenTitleText} />
        </div>
      )}
      <div className="taste-path-page-container" style={{ display: isLoadingScreenOpen ? 'none' : 'flex' }}>
        {isTastePathDone ? (
          <TastePathEnd />
        ) : (
          <div className="taste-path-content">
            <div className="header-section">
              <p className="title">
                <LocaleFragment message={tastePathPage.title} />
              </p>
              <button type="button" onClick={navigateToTastePathStartPage}>
                <img alt="info icon" src={infoIcon} />
              </button>
            </div>
            {tasteJourneyData && <TasteJourneyMap productCategory={productCategory} steps={tasteJourneyData} />}
            <div className="description-section">
              <p className="description-text">{randomDescriptionText}</p>
              <p className={`description-category-text ${productCategory}`}>{technicalCaption}</p>
            </div>
            <animated.div style={cardAnimation}>
              <div className="product-card-section">
                {!shouldHideTastePathTooltipModal && productInstanceData && (
                  <Tooltip
                    className="taste-path-tooltip-modal"
                    description={tooltipModalSubTitle}
                    handleCloseClick={handleCloseTooltip}
                    title={tooltipModalTitle}
                  />
                )}
                <ProductCard
                  isResponsive
                  discoveryQuiz={discoveryQuiz}
                  feedback={feedbackFilter(feedbackData, delayedProduct)}
                  handleUpdateFeedback={handleUpdateFeedback}
                  handleUpdateWishlistProductList={handleUpdateWishlistProductList}
                  isEnableLocationMapAddon={isEnableLocationMapAddon}
                  isEnableVusionAddon={isEnableVusionAddon}
                  isFeedbackEnabled={isFeedbackEnabled}
                  isLoading={isTastePathProductsLoading}
                  isProductInWishlist={isProductInWishlistFilter(wishlistProductIds, delayedProduct)}
                  isProductListLoading={isProductListLoading}
                  isShowProductLocationAddon={isShowProductLocationAddon}
                  isWishlistEnabled={isWishlistEnabled}
                  locale={locale}
                  mixpanelPositionContext={MP_POSITION_CONTEXT.TASTE_PATH_PAGE}
                  productInstanceData={delayedProduct}
                  storeType="events"
                />
              </div>
            </animated.div>
            {shouldDisplaySkipCategoryBtn && (
              <Button
                className="cta-button"
                size="sm"
                text={buttonText}
                variant={VH_VARIANTS.LIGHT}
                handleClick={() => {
                  MixpanelTracker.events.skipCharacterType(productStyle);
                  handleSkipTastePathCategory();
                }}
              />
            )}
          </div>
        )}
        {!!feedbackData.length && (
          <div className="path-products-list-section">
            <p className="list-title">
              <LocaleFragment message={alreadyTasted} />
            </p>
            {feedbackData.map(({ feedback, gprl }, idx) => (
              <TastePathProductItem
                key={idx}
                feedback={feedback}
                handleUpdateFeedback={handleUpdateFeedback}
                isFeedbackModalDisabled={isTastePathDone}
                isUpdatedItem={gprl.product.identifier === lastUpdatedFeedbackId}
                productInstanceData={gprl}
              />
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default TastePathPage;
