import { FC, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';

import LoginContainer from '@lib/tools/shared/auths/views/containers/LoginContainer';
import RegistrationContainer from '@lib/tools/shared/auths/views/containers/RegistrationContainer';
import ResetPasswordContainer from '@lib/tools/shared/auths/views/containers/ResetPasswordContainer';
import ConfirmResetPasswordContainer from '@lib/tools/shared/auths/views/containers/SetPasswordContainer';
import FoodHabitsContainer from '@lib/tools/shared/quizzes/views/containers/FoodHabitsContainer';
import QuizContainer from '@lib/tools/shared/quizzes/views/containers/QuizContainer';
import RecipesContainer from '@lib/tools/shared/recipes/views/containers/RecipeContainer';
import { PAGES } from '@lib/tools/views/urls';
import ErrorPageNotFound from '@lib/tools/views/web/components/ErrorPageNotFound';

import ArticleCatalogContainer from 'containers/app/ArticleCatalogContainer';
import ArticlePageContainer from 'containers/app/ArticlePageContainer';
import EdutainmentContainer from 'containers/app/Edutainment/EdutainmentContainer';
import EdutainmentFormContainer from 'containers/app/Edutainment/EdutainmentFormContainer';
import ExperienceCatalogContainer from 'containers/app/ExperienceCatalogContainer';
import ExperienceSearchContainer from 'containers/app/ExperienceSearchContainer';
import GlobalSearchResultContainer from 'containers/app/GlobalSearchContainer';
import HintPageContainer from 'containers/app/HintPageContainer';
import HomePageContainer from 'containers/app/HomeContainer';
import OnBoardingContainer from 'containers/app/OnBoardingContainer';
import ProductPageContainer from 'containers/app/ProductPageContainer';
import ResultTestContainer from 'containers/app/ResultTestContainer';
import SocialWelcomePageContainer from 'containers/app/SocialWelcomePageContainer';
import OpeningContainer from 'containers/app/StartPageContainer';
import productCatalogContainer from 'containers/ProductCatalogContainer';
import JournalContainer from 'containers/TasteId/Journal/JournalContainer';
import TasteIdLegalPageContainer from 'containers/TasteId/TasteIdLegalPageContainer';
import TasteIdPageContainer from 'containers/TasteId/TasteIdPageContainer';
import TasteIdSettingsPageContainer from 'containers/TasteId/TasteIdSettingsPageContainer';
import TasteIdTagsPageContainer from 'containers/TasteId/TasteIdTagsPageContainer';
import { AnonRoutes, AuthRoutes } from 'routes/AccessApp';

const Router: FC = () => {
  const RouteGroup = (c, ...paths) => {
    return paths.map(p => <Route key={p} Component={c} path={p} />);
  };

  const basenameApp = '/:lang/:retailer/loc/:location/';
  const appUrl = url => basenameApp + url;

  return (
    <Suspense fallback={null}>
      <Routes>
        <Route path={basenameApp}>
          {/* Protected routes */}
          <Route Component={AuthRoutes}>
            <Route key="urlEdutainment" Component={EdutainmentContainer} path={appUrl(PAGES.vinhood.edutainment)} />

            <Route
              key="urlVinhoodEdutainmentFormPage"
              Component={EdutainmentFormContainer}
              path={appUrl(PAGES.vinhood.edutainmentForm)}
            />

            <Route key="urlOnBoarding" Component={OnBoardingContainer} path={appUrl(PAGES.vinhood.onboarding)} />

            <Route key="urlHome" Component={HomePageContainer} path={appUrl(PAGES.vinhood.home)} />

            <Route key="urlTasteId" Component={TasteIdPageContainer} path={appUrl(PAGES.vinhood.tasteId.tasteId)} />
            <Route
              key="urlTasteIdTags"
              Component={TasteIdTagsPageContainer}
              path={appUrl(PAGES.vinhood.tasteId.tasteIdTags)}
            />
            <Route
              key="urlTasteIdLegal"
              Component={TasteIdLegalPageContainer}
              path={appUrl(PAGES.vinhood.tasteId.tasteIdLegal)}
            />
            <Route
              key="urlTasteIdSettings"
              Component={TasteIdSettingsPageContainer}
              path={appUrl(PAGES.vinhood.tasteId.tasteIdSettings)}
            />

            <Route
              key="urlVinhoodGlobalSearchResults"
              Component={GlobalSearchResultContainer}
              path={appUrl(PAGES.vinhood.globalSearchResults)}
            />

            <Route
              key="urlExperiences"
              Component={ExperienceCatalogContainer}
              path={appUrl(PAGES.vinhood.experiences)}
            />

            <Route key="urlArticlesCatalog" Component={ArticleCatalogContainer} path={appUrl(PAGES.vinhood.articles)} />

            <Route key="urlArticle" Component={ArticlePageContainer} path={appUrl(PAGES.vinhood.article)} />

            <Route
              key="urlExperienceSearch"
              Component={ExperienceSearchContainer}
              path={appUrl(PAGES.vinhood.experienceSearch)}
            />

            <Route key="urlHint" Component={HintPageContainer} path={appUrl(PAGES.vinhood.hint)} />

            <Route key="urlCatalog" Component={productCatalogContainer} path={appUrl(PAGES.vinhood.catalog)} />
            <Route
              key="urlTasteIdJournal"
              Component={JournalContainer}
              path={appUrl(PAGES.vinhood.tasteId.tasteIdJournal)}
            />

            <Route key="urlRecipes" Component={RecipesContainer} path={appUrl(PAGES.vinhood.recipeSearch)} />
          </Route>

          {/* Anonymous routes */}
          <Route Component={AnonRoutes}>
            <Route key="urlLogin" Component={LoginContainer} path={appUrl(PAGES.vinhood.signIn)} />

            <Route
              key="urlResetPassword"
              Component={ResetPasswordContainer}
              path={appUrl(PAGES.vinhood.resetPassword)}
            />

            <Route
              key="urlConfirmResetPassword"
              Component={ConfirmResetPasswordContainer}
              path={appUrl(PAGES.vinhood.confirmPasswordReset)}
            />

            <Route
              key="urlRegistrationPart"
              Component={RegistrationContainer}
              path={appUrl(PAGES.vinhood.registration)}
            />

            <Route key="urlEntrypoint" Component={OpeningContainer} path="" />
          </Route>

          {/* Public routes */}
          <Route key="urlFoodHabits" Component={FoodHabitsContainer} path={appUrl(PAGES.vinhood.quiz.chooseTaste)} />

          {/* ! Conditionally handle multi-product quizzes as public route and rest as protected  */}
          {RouteGroup(QuizContainer, appUrl('/quiz/:quizType/'))}
          {RouteGroup(ResultTestContainer, appUrl(PAGES.vinhood.quizResults.result))}
          <Route key="urlProduct" Component={ProductPageContainer} path={appUrl(PAGES.vinhood.product)} />

          <Route
            key="urlSocialWelcome"
            Component={SocialWelcomePageContainer}
            path={appUrl(PAGES.vinhood.socialWelcome)}
          />
          <Route key="urlAppTestStartPage" Component={OpeningContainer} path={`${appUrl(PAGES.vinhood.start)}`} />
        </Route>

        {/* No basename */}
        <Route key="urlErrorNotFound" Component={ErrorPageNotFound} path={PAGES.error.notFound} />
      </Routes>
    </Suspense>
  );
};

export default Router;
