/* eslint-disable class-methods-use-this */
import mixpanel from 'mixpanel-browser';

import { IPreferenceExposure } from '@app/native/src/models/UserFoodPreferences.model';

import { TCharacter } from '@lib/core/characters/types';
import {
  HARDCODED_AGREEMENT_ID_MARKETING as MARKETING,
  HARDCODED_AGREEMENT_ID_PROFILING as PROFILING,
  // HARDCODED_AGREEMENT_ID_VINHOOD_PARTNER as PARTNER,
} from '@lib/core/service/consts';
import { IAgreementsTypes } from '@lib/core/service/slices/technical/agreements';
import { IFeedbackData } from '@lib/core/users/slices/feedbacks';
import { TUserAgreementItem } from '@lib/core/users/types';
import { MP_PROPERTIES } from '@lib/tools/dat/mixpanel/consts';
import { checkIfMixpanelExists, disableOnKiosk, disableOnWidget } from '@lib/tools/dat/mixpanel/decorators';
import Utilities from '@lib/tools/dat/mixpanel/utils';
import { languages } from '@lib/tools/locale/utils/consts';
import {
  PRODUCT_CATEGORY_BEER as BEER,
  PRODUCT_CATEGORY_COFFEE as COFFEE,
  PRODUCT_CATEGORY_WINE as WINE,
} from '@lib/tools/shared/helpers/consts';

/** Methods to manange User identity and properties in Mixpanel. */
@checkIfMixpanelExists()
export default class Profile {
  private utils: Utilities;

  constructor() {
    this.utils = new Utilities();
  }
  // @todo create a method for each different way that a user can update their profile:
  // - ✅ handle identity
  // - ✅ update personal info (email, name, surname, country)
  // - ✅ update characters (when finishing a multi-prod test)
  // - ✅ update privacy options
  // - ✅ update preferences and tags (e.g. ecopackaging, inclusinevess, no-gluten blablablah...)
  // - ❓ probably update article likes, product likes, etc...

  /** Remove personal info from user profile in Mixpanel. */
  private anonymizeUserProfile() {
    mixpanel.people.unset([
      MP_PROPERTIES.email,
      MP_PROPERTIES.firstName,
      MP_PROPERTIES.lastName,
      MP_PROPERTIES.homeCountry,
    ]);
  }

  /**
   * Invoke when the user identity is verified, that is to say, when the user PK is available in the user slice.
   *
   * It should be invoked when the user authenticates (either through registration or login)
   * as well as when the app loads if the user is already authenticated.
   *
   * Failing to do so will cause other methods that set user properties to fail.
   *
   * @param userID - Primary key of the user
   */
  @disableOnWidget()
  @disableOnKiosk()
  public handleUserIdentity(userID: string | number) {
    if (userID) this.utils.identify(String(userID));
  }

  /**
   * Indicates if the user has been identified by Mixpanel.
   * Identification can happen via signup, login, or fidelity card scan.
   */
  public get didIdentify() {
    return this.utils.didIdentify;
  }

  /**
   * Invoke when the user is authenticated and their personal info has been updated,
   * or when the profiling preference changes.
   *
   * @param email - User email
   * @param firstName - User first name
   * @param lastName - User last name
   * @param countryCode - Country code of the user's country
   */
  @disableOnKiosk()
  public updateUserPersonalInfo(email: string, firstName: string, lastName: string, countryCode: string) {
    const enabledProfiling = this.utils.getSuperProperty(MP_PROPERTIES.enabledProfiling);
    if (!enabledProfiling) return;
    if (!email) return;

    const regionNameInEnglish = new Intl.DisplayNames([languages.ENGLISH], { type: 'region' });
    const homeCountry = countryCode ? regionNameInEnglish.of(countryCode) : countryCode;

    const args = { email, firstName, homeCountry, lastName };
    this.utils.setUserProfileProperties(args, true);
  }

  /**
   * Invoke when the user is authenticated and their characters have been updated.
   *
   * @param characters - Object where keys are the product type (e.g. {@link WINE wine})
   * and values are the {@link TCharacter details} about the respective character
   */
  public updateUserCharacters(characters: Record<string, TCharacter>) {
    if (!Object.keys(characters).length) return;

    const userBeerCharacterID = characters?.[BEER]?.identifier;
    const userCoffeeCharacterID = characters?.[COFFEE]?.identifier;
    const userWineCharacterID = characters?.[WINE]?.identifier;

    const args = { userBeerCharacterID, userCoffeeCharacterID, userWineCharacterID };
    this.utils.setUserProfileProperties(args, true);
    this.utils.setSuperProperties(args, true);
  }

  /**
   * Invoke when the user is anonymous and chooses whether to be profiled or not.
   *
   * @param options - Anonymous user agreements as per {@link IAgreementsTypes this interface}
   */
  @disableOnKiosk()
  public updateAnonymousProfilingOption(options: IAgreementsTypes) {
    const enabledProfiling = options.isUserAllowedProfiling;
    this.utils.setSuperProperties({ enabledProfiling }, true);
  }

  /**
   * Invoke when the user is authenticated and their privacy options have been updated.
   *
   * @param privacyLogs - List of objects where each object represents a specific type of privacy selection
   * as per {@link TUserAgreementItem this interface}
   */
  @disableOnKiosk()
  public updateUserPrivacyOptions(privacyLogs: TUserAgreementItem[]) {
    // WARN: privacy logs don't get POSTed for social registration
    if (!privacyLogs?.length) return;

    const enabledProfiling = privacyLogs.find(({ privacy_id }) => privacy_id === PROFILING)?.selection || false;
    const enabledMarketing = privacyLogs.find(({ privacy_id }) => privacy_id === MARKETING)?.selection || false;
    // const enabledPartnerVinhood = privacyLogs.find(({ privacy_id }) => privacy_id === PARTNER)?.selection || false;

    const args = { enabledMarketing, enabledProfiling };
    this.utils.setUserProfileProperties(args, true);
    this.utils.setSuperProperties({ enabledProfiling }, true);

    if (!enabledProfiling) this.anonymizeUserProfile();
  }

  /**
   * Invoke when the user is authenticated and their preferences have been updated.
   *
   * @param allFoodPreferences - Food preferences as per {@link IPreferenceExposure this interface}
   * @param allTagsPreferences - Tags preferences as per {@link IPreferenceExposure this interface}
   */
  @disableOnKiosk()
  public updateUserPreferences(allFoodPreferences: IPreferenceExposure, allTagsPreferences: IPreferenceExposure) {
    const userFoodPreferences = Object.keys(this.utils.pickBy(allFoodPreferences, v => v.isEnabled)) || [];
    const userTagsActive = Object.keys(this.utils.pickBy(allTagsPreferences, v => v.isEnabled)) || [];

    if (!this.utils.size(userFoodPreferences) && !this.utils.size(userTagsActive)) return;

    const args = { userFoodPreferences, userTagsActive };
    this.utils.setUserProfileProperties(args, true);
    this.utils.setSuperProperties(args, true);
  }

  /**
   * Invoke when the user is authenticated and their product wishlist has been updated.
   *
   * @param productList - wishlist products Ids
   */
  public updateUserProductWishlist(wishlistIds: string[]) {
    const args = { userProductWishlist: wishlistIds };
    this.utils.setUserProfileProperties(args, true);
  }

  /**
   * Invoke when the user is authenticated and their feedback list has been updated.
   *
   * @param feedbackData - List of rated products as per {@link IFeedbackData this interface}
   */
  @disableOnKiosk()
  public updateUserProductFeedback(feedbackData: IFeedbackData[]) {
    const getProductIdentifiersForScore = (score: number) => {
      return feedbackData.filter(data => data.feedback === score).map(data => data.gprl.product.identifier);
    };

    const userProductsScore1 = getProductIdentifiersForScore(1);
    const userProductsScore2 = getProductIdentifiersForScore(2);
    const userProductsScore3 = getProductIdentifiersForScore(3);
    const userProductsScore4 = getProductIdentifiersForScore(4);
    const userProductsScore5 = getProductIdentifiersForScore(5);

    const args = { userProductsScore1, userProductsScore2, userProductsScore3, userProductsScore4, userProductsScore5 };
    this.utils.setUserProfileProperties(args, true);
  }
}
