import classNames from 'classnames';
import { FC } from 'react';

import { localeCommon } from '@lib/tools/locale/source/web/common';
import { ILocaleText } from '@lib/tools/locale/views/LocaleFragment';
import { VH_VARIANTS } from '@lib/tools/shared/helpers/consts';

import Button from '@components/web/src/atoms/Buttons/Button';
import AnswerItem from '@components/web/src/atoms/TestAnswer/TestAnswerItem/TestAnswer';
import BackButton from '@components/web/src/components/Button/BackButton/BackButton';
import Question from '@components/web/src/components/Quiz/Question/Question';
import KioskCirclesBackground from '@components/web/src/kiosk/KioskCirclesBackground/KioskCirclesBackground';

type Props = {
  answers: {
    text: ILocaleText;
    image: string;
    isDisabled: boolean;
    isSelected: boolean;
    isVisible: boolean;
    slug: string;
  }[];
  isDisabledBtn: boolean;
  isDesignSetVinhoodExperience: boolean;
  isApplicationKiosk: boolean;
  isDevToolsEnabled: boolean;
  handleUserPreferences: () => void;
  onAnswerSelectHandler: (id: string) => void;
  goBack?: () => void;
  customColors: {
    customQuizBackgroundColor: string;
    customTextColor: string;
    customSecondaryColor: string;
  };
};

const FoodHabits: FC<Props> = ({
  answers,
  isDisabledBtn,
  isDesignSetVinhoodExperience,
  isApplicationKiosk,
  isDevToolsEnabled,
  onAnswerSelectHandler,
  handleUserPreferences,
  goBack,
  customColors,
}) => {
  const { foodHabitsPage, commonMessages } = localeCommon;

  const { customQuizBackgroundColor, customTextColor, customSecondaryColor } = customColors;
  const customButtonArrowColor =
    isDesignSetVinhoodExperience && customSecondaryColor ? customSecondaryColor : '#4091A0';

  return (
    <div
      className={classNames('food-habits-container', { isLargeVariant: isApplicationKiosk })}
      style={{ backgroundColor: customQuizBackgroundColor }}
    >
      {isApplicationKiosk && <KioskCirclesBackground />}
      <Question
        customTextColor={customTextColor}
        isApplicationKiosk={isApplicationKiosk}
        title={foodHabitsPage.titleQuestionText}
      />
      <div className="answers-container">
        {answers.map(answer => {
          const { slug, image, text, isDisabled, isSelected, isVisible } = answer;
          return (
            isVisible && (
              <AnswerItem
                key={slug}
                isFoodHabitsQuiz
                customTextColor={customTextColor}
                id={slug}
                image={image}
                isApplicationKiosk={isApplicationKiosk}
                isDevToolsEnabled={isDevToolsEnabled}
                isDisabled={isDisabled}
                isSelected={isSelected}
                text={text}
                onSelect={id => onAnswerSelectHandler(id)}
              />
            )
          );
        })}
      </div>
      <Button
        className="button-cta"
        disabled={isDisabledBtn}
        size={isApplicationKiosk ? 'lg' : 'sm'}
        text={foodHabitsPage.ctaBtn}
        variant={VH_VARIANTS.PRIMARY}
        onClick={handleUserPreferences}
      />
      <BackButton
        className="go-back-btn"
        customArrowColor={customButtonArrowColor}
        handleClick={goBack}
        size="sm"
        style={{ color: customTextColor }}
        text={commonMessages.goBackBtn}
      />
    </div>
  );
};

export default FoodHabits;
