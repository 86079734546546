import { FC, ReactNode } from 'react';

import { TLanguage } from '@lib/core/retailers/types';
import ServiceInstanceContainer from '@lib/tools/shared/serviceInstances/views/containers/ServiceInstanceContainer';

import { FooterWidgetAnonymous, FooterWidgetLogged } from '@components/web/src/atoms/Footers';
import Header from '@components/web/src/atoms/Header/Header';
import * as S from '@components/web/src/atoms/HeaderFooter/HeaderFooterWidget/styles';

interface IProps {
  children: ReactNode;
  isUserAuthenticated: boolean;
  isHideGearIcon: boolean;
  isDarkTheme: boolean;
  isNavigateUserToTest: boolean;
  isShowLanguageSwitcher: boolean;
  isShowServiceInstanceContainer: boolean;
  isEnableRedirectionToApp: boolean;
  locale: string;
  retailerLanguages: TLanguage[];
  profileImageUrl?: string;
  isAnonymousWidgetAccess: boolean;
  shouldHideHomeLink: boolean;
  shouldHideCatalogLink: boolean;
  shouldHideTasteIdLink: boolean;
  shouldHideExploreLink: boolean;
  shouldShowTasteIdGuide?: boolean;
  isFixedBody: boolean;
  handleSetTasteIdPageShown?: () => void;
}

const HeaderFooterWidget: FC<IProps> = ({
  children,
  isUserAuthenticated,
  isHideGearIcon,
  isDarkTheme,
  isNavigateUserToTest,
  isShowLanguageSwitcher,
  isShowServiceInstanceContainer,
  isEnableRedirectionToApp,
  locale,
  retailerLanguages,
  profileImageUrl,
  isAnonymousWidgetAccess,
  shouldHideCatalogLink,
  shouldHideTasteIdLink,
  shouldHideHomeLink,
  shouldHideExploreLink,
  shouldShowTasteIdGuide,
  isFixedBody,
  handleSetTasteIdPageShown,
}) => (
  <S.HeaderFooterWidgetContainer data-testid="HeaderFooterWidget" id="root-scroll-element">
    <Header
      isDarkTheme={isDarkTheme}
      isNavigateUserToTest={isNavigateUserToTest}
      isShowLanguageSwitcher={isShowLanguageSwitcher}
      locale={locale}
      retailerLanguages={retailerLanguages}
    />
    {isShowServiceInstanceContainer && <ServiceInstanceContainer isEnableRedirectionToApp={isEnableRedirectionToApp} />}
    <S.BodyContainer $isFixedBody={isFixedBody} data-testid="BodyContainer">
      {children}
    </S.BodyContainer>
    {isUserAuthenticated && !isAnonymousWidgetAccess ? (
      <FooterWidgetLogged
        handleSetTasteIdPageShown={handleSetTasteIdPageShown}
        profileImageUrl={profileImageUrl}
        shouldHideCatalogLink={shouldHideCatalogLink}
        shouldHideExploreLink={shouldHideExploreLink}
        shouldHideHomeLink={shouldHideHomeLink}
        shouldHideTasteIdLink={shouldHideTasteIdLink}
        shouldShowTasteIdGuide={shouldShowTasteIdGuide}
      />
    ) : (
      <FooterWidgetAnonymous isHideGearIcon={isHideGearIcon} />
    )}
  </S.HeaderFooterWidgetContainer>
);

export default HeaderFooterWidget;
