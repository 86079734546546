// ToDo we don't need to type actions, we can remove PayloadAction from all slices because store typed properly now

import { createSlice } from '@reduxjs/toolkit';

import { TProductCategory, TProductInstance } from '@lib/core/products/types';
import { selectRetailerSlug } from '@lib/core/retailers/selectors/retailer';
import { selectRetailerLocationSlug } from '@lib/core/retailers/selectors/retailerLocation';
import {
  GPRL_CHARACTER_QUERY,
  PRODUCT_REQUEST_FOR_HOME_PAGE,
  PRODUCT_REQUEST_FOR_RESULT_PAGE,
} from '@lib/core/service/consts';
import { createTypedAsyncThunk } from '@lib/core/service/createTypedAsyncThunk';
import backendApiUrls from '@lib/core/service/requests/backend_api_urls';
import request from '@lib/core/service/requests/request';
import { IB2CProductsFetchedPage } from '@lib/core/service/types/interface';
import { createBackendRetailerLocationSlug, createBackendRetailerUrl } from '@lib/core/service/utils';
import {
  PRODUCT_CATEGORY_BEER,
  PRODUCT_CATEGORY_COFFEE,
  PRODUCT_CATEGORY_QUERY,
  PRODUCT_CATEGORY_WINE,
} from '@lib/tools/shared/helpers/consts';
import { getUniqueItemForToday } from '@lib/tools/shared/helpers/utils';

interface IFetchProductsOptions {
  offset: number;
  limit?: number;
  [PRODUCT_CATEGORY_QUERY]?: string;
  [GPRL_CHARACTER_QUERY]?: string;
}

interface IFetchSwiperProductsPayload {
  params: IFetchProductsOptions;
  productResultFor: typeof PRODUCT_REQUEST_FOR_RESULT_PAGE | typeof PRODUCT_REQUEST_FOR_HOME_PAGE;
  customRetailerSlug?: string;
  customRetailerLocationSlug?: string;
}

// ToDo refactor idea: system which should be able to fetch, save and clean certain amount of products based on key
// For example: we want to have 3 product for b2c welcome, we create enum [productsSets.B2CWelcome] and start logic by
// provide it as identifier in welcome container. Using same identifiers we will be able also to clean any set of them
export const fetchSwiperProducts = createTypedAsyncThunk(
  'table/fetchSwiperProducts',
  async (
    { params, productResultFor, customRetailerLocationSlug, customRetailerSlug }: IFetchSwiperProductsPayload,
    { getState },
  ) => {
    const retailerSlug = customRetailerSlug || selectRetailerSlug(getState());
    const retailerLocationSlug = customRetailerLocationSlug || selectRetailerLocationSlug(getState());

    const requestUrl = createBackendRetailerLocationSlug(
      createBackendRetailerUrl(backendApiUrls.apiUrlB2CProductsList, retailerSlug),
      retailerLocationSlug,
    );

    const json = await request(requestUrl, {
      additionalHeaders: {
        Accept: 'application/json; version="2.0"',
      },
      params,
    });
    const { results } = json;
    const productResults =
      productResultFor === PRODUCT_REQUEST_FOR_HOME_PAGE
        ? getUniqueItemForToday({ itemArray: results, returnNumberOfUniqueItemForDay: 3 })
        : results;
    return {
      [PRODUCT_CATEGORY_QUERY]: params[PRODUCT_CATEGORY_QUERY],
      json,
      productResultFor,
      products: productResults,
    };
  },
);
export interface IProductsSwiperState {
  isProductsRequestLoading: Record<TProductCategory, boolean>;
  productsRequestError: string;
  productsData: Record<string, TProductInstance[]>;
  productsCount: Record<string, number | null>;
  isProductsFetchedFor: Record<IB2CProductsFetchedPage, boolean>;
}
export const initialState: IProductsSwiperState = {
  isProductsFetchedFor: {
    [PRODUCT_REQUEST_FOR_HOME_PAGE]: false,
    [PRODUCT_REQUEST_FOR_RESULT_PAGE]: false,
  },
  isProductsRequestLoading: {
    [PRODUCT_CATEGORY_BEER]: false,
    [PRODUCT_CATEGORY_COFFEE]: false,
    [PRODUCT_CATEGORY_WINE]: false,
  },
  productsCount: {
    [PRODUCT_CATEGORY_BEER]: null,
    [PRODUCT_CATEGORY_COFFEE]: null,
    [PRODUCT_CATEGORY_WINE]: null,
  },
  productsData: {
    [PRODUCT_CATEGORY_BEER]: [],
    [PRODUCT_CATEGORY_COFFEE]: [],
    [PRODUCT_CATEGORY_WINE]: [],
  },
  productsRequestError: '',
};

export const productSwiperSlice = createSlice({
  extraReducers: builder => {
    builder.addCase(fetchSwiperProducts.pending, (state, action) => {
      // @ts-ignore
      const productCategory = action.meta?.arg[PRODUCT_CATEGORY_QUERY];
      state.isProductsRequestLoading = { ...state.isProductsRequestLoading, [productCategory]: true };
    });
    builder.addCase(fetchSwiperProducts.fulfilled, (state, action) => {
      const productCategory = action.payload[PRODUCT_CATEGORY_QUERY];
      const productResultFor = action.payload?.productResultFor;
      state.isProductsRequestLoading = { ...state.isProductsRequestLoading, [productCategory]: false };
      state.productsData = { ...state.productsData, [productCategory]: action.payload.products };
      state.productsCount = { ...state.productsCount, [productCategory]: action.payload.json.count };
      state.isProductsFetchedFor = { ...state.isProductsFetchedFor, [productResultFor]: true };
    });
    builder.addCase(fetchSwiperProducts.rejected, (state, action: any) => {
      const productCategory = action.meta?.arg[PRODUCT_CATEGORY_QUERY];
      if (action.payload?.errorMessage) {
        state.productsRequestError = action.payload.errorMessage;
      } else if (action.error?.message) {
        state.productsRequestError = action.error.message;
      }
      state.isProductsRequestLoading = { ...state.isProductsRequestLoading, [productCategory]: false };
    });
  },
  initialState,
  name: 'productsSwiper',
  reducers: {
    resetProductSwiperState: state => {
      state.isProductsRequestLoading = initialState.isProductsRequestLoading;
      state.productsData = initialState.productsData;
      state.isProductsFetchedFor = initialState.isProductsFetchedFor;
    },
  },
});

export default productSwiperSlice.reducer;
export const { resetProductSwiperState } = productSwiperSlice.actions;
