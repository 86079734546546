import { useDispatch, useSelector } from 'react-redux';

import {
  selectIsWishlistLoaded,
  selectIsWishlistLoading,
  selectWishListData,
  selectWishlistProductIds,
} from '@lib/core/users/selectors/wishlist';
import { updateWishlist } from '@lib/core/users/slices/wishlist';

export const useWishlist = () => {
  const dispatch = useDispatch();

  const wishlistProductIds = useSelector(selectWishlistProductIds);
  const wishlistData = useSelector(selectWishListData);
  const isProductListLoading = useSelector(selectIsWishlistLoading);
  const isWishlistLoaded = useSelector(selectIsWishlistLoaded);

  const handleUpdateWishlistProductList = productId => dispatch(updateWishlist({ productId }));

  return {
    handleUpdateWishlistProductList,
    isProductListLoading,
    isWishlistLoaded,
    wishlistData,
    wishlistProductIds,
  };
};
