import { Dispatch, SetStateAction, useState } from 'react';

import { localeApp } from '@lib/tools/locale/source/web/app';
import { VH_VARIANTS } from '@lib/tools/shared/helpers/consts';

import { IModalOption, IToggleOptionChange } from '@components/web/src/app/Modals/modalTypes';
import Button from '@components/web/src/atoms/Buttons/Button';
import { STATIC_COLORS } from '@components/web/src/foundations';
import AppModalHeader from '@components/web/src/templates/Modals/AppModalHeader/AppModalHeader';
import * as S from '@components/web/src/templates/Modals/ManageModal/styles';
import ModalWrapper from '@components/web/src/templates/Modals/ModalWrapper/ModalWrapper';
import RenderModalOptions from '@components/web/src/templates/Modals/RenderModalOptions/RenderModalOptions';

type Props = {
  accountOptions: IModalOption;
  isModalOpen: boolean;
  hideModal: () => void;
  handleConfirmButton?: (values: Record<string, boolean>) => void;
  handleCancelButton?: () => void;
};

const ManageModal = ({ accountOptions, isModalOpen, handleConfirmButton, handleCancelButton, hideModal }: Props) => {
  const [accountState, setAccountState] = useState(accountOptions);

  const handleOnContinue = () => {
    let finalSelectedValues = {};
    Object.keys(accountState).forEach(option => {
      finalSelectedValues = {
        ...finalSelectedValues,
        [option]: accountState[option].isEnabled,
      };
    });

    handleConfirmButton(finalSelectedValues);
  };

  const handleOptionChange = ({ optionName, optionType }: IToggleOptionChange) => {
    let currentSelectedObjectState: IModalOption;
    let currentSetterFunction: Dispatch<SetStateAction<IModalOption>>;
    switch (optionType) {
      case 'account':
        currentSelectedObjectState = { ...accountState };
        currentSetterFunction = setAccountState;
        break;

      default:
        currentSelectedObjectState = { ...accountState };
        currentSetterFunction = setAccountState;
        break;
    }
    const findSelectedObject = currentSelectedObjectState[optionName];
    const finalSelectedObject = {
      ...findSelectedObject,
      isEnabled: !findSelectedObject.isEnabled,
    };
    currentSelectedObjectState[optionName] = finalSelectedObject;
    currentSetterFunction(currentSelectedObjectState);
  };

  const { manageModal } = localeApp;

  return (
    <ModalWrapper data-testid="ManageModal" hideModal={hideModal} isOpen={isModalOpen}>
      <S.ManageModalContainer>
        <AppModalHeader icon="warning" onCrossClick={hideModal} />
        <S.Title color={STATIC_COLORS.gray['900']} size="subtitle1" text={manageModal.title} />

        <S.Description color={STATIC_COLORS.base.black} size="body1" text={manageModal.description} />

        <S.ModalItems>
          <RenderModalOptions
            hideToggleText
            isManageModalVariant
            flexGap={24}
            optionType="account"
            options={accountState}
            onToggle={handleOptionChange}
          />
        </S.ModalItems>
        <S.ModalActions>
          <Button
            fontSize="subtitle2"
            handleClick={handleCancelButton}
            size="sm"
            text={manageModal.buttonCancelText}
            textWeight="medium"
            variant={VH_VARIANTS.LIGHT}
          />
          <Button
            fontSize="subtitle2"
            handleClick={handleOnContinue}
            size="sm"
            text={manageModal.buttonConfirmText}
            textWeight="medium"
          />
        </S.ModalActions>
      </S.ManageModalContainer>
    </ModalWrapper>
  );
};

export default ManageModal;
