import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { useCharacters } from '@lib/core/characters/hooks';
import { isCharacterByIdentifiers } from '@lib/core/characters/utils/filters';
import { TProductInstance } from '@lib/core/products/types';
import { useRetailerLocation } from '@lib/core/retailers/hooks/retailerLocation';
import {
  B2C_GET_PRODUCTS_REQUEST_LIMIT,
  FORMAT_QUERY,
  GPRL_CHARACTER_QUERY,
  IS_FROM_PRODUCT_DETAILS_PAGE,
  ORIGINS_QUERY,
  PRODUCTS_MAX_PRICE_QUERY,
  PRODUCTS_MIN_PRICE_QUERY,
  PRODUCT_CHARACTERISTICS_QUERY,
  PRODUCT_NAME_QUERY,
  SEARCH_BY_NAME_FIELDS_QUERY,
  STYLE_QUERY,
} from '@lib/core/service/consts';
import { useApp } from '@lib/core/service/hooks';
import {
  fetchProductsListsExtended,
  fetchProductsListsFeatured,
  resetProductCatalogState,
  setStaticMatchedProducts,
} from '@lib/core/service/slices/productCatalogSlice';
import { useFeedback, useUser, useWishlist } from '@lib/core/users/hooks';
import MixpanelTracker from '@lib/tools/dat/mixpanel';
import useDiscoveryQuizData from '@lib/tools/discoveryQuiz/hooks';
import { useCatalogFilter } from '@lib/tools/filterManager/hooks';
import {
  TProductCatalogOrdering,
  actionApplyCatalogOrdering,
  actionResetCatalogFilters,
} from '@lib/tools/filterManager/slices/productFilter';
import { updateCharactersToggleActive } from '@lib/tools/filterManager/slices/toggleFilterSlice';
import { removeUrlParams } from '@lib/tools/shared/helpers';
import {
  CHARACTERS_URL_PARAM,
  EXTENSIVE_CATALOG,
  FILTER_TYPE_CHARACTERISTICS,
  FILTER_TYPE_CHARACTER_TOGGLE,
  FILTER_TYPE_FORMAT,
  FILTER_TYPE_ITEM_NAME,
  FILTER_TYPE_ORDERING,
  FILTER_TYPE_ORIGIN,
  FILTER_TYPE_PRICE_RANGE_MAX,
  FILTER_TYPE_PRICE_RANGE_MIN,
  FILTER_TYPE_RATING,
  FILTER_TYPE_SHOW_FAVORITES,
  FILTER_TYPE_STYLE,
  ORDERING_TYPE_RANK,
  OS_PRODUCT_NAME_QUERY,
  PRODUCT_CATEGORY_QUERY,
  PRODUCT_IDENTIFIER_QUERY,
} from '@lib/tools/shared/helpers/consts';
import { IHandleProductsRequestParams } from '@lib/tools/shared/helpers/interfaces';
import { useAddons, useEffectSkipFirst, useTypedSelector } from '@lib/tools/views/hooks';

import { IS_FROM_TASTE_PATH } from '@components/web/src/foundations/consts';
import ExtendedCatalog, { IExtendedCatalogProps } from '@components/web/src/pages/widget/Catalog/ExtendedCatalog';

const ExtendedCatalogContainer = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const {
    isEnableLocationMapAddon,
    isEnableVusionAddon,
    isShowProductLocationAddon,
    isEnableTastePathAddon,
    isFeatureProductsAddon,
  } = useAddons();

  const { locale, productCategory } = useApp();
  const { retailerStoreType } = useRetailerLocation();
  const { userCharacterForSinglePCRetailer, isUserAuthenticated } = useUser();
  const characterStringFromUrl = searchParams.get(CHARACTERS_URL_PARAM) || '';
  const isFromProductDetailsPage = !!searchParams.get(IS_FROM_PRODUCT_DETAILS_PAGE);
  const isFromTastePath = !!searchParams.get(IS_FROM_TASTE_PATH);

  const [featuredProductsOutOfSwiper, setFeaturedProductsOutOfSwiper] = useState<TProductInstance[]>([]);

  const currentUserCharacterID = characterStringFromUrl || userCharacterForSinglePCRetailer?.identifier || '';

  const {
    filterManager: { isFilterOpened },
  } = useTypedSelector(state => state.productFilters);
  const {
    isProductsRequestLoading,
    lastDataLoadedTime,
    featuredProducts,
    lastFeaturedProductsLoadedTime,
    staticMatchedProducts,
    productsData: { results: products, count: itemsCount },
  } = useTypedSelector(state => state.productCatalog);
  const {
    [FILTER_TYPE_CHARACTERISTICS]: selectedCharacteristicsQuery,
    [FILTER_TYPE_FORMAT]: selectedFormatsQuery,
    [FILTER_TYPE_ORIGIN]: selectedOriginsQuery,
    [FILTER_TYPE_STYLE]: selectedStylesQuery,
    [FILTER_TYPE_PRICE_RANGE_MAX]: priceRangeMaxQuery,
    [FILTER_TYPE_PRICE_RANGE_MIN]: priceRangeMinQuery,
    [FILTER_TYPE_ITEM_NAME]: searchTextQuery,
    [FILTER_TYPE_SHOW_FAVORITES]: selectedWishlistProductsQuery,
    [FILTER_TYPE_CHARACTER_TOGGLE]: isUseMyCharacterToggleEnabled,
    [FILTER_TYPE_ORDERING]: activeOrdering,
    [FILTER_TYPE_RATING]: selectedRatedProductsQuery,
  } = useCatalogFilter();
  const [paginationOccurrence, setPaginationOccurrence] = useState(false);

  const handleProductsRequest = ({
    isPagination = false,
    isForCollectFilters = false,
  }: IHandleProductsRequestParams) => {
    const paginationParams = {
      limit: isForCollectFilters ? 0 : B2C_GET_PRODUCTS_REQUEST_LIMIT,
      offset: isPagination ? products.length : 0,
    };

    const filterParams = {};
    filterParams[PRODUCT_CATEGORY_QUERY] = productCategory;

    if (priceRangeMaxQuery) {
      filterParams[PRODUCTS_MAX_PRICE_QUERY] = priceRangeMaxQuery;
      filterParams[PRODUCTS_MIN_PRICE_QUERY] = priceRangeMinQuery;
    }

    if (searchTextQuery) {
      filterParams[PRODUCT_NAME_QUERY] = `*${searchTextQuery}*`;
      filterParams[SEARCH_BY_NAME_FIELDS_QUERY] = OS_PRODUCT_NAME_QUERY;
    }

    if (selectedOriginsQuery) {
      filterParams[ORIGINS_QUERY] = selectedOriginsQuery;
    }

    if (selectedFormatsQuery) {
      filterParams[FORMAT_QUERY] = selectedFormatsQuery;
    }

    if (selectedStylesQuery) {
      filterParams[STYLE_QUERY] = selectedStylesQuery;
    }

    if (selectedCharacteristicsQuery) {
      filterParams[PRODUCT_CHARACTERISTICS_QUERY] = selectedCharacteristicsQuery;
    }

    if (currentUserCharacterID && isUseMyCharacterToggleEnabled && !isForCollectFilters) {
      filterParams[GPRL_CHARACTER_QUERY] = currentUserCharacterID;
    }

    if (activeOrdering) {
      filterParams[FILTER_TYPE_ORDERING] = activeOrdering;
    }

    if (selectedWishlistProductsQuery) {
      filterParams[PRODUCT_IDENTIFIER_QUERY] = selectedWishlistProductsQuery;
    }

    if (selectedRatedProductsQuery) {
      filterParams[PRODUCT_IDENTIFIER_QUERY] = selectedRatedProductsQuery;
    }

    if (selectedWishlistProductsQuery && selectedRatedProductsQuery) {
      /** Both filters use the same PRODUCT_IDENTIFIER_QUERY parameter.
       * If both filters are applied we need to find common Ids between them .
       */

      const findCommonProductIds = (wishlist, feedback) =>
        wishlist
          .split('%')
          .filter(item => feedback.includes(item))
          .map(identifier => `%${identifier}`)
          .join('');

      filterParams[PRODUCT_IDENTIFIER_QUERY] = findCommonProductIds(
        selectedWishlistProductsQuery,
        selectedRatedProductsQuery,
      );
    }

    setPaginationOccurrence(isPagination);

    if (!isPagination && featuredProductsOutOfSwiper.length) {
      setFeaturedProductsOutOfSwiper([]);
    }

    return dispatch(
      fetchProductsListsExtended({
        isForCollectFilters,
        isPagination,
        params: {
          ...paginationParams,
          ...filterParams,
        },
        shouldShuffleResponse: true,
      }),
    );
  };

  const handleCancelCharacter = () => {
    dispatch(updateCharactersToggleActive({ value: false }));
  };

  useEffect(() => {
    if (isFeatureProductsAddon && !isFromProductDetailsPage && !isFromTastePath) {
      const params = {
        [FILTER_TYPE_ORDERING]: ORDERING_TYPE_RANK,
        [GPRL_CHARACTER_QUERY]: currentUserCharacterID,
        [PRODUCT_CATEGORY_QUERY]: productCategory,
      };
      dispatch(fetchProductsListsFeatured({ params }));
    }
  }, [isFeatureProductsAddon]);

  useEffect(() => {
    MixpanelTracker.events.productCatalogView(productCategory);

    if (!isFromProductDetailsPage && !isFromTastePath) {
      dispatch(actionResetCatalogFilters());
      dispatch(resetProductCatalogState());
    } else if (isFromProductDetailsPage) {
      removeUrlParams({
        keys: [IS_FROM_PRODUCT_DETAILS_PAGE],
      });
    }
  }, []);

  const generateSwiperProducts = () => {
    let swiperProducts: TProductInstance[] = [];
    if (isFeatureProductsAddon) {
      if (featuredProducts.length > 3) {
        swiperProducts = featuredProducts.slice(0, 3);
        setFeaturedProductsOutOfSwiper(featuredProducts.slice(3));
      } else {
        const filteredProducts = products.filter(
          product => !featuredProducts.some(featureProduct => featureProduct.identifier === product.identifier),
        );
        swiperProducts = [...featuredProducts, ...filteredProducts].slice(0, 3);
      }
    } else {
      swiperProducts = products.slice(0, 3);
    }
    dispatch(setStaticMatchedProducts(swiperProducts));
  };

  useEffectSkipFirst(() => {
    if (
      lastDataLoadedTime &&
      (!isFeatureProductsAddon || lastFeaturedProductsLoadedTime) &&
      !staticMatchedProducts.length
    ) {
      generateSwiperProducts();
    }
  }, [lastFeaturedProductsLoadedTime, lastDataLoadedTime, isFeatureProductsAddon]);

  const calculatedProducts = useMemo(
    () => [
      ...featuredProductsOutOfSwiper,
      ...products.filter(
        product => !staticMatchedProducts.some(featureProduct => featureProduct.identifier === product.identifier),
      ),
    ],
    [products, featuredProducts, staticMatchedProducts],
  );

  const { characters } = useCharacters();
  const translatedUserCharactersData = characters.filter(character =>
    isCharacterByIdentifiers(character, currentUserCharacterID.split('-')),
  );

  const charactersFilterTagsData = useMemo(
    () =>
      translatedUserCharactersData.map(character => ({
        filterType: FILTER_TYPE_CHARACTER_TOGGLE,
        isActive: isUseMyCharacterToggleEnabled,
        name: character?.name,
        value: character?.identifier,
      })),
    [translatedUserCharactersData, isUseMyCharacterToggleEnabled],
  );

  const filterProps = {
    additionalCharacters: isUseMyCharacterToggleEnabled ? charactersFilterTagsData : [],
    additionalFiltersEnabled: {
      [FILTER_TYPE_CHARACTER_TOGGLE]: true,
      ...(isUserAuthenticated && { [FILTER_TYPE_RATING]: true }),
      [FILTER_TYPE_SHOW_FAVORITES]: true,
    },
    handleCancelCharacter,
    isSearchEnabled: false,
  };

  const excludedProductsCount = products.length - calculatedProducts.length;
  const fixedItemsCount = typeof itemsCount === 'number' ? itemsCount - excludedProductsCount : undefined;

  useEffect(() => {
    const container = document.querySelector('.see-all-products-button');
    if (container && !paginationOccurrence) {
      container.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [isFilterOpened]);

  const { handleUpdateFeedback, isFeedbackLoading, feedbackData } = useFeedback();
  // TODO: replace useWishlist to useProductList after full start using useProductList
  const { wishlistProductIds, handleUpdateWishlistProductList, isProductListLoading } = useWishlist();

  const { discoveryQuiz } = useDiscoveryQuizData();

  const handleChangeOrdering = (newValue: TProductCatalogOrdering) => {
    dispatch(actionApplyCatalogOrdering(newValue));
  };

  const catalogProps: IExtendedCatalogProps = {
    activeOrdering,
    catalogType: EXTENSIVE_CATALOG,
    discoveryQuiz,
    feedbackData,
    filterProps,
    handleChangeOrdering,
    handleProductsRequest,
    handleUpdateFeedback,
    handleUpdateWishlistProductList,
    isChangeOrderingEnabled: !!priceRangeMaxQuery,
    isEnableLocationMapAddon,
    isEnableTastePathAddon,
    isEnableVusionAddon,
    isFeedbackEnabled: isUserAuthenticated,
    isFeedbackLoading,
    isProductListLoading,
    isProductsLoaded: !!lastDataLoadedTime,
    isProductsRequestLoading,
    isShowProductLocationAddon,
    isWishlistEnabled: isUserAuthenticated,
    itemsCount: fixedItemsCount,
    locale,
    matchedProducts: staticMatchedProducts || [],
    productCategory,
    products: calculatedProducts,
    storeType: retailerStoreType,
    wishlistProductIds,
    withOpenedProductList: isFromProductDetailsPage,
  };

  return <ExtendedCatalog {...catalogProps} />;
};

export default ExtendedCatalogContainer;
