import { FC, useEffect, useState } from 'react';

import { ILocaleText } from '@lib/tools/locale/views/LocaleFragment';

import bookmarkIcon from '@components/web/src/assets/icons/Bookmark.svg';
import Confetti from '@components/web/src/atoms/Confetti/Confetti';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Text } from '@components/web/src/foundations/Text/Text';
import * as S from '@components/web/src/templates/Login&Registration/Registration/RegisterDetailsBanner/styles';

export type TRegisterDetailsBannerLocale = Record<'localeIndex' | 'localeVariables', Record<string, any>>;

interface IProps {
  title: ILocaleText;
  description: ILocaleText;
  extraDescription?: ILocaleText;
  icon?: boolean;
  animation?: boolean;
  locale?: TRegisterDetailsBannerLocale;
}

const RegisterDetailsBanner: FC<IProps> = ({
  title,
  description,
  icon = false,
  animation = false,
  locale,
  extraDescription,
}) => {
  const [startAnimation, setStartAnimation] = useState(animation);

  useEffect(() => {
    // to hide confetti animation
    const timer = setTimeout(() => setStartAnimation(null), 2000);

    return () => {
      clearTimeout(timer);
      setStartAnimation(null);
    };
  }, [startAnimation]);

  return (
    <S.RegisterDetailsBannerContainer data-testid="RegisterDetailsBannerContainer">
      {startAnimation && <Confetti />}
      <S.Header>
        <Text
          color={STATIC_COLORS.base.white}
          fontFamily="Fraunces"
          localeIndex={locale?.localeIndex}
          localeVariables={locale?.localeVariables}
          size="h4"
          text={title}
          weight="semibold"
        />
        {icon && <img alt="bookmark icon" src={bookmarkIcon} />}
        <S.StyledText
          color={STATIC_COLORS.base.white}
          localeIndex={locale?.localeIndex}
          localeVariables={locale?.localeVariables}
          size="body1"
          text={description}
          weight="medium"
        />
        {extraDescription && (
          <Text color={STATIC_COLORS.base.white} size="body1" text={extraDescription} weight="medium" />
        )}
      </S.Header>
    </S.RegisterDetailsBannerContainer>
  );
};

export default RegisterDetailsBanner;
