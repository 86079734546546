import { FC } from 'react';

import { TProductCategory } from '@lib/core/products/types';
import { localeApp } from '@lib/tools/locale/source/web/app';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import { LocaleUtils } from '@lib/tools/locale/utils';
import { PRODUCT_CATEGORY_COFFEE } from '@lib/tools/shared/helpers/consts';

import PerfectFor from '@components/web/src/app/PerfectFor/PerfectFor';
import ChefRecommendsCard, {
  IRecipe,
} from '@components/web/src/components/Cards/ChefRecommendsCard/ChefRecommendsCard';
import PreparationCard, {
  IPreparationItem,
} from '@components/web/src/components/Cards/PreparationCard/PreparationCard';
import SectionHeader from '@components/web/src/components/SectionHeader/SectionHeader';
import SwiperHOC from '@components/web/src/components/Swiper/SwiperHOC';
import ReadMoreCard, { IReadMoreCardProps } from '@components/web/src/organisms/Cards/ReadMoreCard/ReadMoreCard';

type Props = {
  perfectForData: string;
  sustainabilityData: IReadMoreCardProps[];
  recipesData: IRecipe[];
  preparationsData: IPreparationItem[];
  productCategory: TProductCategory;
};

const TastingPage: FC<Props> = ({
  perfectForData,
  sustainabilityData,
  recipesData,
  preparationsData,
  productCategory,
}) => {
  const { productPage } = localeApp;
  const { productCategories } = localeCommon;

  const { publishedTerms } = LocaleUtils;

  const productCategoriesText = publishedTerms[productCategories[productCategory].id];

  return (
    <div className="tasting-container">
      <section className="perfectFor">
        <PerfectFor perfectFor={perfectForData} />
      </section>
      {recipesData.length > 0 && (
        <section className="chef-section">
          <SectionHeader
            sectionDescription={productPage.chefRecommendsSubtitleText}
            sectionTitle={productPage.chefRecommendsTitleText}
            descriptionLocaleOptions={{
              productCategoriesText,
              productCategory,
            }}
          />
          <SwiperHOC>
            {recipesData.map((recipeItem, idx) => (
              <ChefRecommendsCard
                key={`${idx}`}
                image={recipeItem.image}
                subtitle={recipeItem.subtitle}
                title={recipeItem.title}
              />
            ))}
          </SwiperHOC>
        </section>
      )}
      {productCategory === PRODUCT_CATEGORY_COFFEE && (
        <section className="preparations-section">
          <SectionHeader
            sectionDescription={productPage.coffeePreparationsSubtitleText}
            sectionTitle={productPage.coffeePreparationsTitleText}
          />
          <SwiperHOC spaceBetween={16}>
            {preparationsData?.map((preparationsItem, idx) => (
              <PreparationCard
                key={idx.toString()}
                description={preparationsItem.description}
                image={preparationsItem.image}
                subtitle={preparationsItem.subtitle}
                title={preparationsItem.title}
              />
            ))}
          </SwiperHOC>
        </section>
      )}
      {sustainabilityData.length > 0 && (
        <section className="sustainability-section">
          <SectionHeader
            sectionDescription={productPage.sustainabilitySubtitleText}
            sectionTitle={productPage.sustainabilityTitleText}
            descriptionLocaleOptions={{
              productCategoriesText,
              productCategory,
            }}
          />
          <SwiperHOC>
            {sustainabilityData.map((sustainabilityItem, idx) => (
              <ReadMoreCard
                key={idx.toString()}
                isTransparentVariant
                cardTitle={sustainabilityItem.cardTitle}
                description={sustainabilityItem.description}
                minimumCharacters={105}
              />
            ))}
          </SwiperHOC>
        </section>
      )}
    </div>
  );
};

export default TastingPage;
