import { useSelector } from 'react-redux';

import { TProductCategory } from '@lib/core/products/types';
import {
  selectAssociatedRetailerLocation,
  selectIsRetailerLocationLoading,
  selectIsRetailerLocationStoreTypeEcommerce,
  selectRetailerCatalogType,
  selectRetailerDesignSet,
  selectRetailerLocationAddOns,
  selectRetailerLocationAddress,
  selectRetailerLocationCoordinates,
  selectRetailerLocationCustomStyle,
  selectRetailerLocationDescription,
  selectRetailerLocationEmail,
  selectRetailerLocationId,
  selectRetailerLocationImages,
  selectRetailerLocationLogo,
  selectRetailerLocationName,
  selectRetailerLocationPhone,
  selectRetailerLocationProducerId,
  selectRetailerLocationProductCategories,
  selectRetailerLocationSlug,
  selectRetailerLocationStoreType,
  selectRetailerLocationTasteMatch,
  selectRetailerLocationWebsite,
} from '@lib/core/retailers/selectors/retailerLocation';
import { TAssociatedKioskRetailerLocation, TRetailerLocationStoreType } from '@lib/core/retailers/types';
import { TDesignSetName } from '@lib/core/retailers/types/designSets';
import { ITheme } from '@lib/tools/shared/helpers/interfaces';

type IRetailerLocationDetails = {
  isRetailerLocationStoreTypeEcommerce: boolean;
  addOns: string[];
  retailerDesign: TDesignSetName;
  retailerLocationId: string;
  retailerLocationSlug: string;
  retailerStoreType: TRetailerLocationStoreType;
  retailerLocationProductCategories: TProductCategory[];
  customStyle: ITheme;
  isRetailerLocationLoading: boolean;
  associatedRetailerLocation: TAssociatedKioskRetailerLocation;
  retailerLocationName: string;
  retailerLocationWebsite: string;
  retailerLocationLogo: string;
  retailerLocationPhone: string;
  retailerLocationEmail: string;
  retailerLocationDescription: string;
  retailerLocationImages: string[];
  retailerLocationAddress: string;
  retailerLocationCoordinates: [number, number] | null;
  retailerLocationProducerId: string;
  retailerLocationTasteMatch: Record<string, number>;
  retailerLocationCatalogType: string;
};

export const useRetailerLocation = (): IRetailerLocationDetails => {
  return {
    addOns: useSelector(selectRetailerLocationAddOns),
    associatedRetailerLocation: useSelector(selectAssociatedRetailerLocation),
    customStyle: useSelector(selectRetailerLocationCustomStyle),
    isRetailerLocationLoading: useSelector(selectIsRetailerLocationLoading),
    isRetailerLocationStoreTypeEcommerce: useSelector(selectIsRetailerLocationStoreTypeEcommerce),
    retailerDesign: useSelector(selectRetailerDesignSet),
    retailerLocationAddress: useSelector(selectRetailerLocationAddress),
    retailerLocationCatalogType: useSelector(selectRetailerCatalogType),
    retailerLocationCoordinates: useSelector(selectRetailerLocationCoordinates),
    retailerLocationDescription: useSelector(selectRetailerLocationDescription),
    retailerLocationEmail: useSelector(selectRetailerLocationEmail),
    retailerLocationId: useSelector(selectRetailerLocationId),
    retailerLocationImages: useSelector(selectRetailerLocationImages),
    retailerLocationLogo: useSelector(selectRetailerLocationLogo),
    retailerLocationName: useSelector(selectRetailerLocationName),
    retailerLocationPhone: useSelector(selectRetailerLocationPhone),
    retailerLocationProducerId: useSelector(selectRetailerLocationProducerId),
    retailerLocationProductCategories: useSelector(selectRetailerLocationProductCategories),
    retailerLocationSlug: useSelector(selectRetailerLocationSlug),
    retailerLocationTasteMatch: useSelector(selectRetailerLocationTasteMatch),
    retailerLocationWebsite: useSelector(selectRetailerLocationWebsite),
    retailerStoreType: useSelector(selectRetailerLocationStoreType),
  };
};
