import { css, styled } from 'styled-components';

import { TTabIcon } from '@components/web/src/atoms/Tabs/TabButton/TabButton';
import { DEFAULT_COLOR_THEME, STATIC_COLORS } from '@components/web/src/foundations';
import { ROOT_ELEMENT_KIOSK } from '@components/web/src/foundations/consts';
import { Text } from '@components/web/src/foundations/Text/Text';

export const StyledTabButton = styled.button<{
  $isActive: boolean;
  $tabIcon: TTabIcon;
}>`
  all: unset;
  z-index: 2;
  display: flex;
  align-items: center;
  max-width: fit-content;
  max-height: 38px;
  padding: 0 4px 12px;
  cursor: pointer;
  position: relative;

  ${({ theme }) => {
    if (theme?.rootElementType === ROOT_ELEMENT_KIOSK) {
      return css`
        padding: 0 4px 16px;
      `;
    }
    return css`
      padding: 0 4px 12px;
    `;
  }}
  ${({ $isActive, $tabIcon }) =>
    $isActive &&
    css`
      &::after {
        content: '';
        z-index: 2;
        position: absolute;
        bottom: 0;
        left: 0;
        min-height: 2px;
        width: 100%;
        background: ${
          $tabIcon && $tabIcon !== 'none'
            ? STATIC_COLORS.productColors[$tabIcon][500]
            : DEFAULT_COLOR_THEME.primaryColor[-700]
        };
      `}
      }
`;

export const Icon = styled.div<{ $isActive: boolean }>`
  width: 24px;
  height: 24px;
  margin-right: 8px;

  svg {
    width: 100%;
    height: 100%;

    path {
      stroke: ${({ $isActive }) => ($isActive ? DEFAULT_COLOR_THEME.primaryColor[-700] : 'inherit')};
    }
  }
`;

export const StyledText = styled(Text)<{ $isActive: boolean }>`
  text-transform: capitalize;
  color: ${({ $isActive }) => ($isActive ? DEFAULT_COLOR_THEME.primaryColor[-700] : STATIC_COLORS.gray['600'])};
  font-weight: ${({ $isActive }) => $isActive && 600};
`;
