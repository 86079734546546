import { FC } from 'react';

import BackgroundCircles from '@components/web/src/shared/BackgroundCircles/BackgroundCirlcles';

export type Props = {
  customColor?: string;
};

const KioskCirclesBackground: FC<Props> = ({ customColor = null }) => {
  return (
    <div className="circles-background-container">
      <div className="circles-background">
        <BackgroundCircles customColor={customColor} />
      </div>
    </div>
  );
};

export default KioskCirclesBackground;
