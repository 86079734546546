import classNames from 'classnames';
import { FC } from 'react';

import { TProductCategory } from '@lib/core/products/types';

import RecipeItem, { IB2CRecipe } from '@components/web/src/components/Recipe/RecipeItem/RecipeItem';

type Props = {
  recipes: IB2CRecipe[];
  isRecipesLoading: boolean;
  productCategory: TProductCategory;
  isDesignSetVinhoodApp: boolean;
  isApplicationKiosk?: boolean;
};

const RecipeResult: FC<Props> = ({
  isApplicationKiosk,
  recipes,
  isRecipesLoading,
  productCategory,
  isDesignSetVinhoodApp,
}) => {
  return (
    <div className={classNames('recipe-search-result-container', { isLoading: isRecipesLoading })}>
      {isRecipesLoading
        ? Array.from({ length: isApplicationKiosk ? 14 : 5 }).map((_, idx) => (
            <RecipeItem
              key={idx.toString()}
              isLoading
              isApplicationKiosk={isApplicationKiosk}
              isDesignSetVinhoodApp={isDesignSetVinhoodApp}
              productCategory={productCategory}
            />
          ))
        : recipes?.map(recipe => (
            <RecipeItem
              key={recipe.identifier}
              data={recipe}
              isApplicationKiosk={isApplicationKiosk}
              isDesignSetVinhoodApp={isDesignSetVinhoodApp}
              productCategory={productCategory}
            />
          ))}
    </div>
  );
};

export default RecipeResult;
