import { css, styled } from 'styled-components';

import { ROOT_ELEMENT_KIOSK } from '@components/web/src/foundations/consts';

export const TasteMatchModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px 16px 24px;
  align-items: center;

  ${({ theme }) =>
    theme?.rootElementType === ROOT_ELEMENT_KIOSK &&
    css`
      min-width: 880px;
      padding: 16px 40px 24px;
    `}
`;

export const TasteMatchContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 28px;
  align-items: center;

  ${({ theme }) =>
    theme?.rootElementType === ROOT_ELEMENT_KIOSK &&
    css`
      gap: 8px;
    `}
`;

export const TasteMatchActions = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;

  ${({ theme }) =>
    theme?.rootElementType === ROOT_ELEMENT_KIOSK &&
    css`
      margin-top: 16px;
      gap: 10px;
    `}
`;

export const Icon = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  margin-left: auto;
  cursor: pointer;

  svg {
    stroke: black;
  }
`;
