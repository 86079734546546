import { FC, Fragment } from 'react';

import { IModalOption, IOptionType, IToggleOptionChange } from '@components/web/src/app/Modals/modalTypes';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import ModalOptionItem from '@components/web/src/templates/Modals/ModalOptionItem/ModalOptionItem';

interface IProps {
  options: IModalOption;
  flexGap?: number;
  optionType: IOptionType;
  onToggle?: ({ optionName, optionType }: IToggleOptionChange) => void;
  hideToggleText?: boolean;
  isManageModalVariant?: boolean;
}

const RenderModalOptions: FC<IProps> = ({
  options,
  isManageModalVariant,
  flexGap = 16,
  onToggle,
  hideToggleText,
  optionType,
}) => {
  return (
    <Flexbox align="flex-start" direction="column" gap={flexGap}>
      {Object.keys(options)?.map((option, idx) => (
        <Fragment key={idx}>
          <ModalOptionItem
            key={option}
            data={options[option]}
            hideToggleText={hideToggleText}
            isManageModalVariant={isManageModalVariant}
            optionName={option}
            optionType={optionType}
            onToggle={onToggle}
          />
        </Fragment>
      ))}
    </Flexbox>
  );
};

export default RenderModalOptions;
