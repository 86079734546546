import classNames from 'classnames';
import { FC, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { TLanguage } from '@lib/core/retailers/types';
import { prependBasename } from '@lib/core/service/utils';
import { isAppInIframe } from '@lib/tools/comms/utils';
import MixpanelTracker from '@lib/tools/dat/mixpanel';
import { useCustomStyles } from '@lib/tools/views/hooks';
import { PAGES } from '@lib/tools/views/urls';

import * as S from '@components/web/src/atoms/Header/styles';
import LanguageSwitcher from '@components/web/src/components/LanguageSwitcher/LanguageSwitcher';
import { Image } from '@components/web/src/foundations/Image/Image';
import FidelityDetachButton from '@components/web/src/kiosk/FidelityDetachButton/FidelityDetachButton';
import CloseIcon from '@components/web/src/shared/Icons/CloseIcon';
import VinhoodLogo from '@components/web/src/shared/Logo';
import { LOGO_FILL_COLORS, PRIVACY_PAGE_BACKGROUND_COLOR } from '@components/web/src/widget/consts';

interface IProps {
  isShowLanguageSwitcher: boolean;
  isShowDetachFidelityCard?: boolean;
  isDarkTheme?: boolean;
  isApplicationKiosk?: boolean;
  isNavigateUserToTest?: boolean;
  retailerLanguages: TLanguage[];
  locale: string;
  onDetachFidelityCardClick?: () => void;
}

const Header: FC<IProps> = ({
  isShowLanguageSwitcher,
  isShowDetachFidelityCard,
  isDarkTheme,
  isApplicationKiosk,
  isNavigateUserToTest,
  retailerLanguages,
  locale,
  onDetachFidelityCardClick = () => {},
}) => {
  const navigate = useNavigate();

  const { customHeaderLogo } = useCustomStyles();

  const switcherType = isApplicationKiosk ? 'icon' : 'select';

  const headerBackgroundColor = useMemo(() => {
    if (isDarkTheme) {
      return PRIVACY_PAGE_BACKGROUND_COLOR;
    }
    return 'var(--color-secondary-100)';
  }, [isDarkTheme]);

  const fillColor = useMemo(() => {
    if (isDarkTheme) {
      return LOGO_FILL_COLORS.dark;
    }
    return LOGO_FILL_COLORS.light;
  }, [isDarkTheme]);

  const onLogoClick = () => {
    const quizPageUrl = PAGES.vinhood.quiz.chooseExpert;
    const homePageUrl = PAGES.vinhood.home;
    navigate(prependBasename(isNavigateUserToTest ? quizPageUrl : homePageUrl));
  };

  return (
    <S.HeaderContainer
      data-testid="HeaderContainer"
      style={headerBackgroundColor ? { backgroundColor: headerBackgroundColor } : {}}
    >
      <S.HeaderLogoWrapper data-testid="HeaderLogoWrapper">
        <div className="logo" role="button" tabIndex={0} onClick={onLogoClick} onKeyDown={onLogoClick}>
          {customHeaderLogo ? (
            <Image alt="logo" src={customHeaderLogo} />
          ) : (
            <VinhoodLogo fillColor={fillColor} isApplicationKiosk={isApplicationKiosk} />
          )}
        </div>
        <S.HeaderRightSection data-testid="HeaderRightSection">
          {isShowDetachFidelityCard && (
            <FidelityDetachButton
              onClick={() => {
                onDetachFidelityCardClick();
                MixpanelTracker.events.detachFidelityCard();
              }}
            />
          )}
          {isShowLanguageSwitcher && (
            <LanguageSwitcher
              isDarkTheme={isDarkTheme}
              languageSwitcherType={switcherType}
              locale={locale}
              retailerLanguages={retailerLanguages}
            />
          )}
          {isAppInIframe && !isApplicationKiosk && (
            <button
              className={classNames('close-button', {
                invisible: isAppInIframe,
              })}
            >
              <CloseIcon fillColor={fillColor} />
            </button>
          )}
        </S.HeaderRightSection>
      </S.HeaderLogoWrapper>
    </S.HeaderContainer>
  );
};

export default Header;
