import { useNavigate } from 'react-router-dom';

import { B2C_PROFILE_PAGE_TABS } from '@app/web/src/helpers/consts';

import { PROFILE_TAB_SLUG_REPLACEMENT } from '@lib/core/service/consts';
import { useApp } from '@lib/core/service/hooks';
import { prependBasename } from '@lib/core/service/utils';
import { useUser } from '@lib/core/users/hooks';
import { VH_RETAILER_URL_SLUG_APP } from '@lib/tools/shared/helpers/consts';
import { PAGES } from '@lib/tools/views/urls';

import ExitPage from '@components/web/src/pages/widget/ExitPage/ExitPage';

const ExitContainer = () => {
  const navigate = useNavigate();
  const { isUserAuthenticated, handleResetUserData } = useUser();
  const isLogged = isUserAuthenticated;
  const { locale } = useApp();

  const navigateToHomePage = () => {
    navigate(prependBasename(PAGES.vinhood.home));
  };

  const navigateToAppSettingsPage = () => {
    const url = `${window.location.origin}/${locale}/${VH_RETAILER_URL_SLUG_APP}${PAGES.vinhood.profile.replace(
      PROFILE_TAB_SLUG_REPLACEMENT,
      B2C_PROFILE_PAGE_TABS.settings,
    )}`;

    window.open(url, '_blank');
  };

  const resetUserData = () => {
    if (isLogged) {
      return navigateToAppSettingsPage();
    }
    handleResetUserData();

    return navigateToHomePage();
  };

  return <ExitPage isLogged={isLogged} navigateToHomePage={navigateToHomePage} resetUserData={resetUserData} />;
};

export default ExitContainer;
