import { FC, useState } from 'react';

import { TCharacter } from '@lib/core/characters/types';
import { TProductCategory } from '@lib/core/products/types';
import {
  RegisterPageScenarios,
  TRegisterPageScenarioState,
} from '@lib/core/service/slices/technical/registerPageScenario';
import { localeApp } from '@lib/tools/locale/source/web/app';
import { localeCommon } from '@lib/tools/locale/source/web/common';

import CharacterAdjective, {
  ICharacterAdjectiveProps,
} from '@components/web/src/atoms/CharacterAdjective/CharacterAdjective';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Text } from '@components/web/src/foundations/Text/Text';
import * as S from '@components/web/src/organisms/CharacterAdjectivesGroup/styles';
import { TSwiperData } from '@components/web/src/organisms/ExtraInfoSwiper/ExtraInfoSwiper';
import ExtraInfoModal from '@components/web/src/templates/Modals/ExtraInfoModal/ExtraInfoModal';

export interface ICharacterAdjectiveData {
  characterAdjectiveItemsData: ICharacterAdjectiveProps[];
  characterAdjectiveModalData: TSwiperData[];
}

interface IProps {
  characterData: TCharacter;
  productCategory: TProductCategory;
  characterAdjectiveData: ICharacterAdjectiveData;
  isUserAuthenticated?: boolean;
  isTestResultPage?: boolean;
  handleAdjectiveClick?: (payload: TRegisterPageScenarioState) => void;
}

const CharacterAdjectivesGroup: FC<IProps> = ({
  characterData,
  productCategory,
  characterAdjectiveData,
  isUserAuthenticated = false,
  isTestResultPage = false,
  handleAdjectiveClick,
}) => {
  const [isEssenceModalOpen, setIsEssenceModalOpen] = useState(false);

  const { characterTitleText, authenticatedCharacterTitleText } = localeApp.testResultPage;
  const { productCategories } = localeCommon;
  const title = isUserAuthenticated ? authenticatedCharacterTitleText : characterTitleText;

  const { characterAdjectiveItemsData, characterAdjectiveModalData } = characterAdjectiveData;

  const [sortedCharacterAdjectivesModalData, setSortedCharacterAdjectivesModalData] =
    useState(characterAdjectiveModalData);

  const characterName = characterData?.name || '';
  const characterImage = characterData?.image || '';
  const technicalCaption = characterData?.technical_caption || '';

  const sortCharacterAdjectivesData = identifier => {
    const index = characterAdjectiveModalData?.findIndex(item => item.identifier === identifier);
    if (index !== -1) {
      const sortedData = [
        characterAdjectiveModalData[index],
        ...characterAdjectiveModalData?.slice(0, index),
        ...characterAdjectiveModalData?.slice(index + 1),
      ];
      if (isTestResultPage) {
        handleAdjectiveClick({
          productCategory,
          scenario: RegisterPageScenarios.AdjectiveClick,
          swiperData: sortedData,
        });
      } else {
        setIsEssenceModalOpen(true);
        setSortedCharacterAdjectivesModalData(sortedData);
      }
    }
  };

  return (
    <S.CharacterAdjectivesGroupContainer data-testid={`CharacterAdjectiveGroup-${productCategory}`}>
      <S.CharacterAdjectivesGroup>
        <S.TextWrapper>
          <Text
            color={STATIC_COLORS.base.black}
            fontFamily="Fraunces"
            localeIndex={{ productCategory }}
            localeVariables={{ productCategoryText: productCategories[productCategory] }}
            size="h6"
            text={title}
            weight="semibold"
          />
          <Text
            color={STATIC_COLORS.productColors[productCategory][500]}
            fontFamily="Fraunces"
            size="h2"
            text={characterName}
            weight="semibold"
          />
          <Text color={STATIC_COLORS.base.black} size="body1" text={technicalCaption} weight="normal" />
        </S.TextWrapper>
        <S.CharacterImage $isTestResultPage={isTestResultPage} alt="character" src={characterImage} />
        <S.CharacterAdjectivesWrapper>
          {characterAdjectiveItemsData?.map((characterAdjective, idx) => (
            <CharacterAdjective
              key={idx}
              identifier={characterAdjective.identifier}
              preTitle={characterAdjective.preTitle}
              productCategory={productCategory}
              score={characterAdjective.score}
              title={characterAdjective.title}
              handleClick={() => {
                sortCharacterAdjectivesData(characterAdjective.identifier);
              }}
            />
          ))}
        </S.CharacterAdjectivesWrapper>
        {isEssenceModalOpen && (
          <ExtraInfoModal
            hideModal={() => setIsEssenceModalOpen(false)}
            isModalOpen={isEssenceModalOpen}
            swiperData={sortedCharacterAdjectivesModalData}
            variant={RegisterPageScenarios.AdjectiveClick}
          />
        )}
      </S.CharacterAdjectivesGroup>
    </S.CharacterAdjectivesGroupContainer>
  );
};

export default CharacterAdjectivesGroup;
