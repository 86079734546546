import { createSlice } from '@reduxjs/toolkit';

import {
  apiUrlGetAssociatedCharacter,
  apiUrlGetCharacters,
  apiUrlGetRetailerCharacter,
} from '@lib/core/characters/slices/urls';
import { TCharacter } from '@lib/core/characters/types';
import { TProductCategory } from '@lib/core/products/types';
import { selectUserQuizCharacters } from '@lib/core/quizzes/selectors';
import { createTypedAsyncThunk } from '@lib/core/service/createTypedAsyncThunk';
import request from '@lib/core/service/requests/request';
import { setProductCategory } from '@lib/core/service/slices';
import { actionSetUserCharacters } from '@lib/core/users/slices/user';

export interface ICharactersSlice {
  data: TCharacter[];
  isLoading: boolean;
}

export const initialState: ICharactersSlice = {
  data: [],
  isLoading: false,
};

export const actionGetAssociatedCharacter = createTypedAsyncThunk(
  'characters/associatedCharacters/get',
  async (productCategory: TProductCategory, { getState, dispatch }) => {
    const [userQuizCharacter] = selectUserQuizCharacters(getState());

    request(apiUrlGetAssociatedCharacter(productCategory, userQuizCharacter.identifier)).then(
      (response: TCharacter) => {
        dispatch(actionSetUserCharacters([response]));
        dispatch(setProductCategory(productCategory));
      },
    );
  },
);

export const actionGetCharacters = createTypedAsyncThunk('characters/get', async () => {
  const data = await request(apiUrlGetCharacters());
  return data;
});

export const actionGetRetailerCharacter = createTypedAsyncThunk(
  'characters/retailerCharacters/get',
  async ({ characterId }: { characterId: string }) => {
    return characterId ? request(apiUrlGetRetailerCharacter({ characterID: characterId })).then(json => json[0]) : {};
  },
);

export const index = createSlice({
  extraReducers: builder => {
    builder.addCase(actionGetCharacters.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(actionGetCharacters.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(actionGetCharacters.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
  },
  initialState,
  name: 'characters',
  reducers: {},
});

export default index.reducer;
