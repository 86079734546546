import { actionResetUserQuiz } from '@lib/core/quizzes/slices';
import { resetController } from '@lib/core/service/requests/abortController';
import { setIsAnonymousPreference } from '@lib/core/service/slices/kioskRootSlice';
import { resetPromotionProducts } from '@lib/core/service/slices/promotionProductsSlice';
import { AppDispatch } from '@lib/core/service/types/appStateType';
import { prependBasename } from '@lib/core/service/utils';
import { history } from '@lib/core/service/utils/Navigator';
import { detachFidelityCard } from '@lib/core/users/slices/fidelityCard';
import { actionResetProductList } from '@lib/core/users/slices/productList';
import { actionResetUserProfileId } from '@lib/core/users/slices/profile';
import { actionSetUserCharacters } from '@lib/core/users/slices/user';
import { PRODUCT_CATEGORY_COLORS, USER_PRODUCT_PREFERENCES } from '@lib/tools/shared/helpers/consts';
import { PAGES } from '@lib/tools/views/urls';

export const setUrlParams = (params: { key: string; value: string }[]) => {
  const {
    location: { pathname, search },
  } = window;
  const searchParams = new URLSearchParams(search);

  params.forEach(({ key, value }) => {
    if (value) {
      searchParams.set(key, value);
    } else {
      searchParams.delete(key);
    }
  });

  history.push(`${pathname}?${searchParams.toString()}`);
};

export const removeUrlParams = ({ keys }: { keys: string[] }) => {
  const {
    location: { pathname, search },
  } = window;
  const newSearchParams = new URLSearchParams(search);
  keys.forEach(key => {
    const currentParamValue = newSearchParams.get(key);
    if (currentParamValue) {
      newSearchParams.delete(key);
    }
  });
  const newSearchParamsString = newSearchParams.toString();
  if (newSearchParamsString) {
    history.push(`${pathname}?${newSearchParams.toString()}`);
  } else {
    history.push(pathname);
  }
};

export const parseLimitedCatalogProduct = productInstanceData => {
  const {
    product: { character: productCharacter },
    price,
    currency,
  } = productInstanceData;
  return { ...productInstanceData, character: productCharacter, price: { currency, value: price } };
};

const awaitAbortcontroller = async () => {
  await resetController();
};

export const resetVinhoodUserSession = ({
  dispatch,
  showFidelityMessage = false,
}: {
  dispatch: AppDispatch;
  showFidelityMessage?: boolean;
}) => {
  awaitAbortcontroller();
  dispatch(detachFidelityCard({ displayMessage: showFidelityMessage }));
  dispatch(actionResetUserQuiz());
  dispatch(setIsAnonymousPreference());
  dispatch(resetPromotionProducts());
  dispatch(actionResetUserProfileId());
  dispatch(actionResetProductList());
  dispatch(actionSetUserCharacters([]));
  localStorage.removeItem(USER_PRODUCT_PREFERENCES);
  history.push(prependBasename(PAGES.vinhood.welcome));
};

/**
 * @param {string} queryString - queryparams
 * @param {string} queryKey - parameter to get value of
 * @returns {string} value of the parameter
 */
export const getQueryValue = (queryString, querykey) => {
  const params = new URLSearchParams(queryString);
  return params.get(querykey);
};

export const getPrimaryProductCategoryColor = (productCategory: string) =>
  PRODUCT_CATEGORY_COLORS.primary[productCategory] || '';

export const getSecondaryProductCategoryColor = (productCategory: string) =>
  PRODUCT_CATEGORY_COLORS.secondary[productCategory] || '';

export const getRandomIndex = (limitValue: number) => Math.floor(Math.random() * limitValue);

export const isVideoLink = url => {
  // Define an array of video file extensions
  const videoExtensions = ['.mp4', '.avi', '.mkv', '.mov', '.wmv', '.flv', '.webm'];

  // Get the file extension from the URL
  const fileExtension = url.split('.').pop().toLowerCase();

  // Check if the file extension is in the list of video extensions
  return videoExtensions.includes(`.${fileExtension}`);
};
