import { FC, useEffect, useState } from 'react';

import { TRetailerAgreementItem } from '@lib/core/retailers/types';
import { Agreements } from '@lib/core/service/slices/technical/types';
import { localeApp } from '@lib/tools/locale/source/web/app';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';

import Button from '@components/web/src/atoms/Buttons/Button';
import ProfileSectionHeader from '@components/web/src/atoms/Headers/ProfileSectionHeader/ProfileSectionHeader';
import ModalWrapper from '@components/web/src/templates/Modals/ModalWrapper/ModalWrapper';
import RenderModalOptions from '@components/web/src/templates/Modals/RenderModalOptions/RenderModalOptions';

interface IProps {
  isModalOpen: boolean;
  agreements: Record<string, Partial<TRetailerAgreementItem>>;
  hideModal: () => void;
  handleConfirmButton?: () => void;
  handleSavePreferences?: (preferencesOptions) => void;
}

const PreferencesModal: FC<IProps> = ({
  isModalOpen,
  agreements,
  hideModal,
  handleSavePreferences,
  handleConfirmButton,
}) => {
  const [agreementsState, setAgreementsState] = useState({});
  useEffect(() => {
    if (isModalOpen) {
      setAgreementsState(agreements);
    }
  }, [agreements, isModalOpen]);

  const handleChangeAgreement = ({ optionName: agreementType }) => {
    setAgreementsState({
      ...agreementsState,
      [agreementType]: { ...agreementsState[agreementType], isEnabled: !agreementsState[agreementType]?.isEnabled },
    });
  };

  const handleOnSave = () => {
    handleSavePreferences(agreementsState);
  };

  const handleOnCloseOrCancel = () => {
    setAgreementsState({});
    hideModal();
  };

  const {
    preferencesModal: {
      modalHeaderTitle,
      modalHeaderDescription,
      privacyTitle,
      privacyOptionDescription,
      cookieTitle,
      buttonConfirmText,
    },
  } = localeApp;

  return (
    <ModalWrapper
      fullWidthModal
      hideModal={handleOnCloseOrCancel}
      isOpen={isModalOpen}
      modalClassName="preferences-modal-container"
    >
      <ProfileSectionHeader description={modalHeaderDescription} title={modalHeaderTitle} />
      <div className="content-wrapper">
        <div className="content-section">
          <p className="header-text">
            <LocaleFragment message={cookieTitle} />
          </p>
          <RenderModalOptions
            optionType="cookie"
            options={{
              [Agreements.isUserAllowedUseLocalStorage]: agreementsState[Agreements.isUserAllowedUseLocalStorage] || {},
              [Agreements.isUserAllowedProfiling]: agreementsState[Agreements.isUserAllowedProfiling] || {},
            }}
            onToggle={handleChangeAgreement}
          />
        </div>
        <div className="content-section">
          <p className="header-text">
            <LocaleFragment message={privacyTitle} />{' '}
          </p>
          <p className="privacy-description-text">
            <LocaleFragment message={privacyOptionDescription} />{' '}
          </p>
          <RenderModalOptions
            optionType="privacy"
            options={{
              [Agreements.isUserAllowedUseEmailForMarketing]:
                agreementsState[Agreements.isUserAllowedUseEmailForMarketing] || {},
            }}
            onToggle={handleChangeAgreement}
          />
          <Button handleClick={handleConfirmButton} size="sm" text={buttonConfirmText} onClick={handleOnSave} />
        </div>
      </div>
    </ModalWrapper>
  );
};

export default PreferencesModal;
