import styled, { css } from 'styled-components';

export const SwiperSection = styled.div<{ $isSingleProduct }>`
  padding-inline-start: 16px;
  margin-block-end: 32px;
  border-radius: 16px 16px 0 0;

  ${({ $isSingleProduct }) =>
    $isSingleProduct &&
    css`
      padding-right: 16px;
    `}
`;

export const SwiperTitleSection = styled.div`
  padding: 16px 0;
`;
