import Compressor from 'compressorjs';
import React, { FC, useRef } from 'react';

import { convertImageFiletoBase64 } from '@lib/core/service/utils';
import { localeApp } from '@lib/tools/locale/source/web/app';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';

import { ReactComponent as EditIcon } from '@components/web/src/assets/legacy/b2c/icons/icon_edit_white.svg';
import { ReactComponent as UserProfileDummyIcon } from '@components/web/src/assets/legacy/icons/user_profile_icon.svg';
import Button from '@components/web/src/atoms/Buttons/Button';
import { IMAGE_ERRORS } from '@components/web/src/foundations/consts';

const MAXIMUM_IMAGE_CONVERT_SIZE = 4000000;
const MAXIMUM_IMAGE_HEIGHT = 400;
const MAXIMUM_IMAGE_WIDTH = 400;
const MAXIMUM_IMAGE_QUALITY = 1;
const MAXIMUM_USER_UPLOAD_IMAGE_SIZE = 5;
const MAXIMUM_UPLOAD_IMAGE_SIZE_IN_KB = 500;

interface IProps {
  profilePicture: string;
  profilePictureUpdateError?: string;
  handleProfilePictureUpdate?: (base64ImageData: string) => void;
  handleProfilePictureUpdateError: React.Dispatch<React.SetStateAction<string>>;
}

const ProfilePictureSelection: FC<IProps> = ({
  profilePicture,
  profilePictureUpdateError,
  handleProfilePictureUpdateError,
  handleProfilePictureUpdate,
}) => {
  const profilePictureInputRef = useRef(null);
  const onProfilePictureEditHandler = () => profilePictureInputRef.current.click();

  const onImageChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files[0];
    handleProfilePictureUpdateError('');
    if (selectedFile) {
      const imageType =
        selectedFile.type === 'image/jpg' || selectedFile.type === 'image/png' || selectedFile.type === 'image/jpeg';
      if (!imageType) {
        handleProfilePictureUpdateError(IMAGE_ERRORS[415]);
        return;
      }
      if (selectedFile.size / 1024 / 1024 >= MAXIMUM_USER_UPLOAD_IMAGE_SIZE) {
        handleProfilePictureUpdateError(IMAGE_ERRORS[513]);
      } else if (selectedFile.size / 1024 >= MAXIMUM_UPLOAD_IMAGE_SIZE_IN_KB) {
        // eslint-disable-next-line no-new
        await new Compressor(selectedFile, {
          convertSize: MAXIMUM_IMAGE_CONVERT_SIZE,
          height: MAXIMUM_IMAGE_HEIGHT,
          quality: MAXIMUM_IMAGE_QUALITY,
          success: async compressedImage => {
            try {
              const base64ImageData = await convertImageFiletoBase64(compressedImage);
              handleProfilePictureUpdate(base64ImageData);
            } catch (err) {
              console.error(err);
            }
          },
          width: MAXIMUM_IMAGE_WIDTH,
        });
      } else {
        try {
          const base64ImageData = await convertImageFiletoBase64(event.target.files[0]);
          handleProfilePictureUpdate(base64ImageData);
        } catch (err) {
          console.error(err);
        }
      }
    }
  };
  const {
    profilePictureSelection: {
      profileHeaderText,
      profileImageInfoText,
      profileLargeImageErrorText,
      profileImageInvalidErrorText,
    },
  } = localeApp;

  return (
    <div className="profile-picture-container">
      <p className="title">
        <LocaleFragment message={profileHeaderText} />
      </p>
      <div className="profile-container">
        <div className="profile-avatar">
          {profilePicture ? (
            <img alt="" src={profilePicture} />
          ) : (
            <UserProfileDummyIcon className="user-profile-dummy" />
          )}
        </div>
        <div className="profile-action">
          <input
            ref={profilePictureInputRef}
            accept="image/*"
            style={{ display: 'none' }}
            type="file"
            onChange={onImageChange}
          />
          <Button icon={<EditIcon />} size="sm" onClick={onProfilePictureEditHandler} />
        </div>
        <p className="info">
          <LocaleFragment message={profileImageInfoText} />
        </p>
      </div>
      {profilePictureUpdateError === IMAGE_ERRORS[513] && (
        <div className="profile-error">
          <LocaleFragment message={profileLargeImageErrorText} />
        </div>
      )}
      {profilePictureUpdateError === IMAGE_ERRORS[415] && (
        <div className="profile-error">
          <LocaleFragment message={profileImageInvalidErrorText} />
        </div>
      )}
    </div>
  );
};

export default ProfilePictureSelection;
