import { FC, SyntheticEvent, useEffect, useState } from 'react';

import { localeCommon } from '@lib/tools/locale/source/web/common';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';

import WishlistAnimation from '@components/web/src/atoms/Wishlist/WishlistAnimation/WishlistAnimation';

interface IProps {
  productId: string;
  isProductInWishlist: boolean;
  isProductListLoading?: boolean;
  shouldHideStatus?: boolean;
  handleUpdateWishlistProductList: (productId: string) => void;
}

const Wishlist: FC<IProps> = ({
  productId,
  isProductInWishlist = false,
  isProductListLoading = false,
  shouldHideStatus = false,
  handleUpdateWishlistProductList,
}) => {
  const {
    wishlist: { save, saved },
  } = localeCommon;

  const [isStartAnimation, setIsStartAnimation] = useState(false);

  const statusText = isProductInWishlist ? saved : save;

  const handleClick = (e: SyntheticEvent) => {
    e.stopPropagation();
    handleUpdateWishlistProductList(productId);

    if (!isProductInWishlist) {
      setIsStartAnimation(true);
    }
  };

  useEffect(() => {
    if (!isProductInWishlist) return () => {};

    const timer = setTimeout(() => {
      setIsStartAnimation(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, [isProductInWishlist, isStartAnimation]);

  return (
    <button className="wishlist-container" disabled={isProductListLoading} onClick={handleClick}>
      <WishlistAnimation
        isActive={isProductInWishlist && isStartAnimation}
        isAnimationEnded={isProductInWishlist && !isStartAnimation}
        isAnimationNotStarted={!isProductInWishlist}
      />
      {!shouldHideStatus && (
        <span>
          <LocaleFragment message={statusText} />
        </span>
      )}
    </button>
  );
};

export default Wishlist;
