import { FC, useEffect, useRef, useState } from 'react';

import { TCharactersBySubCategory } from '@lib/core/characters/types';
import { TProductCategory } from '@lib/core/products/types';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';
import { MAP_CATEGORY } from '@lib/tools/shared/helpers/consts';

import { ReactComponent as LeftChevronIcon } from '@components/web/src/assets/legacy/icons/icon_chevron-left.svg';
import { ReactComponent as RightChevronIcon } from '@components/web/src/assets/legacy/icons/icon_chevron-right.svg';
import CharactersMap from '@components/web/src/components/Character/CharactersMap/CharactersMap';

interface IProps {
  productCategory: TProductCategory;
  charactersCount: number | null;
  charactersBySubCategory: TCharactersBySubCategory;
  lang?: string;
  userCharacterName?: string;
  groupCount?: number | null;
}

const CharactersMapScroller: FC<IProps> = ({
  productCategory,
  charactersCount,
  charactersBySubCategory,
  lang,
  userCharacterName,
  groupCount,
}) => {
  const scrollRef = useRef(null);
  const [scrollTargetItem, setScrollTargetItem] = useState<HTMLDivElement | null>(null);

  const scrollToUserCharacter = () => {
    if (scrollRef.current && userCharacterName) {
      const targetItem = scrollRef.current.querySelector(`[data-itemid=${userCharacterName}]`);
      if (targetItem) {
        setScrollTargetItem(targetItem);
      }
    }
  };

  useEffect(() => {
    if (scrollTargetItem) {
      scrollRef.current.scrollTo({
        behavior: 'smooth',
        left: scrollTargetItem.offsetLeft,
      });
    }
  }, [scrollTargetItem]);

  useEffect(() => {
    scrollToUserCharacter();
  }, [userCharacterName, scrollRef, charactersBySubCategory]);

  const handleScroll = scrollDirection => {
    if (scrollDirection === 'right') {
      scrollRef.current.scrollLeft += scrollRef.current?.offsetWidth / 1.5;
    } else {
      scrollRef.current.scrollLeft -= scrollRef.current?.offsetWidth / 1.5;
    }
  };
  const { productCategories, productCategoriesPlural } = localeCommon;
  const { info, productIntro, productCharactersName, charactersCountDescription } = localeCommon.charactersMapScroller;
  const productCategoryText = productCategories[productCategory];
  const productCategoryPluralText = productCategoriesPlural[productCategory];
  const productCharactersNameText = (
    <LocaleFragment
      message={productCharactersName}
      index={{
        productCategory,
      }}
      variables={{
        productCategoryPluralText, // we later handle the capital on some other way
      }}
    />
  );

  const charactersCountDescriptionText = (
    <LocaleFragment
      index={{ productCategory }}
      message={charactersCountDescription}
      options={{
        charactersCount: String(charactersCount),
        groupCount: String(groupCount),
      }}
      variables={{
        productCategoryPluralText,
      }}
    />
  );

  return (
    <div className="characters-scroller-container">
      <div className="characters-info-header">
        <p className={`product ${productCategory}`}>
          <LocaleFragment index={{ productCategory }} message={productIntro} variables={{ productCategoryText }} />
        </p>
        <p className="characters-name">{productCharactersNameText}</p>
        <span className="division-count">{charactersCountDescriptionText}</span>
        <p className="info-text">
          <LocaleFragment message={info} />
        </p>
      </div>
      <div className="characters-map-scroll-wrapper">
        <div ref={scrollRef} className="scroll-body">
          <div className="characters-map-scrolled">
            {Object.keys(charactersBySubCategory)?.map((character, index) => {
              const characters = charactersBySubCategory[character];
              const characterSubCategoryText = characters[0].attributes?.[`${MAP_CATEGORY}${lang}`] || '';
              return (
                <CharactersMap
                  key={index}
                  characterSubCategoryText={characterSubCategoryText}
                  characters={characters}
                  lang={lang}
                />
              );
            })}
          </div>
        </div>
        <div className="scroll-buttons-container">
          <span aria-hidden className="icon-container" onClick={() => handleScroll('left')}>
            <LeftChevronIcon />
          </span>
          <span aria-hidden className="icon-container" onClick={() => handleScroll('right')}>
            <RightChevronIcon />
          </span>
        </div>
      </div>
    </div>
  );
};

export default CharactersMapScroller;
