import { createSlice } from '@reduxjs/toolkit';

import { TProductCategory } from '@lib/core/products/types';
import { PRODUCT_CATEGORY_NONE } from '@lib/core/service/consts';
import serviceInstanceSlice, { IServiceInstance } from '@lib/core/service/slices/serviceInstanceSlice';
import { languages } from '@lib/tools/locale/utils/consts';

interface IService {
  isSplashScreenPageShown: boolean;
  isHostMocked: boolean;
  isDevToolsEnabled: boolean;
  isLocaleEditMode: boolean;
  productCategory: TProductCategory;
  shouldDisplayTastePathStartPage: boolean;
  shouldHideTastePathTooltipModal: boolean;
  isTasteIdGuideShown: boolean;
  previousRoute: string;
  route: string;
  lang: string;
  serviceInstance: IServiceInstance;
}

export const initialState: IService = {
  isDevToolsEnabled: false,
  isHostMocked: false,
  isLocaleEditMode: false,
  isSplashScreenPageShown: false,
  isTasteIdGuideShown: false,
  lang: languages.ENGLISH,
  previousRoute: '',
  productCategory: PRODUCT_CATEGORY_NONE,
  route: '',
  serviceInstance: serviceInstanceSlice(undefined, { type: '' }),
  shouldDisplayTastePathStartPage: false,
  shouldHideTastePathTooltipModal: false,
};

export const serviceSlice = createSlice({
  extraReducers: builder => {
    builder.addDefaultCase((state, action) => {
      state.serviceInstance = serviceInstanceSlice(state.serviceInstance, action);
    });
  },
  initialState,
  name: 'service',
  reducers: {
    setDisplayTastePathStartPage(state, action) {
      state.shouldDisplayTastePathStartPage = action.payload;
    },
    setHideTastePathTooltipModal(state) {
      state.shouldHideTastePathTooltipModal = true;
    },
    setIsDevToolsEnabled(state) {
      state.isDevToolsEnabled = true;
    },
    setIsTasteIdGuideShown: (state, action) => {
      state.isTasteIdGuideShown = action.payload;
    },
    setProductCategory(state, action) {
      state.productCategory = action.payload;
    },
    setServiceLocale(state, action) {
      state.lang = action.payload;
    },
    setSplashScreenPageShown: state => {
      state.isSplashScreenPageShown = true;
    },
    toggleLocaleEditMode(state, action) {
      state.isLocaleEditMode = action.payload;
    },
    toggleMockHost(state, action) {
      state.isHostMocked = action.payload;
    },
    writeHistory(state, action) {
      if (state.route !== action.payload) {
        if (!state.previousRoute) {
          return {
            ...state,
            previousRoute: action.payload,
            route: action.payload,
          };
        }
        return {
          ...state,
          previousRoute: state.route,
          route: action.payload,
        };
      }
      return {
        ...state,
      };
    },
  },
});

export default serviceSlice.reducer;

export const {
  setServiceLocale,
  setIsDevToolsEnabled,
  setProductCategory,
  setDisplayTastePathStartPage,
  setHideTastePathTooltipModal,
  writeHistory,
  toggleLocaleEditMode,
  toggleMockHost,
  setSplashScreenPageShown,
  setIsTasteIdGuideShown,
} = serviceSlice.actions;
