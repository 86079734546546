import { FC } from 'react';

import { TCharacter } from '@lib/core/characters/types';
import { TProductCategory, TProductInstance } from '@lib/core/products/types';
import { TRetailerLocationStoreType } from '@lib/core/retailers/types';
import {
  RegisterPageScenarios,
  TRegisterPageScenarioState,
} from '@lib/core/service/slices/technical/registerPageScenario';

import { FooterAppAnonymous } from '@components/web/src/atoms/Footers';
import RegistrationBanner from '@components/web/src/components/Authentication/RegistrationBanner/RegistrationBanner';
import ProductCardSkeleton from '@components/web/src/components/Skeleton/ProductCardSkeleton/ProductCardSkeleton';
import CharacterAdjectivesGroup, {
  ICharacterAdjectiveData,
} from '@components/web/src/organisms/CharacterAdjectivesGroup/CharacterAdjectivesGroup';
import { TSwiperData } from '@components/web/src/organisms/ExtraInfoSwiper/ExtraInfoSwiper';
import LearnMoreSection from '@components/web/src/organisms/TestResult/LearnMoreSection/LearnMoreSection';
import ProductSection from '@components/web/src/organisms/TestResult/ProductSection/ProductSection';
import QuizSection, { IQuizSectionQuizData } from '@components/web/src/organisms/TestResult/QuizSection/QuizSection';
import * as S from '@components/web/src/templates/ResultPages/TestResultPage/styles';

interface IProps {
  locale: string;
  storeType: TRetailerLocationStoreType;
  productInstanceData: TProductInstance;
  productCategory: TProductCategory;
  characterData: TCharacter;
  quizData: IQuizSectionQuizData[];
  charactersModalData: TSwiperData[];
  characterAdjectiveData: ICharacterAdjectiveData;
  retailerLocationLogo?: string;
  isDesignSetVinhoodExperience?: boolean;
  isLoadingProduct?: boolean;
  handleSetResultPageState?: (payload: TRegisterPageScenarioState) => void;
}

const TestResultPage: FC<IProps> = ({
  locale,
  storeType,
  productInstanceData,
  characterData,
  quizData,
  charactersModalData,
  productCategory,
  characterAdjectiveData,
  retailerLocationLogo,
  handleSetResultPageState,
  isDesignSetVinhoodExperience = false,
  isLoadingProduct = false,
}) => {
  return (
    <S.TestResultPageContainer data-testid={`TestResultPage-${productCategory}-${storeType}`}>
      <CharacterAdjectivesGroup
        isTestResultPage
        characterAdjectiveData={characterAdjectiveData}
        characterData={characterData}
        handleAdjectiveClick={handleSetResultPageState}
        productCategory={productCategory}
      />
      {isLoadingProduct && (
        <S.SkeletonWrapper>
          <ProductCardSkeleton />
        </S.SkeletonWrapper>
      )}
      {!!productInstanceData && (
        <ProductSection
          handleWishlistClick={handleSetResultPageState}
          locale={locale}
          productCategory={productCategory}
          productInstanceData={productInstanceData}
          retailerLocationLogo={retailerLocationLogo}
          storeType={storeType}
        />
      )}
      <QuizSection
        characterData={characterData}
        handleQuizSectionClick={payload => handleSetResultPageState({ ...payload, productCategory })}
        quizData={quizData}
      />
      <LearnMoreSection
        characterData={characterData}
        charactersModalData={charactersModalData}
        handleLearnMoreSectionClick={handleSetResultPageState}
        productCategory={productCategory}
      />
      {!isDesignSetVinhoodExperience && <FooterAppAnonymous shouldOpenModalsUnderHeader showGearIcon />}
      <S.BannerWrapper $isVariantApp={!isDesignSetVinhoodExperience}>
        <RegistrationBanner
          handleNavigateToRegistrationPage={() =>
            handleSetResultPageState({ scenario: RegisterPageScenarios.BannerClick })
          }
        />
      </S.BannerWrapper>
    </S.TestResultPageContainer>
  );
};

export default TestResultPage;
