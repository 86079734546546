import { FC, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import useTasteIdData from '@app/web/src/hooks/useTasteIdData';

import { useRetailerLocation } from '@lib/core/retailers/hooks/retailerLocation';
import { useApp } from '@lib/core/service/hooks';
import { prependBasename } from '@lib/core/service/utils';
import { useFeedback, useUser, useWishlist } from '@lib/core/users/hooks';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import { PRODUCT_CATEGORY_URL_PARAM } from '@lib/tools/shared/helpers/consts';
import { useAddons } from '@lib/tools/views/hooks';

import { TTabIcon } from '@components/web/src/atoms/Tabs/TabButton/TabButton';
import Tabs, { ITabs } from '@components/web/src/atoms/Tabs/Tabs/Tabs';
import GenericSpinner from '@components/web/src/components/GenericSpinner/GenericSpinner';
import TasteIdPage from '@components/web/src/templates/TasteId/TasteIdPage/TasteIdPage';

const TasteIdPageContainer: FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { locale } = useApp();
  const { pathname, search } = useLocation();
  const { retailerStoreType } = useRetailerLocation();

  const { productCategories: localeProductCategories } = localeCommon;

  const [activeTab] = useState(searchParams.get(PRODUCT_CATEGORY_URL_PARAM));

  useEffect(() => {
    if (activeTab) {
      searchParams.delete(PRODUCT_CATEGORY_URL_PARAM);
      setSearchParams(searchParams);
    }
  }, [activeTab]);

  const { isTasteIdResultPageAddon } = useAddons();
  const { isUserAuthenticated } = useUser();
  const { handleUpdateFeedback, feedbackData, isFeedbackLoading } = useFeedback();
  const { wishlistProductIds, handleUpdateWishlistProductList, isProductListLoading } = useWishlist();

  const {
    productCategories,
    translatedUserCharacters,
    isTasteIdDataLoading,
    characterAdjectiveData,
    characterInfoCardsData,
    characterDetailCardsData,
    userPreferences,
    productsByCategoryData,
  } = useTasteIdData();

  const handleNavigateClick = (link: string, params?: { [key: string]: string }) => {
    navigate(prependBasename(link, params), {
      state: { from: pathname + search },
    });
  };

  const tabsData: ITabs = useMemo(
    () => ({
      active: activeTab || productCategories[0],
      tabs: productCategories.map(category => ({
        children: (
          <TasteIdPage
            key={category}
            characterAdjectiveData={characterAdjectiveData?.[category]}
            characterData={translatedUserCharacters?.[category]}
            characterDetailCardsData={characterDetailCardsData?.[category]}
            characterInfoCardsData={characterInfoCardsData?.[category]}
            feedbackData={feedbackData}
            handleNavigateClick={handleNavigateClick}
            handleUpdateFeedback={handleUpdateFeedback}
            handleUpdateWishlistProductList={handleUpdateWishlistProductList}
            isFeedbackEnabled={isUserAuthenticated}
            isProductListLoading={isProductListLoading}
            isTasteIdResultPageAddon={isTasteIdResultPageAddon}
            isUserAuthenticated={isUserAuthenticated}
            isWishlistEnabled={isUserAuthenticated}
            locale={locale}
            productCategory={category}
            productSwiperData={productsByCategoryData[category]}
            storeType={retailerStoreType}
            userPreferences={userPreferences}
            wishlistProductIds={wishlistProductIds}
          />
        ),
        tabCode: category,
        tabIcon: category as TTabIcon,
        tabText: localeProductCategories[category],
      })),
    }),
    [isTasteIdDataLoading, isFeedbackLoading, isProductListLoading, userPreferences],
  );

  return (
    <>
      {isTasteIdDataLoading ? (
        <GenericSpinner />
      ) : (
        <Tabs
          isAutoSpacedTabs
          active={tabsData.active}
          gradientVariant="custom-down"
          isHiddenTabs={productCategories?.length <= 1}
          tabs={tabsData.tabs}
        />
      )}
    </>
  );
};

export default TasteIdPageContainer;
