import styled from 'styled-components';

import { Text } from '@components/web/src/foundations/Text/Text';

export const JournalProductListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .infinite-scroll-component__outerdiv {
    overflow-y: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .infinite-scroll-component {
    padding: 0 16px 70px 0;
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: flex-start;
    width: 100%;
  }
`;

export const SkeletonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: flex-start;
  width: 100%;
  height: 100%;
`;

export const JournalCounter = styled.div`
  display: flex;
  align-items: center;
  height: 36px;
  margin: 0 0 16px 22px;
`;

export const ProductText = styled(Text)`
  text-transform: lowercase;
`;

export const EndOfList = styled.div`
  margin-top: 8px;
  display: flex;
  width: 100%;
  justify-content: center;
`;
