import styled, { css } from 'styled-components';

import Button from '@components/web/src/atoms/Buttons/Button';
import { DEFAULT_COLOR_THEME } from '@components/web/src/foundations';

export const ProductJournalSwiper = styled.div<{ $isProductsSwiperAvailable: boolean }>`
  display: flex;
  flex-direction: column;
  ${({ $isProductsSwiperAvailable }) =>
    $isProductsSwiperAvailable &&
    css`
      gap: 40px;
    `}
  padding: 30px 16px;
`;
export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 32px;
`;

export const StyledButton = styled(Button)`
  border-radius: 28px;
  border: 0px solid ${DEFAULT_COLOR_THEME.secondaryColor['-300']};
  background: ${DEFAULT_COLOR_THEME.secondaryColor['-500']};
`;

export const SubtitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  img {
    margin-right: 16px;
    width: 66px;
    height: 68px;
  }
`;
export const ButtonWrapper = styled.div`
  display: flex;
  height: 342px;
  padding: 0px 96px 0px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
