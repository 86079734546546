import { FC, ReactNode } from 'react';

import { TLanguage } from '@lib/core/retailers/types';

import { FooterKiosk } from '@components/web/src/atoms/Footers';
import Header from '@components/web/src/atoms/Header/Header';
import * as S from '@components/web/src/atoms/HeaderFooter/HeaderFooterKiosk/styles';

interface IProps {
  children: ReactNode;
  isKioskHomePage: boolean;
  isShowDetachFidelityCard: boolean;
  isShowLanguageSwitcher: boolean;
  retailerLanguages: TLanguage[];
  locale: string;
  onDetachFidelityCardClick: () => void;
}

const HeaderFooterKiosk: FC<IProps> = ({
  children,
  isKioskHomePage,
  isShowDetachFidelityCard,
  isShowLanguageSwitcher,
  retailerLanguages,
  locale,
  onDetachFidelityCardClick,
}) => (
  <S.HeaderFooterKioskContainer data-testid="HeaderFooterKiosk" id="root-scroll-element">
    <Header
      isApplicationKiosk
      isShowDetachFidelityCard={isShowDetachFidelityCard}
      isShowLanguageSwitcher={isShowLanguageSwitcher}
      locale={locale}
      retailerLanguages={retailerLanguages}
      onDetachFidelityCardClick={onDetachFidelityCardClick}
    />
    <S.BodyContainer data-testid="BodyContainer">{children}</S.BodyContainer>
    <FooterKiosk isWaveVariant={isKioskHomePage} />
  </S.HeaderFooterKioskContainer>
);

export default HeaderFooterKiosk;
