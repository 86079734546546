import { createSlice } from '@reduxjs/toolkit';

export interface IProfileState {
  userProfileId: string;
}

export const initialState: IProfileState = {
  userProfileId: null,
};

export const profileSlice = createSlice({
  initialState,
  name: 'profile',
  reducers: {
    actionResetUserProfileId: () => initialState,
    actionSetUserProfileId: (state, { payload }: { payload: string }) => {
      state.userProfileId = payload;
    },
  },
});

export const { actionSetUserProfileId, actionResetUserProfileId } = profileSlice.actions;

export default profileSlice.reducer;
