import { TProductCategory } from '@lib/core//products/types';
import { TRecycling, TWellness } from '@lib/core/productAttributes/types';

export const isWellnessForProductCategory = (wellness: TWellness, productCategory: TProductCategory): boolean =>
  wellness.product_type.name === productCategory;

export const isRecyclingForProductCategory = (recycling: TRecycling, productCategory: TProductCategory): boolean =>
  recycling.product_formats.some(category => category.product_category.name === productCategory);

export const isRecyclingForProductFormat = (recycling: TRecycling, productFormat: string): boolean =>
  recycling.product_formats.some(product_format => product_format.identifier === productFormat);
