import { FC } from 'react';

import { localeWidget } from '@lib/tools/locale/source/web/widget';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';
import { VH_VARIANTS } from '@lib/tools/shared/helpers/consts';

import errorIcon from '@components/web/src/assets/legacy/b2b/icons/exit_page_error_icon.svg';
import loggedInIcon from '@components/web/src/assets/legacy/b2b/icons/exit_page_success_icon.svg';
import Button from '@components/web/src/atoms/Buttons/Button';

type Props = {
  navigateToHomePage: () => void;
  resetUserData: () => void;
  isLogged: boolean;
};

const ExitPage: FC<Props> = ({ navigateToHomePage, resetUserData, isLogged }) => {
  const {
    exitPage: { title, errorSubTitle, successSubTitle, goodByBtnText, deleteTasteProfileBtnText, goBackBtnText },
  } = localeWidget;

  const bodyText = isLogged ? successSubTitle : errorSubTitle;
  const resetBtnText = isLogged ? goodByBtnText : deleteTasteProfileBtnText;

  return (
    <div className="exit-page-container">
      <img
        alt={`${isLogged ? 'Logged in icon' : 'Error icon'}`}
        className="icons"
        src={isLogged ? loggedInIcon : errorIcon}
      />
      <p className="subtitle">
        <LocaleFragment message={title} />
      </p>
      <p className="body-text">
        <LocaleFragment message={bodyText} />
      </p>
      <div className="buttons-container">
        <Button className="cta-btn" text={goBackBtnText} variant={VH_VARIANTS.LIGHT} onClick={navigateToHomePage} />
        <Button className="cta-btn reset-btn" text={resetBtnText} onClick={resetUserData} />
      </div>
    </div>
  );
};

export default ExitPage;
