import { css, styled } from 'styled-components';

import { ROOT_ELEMENT_KIOSK } from '@components/web/src/foundations/consts';
import { DEFAULT_COLOR_THEME } from '@components/web/src/foundations/index';
import { Text } from '@components/web/src/foundations/Text/Text';

export const TestAnswerContainer = styled.button`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  align-self: stretch;
  padding: 0;
  background: transparent !important;
  border: none !important;
  width: 136px;

  &:disabled {
    pointer-events: none !important;
    border: 1px solid red;
  }

  ${({ theme }) =>
    theme?.rootElementType === ROOT_ELEMENT_KIOSK &&
    css`
      width: 358px;
    `}
`;

export const ImageWrapper = styled.div<{ $isSelected: boolean; $isDisableInDevTools: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 75.5px;
  height: 75.5px;

  ${({ theme }) =>
    theme?.rootElementType === ROOT_ELEMENT_KIOSK &&
    css`
      width: 160px;
      height: 160px;
    `}

  ${({ $isSelected }) =>
    $isSelected &&
    `
    position: relative;
    z-index:1;

    &::before {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 100%;
      content: '';
      background-color: ${DEFAULT_COLOR_THEME.secondaryColor[100]};
      filter: brightness(150%);
      border-radius: 50%;
      mix-blend-mode: screen;
      opacity: 0.5;
      transform: translate(-50%, -50%);
    }
  `}
 ${({ $isDisableInDevTools, $isSelected }) =>
    $isDisableInDevTools
      ? `
      opacity: ${$isSelected ? '0.25' : '1'};
    `
      : ''}
`;

export const AnimationWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(2);
`;

export const AnswerImage = styled.img<{ $isSelected: boolean }>`
  object-fit: contain;
  width: 100%;

  ${({ $isSelected }) =>
    $isSelected &&
    `
    z-index: -1;
    opacity: 0.25;
    `}

  ${({ theme }) =>
    theme?.rootElementType === ROOT_ELEMENT_KIOSK &&
    css`
      width: 153px;
      height: 153px;
    `}
`;

export const TestAnswerText = styled(Text)`
  text-align: center;
`;
