import { useDispatch, useSelector } from 'react-redux';

import {
  selectProductListLoading,
  selectProductListWishlistData,
  selectProductListWishlistProductIds,
} from '@lib/core/users/selectors/productList';
import { actionUpdateProductList } from '@lib/core/users/slices/productList';
import { PRODUCT_LIST_WISHLIST } from '@lib/tools/shared/helpers/consts';

export const useProductList = () => {
  const dispatch = useDispatch();

  const wishlistProductIds = useSelector(selectProductListWishlistProductIds);
  const productListWishlistData = useSelector(selectProductListWishlistData);

  const isProductListLoading = useSelector(selectProductListLoading);

  const handleUpdateWishlistProductList = (productId: string) =>
    dispatch(
      actionUpdateProductList({
        listName: PRODUCT_LIST_WISHLIST,
        productId,
      }),
    );

  return { handleUpdateWishlistProductList, isProductListLoading, productListWishlistData, wishlistProductIds };
};
