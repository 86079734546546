import { FC } from 'react';
import { useMediaQuery } from 'react-responsive';

import { TProductCategory, TProductInstance } from '@lib/core/products/types';
import { parseFindProduct } from '@lib/core/products/utils';
import { TFeedback } from '@lib/core/users/slices/feedbacks';
import MixpanelTracker from '@lib/tools/dat/mixpanel';
import { MP_POSITION_CONTEXT } from '@lib/tools/dat/mixpanel/consts';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import { LocaleUtils } from '@lib/tools/locale/utils';
import LocaleFragment, { ILocaleText } from '@lib/tools/locale/views/LocaleFragment';
import { PRODUCT_CATEGORY_COFFEE, VH_VARIANTS } from '@lib/tools/shared/helpers/consts';

import CTAButton from '@components/web/src/app/CTAIconBtn/CTAButton/CTAButton';
import Button from '@components/web/src/atoms/Buttons/Button';
import RegistrationBanner from '@components/web/src/components/Authentication/RegistrationBanner/RegistrationBanner';
import ChefRecommendsCard, {
  IRecipe,
} from '@components/web/src/components/Cards/ChefRecommendsCard/ChefRecommendsCard';
import EssenceCard, { IEssenceProps } from '@components/web/src/components/Cards/EssenceCard/EssenceCard';
import IdentityCard, { IIdentityProps } from '@components/web/src/components/Cards/IdentityCard/IdentityCard';
import PreparationCard, {
  IPreparationItem,
} from '@components/web/src/components/Cards/PreparationCard/PreparationCard';
import SwiperHOC from '@components/web/src/components/Swiper/SwiperHOC';
import { B2B_MOBILE_RESOLUTION_MAX_VALUE } from '@components/web/src/kiosk/consts';
import KioskCirclesBackground from '@components/web/src/kiosk/KioskCirclesBackground/KioskCirclesBackground';
import ProductDetails from '@components/web/src/organisms/Cards/ProductDetails/ProductDetails';
import ReadMoreCard, { IReadMoreCardProps } from '@components/web/src/organisms/Cards/ReadMoreCard/ReadMoreCard';
import FindProductPopup from '@components/web/src/shared/FindProductPopup/FindProductPopup';
import { IDiscoveryQuiz } from '@components/web/src/templates/Modals/DiscoveryQuizModal/DiscoveryQuizModal';

interface IProps {
  locale: string;
  productInstanceData: TProductInstance;
  productCategory: TProductCategory;
  perfectForData: string;
  recipesData: IRecipe[];
  essencesData: IEssenceProps[];
  preparationsData: IPreparationItem[];
  productIdentitiesData: IIdentityProps;
  sustainabilityData: IReadMoreCardProps[];
  mobileKioskQRUrl: string;
  isShowPromoLabel: boolean;
  isApplicationKiosk: boolean;
  isEnableLocationMapAddon: boolean;
  isShowProductLocationAddon: boolean;
  isEnableVusionAddon: boolean;
  shouldDisplayWidgetRegistrationButton: boolean;
  isWishlistEnabled: boolean;
  isProductListLoading?: boolean;
  isProductInWishlist: boolean;
  isUserHasAnyCharacter: boolean;
  isProductPDFDownload?: boolean;
  isFeedbackEnabled: boolean;
  feedback?: TFeedback;
  discoveryQuiz: IDiscoveryQuiz;
  navigateToRegistrationPage: () => void;
  navigateToQuizSelectionPage: () => void;
  handleUpdateFeedback?: (feedback: TFeedback, productId: string) => void;
  handleUpdateWishlistProductList?: (productId: string) => void;
  onBackButtonClick: () => void;
  navigateToCatalog: () => void;
}

const ProductPage: FC<IProps> = ({
  locale,
  productInstanceData,
  productCategory,
  perfectForData,
  recipesData,
  essencesData,
  productIdentitiesData,
  preparationsData,
  sustainabilityData,
  mobileKioskQRUrl,
  navigateToRegistrationPage,
  isApplicationKiosk,
  isShowPromoLabel,
  isEnableLocationMapAddon,
  isShowProductLocationAddon,
  isEnableVusionAddon,
  shouldDisplayWidgetRegistrationButton,
  isWishlistEnabled,
  isProductInWishlist,
  isProductListLoading,
  isProductPDFDownload,
  feedback,
  isFeedbackEnabled,
  discoveryQuiz,
  isUserHasAnyCharacter,
  navigateToQuizSelectionPage,
  handleUpdateFeedback,
  handleUpdateWishlistProductList,
  onBackButtonClick,
  navigateToCatalog,
}) => {
  const { publishedTerms } = LocaleUtils;

  const { commonMessages, productPage, productCategoriesPlural, productCategories, productDetails } = localeCommon;

  const productCategoriesText = publishedTerms[productCategories[productCategory].id];
  const productCategoryPluralText = publishedTerms[productCategoriesPlural[productCategory].id];
  const { productIdentities, identityWarningText } = productIdentitiesData;

  const isMobileResolution = useMediaQuery({ maxWidth: B2B_MOBILE_RESOLUTION_MAX_VALUE });

  const { isFindProductButtonEnable, productLocationDescription, productLocationMap, productEcommerceId } =
    parseFindProduct({
      isEnableLocationMapAddon,
      isEnableVusionAddon,
      isShowProductLocationAddon,
      productInstanceData,
    });

  const Title = ({ title, localeOptions }: { title: ILocaleText; localeOptions?: Record<string, string> }) => (
    <p className="page-title">
      <LocaleFragment message={title} options={localeOptions} />
    </p>
  );
  const SubTitle = ({ subTitle, localeOptions }: { subTitle: ILocaleText; localeOptions?: Record<string, string> }) => (
    <p className="page-subtitle">
      <LocaleFragment message={subTitle} options={localeOptions} />
    </p>
  );

  return (
    <div className="product-page-container">
      {isApplicationKiosk && <KioskCirclesBackground />}
      <CTAButton
        btnIcon="leftarrow"
        btnText={commonMessages.back}
        className="go-back-btn"
        handleBtnClick={onBackButtonClick}
      />
      <ProductDetails
        discoveryQuiz={discoveryQuiz}
        feedback={feedback}
        handleUpdateFeedback={handleUpdateFeedback}
        handleUpdateWishlistProductList={handleUpdateWishlistProductList}
        isEnableLocationMapAddon={isEnableLocationMapAddon}
        isEnableVusionAddon={isEnableVusionAddon}
        isFeedbackEnabled={isFeedbackEnabled}
        isProductInWishlist={isProductInWishlist}
        isProductListLoading={isProductListLoading}
        isProductPDFDownload={isProductPDFDownload}
        isShowProductLocationAddon={isShowProductLocationAddon}
        isShowPromoLabel={isShowPromoLabel}
        isUserHasAnyCharacter={isUserHasAnyCharacter}
        isWishlistEnabled={isWishlistEnabled}
        locale={locale}
        mobileKioskQRUrl={mobileKioskQRUrl}
        navigateToQuizSelectionPage={navigateToQuizSelectionPage}
        productInstanceData={productInstanceData}
      />
      <div className="product-content">
        <div className="product-identity">
          {perfectForData && (
            <div>
              <Title title={productPage.perfectForTitle} />
              <p className="perfect-for">{perfectForData}</p>
            </div>
          )}
          {productCategory === PRODUCT_CATEGORY_COFFEE && (
            <div className="product-preparations">
              <Title title={productPage.preparationsTitle} />
              <SwiperHOC spaceBetween={13}>
                {preparationsData?.map((preparationsItem, idx) => <PreparationCard key={idx} {...preparationsItem} />)}
              </SwiperHOC>
            </div>
          )}
          <div>
            <Title title={productPage.identityCardTitle} />
            <SubTitle
              subTitle={productPage.identityCardSubtitle}
              localeOptions={{
                productCategoriesText,
                productCategory,
              }}
            />
            <IdentityCard identityWarningText={identityWarningText} productIdentities={productIdentities} />
          </div>
          {isFindProductButtonEnable && !isApplicationKiosk && (
            <FindProductPopup
              isEnableLocationMapAddon={isEnableLocationMapAddon}
              isEnableVusionAddon={isEnableVusionAddon}
              productEcommerceId={productEcommerceId}
              productLocationDescription={productLocationDescription}
              productLocationMap={productLocationMap}
            >
              <Button
                size="sm"
                text={productDetails.findItOnShelf}
                type="button"
                variant={VH_VARIANTS.PRIMARY}
                onClick={() =>
                  MixpanelTracker.events.findMe(
                    productInstanceData,
                    isProductInWishlist,
                    null,
                    MP_POSITION_CONTEXT.PRODUCT_PAGE,
                  )
                }
              />
            </FindProductPopup>
          )}
          <Button
            className="see-similar-btn"
            iconVariant="stack"
            localeOptions={{ productCategory, productCategoryPluralText }}
            size={isMobileResolution ? 'sm' : 'lg'}
            text={productPage.seeSimilarBtn}
            variant={VH_VARIANTS.LIGHT}
            handleClick={() => {
              MixpanelTracker.events.seeSimilarProductsClick(productInstanceData);
              navigateToCatalog();
            }}
          />
        </div>
        <div className="product-tasting">
          {recipesData.length > 0 && (
            <div>
              <Title title={productPage.chefRecommendsTitle} />
              <SubTitle
                subTitle={productPage.chefRecommendsSubtitle}
                localeOptions={{
                  productCategoriesText,
                  productCategory,
                }}
              />
              <SwiperHOC spaceBetween={13}>
                {recipesData.map((recipeItem, idx) => (
                  <ChefRecommendsCard key={idx} {...recipeItem} />
                ))}
              </SwiperHOC>
            </div>
          )}
          {essencesData.length > 0 && (
            <div>
              <Title
                title={productPage.essenceCardTitle}
                localeOptions={{
                  productCategoriesText,
                  productCategory,
                }}
              />
              <SubTitle subTitle={productPage.essenceCardSubtitle} />
              <SwiperHOC spaceBetween={13}>
                {essencesData.map(essence => (
                  <EssenceCard key={essence.identifier} productCategory={productCategory} {...essence} />
                ))}
              </SwiperHOC>
            </div>
          )}
          {sustainabilityData.length > 0 && (
            <div>
              <Title title={productPage.sustainabilityTitle} />
              <SubTitle
                subTitle={productPage.sustainabilitySubtitle}
                localeOptions={{
                  productCategoriesText,
                  productCategory,
                }}
              />
              <SwiperHOC className="sustainability-swiper" spaceBetween={13}>
                {sustainabilityData.map((sustainabilityItem, idx) => {
                  return (
                    <ReadMoreCard
                      key={idx}
                      cardTitle={sustainabilityItem?.cardTitle}
                      description={sustainabilityItem?.description}
                      minimumCharacters={isMobileResolution ? 120 : 100}
                    />
                  );
                })}
              </SwiperHOC>
            </div>
          )}
        </div>
      </div>
      {shouldDisplayWidgetRegistrationButton && (
        <RegistrationBanner handleNavigateToRegistrationPage={navigateToRegistrationPage} />
      )}
    </div>
  );
};

export default ProductPage;
