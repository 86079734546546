import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { ENV_RECAPTCHA_SITE_KEY } from '@lib/core/service/consts';
import { selectIsGoogleCaptchaIsDisabled } from '@lib/core/service/selectors/technical';
import { prependBasename } from '@lib/core/service/utils';
import { actionResetPassword, resetAuthError, resetResetPasswordState } from '@lib/core/users/slices/auth';
import { useTypedSelector } from '@lib/tools/views/hooks';
import { useTypedDispatch } from '@lib/tools/views/hooks/useTypedDispatch';
import { PAGES } from '@lib/tools/views/urls';
import ReCaptchaContainer from '@lib/tools/views/web/containers/reCaptchaContainer';

import { ForgotPasswordInputObject } from '@components/web/src/pages/Authentication/AuthFormInterface';
import CheckResetPasswordPage from '@components/web/src/templates/Login&Registration/CheckResetPasswordPage/CheckResetPasswordPage';
import ResetPasswordPage from '@components/web/src/templates/Login&Registration/ResetPasswordPage/ResetPasswordPage';

const ResetPasswordContainer = () => {
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const recaptchaRef: any = useRef();

  const { rejectEndpointError, resetPasswordEmailSuccess } = useTypedSelector(state => state.auth);
  const isCaptchaDisabled = useSelector(selectIsGoogleCaptchaIsDisabled);
  const [, setIsBtnDisabled] = useState(ENV_RECAPTCHA_SITE_KEY && !isCaptchaDisabled);
  const [email, setEmail] = useState('');

  const handleGoBack = () => {
    navigate(prependBasename(PAGES.vinhood.signIn), { state: { ...location?.state } });
    dispatch(resetAuthError());
    dispatch(resetResetPasswordState());
  };

  const handleRecaptcha = (sitekey: string) => {
    if (sitekey) {
      dispatch(
        actionResetPassword({
          email,
          sitekey,
        }),
      );
    }
  };

  const activateRecaptcha = () => {
    recaptchaRef.current.execute();
  };

  const onSubmitHandler = (data: ForgotPasswordInputObject) => {
    recaptchaRef.current.reset();
    dispatch(resetAuthError());
    setEmail(data.email);
    activateRecaptcha();
  };

  return (
    <>
      <ReCaptchaContainer
        handleValue={handleRecaptcha}
        isDisabled={!!ENV_RECAPTCHA_SITE_KEY && !isCaptchaDisabled} // looks strange
        recaptchaRef={recaptchaRef}
        setIsBtnDisabled={setIsBtnDisabled}
      />
      {resetPasswordEmailSuccess ? (
        <CheckResetPasswordPage email={email} handleGoBack={handleGoBack} handlePasswordResend={activateRecaptcha} />
      ) : (
        <ResetPasswordPage errors={rejectEndpointError} handleGoBack={handleGoBack} onSubmitHandler={onSubmitHandler} />
      )}
    </>
  );
};

export default ResetPasswordContainer;
