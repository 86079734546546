import classNames from 'classnames';
import { FC } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { TProductInstance } from '@lib/core/products/types';
import { TRetailerLocationStoreType } from '@lib/core/retailers/types';
import { IFeedbackData, TFeedback } from '@lib/core/users/slices/feedbacks';
import { feedbackFilter, isProductInWishlistFilter } from '@lib/core/users/utils/filters';
import { MP_POSITION_CONTEXT } from '@lib/tools/dat/mixpanel/consts';
import { IHandleProductsRequestParams } from '@lib/tools/shared/helpers/interfaces';

import ProductCard, { TProductCardVariants } from '@components/web/src/organisms/Cards/ProductCard/ProductCard';
import { IDiscoveryQuiz } from '@components/web/src/templates/Modals/DiscoveryQuizModal/DiscoveryQuizModal';

export type ICatalogProps = {
  isProductsRequestLoading: boolean;
  disablePagination?: boolean;
  products: TProductInstance[];
  itemsCount?: number;
  storeType: TRetailerLocationStoreType;
  isEnableLocationMapAddon?: boolean;
  isEnableVusionAddon?: boolean;
  isShowProductLocationAddon?: boolean;
  locale: string;
  isWishlistEnabled?: boolean;
  isFeedbackEnabled?: boolean;
  feedbackData?: IFeedbackData[];
  wishlistProductIds?: string[];
  isProductListLoading?: boolean;
  discoveryQuiz?: IDiscoveryQuiz;
  handleProductsRequest: (args: IHandleProductsRequestParams) => void;
  handleUpdateWishlistProductList?: (productId: string) => void;
  handleUpdateFeedback?: (feedback: TFeedback, productId: string) => void;
  variant?: TProductCardVariants;
};

const CatalogueResult: FC<ICatalogProps> = ({
  disablePagination = false,
  products,
  isProductsRequestLoading = false,
  itemsCount,
  storeType,
  isEnableLocationMapAddon,
  isShowProductLocationAddon,
  isEnableVusionAddon,
  locale,
  isFeedbackEnabled,
  feedbackData = [],
  isWishlistEnabled,
  wishlistProductIds = [],
  isProductListLoading,
  discoveryQuiz,
  handleUpdateWishlistProductList,
  handleUpdateFeedback,
  handleProductsRequest,
  variant,
}) => {
  return (
    <InfiniteScroll
      className={classNames('product-list-container')}
      dataLength={products.length}
      hasMore={!itemsCount || products.length < itemsCount}
      scrollableTarget="root-scroll-element"
      loader={
        isProductsRequestLoading ? (
          <>
            {new Array(6).fill(null).map((_i, index) => (
              <ProductCard key={index} isLoading={isProductsRequestLoading} locale={locale} storeType={storeType} />
            ))}
          </>
        ) : null
      }
      next={
        (isProductsRequestLoading || disablePagination) && !products.length
          ? null
          : () => handleProductsRequest({ isPagination: true })
      }
    >
      {products?.map((productInstanceData, index) => (
        <ProductCard
          key={`${productInstanceData.identifier}${index}`}
          isResponsive
          discoveryQuiz={discoveryQuiz}
          feedback={feedbackFilter(feedbackData, productInstanceData)}
          handleUpdateFeedback={handleUpdateFeedback}
          handleUpdateWishlistProductList={handleUpdateWishlistProductList}
          isEnableLocationMapAddon={isEnableLocationMapAddon}
          isEnableVusionAddon={isEnableVusionAddon}
          isFeedbackEnabled={isFeedbackEnabled}
          isLoading={false}
          isProductInWishlist={isProductInWishlistFilter(wishlistProductIds, productInstanceData)}
          isProductListLoading={isProductListLoading}
          isShowProductLocationAddon={isShowProductLocationAddon}
          isWishlistEnabled={isWishlistEnabled}
          locale={locale}
          mixpanelIndex={index}
          mixpanelPositionContext={MP_POSITION_CONTEXT.SCROLLABLE_CATALOG}
          productInstanceData={productInstanceData}
          storeType={storeType}
          variant={variant}
        />
      ))}
    </InfiniteScroll>
  );
};

export default CatalogueResult;
