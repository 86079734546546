import { css, styled } from 'styled-components';

export const TestResultPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const BannerWrapper = styled.div<{ $isVariantApp: boolean }>`
  position: sticky;
  left: 0;
  bottom: 54px;
  z-index: 9999;
  width: 100%;
  ${({ $isVariantApp }) =>
    $isVariantApp &&
    css`
      bottom: 0;
    `}
`;

export const SkeletonWrapper = styled.div`
  padding: 16px;
`;
