import { Dispatch, FC, SetStateAction, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectRetailerAgreementsIndexedObj } from '@lib/core/retailers/selectors/retailer';
import { HARDCODED_AGREEMENT_ID_PROFILING, HARDCODED_AGREEMENT_ID_TECHNICAL_STUFF } from '@lib/core/service/consts';
import { selectAppAgreements } from '@lib/core/service/selectors/technical';
import { Agreements } from '@lib/core/service/slices/technical/types';
import { localeApp } from '@lib/tools/locale/source/web/app';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';
import { VH_VARIANTS } from '@lib/tools/shared/helpers/consts';

import { IModalOption, IToggleOptionChange } from '@components/web/src/app/Modals/modalTypes';
import Button from '@components/web/src/atoms/Buttons/Button';
import CloseButton from '@components/web/src/components/Button/CloseButton/CloseButton';
import ModalWrapper from '@components/web/src/templates/Modals/ModalWrapper/ModalWrapper';
import RenderModalOptions from '@components/web/src/templates/Modals/RenderModalOptions/RenderModalOptions';

type Props = {
  isModalOpen: boolean;
  hideModal: () => void;
  handleConfirmButton?: (values: Record<string, boolean>) => void;
  handleGoBack?: () => void;
  shouldOpenModalsUnderHeader?: boolean;
  shouldDisplayBackButton?: boolean;
};

const PermissionModal: FC<Props> = ({ isModalOpen, hideModal, handleConfirmButton, shouldOpenModalsUnderHeader }) => {
  const { isUserAllowedProfiling, isUserAllowedUseLocalStorage } = useSelector(selectAppAgreements);

  const { b2cModalsHoc } = localeApp;
  const { technicalTitle, technicalDesc, profilingTitle, profilingDesc } = b2cModalsHoc;

  const {
    [HARDCODED_AGREEMENT_ID_PROFILING]: { is_always_active: isProfilingAgreementAlwaysActive = false } = {},
    [HARDCODED_AGREEMENT_ID_TECHNICAL_STUFF]: { is_always_active: isTechnicalAgreementAlwaysActive = false } = {},
  } = useSelector(selectRetailerAgreementsIndexedObj);

  const cookiesOptions: IModalOption = {
    [Agreements.isUserAllowedUseLocalStorage]: {
      description: technicalDesc,
      isEnabled: isTechnicalAgreementAlwaysActive || isUserAllowedUseLocalStorage,
      is_always_active: isTechnicalAgreementAlwaysActive,
      title: technicalTitle,
    },
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    [Agreements.isUserAllowedProfiling]: {
      description: profilingDesc,
      isEnabled: isProfilingAgreementAlwaysActive || isUserAllowedProfiling,
      is_always_active: isProfilingAgreementAlwaysActive,
      title: profilingTitle,
    },
  };

  const [cookieState, setCookieState] = useState(cookiesOptions);

  const handleOnContinue = () => {
    let finalSelectedValues = {};
    Object.keys(cookieState).forEach(option => {
      finalSelectedValues = {
        ...finalSelectedValues,
        [option]: cookieState[option].isEnabled,
      };
    });

    handleConfirmButton(finalSelectedValues);
  };

  const handleOnToggle = ({ optionName, optionType }: IToggleOptionChange) => {
    let currentSelectedObjectState: IModalOption;
    let currentSetterFunction: Dispatch<SetStateAction<IModalOption>>;
    switch (optionType) {
      case 'cookie':
        currentSelectedObjectState = { ...cookieState };
        currentSetterFunction = setCookieState;
        break;
      default:
        currentSelectedObjectState = { ...cookieState };
        currentSetterFunction = setCookieState;
        break;
    }
    const findSelectedObject = currentSelectedObjectState[optionName];
    const finalSelectedObject = {
      ...findSelectedObject,
      isEnabled: !findSelectedObject.isEnabled,
    };
    currentSelectedObjectState[optionName] = finalSelectedObject;

    currentSetterFunction(currentSelectedObjectState);
  };

  const {
    permissionStartPage: { headerTitle, headerDescription, cookieOptionTitle, btnConfirm },
  } = localeApp;

  return (
    <ModalWrapper
      hideModal={hideModal}
      isOpen={isModalOpen}
      modalClassName={`permission-page-container ${shouldOpenModalsUnderHeader ? 'under-header' : ''}`}
    >
      <div className="top-section">
        <CloseButton handleClick={hideModal} />
        <p className="top-title">
          <LocaleFragment message={headerTitle} />
        </p>
        <p className="top-description">
          <LocaleFragment message={headerDescription} />
        </p>
      </div>

      <div className="bottom-section">
        <p className="bottom-title">
          <LocaleFragment message={cookieOptionTitle} />
        </p>
        <RenderModalOptions optionType="cookie" options={cookieState} onToggle={handleOnToggle} />
        <Button handleClick={handleOnContinue} size="sm" text={btnConfirm} variant={VH_VARIANTS.PRIMARY} />
      </div>
    </ModalWrapper>
  );
};

export default PermissionModal;
