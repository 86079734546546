import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { useUserQuiz } from '@lib/core/quizzes/hooks';
import { useApp } from '@lib/core/service/hooks';
import { useServiceInstance } from '@lib/core/service/hooks/useServiceInstance';
import { selectAppAgreements } from '@lib/core/service/selectors/technical';
import { setIsTasteIdGuideShown } from '@lib/core/service/slices';
import { actionChangeAgreements } from '@lib/core/service/slices/technical/agreements';
import { actionResetRegisterPageScenarioData } from '@lib/core/service/slices/technical/registerPageScenario';
import { Agreements } from '@lib/core/service/slices/technical/types';
import { prependBasename } from '@lib/core/service/utils';
import { useUser } from '@lib/core/users/hooks';
import {
  actionRegister,
  facebookLoginCallback,
  googleLoginCallback,
  postTermsOfServiceAfterRegistration,
  resetAuthError,
} from '@lib/core/users/slices/auth';
import RouteUtils from '@lib/tools/routes';
import { REGISTRATION_URL_PARAM, START_EMAIL_REGISTRATION_URL_PARAM } from '@lib/tools/shared/auths/helpers/consts';
import { setUrlParams } from '@lib/tools/shared/helpers';
import { PRODUCT_CATEGORY_URL_PARAM } from '@lib/tools/shared/helpers/consts';
import { useAddons, useRetailerDesignSet, useTypedSelector } from '@lib/tools/views/hooks';
import { useTypedDispatch } from '@lib/tools/views/hooks/useTypedDispatch';
import { PAGES } from '@lib/tools/views/urls';

import { RegistrationFormInputObject } from '@components/web/src/pages/Authentication/AuthFormInterface';
import LoadingPage from '@components/web/src/templates/Loading/LoadingPage';
import RegistrationPage from '@components/web/src/templates/Login&Registration/Registration/RegistrationPage/RegistrationPage';

const RegistrationContainer: FC = () => {
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const { isUserAllowedUseEmailForMarketing, isAgreementLoading } = useSelector(selectAppAgreements);
  const { locale } = useApp();
  const { isServiceInstanceFeatureEnabled } = useServiceInstance();
  const { userQuizId } = useUserQuiz();
  const { isUserAuthenticated, isFetchingUserData } = useUser();
  const { isDesignSetVinhoodApp, isDesignSetVinhoodExperience } = useRetailerDesignSet();
  const { isHideSkipAndGoToProductsAddon } = useAddons();
  const isSocialAuthTesting = searchParams.get('is_social_test') === 'true';

  const { error: authErrors = {}, isLoading: isFetchingUser } = useTypedSelector(state => state.auth);

  const isEnabledAutoOpenEmailForm = searchParams.get(REGISTRATION_URL_PARAM) === START_EMAIL_REGISTRATION_URL_PARAM;

  const [isEmailAuthorization, setIsEmailAuthorization] = useState(isEnabledAutoOpenEmailForm);

  const registerPageScenarioState = useTypedSelector(state => state.registerPageScenario);

  useEffect(() => {
    if (isDesignSetVinhoodApp && !userQuizId) {
      navigate(prependBasename(PAGES.vinhood.quiz.chooseTaste));
    } else if (isDesignSetVinhoodExperience && isUserAuthenticated) {
      navigate(prependBasename(PAGES.vinhood.legal.privacy));
    }
  }, [userQuizId, isUserAuthenticated, isFetchingUser]);

  useEffect(() => {
    return () => {
      dispatch(resetAuthError());
    };
  }, []);

  const responseApple = response => {
    console.info(response);
  };

  const handleTasteIdGuideShownStatus = () => {
    dispatch(setIsTasteIdGuideShown(false));
  };

  const handleFormSubmit = async (data: RegistrationFormInputObject) => {
    const { email, password, lastName, name } = data;

    await dispatch(
      actionRegister({
        email,
        first_name: name,
        last_name: lastName,
        password,
      }),
    ).then(() => {
      postTermsOfServiceAfterRegistration(dispatch, true);
      handleTasteIdGuideShownStatus();
    });
  };

  const onEmailAuthButtonClick = () => {
    setIsEmailAuthorization(state => !state);
    dispatch(resetAuthError());
  };

  const navigateToCatalog = () => {
    if (isServiceInstanceFeatureEnabled) {
      RouteUtils.navigateToWidgetServiceInstance(PAGES.vinhood.catalog);
      return;
    }

    navigate(prependBasename(PAGES.vinhood.catalog));
  };

  const navigateToLoginPage = () => navigate(prependBasename(PAGES.vinhood.signIn), { state: { ...location?.state } });

  const onBackButtonClick = () => {
    navigate(prependBasename(PAGES.vinhood.quizResults.result));
    setUrlParams([{ key: PRODUCT_CATEGORY_URL_PARAM, value: registerPageScenarioState.productCategory }]);
    dispatch(actionResetRegisterPageScenarioData());
  };

  const handleChangeMarketingAgreementValue = (newValue: boolean) =>
    dispatch(actionChangeAgreements({ [Agreements.isUserAllowedUseEmailForMarketing]: newValue }));

  return (
    <>
      {isFetchingUserData || isAgreementLoading || isUserAuthenticated ? (
        <LoadingPage />
      ) : (
        <RegistrationPage
          authErrors={authErrors}
          handleChangeMarketingAgreementValue={handleChangeMarketingAgreementValue}
          handleFormSubmit={handleFormSubmit}
          isDesignSetVinhoodExperience={isDesignSetVinhoodExperience}
          isEmailAuthorization={isEmailAuthorization}
          isHideSkipAndGoToProductsAddon={isHideSkipAndGoToProductsAddon}
          isServiceInstanceFeatureEnabled={isServiceInstanceFeatureEnabled}
          isSocialAuthTesting={isSocialAuthTesting}
          isUserAllowedUseEmailForMarketing={isUserAllowedUseEmailForMarketing}
          locale={locale}
          navigateToCatalog={navigateToCatalog}
          navigateToLoginPage={navigateToLoginPage}
          registerPageScenarioState={registerPageScenarioState}
          responseApple={responseApple}
          responseFacebook={facebookLoginCallback(dispatch, true)}
          responseGoogle={googleLoginCallback(dispatch, true)}
          onBackButtonClick={onBackButtonClick}
          onEmailAuthButtonClick={onEmailAuthButtonClick}
        />
      )}
    </>
  );
};

export default RegistrationContainer;
