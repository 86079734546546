import { FC, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { TLanguage } from '@lib/core/retailers/types';
import { setServiceLocale } from '@lib/core/service/slices';
import { languages } from '@lib/tools/locale/utils/consts';
import useOutsideClick from '@lib/tools/views/hooks/useOutsideClick';

import GBicon from '@components/web/src/assets/legacy/b2b/kiosk/icons/GB.svg';
import ITicon from '@components/web/src/assets/legacy/b2b/kiosk/icons/IT.svg';
import LanguageLists from '@components/web/src/components/LanguageSwitcher/LanguageList/LanguageLists';
import SelectedLanguage from '@components/web/src/components/LanguageSwitcher/SelectedLanguage/SelectedLanguage';

export type ILanguageSwitcherType = 'select' | 'icon';

interface IProps {
  isDarkTheme?: boolean;
  languageSwitcherType: ILanguageSwitcherType;
  retailerLanguages: TLanguage[];
  locale: string;
}

const LanguageSwitcher: FC<IProps> = ({ isDarkTheme = false, retailerLanguages, languageSwitcherType, locale }) => {
  const dispatch = useDispatch();

  const [showLanguagesList, setShowLanguagesList] = useState(false);

  const handleOutsideClick = () => {
    setShowLanguagesList(false);
  };

  const ref = useRef();
  useOutsideClick(showLanguagesList ? ref : null, handleOutsideClick);

  const handleToggleLanguagesList = () => {
    setShowLanguagesList(!showLanguagesList);
  };

  const handleSelectLanguage = selectedLanguageCode => {
    if (selectedLanguageCode !== locale) {
      dispatch(setServiceLocale(selectedLanguageCode));
    }
    setShowLanguagesList(false);
  };

  if (!retailerLanguages || retailerLanguages?.length < 2) return null;
  return (
    <>
      {languageSwitcherType === 'select' ? (
        <div ref={ref} aria-hidden className="languages-container">
          <div
            aria-hidden
            className={`language-switcher-wrapper ${showLanguagesList && 'isActive'}`}
            onClick={handleToggleLanguagesList}
          >
            <SelectedLanguage
              activeLanguage={locale}
              isBlackVariant={isDarkTheme}
              showLanguageList={showLanguagesList}
            />
          </div>
          {showLanguagesList && <LanguageLists activeLanguage={locale} handleSelectedLanguage={handleSelectLanguage} />}
        </div>
      ) : (
        <div className="icon-language-switcher-container">
          {retailerLanguages.map(language => (
            <button
              key={language.code}
              className={locale !== language.code ? 'selected-language' : ''}
              onClick={() => handleSelectLanguage(language.code)}
            >
              <img
                alt={`${language.code} icons`}
                className={locale !== language.code ? 'icon-opacity' : ''}
                src={language.code === languages.ITALIAN ? ITicon : GBicon}
              />
            </button>
          ))}
        </div>
      )}
    </>
  );
};

export default LanguageSwitcher;
