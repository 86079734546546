import classNames from 'classnames';
import { FC, useState } from 'react';

import { TQuizAnswerData } from '@lib/core/quizzes/types';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import { ILocaleText } from '@lib/tools/locale/views/LocaleFragment';

import AnswerItem from '@components/web/src/atoms/TestAnswer/TestAnswerItem/TestAnswer';
import BackButton from '@components/web/src/components/Button/BackButton/BackButton';
import ProgressBar from '@components/web/src/components/ProgressBar/ProgressBar';
import Question from '@components/web/src/components/Quiz/Question/Question';
import KioskCirclesBackground from '@components/web/src/kiosk/KioskCirclesBackground/KioskCirclesBackground';

interface Props {
  answers: TQuizAnswerData[];
  question: ILocaleText;
  progressPercent: number;
  firstQuestion: boolean;
  withoutProgressBar?: boolean;
  isApplicationKiosk: boolean;
  isDesignSetVinhoodExperience: boolean;
  isDevToolsEnabled: boolean;
  isUserQuizLoading?: boolean;
  goBack: () => void;
  onAnswerSelectHandler: (id: string) => void;
  customColors: {
    customQuizBackgroundColor: string;
    customTextColor: string;
    customSecondaryColor: string;
  };
}

const QuizPage: FC<Props> = ({
  answers,
  withoutProgressBar,
  question,
  progressPercent,
  isApplicationKiosk,
  isDesignSetVinhoodExperience,
  isDevToolsEnabled,
  isUserQuizLoading,
  goBack,
  onAnswerSelectHandler,
  customColors,
}) => {
  const [isDisableQuiz, setIsDisableQuiz] = useState(false);

  const { commonMessages } = localeCommon;
  const { customQuizBackgroundColor, customTextColor, customSecondaryColor } = customColors;

  const customButtonArrowColor =
    isDesignSetVinhoodExperience && customSecondaryColor ? customSecondaryColor : '#4091A0';

  return (
    <div
      className={classNames('quiz-page-container', { isLargeVariant: isApplicationKiosk, withoutProgressBar })}
      style={{ backgroundColor: customQuizBackgroundColor }}
    >
      {isApplicationKiosk && <KioskCirclesBackground />}
      {!withoutProgressBar && <ProgressBar progressPercentage={progressPercent} />}
      <Question customTextColor={customTextColor} isApplicationKiosk={isApplicationKiosk} title={question} />
      <div className="answers-container">
        {answers?.map(answer => {
          const answerId = Object.keys(answer)[0];
          return (
            <AnswerItem
              key={answerId}
              customTextColor={customTextColor}
              id={answerId}
              image={answer[answerId].image}
              isApplicationKiosk={isApplicationKiosk}
              isDevToolsEnabled={isDevToolsEnabled}
              isDisabled={isDisableQuiz}
              isSelected={false}
              isUserQuizLoading={isUserQuizLoading}
              setIsDisableQuiz={setIsDisableQuiz}
              text={answer[answerId].text}
              onSelect={onAnswerSelectHandler}
            />
          );
        })}
      </div>
      {goBack && (
        <BackButton
          className="go-back-btn"
          customArrowColor={customButtonArrowColor}
          handleClick={goBack}
          size="sm"
          style={{ color: customTextColor }}
          text={commonMessages.goBackBtn}
        />
      )}
    </div>
  );
};

export default QuizPage;
