import { FC } from 'react';

import { TJournalPanelFilter, TProductCategory, TProductInstance } from '@lib/core/products/types';
import { TRetailerLocationStoreType } from '@lib/core/retailers/types';
import { IFeedbackData, TFeedback } from '@lib/core/users/slices/feedbacks';
import FilterContainer from '@lib/tools/filterManager/views/containers/FilterContainer';
import { localeApp } from '@lib/tools/locale/source/web/app';
import { localeCommon } from '@lib/tools/locale/source/web/common';

import CTAButton from '@components/web/src/app/CTAIconBtn/CTAButton/CTAButton';
import { JournalProductsList } from '@components/web/src/app/Journal/JournalProductsList';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Text } from '@components/web/src/foundations/Text/Text';
import { IDiscoveryQuiz } from '@components/web/src/templates/Modals/DiscoveryQuizModal/DiscoveryQuizModal';
import { JournalPanel } from '@components/web/src/templates/TasteId/TasteIdJournalPage/JournalPanel';
import * as S from '@components/web/src/templates/TasteId/TasteIdJournalPage/styles';

interface IProps {
  feedbackData: IFeedbackData[];
  activeJournalPanelFilter: TJournalPanelFilter;
  isProductsRequestLoading: boolean;
  locale: string;
  isFilterOpened?: boolean;
  isAllProductsLoaded?: boolean;
  storeType: TRetailerLocationStoreType;
  productCategory: TProductCategory;
  products: TProductInstance[];
  itemsCount: number;
  wishlistProductIds: string[];
  isProductListLoading?: boolean;
  discoveryQuiz: IDiscoveryQuiz;
  handleBackBtnClick?: () => void;
  handlePanelFilterChange: (value: TJournalPanelFilter) => void;
  handleProductsRequest: ({ isPagination }: { isPagination?: boolean }) => void;
  handleUpdateFeedback: (feedback: TFeedback, productId: string) => void;
  handleUpdateWishlistProductList: (productId: string) => void;
}

const JournalPage: FC<IProps> = ({
  feedbackData,
  isFilterOpened = false,
  isAllProductsLoaded = false,
  activeJournalPanelFilter,
  isProductsRequestLoading,
  itemsCount,
  locale,
  productCategory,
  products,
  wishlistProductIds,
  isProductListLoading,
  storeType,
  discoveryQuiz,
  handleBackBtnClick,
  handleProductsRequest,
  handleUpdateFeedback,
  handleUpdateWishlistProductList,
  handlePanelFilterChange,
}) => {
  const { journalPage } = localeApp;
  const { productCategories } = localeCommon;

  return (
    <>
      <S.JournalPageHeader data-testid="JournalPageHeader">
        <CTAButton btnIcon="leftarrow" btnText={localeCommon.commonMessages.back} handleBtnClick={handleBackBtnClick} />
        <Text
          color={STATIC_COLORS.base.black}
          localeIndex={{ productCategory }}
          localeVariables={{ productCategoryText: productCategories[productCategory] }}
          size="body1"
          text={journalPage.journalHeaderText}
          weight="bold"
        />
      </S.JournalPageHeader>
      <S.JournalPageContainer $productCategory={productCategory} data-testid="JournalPageContainer">
        <JournalPanel
          activeJournalPanelFilter={activeJournalPanelFilter}
          handlePanelFilterChange={handlePanelFilterChange}
          productCategory={productCategory}
        />
        <S.JournalPageContent data-testid="JournalPageContent">
          <FilterContainer
            isJournalVariant
            isSearchEnabled
            handleApply={handleProductsRequest}
            productCategory={productCategory}
          />
          {!isFilterOpened && (
            <JournalProductsList
              isFeedbackEnabled
              isWishlistEnabled
              activeJournalPanelFilter={activeJournalPanelFilter}
              discoveryQuiz={discoveryQuiz}
              feedbackData={feedbackData}
              handleProductsRequest={handleProductsRequest}
              handleUpdateFeedback={handleUpdateFeedback}
              handleUpdateWishlistProductList={handleUpdateWishlistProductList}
              isAllProductsLoaded={isAllProductsLoaded}
              isProductListLoading={isProductListLoading}
              isProductsRequestLoading={isProductsRequestLoading}
              itemsCount={itemsCount}
              locale={locale}
              productCategory={productCategory}
              products={products}
              storeType={storeType}
              wishlistProductIds={wishlistProductIds}
            />
          )}
        </S.JournalPageContent>
      </S.JournalPageContainer>
    </>
  );
};

export default JournalPage;
