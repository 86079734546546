import { zodResolver } from '@hookform/resolvers/zod';
import { FC, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { ZodType, z } from 'zod';

import { localeWidget } from '@lib/tools/locale/source/web/widget';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';

import Button from '@components/web/src/atoms/Buttons/Button';

interface IProps {
  logo: string;
  retailerName: string;
  privacyUrl: string;
  submitHandler: (e) => void;
  privacyOptionTextFromRetailer: string;
}

const PolicyAcceptancePage: FC<IProps> = ({
  logo,
  retailerName,
  privacyUrl,
  privacyOptionTextFromRetailer,
  submitHandler,
}) => {
  const {
    policyAcceptance: { preTitle, title, buttonText, agreed, notAgreed, privacyPolicy },
  } = localeWidget;

  const validationSchema: ZodType<any> = useMemo(
    () =>
      z.object({
        userSelection: z.string().nonempty('agreement is required'),
      }),
    [],
  );

  const {
    handleSubmit,
    register,
    formState: { isValid },
  } = useForm({
    resolver: zodResolver(validationSchema),
  });

  return (
    <div className="policy-acceptance-page-container">
      <div className="policy-acceptance-header">
        {logo && (
          <div className="policy-icon">
            <img alt="retailer logo" loading="lazy" src={logo} title="fetching image..." />
          </div>
        )}
        <p className="pre-title">
          <LocaleFragment message={preTitle} />
        </p>
        <p className="title">
          <LocaleFragment message={title} options={{ retailerName }} />
        </p>
        {privacyUrl && (
          <span
            aria-hidden
            style={{ cursor: 'pointer', textDecoration: 'underline' }}
            onClick={() => window.open(privacyUrl, '_blank')}
          >
            <p className="body-subtitle">
              <LocaleFragment message={privacyPolicy} options={{ retailerName }} />
            </p>
          </span>
        )}
      </div>
      <div className="policy-content">
        <p className="description">{privacyOptionTextFromRetailer}</p>
        <form className="form" onSubmit={handleSubmit(submitHandler)}>
          <div className="radio-button-container">
            <label htmlFor="agree">
              <input id="agree" name="userSelection" type="radio" value="true" {...register('userSelection')} />
              <LocaleFragment message={agreed} />
            </label>
            <label htmlFor="notagree">
              <input id="notagree" name="userSelection" type="radio" value="false" {...register('userSelection')} />
              <LocaleFragment message={notAgreed} />
            </label>
          </div>
          <Button disabled={!isValid} size="sm" text={buttonText} type="submit" />
        </form>
      </div>
    </div>
  );
};

export default PolicyAcceptancePage;
