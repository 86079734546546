import { FC, ReactNode } from 'react';

import { B2C_MODALS } from '@lib/core/service/consts';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';
import { useModals } from '@lib/tools/modals/hooks';
import { MODALS } from '@lib/tools/shared/helpers/consts';
import { currentYear } from '@lib/tools/shared/helpers/utils';

import { ReactComponent as GearIcon } from '@components/web/src/assets/legacy/b2c/icons/icon_green50_gear.svg';
import { ReactComponent as WavyImage } from '@components/web/src/assets/legacy/b2c/images/footer_waves.svg';
import B2CModalsHOC from '@components/web/src/templates/Modals/B2CModalsHOC';

interface IFooterLink {
  id: string;
  content: string | ReactNode;
  onClick: () => void;
}

interface IProps {
  shouldOpenModalsUnderHeader?: boolean;
  showGearIcon?: boolean;
}

const FooterAppAnonymous: FC<IProps> = ({ shouldOpenModalsUnderHeader, showGearIcon = true }) => {
  const { openModal } = useModals();

  const { termsAndConditionsTitle, privacyPolicyTitle, cookiePolicyTitle, copyrightText } = localeCommon.commonMessages;

  const footerLinks: IFooterLink[] = [
    {
      content: <LocaleFragment message={termsAndConditionsTitle} />,
      id: '1',
      onClick: () => openModal(B2C_MODALS.LEGAL_POLICY_MODAL),
    },
    {
      content: <LocaleFragment message={privacyPolicyTitle} />,
      id: '2',
      onClick: () => openModal(B2C_MODALS.PRIVACY_POLICY_MODAL),
    },
    {
      content: <LocaleFragment message={cookiePolicyTitle} />,
      id: '3',
      onClick: () => openModal(B2C_MODALS.COOKIE_POLICY_MODAL),
    },
    {
      content: <GearIcon />,
      id: '4',
      onClick: () => openModal(MODALS.PERMISSION_MODAL),
    },
  ];

  if (!showGearIcon) delete footerLinks[3];

  return (
    <B2CModalsHOC shouldOpenModalsUnderHeader={shouldOpenModalsUnderHeader}>
      <div className="footer-app-anonymous-container">
        <div className="wavyimage-container">
          <WavyImage className="wavyimage-element" />
        </div>
        <div className="footer-content">
          <span className="copyright-text">
            <LocaleFragment message={copyrightText} options={{ currentYear }} />
          </span>
          <div className="content-link-container">
            {footerLinks.map(contentItem => (
              <button key={contentItem.id} className="link" onClick={contentItem.onClick}>
                {contentItem.content}
              </button>
            ))}
          </div>
        </div>
      </div>
    </B2CModalsHOC>
  );
};

export default FooterAppAnonymous;
