import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { fetchExperiencesLists } from '@lib/core/experiences/slices/experienceCatalogSlice';
import { TProductInstance } from '@lib/core/products/types';
import { getLimitedProductsListData, getProductsListData } from '@lib/core/products/utils';
import { useRetailer } from '@lib/core/retailers/hooks/retailer';
import { useRetailerLocation } from '@lib/core/retailers/hooks/retailerLocation';
import { actionGetRetailerLocations } from '@lib/core/retailers/slices';
import { ASSOCIATED_RETAILER_SLUG_QUERY, GPRL_CHARACTER_QUERY } from '@lib/core/service/consts';
import { useApp } from '@lib/core/service/hooks';
import { getMultipleUniqueRandomItemsFromArray, prependBasename } from '@lib/core/service/utils';
import { useFeedback, useUser, useWishlist } from '@lib/core/users/hooks';
import MixpanelTracker from '@lib/tools/dat/mixpanel';
import useDiscoveryQuizData from '@lib/tools/discoveryQuiz/hooks';
import { LIMITED_CATALOG, PRODUCT_CATEGORY_QUERY } from '@lib/tools/shared/helpers/consts';
import { useTypedSelector } from '@lib/tools/views/hooks';
import { PAGES } from '@lib/tools/views/urls';

import GenericSpinner from '@components/web/src/components/GenericSpinner/GenericSpinner';
import { ExplorePage } from '@components/web/src/templates/ExplorePage/ExplorePage';

const ExploreContainer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname, search } = useLocation();

  const { productCategory, locale } = useApp();
  const { isUserAuthenticated, userCharacterForSinglePCRetailer, isFetchingUserData, isUserHasAnyCharacter } =
    useUser();
  const { retailerSlug, isRetailerLoading } = useRetailer();
  const {
    retailerStoreType,
    retailerLocationLogo,
    retailerLocationName,
    retailerLocationId,
    retailerLocationSlug,
    retailerLocationWebsite,
    retailerLocationPhone,
    retailerLocationEmail,
    retailerLocationDescription,
    retailerLocationImages,
    retailerLocationAddress,
    retailerLocationCoordinates,
    retailerLocationProducerId,
    retailerLocationTasteMatch,
    isRetailerLocationLoading,
    associatedRetailerLocation,
    retailerLocationCatalogType,
  } = useRetailerLocation();

  const { discoveryQuiz } = useDiscoveryQuizData();
  const { wishlistProductIds, handleUpdateWishlistProductList } = useWishlist();
  const { feedbackData, handleUpdateFeedback } = useFeedback();

  const retailerLocationTasteMatchValue =
    typeof retailerLocationTasteMatch[productCategory] === 'number'
      ? retailerLocationTasteMatch[productCategory]
      : null;

  const {
    experiencesData: { results: experienceData },
    isExperienceRequestLoading,
  } = useTypedSelector(state => state.experienceCatalog);

  const randomLimitedExperienceData = useMemo(
    () => getMultipleUniqueRandomItemsFromArray(experienceData, 3),
    [experienceData],
  );

  const randomRetailerLocationImage = useMemo(
    () => getMultipleUniqueRandomItemsFromArray(retailerLocationImages, 1)[0],
    [retailerLocationImages],
  );

  const [productInstanceData, setProductInstanceData] = useState<TProductInstance | null>(null);
  const [isProductInstanceDataLoading, setIsProductInstanceDataLoading] = useState(false);

  useEffect(() => {
    MixpanelTracker.events.exploreSectionView({
      locationId: retailerLocationId,
      locationName: retailerLocationName,
      locationSlug: retailerLocationSlug,
      tasteMatchLevel: retailerLocationTasteMatchValue,
    });

    /** Update retailerLocation data to get the latest
     * retailerLocationTasteMatch value, which is updated
     * after each user's character update.
     */
    dispatch(
      actionGetRetailerLocations({
        retailerLocationId,
        retailerSlug,
      }),
    );
  }, []);

  useEffect(() => {
    const fetchProductData = async () => {
      if (productInstanceData) return;

      setIsProductInstanceDataLoading(true);

      const isLimitedCatalogType = retailerLocationCatalogType === LIMITED_CATALOG;

      const params = {
        [GPRL_CHARACTER_QUERY]: userCharacterForSinglePCRetailer?.identifier,
        [PRODUCT_CATEGORY_QUERY]: productCategory,
        ...(isLimitedCatalogType && { [ASSOCIATED_RETAILER_SLUG_QUERY]: associatedRetailerLocation?.slug }),
        limit: 1,
        offset: 0,
      };

      if (isLimitedCatalogType) {
        const res = await getLimitedProductsListData(params);
        const productData = res.first_priority?.[0] || res.second_priority?.[0] || res.third_priority?.[0];
        setProductInstanceData(productData);
      } else {
        const res = await getProductsListData(params);
        const productData = res.results[0];
        setProductInstanceData(productData);
      }

      setIsProductInstanceDataLoading(false);
    };

    fetchProductData();
  }, [userCharacterForSinglePCRetailer]);

  useEffect(() => {
    if (!isExperienceRequestLoading && retailerLocationProducerId) {
      dispatch(fetchExperiencesLists({ producer: retailerLocationProducerId, productCategory }));
    }
  }, [productCategory, retailerLocationProducerId]);

  const isLoading =
    isProductInstanceDataLoading ||
    isFetchingUserData ||
    isExperienceRequestLoading ||
    isRetailerLocationLoading ||
    isRetailerLoading;

  const navigateToCatalogPage = () => navigate(prependBasename(PAGES.vinhood.catalog));

  const navigateToQuizSelectionPage = () => navigate(prependBasename(PAGES.vinhood.quiz.chooseTaste));

  const handleRedirectBannerClick = (link: string, params?: string) =>
    navigate(prependBasename(link, params), {
      state: { from: pathname + search },
    });

  return (
    <>
      {isLoading ? (
        <GenericSpinner />
      ) : (
        <ExplorePage
          discoveryQuiz={discoveryQuiz}
          experienceData={randomLimitedExperienceData}
          feedbackData={feedbackData}
          handlePopupClick={() => {}}
          handleRedirectBannerClick={handleRedirectBannerClick}
          handleUpdateFeedback={handleUpdateFeedback}
          handleUpdateWishlistProductList={handleUpdateWishlistProductList}
          isUserAuthenticated={isUserAuthenticated}
          isUserHasAnyCharacter={isUserHasAnyCharacter}
          locale={locale}
          navigateToCatalogPage={navigateToCatalogPage}
          navigateToQuizSelectionPage={navigateToQuizSelectionPage}
          productCategory={productCategory}
          productInstanceData={productInstanceData}
          retailerLocationAddress={retailerLocationAddress}
          retailerLocationCoordinates={retailerLocationCoordinates}
          retailerLocationDescription={retailerLocationDescription}
          retailerLocationEmail={retailerLocationEmail}
          retailerLocationId={retailerLocationId}
          retailerLocationImage={randomRetailerLocationImage}
          retailerLocationLogo={retailerLocationLogo}
          retailerLocationName={retailerLocationName}
          retailerLocationPhone={retailerLocationPhone}
          retailerLocationSlug={retailerLocationSlug}
          retailerLocationTasteMatchValue={retailerLocationTasteMatchValue}
          retailerLocationWebsite={retailerLocationWebsite}
          storeType={retailerStoreType}
          wishlistProductIds={wishlistProductIds}
        />
      )}
    </>
  );
};

export default ExploreContainer;
