import { useSelector } from 'react-redux';

import { TProductCategory } from '@lib/core/products/types';
import {
  selectAppPreviousRoute,
  selectAppRoute,
  selectIsDevToolsEnabled,
  selectIsHostMocked,
  selectIsLocaleEditMode,
  selectIsSplashScreenPageShown,
  selectIsTasteIdGuideShown,
  selectLocale,
  selectServiceProductCategory,
  selectShouldDisplayTastePathStartPage,
  selectShouldHideTastePathTooltipModal,
} from '@lib/core/service/selectors';
import { languages } from '@lib/tools/locale/utils/consts';

type IService = {
  isSplashScreenPageShown: boolean;
  isDevToolsEnabled: boolean;
  isHostMocked: boolean;
  isLocaleEditMode: boolean;
  isLayoutRightToLeft: boolean;
  isTasteIdGuideShown: boolean;
  locale: string;
  previousRoute: string;
  route: string;
  productCategory: TProductCategory;
  shouldDisplayTastePathStartPage: boolean;
  shouldHideTastePathTooltipModal: boolean;
};

/**
 * * Accessible features for views and components
 * ? App state can be structured as core/utils/* modules
 * {
 *   locale: { 
//  *     code: boolean; // en/it/...
//  *     isRtl: boolean;
//  *     editMode: boolean;
 *   },
 *   analytics: { 
 *     code: boolean; // en/it/...
 *   },
 *   testing: {  // ?
 *     code: boolean; // en/it/...
 *   },
 *   route: {
//  *     previousRoute: string;
//  *     route: string;
 *   },
 *   rooms: { // ? Converge with IServiceInstance or IUserRoom?
//  *     isEnabled: string;
//  *     productCategory: TProductCategory;
//  *     retailerLocationId: string;
//  *     retailerSlug: string;
 *   },
 *   devtools: {
//  *     isEnabled: string;
//  *     route: string;
 *   },
 *   user: {
 *     tastePath: {
//  *     isFirstVisit: boolean;
//  *     showHints: boolean;
 *     },
 *   },
 *   service: { // ? Converge with Launcher namespace and build IService. Pass context to components.
//  *     productCategory: TProductCategory;
//  *     terms: TProductCategory;
//  *     geolocation: any;
 *   },
 * }
 */
export const useApp = (): IService => {
  const locale = useSelector(selectLocale);

  const isLocaleRtl = () => {
    const rtlLanguages = [languages.ARABIC, languages.HEBREW];
    return rtlLanguages.some(language => locale.startsWith(language));
  };
  return {
    isDevToolsEnabled: useSelector(selectIsDevToolsEnabled),
    isHostMocked: useSelector(selectIsHostMocked),
    isLayoutRightToLeft: isLocaleRtl(),
    isLocaleEditMode: useSelector(selectIsLocaleEditMode),
    isSplashScreenPageShown: useSelector(selectIsSplashScreenPageShown),
    isTasteIdGuideShown: useSelector(selectIsTasteIdGuideShown),
    locale,
    previousRoute: useSelector(selectAppPreviousRoute),
    productCategory: useSelector(selectServiceProductCategory),
    route: useSelector(selectAppRoute),
    shouldDisplayTastePathStartPage: useSelector(selectShouldDisplayTastePathStartPage),
    shouldHideTastePathTooltipModal: useSelector(selectShouldHideTastePathTooltipModal),
  };
};
