import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { selectRetailerLanguages } from '@lib/core/retailers/selectors/retailer';
import { B2C_MODALS } from '@lib/core/service/consts';
import { useApp } from '@lib/core/service/hooks';
import { setServiceLocale } from '@lib/core/service/slices';
import {
  actionToggleUserProductPreferencesValue,
  actionUpdateUserProductPreferences,
} from '@lib/core/service/slices/productPreferencesSlice';
import { resetProductSwiperState } from '@lib/core/service/slices/productsSwiperSlice';
import { actionFetchCountriesList } from '@lib/core/service/slices/technical/countries';
import { useUser } from '@lib/core/users/hooks';
import { actionGetUserData, actionUpdateUserData, actionUploadUserPicture } from '@lib/core/users/slices/user';
import { localeApp } from '@lib/tools/locale/source/web/app';
import { useModals } from '@lib/tools/modals/hooks';
import { EXPOSURE_PREFERENCES } from '@lib/tools/shared/helpers/consts';
import { useTypedSelector } from '@lib/tools/views/hooks';
import { useTypedDispatch } from '@lib/tools/views/hooks/useTypedDispatch';

import { toastMessage } from '@components/web/src/atoms/Toast/ToastMessage';
import Spinner from '@components/web/src/components/GenericSpinner/GenericSpinner';
import { IMAGE_ERRORS } from '@components/web/src/foundations/consts';
import B2CModalsHOC from '@components/web/src/templates/Modals/B2CModalsHOC';
import SettingsPage from '@components/web/src/templates/TasteId/TasteIdSettingsPage/TasteIdSettingsPage';
import { IProfileFormData } from '@components/web/src/templates/TasteId/TasteIdSettingsPage/UserInfoForm';

type TPreferenceSubmitValue = {
  key: string;
  isEnabled: boolean;
};

const TasteIdSettingsPageContainer: FC = () => {
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();

  const { openModal } = useModals();

  const { locale } = useApp();
  const supportedLanguages = useSelector(selectRetailerLanguages);
  const countryOptions = useTypedSelector(state => state.b2cCountries.countriesList);
  const foodPreferencesState = useTypedSelector(state => state.productPreferences.foodPreferencesData);

  const {
    handleResetUserData,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    userDataRaw: { first_name, last_name, email, country, profile_image },
    isUploadingProfilePicture,
  } = useUser();

  const profileFormData: IProfileFormData = {
    country: country ?? '',
    email: email ?? '',
    firstName: first_name ?? '',
    lastName: last_name ?? '',
  };

  const [profilePictureUpdateError, setProfilePictureUpdateError] = useState('');

  useEffect(() => {
    if (countryOptions.length === 0) dispatch(actionFetchCountriesList());
  }, []);

  const { settingsTabContainer } = localeApp;

  const handleProfilePictureUpdate = (base64ImageData: string) => {
    dispatch(actionUploadUserPicture(base64ImageData))
      .then(() => {
        dispatch(actionGetUserData()).then(() => {
          toastMessage({ message: settingsTabContainer.profilePictureSuccessText });
        });
      })
      .catch(() => setProfilePictureUpdateError(IMAGE_ERRORS[500]));
  };

  const handleProfileFormSubmit = (data: IProfileFormData) => {
    const submitData = {
      country: data.country,
      email: data.email,
      first_name: data.firstName,
      last_name: data.lastName,
    };

    dispatch(actionUpdateUserData(submitData)).then(() =>
      toastMessage({ message: settingsTabContainer.profileSuccessText }),
    );
  };

  const handleLanguageChange = (language: string) => {
    dispatch(actionUpdateUserData({ language })).then(() => {
      dispatch(setServiceLocale(language));
      dispatch(resetProductSwiperState());
    });
  };

  const handleUpdateUserFoodPreferences = (value: TPreferenceSubmitValue) => {
    const { isEnabled, key } = value;
    dispatch(actionToggleUserProductPreferencesValue({ exposure: EXPOSURE_PREFERENCES, slug: key }));
    dispatch(actionUpdateUserProductPreferences({ exposure: EXPOSURE_PREFERENCES, isEnabled, slug: key }));
  };

  const onToggleClick = (optionKey: string) => {
    const changedPreferences = {
      isEnabled: !foodPreferencesState[optionKey].isEnabled,
      key: optionKey,
    };
    handleUpdateUserFoodPreferences(changedPreferences);
  };

  const handleLogout = () => {
    handleResetUserData();
  };

  const handleBackBtnClick = () => {
    navigate(-1);
  };

  return (
    <B2CModalsHOC>
      <>
        <SettingsPage
          countryOptions={countryOptions}
          // ! Check typing
          // @ts-ignore
          currentLanguage={locale}
          foodPreferencesState={foodPreferencesState}
          handleBackBtnClick={handleBackBtnClick}
          handleLanguageChange={handleLanguageChange}
          handleProfileFormSubmit={handleProfileFormSubmit}
          handleProfilePictureUpdate={handleProfilePictureUpdate}
          handleProfilePictureUpdateError={setProfilePictureUpdateError}
          profileFormData={profileFormData}
          profilePicture={profile_image}
          profilePictureUpdateError={profilePictureUpdateError}
          supportedLanguages={supportedLanguages}
          onExitBtnClick={handleLogout}
          onManageBtnClick={() => openModal(B2C_MODALS.MANAGE_MODAL)}
          onPreferencesHintBtnClick={() => openModal(B2C_MODALS.FOOD_PREFERENCES_MODAL)}
          onToggleClick={onToggleClick}
        />
        {isUploadingProfilePicture && <Spinner />}
      </>
    </B2CModalsHOC>
  );
};

export default TasteIdSettingsPageContainer;
