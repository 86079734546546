import { Dispatch, FC, SetStateAction } from 'react';

import { localeApp } from '@lib/tools/locale/source/web/app';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';

import PrivacyPolicyAcceptance from '@components/web/src/app/PrivacyPolicyAcceptance/PrivacyPolicyAcceptance';
import Button from '@components/web/src/atoms/Buttons/Button';

type ISocialWelcomePageProps = {
  handleStartTestClick?: () => void;
  isPrivacyProfilingPolicyChecked: boolean;
  isOptionalEmailPolicyChecked: boolean;
  setIsOptionalEmailPolicyChecked: Dispatch<SetStateAction<boolean>>;
  setIsPrivacyProfilingPolicyChecked: Dispatch<SetStateAction<boolean>>;
};

const SocialWelcomePage: FC<ISocialWelcomePageProps> = ({
  isPrivacyProfilingPolicyChecked,
  isOptionalEmailPolicyChecked,
  setIsOptionalEmailPolicyChecked,
  setIsPrivacyProfilingPolicyChecked,
  handleStartTestClick,
}) => {
  const {
    socialWelcomePage: { buttonText, headerDescriptionText, headerTitleText },
  } = localeApp;

  const sendPrivacyPolicyProps = {
    isOptionalEmailPolicyChecked,
    isPrivacyProfilingPolicyChecked,
    setIsOptionalEmailPolicyChecked,
    setIsPrivacyProfilingPolicyChecked,
  };

  return (
    <div className="social-welcome-container">
      <div className="welcome-header">
        <span className="title">
          <LocaleFragment message={headerTitleText} />
        </span>
        <p className="description">
          <LocaleFragment message={headerDescriptionText} />
        </p>
      </div>
      <PrivacyPolicyAcceptance {...sendPrivacyPolicyProps} />
      <div className="actions">
        <Button disabled={!isPrivacyProfilingPolicyChecked} text={buttonText} onClick={handleStartTestClick} />
      </div>
    </div>
  );
};

export default SocialWelcomePage;
