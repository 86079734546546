import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useApp } from '@lib/core/service/hooks';
import { setDisplayTastePathStartPage } from '@lib/core/service/slices';
import { prependBasename } from '@lib/core/service/utils';
import { useUser } from '@lib/core/users/hooks';
import { selectIsFeedbackLoading } from '@lib/core/users/selectors/feedback';
import { PAGES } from '@lib/tools/views/urls';

import TastePathStartPage from '@components/web/src/pages/TastePathStartPage/TastePathStartPage';

const TastePathStartPageContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isFeedbackLoading = useSelector(selectIsFeedbackLoading);

  const {
    userCharacterForSinglePCRetailer: { name: characterName = '' },
  } = useUser();

  const { shouldDisplayTastePathStartPage } = useApp();

  const navigateToCatalog = () => navigate(prependBasename(PAGES.vinhood.catalog));

  const onHandleClick = () => {
    dispatch(setDisplayTastePathStartPage(false));
  };

  useEffect(() => {
    if (!shouldDisplayTastePathStartPage) {
      navigateToCatalog();
    }
  }, [shouldDisplayTastePathStartPage]);

  return (
    <TastePathStartPage characterName={characterName} isDisabledBtn={isFeedbackLoading} onHandleClick={onHandleClick} />
  );
};

export default TastePathStartPageContainer;
