import { useApp } from '@lib/core/service/hooks';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';

import Checkbox from '@components/web/src/components/Checkbox/Checkbox';

const LegalAgreementCheckboxes = ({
  handleChangeVinhoodRegisterStaticAgreement,
  isUserAllowedVinhoodRegisterStaticAgreement,
  handleChangeMarketingAgreementValue,
  isUserAllowedUseEmailForMarketing,
}) => {
  const { locale } = useApp();
  const { emailRegistrationForm } = localeCommon;
  const termsAndConditionUrl = `https://www.vinhood.com/${locale}/terms-and-conditions/`;
  const privacyPolicyUrl = `https://www.vinhood.com/${locale}/privacy-policy/`;

  const termsAndConditionsAcceptanceText = (
    <LocaleFragment
      message={emailRegistrationForm.termsAndConditionsAcceptance}
      insertIntlJSXPartOptions={{
        linkId: 'terms-and-conditions',
        linkRender: text => (
          <span
            aria-hidden
            className="link-text-wrapper"
            style={{ cursor: 'pointer', textDecoration: 'underline' }}
            onClick={() => window.open(termsAndConditionUrl, '_blank')}
          >
            <span className="no-margin d-inline">{text}</span>
          </span>
        ),
      }}
    />
  );

  const optionalMarketingAcceptanceText = (
    <LocaleFragment
      message={emailRegistrationForm.optionalMarketingAcceptance}
      insertIntlJSXPartOptions={{
        linkId: 'privacy-policy',
        linkRender: text => (
          <span
            aria-hidden
            className="link-text-wrapper"
            style={{ cursor: 'pointer', textDecoration: 'underline' }}
            onClick={() => window.open(privacyPolicyUrl, '_blank')}
          >
            <span className="no-margin d-inline">{text}</span>
          </span>
        ),
      }}
    />
  );

  return (
    <div className="legal-agreement-checkboxes">
      <Checkbox
        id="termsAndConditions"
        isSelected={isUserAllowedVinhoodRegisterStaticAgreement}
        label={termsAndConditionsAcceptanceText}
        onChange={() => handleChangeVinhoodRegisterStaticAgreement(!isUserAllowedVinhoodRegisterStaticAgreement)}
      />
      <Checkbox
        id="optionalMarketing"
        isDisabled={!isUserAllowedVinhoodRegisterStaticAgreement}
        isSelected={isUserAllowedUseEmailForMarketing}
        label={optionalMarketingAcceptanceText}
        onChange={() => handleChangeMarketingAgreementValue(!isUserAllowedUseEmailForMarketing)}
      />
    </div>
  );
};

export default LegalAgreementCheckboxes;
