import { FC } from 'react';
import { useDispatch } from 'react-redux';

import {
  updateCharactersToggleActive,
  updatePromotionToggleActive,
} from '@lib/tools/filterManager/slices/toggleFilterSlice';
import LocaleFragment, { ILocaleText } from '@lib/tools/locale/views/LocaleFragment';
import { FILTER_TYPE_CHARACTER_TOGGLE, FILTER_TYPE_PROMOTION_TOGGLE } from '@lib/tools/shared/helpers/consts';
import { useTypedSelector } from '@lib/tools/views/hooks';

import ToggleButton from '@components/web/src/atoms/ToggleButton/ToggleButton';

type ToggleType = typeof FILTER_TYPE_CHARACTER_TOGGLE | typeof FILTER_TYPE_PROMOTION_TOGGLE;

type Props = {
  title: ILocaleText;
  subtitle?: ILocaleText;
  inactiveSubtitle?: ILocaleText;
  toggleType: ToggleType;
};

const Toggle: FC<Props> = ({ title, subtitle, inactiveSubtitle, toggleType }) => {
  const dispatch = useDispatch();

  const { isCharacterToggleActive, isPromotionToggleActive } = useTypedSelector(state => state.productFilters.toggle);

  const isActive = toggleType === FILTER_TYPE_CHARACTER_TOGGLE ? isCharacterToggleActive : isPromotionToggleActive;

  const updateToggleActive =
    toggleType === FILTER_TYPE_CHARACTER_TOGGLE
      ? (value: boolean) => dispatch(updateCharactersToggleActive({ value }))
      : (value: boolean) => dispatch(updatePromotionToggleActive({ value }));

  return (
    <div className="vinhood-filter-toggle" style={{ backgroundColor: !isActive && '#F4F4F2' }}>
      <ToggleButton isToggled={isActive} onToggle={() => updateToggleActive(!isActive)} />
      <div className="text-wrapper">
        <p className="title">
          <LocaleFragment message={title} />
        </p>
        {subtitle && (
          <p className="subtitle" style={{ color: !isActive && '#454C5A' }}>
            <LocaleFragment message={isActive ? subtitle : inactiveSubtitle} />
          </p>
        )}
      </div>
    </div>
  );
};

export default Toggle;
