import { RootState } from '@lib/core/service/types/appStateType';

/**
 * @returns the handshake status
 */
export const selectCommsHandshake = (state: RootState, defaultValue = false): boolean =>
  state.comms.isHostHandshakeApproved || defaultValue;

/**
 * @returns the handshake status
 */
export const selectCommsHostCustomId = (state: RootState, defaultValue = ''): string =>
  state.comms.hostCustomId || defaultValue;

/**
 * @returns the handshake status
 */
export const selectCommsHostRedirectUrl = (state: RootState, defaultValue = ''): string =>
  state.comms.hostRedirectUrl || defaultValue;
