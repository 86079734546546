import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { selectRetailerLanguages } from '@lib/core/retailers/selectors/retailer';

const LanguageLists = ({ handleSelectedLanguage, activeLanguage }) => {
  const supportedLanguages = useSelector(selectRetailerLanguages);

  const filteredLanguagesLists = useMemo(() => {
    return supportedLanguages.filter(({ code }) => code !== activeLanguage);
  }, [activeLanguage]);

  return (
    <div className="language-lists-container">
      {filteredLanguagesLists?.length > 0 &&
        filteredLanguagesLists?.map(({ code }) => (
          <div key={code} aria-hidden className="languages" onClick={() => handleSelectedLanguage(code)}>
            <div className="language-wrap">
              <span className="language-text">{code.toUpperCase()}</span>
            </div>
          </div>
        ))}
    </div>
  );
};

export default LanguageLists;
