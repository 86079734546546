// eslint-disable-next-line import/no-extraneous-dependencies
import L from 'leaflet';
import { FC, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Marker, TileLayer } from 'react-leaflet';

import { IB2CExperience } from '@app/native/src/interfaces/experience';

import { TProductInstance } from '@lib/core/products/types';
import { TRetailerLocationStoreType } from '@lib/core/retailers/types';
import { IFeedbackData, TFeedback } from '@lib/core/users/slices/feedbacks';
import { feedbackFilter, isProductInWishlistFilter } from '@lib/core/users/utils/filters';
import MixpanelTracker from '@lib/tools/dat/mixpanel';
import { MP_POSITION_CONTEXT } from '@lib/tools/dat/mixpanel/consts';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import { localeWidget } from '@lib/tools/locale/source/web/widget';
import { LocaleUtils } from '@lib/tools/locale/utils';
import {
  PRODUCT_CARD_VARIANTS,
  PRODUCT_CATEGORY_BEER,
  PRODUCT_CATEGORY_COFFEE,
  PRODUCT_CATEGORY_WINE,
} from '@lib/tools/shared/helpers/consts';
import { parseTasteMatchLevel } from '@lib/tools/tasteMatch';

import addressIcon from '@components/web/src/assets/icons/explore/address_icon.svg';
import exploreBeerIcon from '@components/web/src/assets/icons/explore/explore_beer_icon.svg';
import exploreCoffeeIcon from '@components/web/src/assets/icons/explore/explore_coffee_icon.svg';
import exploreWineIcon from '@components/web/src/assets/icons/explore/explore_wine_icon.svg';
import mailIcon from '@components/web/src/assets/icons/explore/mail_icon.svg';
import phoneIcon from '@components/web/src/assets/icons/explore/phone_icon.svg';
import redirectionIcon from '@components/web/src/assets/icons/explore/redirection_icon.svg';
import siteIcon from '@components/web/src/assets/icons/explore/site_icon.svg';
import TasteMatchButton from '@components/web/src/atoms/TasteMatchButton/TasteMatchButton';
import TooltipComponent from '@components/web/src/atoms/Tooltips/Tooltip';
import SwiperHOC from '@components/web/src/components/Swiper/SwiperHOC';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Text } from '@components/web/src/foundations/Text/Text';
import ExperienceCard from '@components/web/src/organisms/Cards/ExperienceCard/ExperienceCard';
import ProductCard from '@components/web/src/organisms/Cards/ProductCard/ProductCard';
import * as S from '@components/web/src/templates/ExplorePage/styles';
import { IDiscoveryQuiz } from '@components/web/src/templates/Modals/DiscoveryQuizModal/DiscoveryQuizModal';
import RedirectFooter from '@components/web/src/templates/TasteId/TasteIdPage/RedirectFooter/RedirectFooter';

// eslint-disable-next-line import/no-extraneous-dependencies
import 'leaflet/dist/leaflet.css';

interface IProps {
  retailerLocationAddress: string;
  retailerLocationCoordinates: [number, number] | null;
  retailerLocationImage: string;
  retailerLocationId: string;
  retailerLocationPhone: string;
  retailerLocationWebsite: string;
  retailerLocationEmail: string;
  retailerLocationName: string;
  retailerLocationLogo: string;
  retailerLocationDescription: string;
  retailerLocationSlug: string;
  retailerLocationTasteMatchValue: number | null;
  productCategory: string;
  productInstanceData: TProductInstance;
  locale: string;
  storeType: TRetailerLocationStoreType;
  isUserHasAnyCharacter: boolean;
  isUserAuthenticated: boolean;
  wishlistProductIds: string[];
  feedbackData: IFeedbackData[];
  discoveryQuiz: IDiscoveryQuiz;
  experienceData: IB2CExperience[];
  shouldShowRedirectionIcon?: boolean;
  handlePopupClick: () => void;
  navigateToCatalogPage: () => void;
  navigateToQuizSelectionPage: () => void;
  handleRedirectBannerClick: (link: string, params?: string) => void;
  handleUpdateWishlistProductList: (productId: string) => void;
  handleUpdateFeedback: (feedback: TFeedback, productId: string) => void;
}

export const ExplorePage: FC<IProps> = ({
  retailerLocationAddress = '',
  retailerLocationCoordinates,
  retailerLocationImage = '',
  retailerLocationPhone = '',
  retailerLocationWebsite = '',
  retailerLocationEmail = '',
  retailerLocationName = '',
  retailerLocationLogo = '',
  retailerLocationDescription = '',
  retailerLocationId,
  retailerLocationSlug,
  retailerLocationTasteMatchValue,
  productCategory,
  productInstanceData,
  locale,
  storeType,
  isUserHasAnyCharacter,
  isUserAuthenticated,
  wishlistProductIds = [],
  feedbackData = [],
  discoveryQuiz,
  experienceData = [],
  shouldShowRedirectionIcon = false,
  handlePopupClick,
  navigateToCatalogPage,
  handleRedirectBannerClick,
  navigateToQuizSelectionPage,
  handleUpdateWishlistProductList,
  handleUpdateFeedback,
}) => {
  const { titleText, experiencesText } = localeWidget.explorePage;
  const { productCategoriesPlural, productCategories, productDetails } = localeCommon;
  const { publishedTerms } = LocaleUtils;
  const productCategoryPluralText = publishedTerms[productCategoriesPlural[productCategory].id];

  const [isTooltipVisible, setTooltipVisible] = useState(true);

  const contactData = [
    retailerLocationWebsite && {
      href: retailerLocationWebsite,
      icon: siteIcon,
      isSiteContact: true,
      text: retailerLocationWebsite,
    },
    retailerLocationPhone && {
      href: `tel:${retailerLocationPhone}`,
      icon: phoneIcon,
      text: retailerLocationPhone,
    },
    retailerLocationEmail && {
      href: `mailto:${retailerLocationEmail}`,
      icon: mailIcon,
      text: retailerLocationEmail,
    },
    retailerLocationAddress && {
      href: undefined,
      icon: addressIcon,
      isAddressContact: true,
      text: retailerLocationAddress,
    },
  ].filter(Boolean);

  const customMarkerIcon = L.icon({
    iconAnchor: [16, 0],
    iconSize: [32, 32],
    iconUrl: {
      [PRODUCT_CATEGORY_BEER]: exploreBeerIcon,
      [PRODUCT_CATEGORY_COFFEE]: exploreCoffeeIcon,
      [PRODUCT_CATEGORY_WINE]: exploreWineIcon,
    }[productCategory],
    popupAnchor: [0, 3],
  });

  return (
    <S.ExplorePageContainer data-testid="ExplorePage">
      {retailerLocationCoordinates && (
        <S.CustomMapContainer
          attributionControl={false}
          center={retailerLocationCoordinates}
          scrollWheelZoom={false}
          zoom={12}
          zoomControl={false}
        >
          <S.MapContainerShadow />
          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
          <Marker icon={customMarkerIcon} position={retailerLocationCoordinates}>
            {retailerLocationAddress && (
              <S.CustomPopup closeButton={false}>
                <div role="button" tabIndex={0} onClick={handlePopupClick} onKeyDown={handlePopupClick}>
                  <Text color={STATIC_COLORS.base.white} size="body2" text={retailerLocationAddress} weight="medium" />
                  {shouldShowRedirectionIcon && <img alt="redirection icon" src={redirectionIcon} />}
                </div>
              </S.CustomPopup>
            )}
          </Marker>
        </S.CustomMapContainer>
      )}
      <S.ContentWrapper data-testid="ContentWrapper">
        <S.InfoSection data-testid="InfoSection">
          {retailerLocationLogo && <img alt="retailer logo" src={retailerLocationLogo} />}
          {retailerLocationDescription && (
            <Text color={STATIC_COLORS.base.black} size="body1" text={retailerLocationDescription} />
          )}
        </S.InfoSection>
        {productInstanceData && (
          <TooltipComponent
            isAlwaysOpen={isTooltipVisible}
            placement="top-end"
            title={productDetails.tasteMatchTooltip}
            trigger={
              <TasteMatchButton
                isLocationTasteMatchEvent
                hideTooltip={() => setTooltipVisible(false)}
                navigateToQuizSelectionPage={navigateToQuizSelectionPage}
                retailerLocationId={retailerLocationId}
                retailerLocationName={retailerLocationName}
                retailerLocationSlug={retailerLocationSlug}
                tasteMatchLevel={parseTasteMatchLevel(retailerLocationTasteMatchValue, isUserHasAnyCharacter)}
                tasteMatchValue={retailerLocationTasteMatchValue}
                variant="catalog"
              />
            }
          />
        )}
        <S.ContactSection data-testid="ContactSection">
          {retailerLocationImage && <S.LocationImage $image={retailerLocationImage} />}
          <Flexbox direction="column" gap={8}>
            {contactData.map(({ icon, text, isSiteContact = false, isAddressContact = false, href }, idx) => (
              <S.Contact key={idx} $isAddressContact={isAddressContact}>
                <img alt="contact link" src={icon} />
                <a href={href} {...(isSiteContact && { target: '_blank' })}>
                  <Text color={STATIC_COLORS.base.black} size="body2" text={text} />
                </a>
              </S.Contact>
            ))}
          </Flexbox>
        </S.ContactSection>
        {productInstanceData && (
          <S.ProductSection data-testid="ProductSection">
            <Text
              color={STATIC_COLORS.base.black}
              fontFamily="Fraunces"
              localeIndex={{ productCategory }}
              localeVariables={{ productCategoryText: productCategories[productCategory] }}
              size="h5"
              text={titleText}
              weight="semibold"
            />
            <ProductCard
              isResponsive
              discoveryQuiz={discoveryQuiz}
              feedback={feedbackFilter(feedbackData, productInstanceData)}
              handleUpdateFeedback={handleUpdateFeedback}
              handleUpdateWishlistProductList={handleUpdateWishlistProductList}
              isFeedbackEnabled={isUserAuthenticated}
              isLoading={false}
              isProductInWishlist={isProductInWishlistFilter(wishlistProductIds, productInstanceData)}
              isWishlistEnabled={isUserAuthenticated}
              locale={locale}
              mixpanelIndex={0}
              mixpanelPositionContext={MP_POSITION_CONTEXT.SWIPER}
              productInstanceData={productInstanceData}
              storeType={storeType}
              variant={PRODUCT_CARD_VARIANTS.COMPACT}
            />
          </S.ProductSection>
        )}
        <S.Button
          size="md"
          text={`${retailerLocationName} ${productCategoryPluralText}`}
          handleClick={() => {
            MixpanelTracker.events.seeAllProducts();
            navigateToCatalogPage();
          }}
          localeOptions={{
            productCategory,
            productCategoryPluralText,
            retailerName: retailerLocationName,
          }}
        />
      </S.ContentWrapper>
      {!!experienceData.length && (
        <S.ExperienceSection $isSingleCard={experienceData.length === 1} data-testid="ExperienceSection">
          <Text
            color={STATIC_COLORS.base.black}
            fontFamily="Fraunces"
            size="h5"
            text={experiencesText}
            weight="semibold"
          />
          {experienceData.length === 1 ? (
            <ExperienceCard key="1" isResponsive experienceData={experienceData[0]} isVisibleBadges={false} />
          ) : (
            <SwiperHOC spaceBetween={20}>
              {experienceData.map((experience, idx) => (
                <ExperienceCard key={idx} experienceData={experience} isVisibleBadges={false} />
              ))}
            </SwiperHOC>
          )}
        </S.ExperienceSection>
      )}
      <RedirectFooter handleNavigateClick={handleRedirectBannerClick} />
    </S.ExplorePageContainer>
  );
};
