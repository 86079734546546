import { FC } from 'react';

import { RegisterPageScenarios } from '@lib/core/service/slices/technical/registerPageScenario';
import { ILocaleText } from '@lib/tools/locale/views/LocaleFragment';

import RegisterExtraInfoSwiper, {
  TExtraInfoSwiperVariant,
  TSwiperData,
} from '@components/web/src/organisms/ExtraInfoSwiper/ExtraInfoSwiper';
import RegisterDetailsBanner, {
  TRegisterDetailsBannerLocale,
} from '@components/web/src/templates/Login&Registration/Registration/RegisterDetailsBanner/RegisterDetailsBanner';
import * as S from '@components/web/src/templates/Login&Registration/Registration/ResultAuthBanners/styles';

interface IProps {
  swiperData?: TSwiperData[];
  scenario?: RegisterPageScenarios;
  title?: ILocaleText;
  description?: ILocaleText;
  showAnimation?: boolean;
  extraDescription?: ILocaleText;
  locale?: TRegisterDetailsBannerLocale;
}

const ResultAuthBanners: FC<IProps> = ({
  swiperData = [],
  scenario,
  showAnimation,
  description,
  extraDescription,
  title,
  locale,
}) => {
  const isSwiperVariant = [RegisterPageScenarios.CharacterClick, RegisterPageScenarios.AdjectiveClick].includes(
    scenario,
  );

  const isWishlistVariant = scenario === RegisterPageScenarios.WishlistClick;

  return (
    <S.ResultAuthBannersWrapper
      data-testid={`ResultAuthBannersWrapper-${scenario}-isQuiz-${!isWishlistVariant}-isWishlist-${isWishlistVariant}`}
      direction="column"
      gap={0}
    >
      {isSwiperVariant ? (
        !!swiperData.length && (
          <RegisterExtraInfoSwiper swiperData={swiperData} variant={scenario as TExtraInfoSwiperVariant} />
        )
      ) : (
        <RegisterDetailsBanner
          animation={showAnimation}
          description={description}
          extraDescription={extraDescription}
          icon={isWishlistVariant}
          locale={locale}
          title={title}
        />
      )}
    </S.ResultAuthBannersWrapper>
  );
};

export default ResultAuthBanners;
