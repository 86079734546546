import { FC, useEffect, useState } from 'react';

import { TProductCategory, TProductInstance } from '@lib/core/products/types';
import { TRetailerLocationStoreType } from '@lib/core/retailers/types';
import { IFeedbackData, TFeedback } from '@lib/core/users/slices/feedbacks';
import { localeWidget } from '@lib/tools/locale/source/web/widget';
import { PRODUCT_CATEGORY_WINE } from '@lib/tools/shared/helpers/consts';
import { IHandleProductsRequestParams } from '@lib/tools/shared/helpers/interfaces';

import ProductListComponent from '@components/web/src/components/Catalog/ProductList/ProductList';
import GenericSpinner from '@components/web/src/components/GenericSpinner/GenericSpinner';
import { STATIC_COLORS } from '@components/web/src/foundations';
import NoProductsAvailable from '@components/web/src/organisms/Catalog/NoProductsAvailable/NoProductsAvailable';
import * as S from '@components/web/src/pages/widget/Catalog/styles';
import { IDiscoveryQuiz } from '@components/web/src/templates/Modals/DiscoveryQuizModal/DiscoveryQuizModal';
import SeeAllProductsButton from '@components/web/src/widget/Buttons/SeeAllProductsButton';
import MatchedProducts from '@components/web/src/widget/Catalog/MatchedProducts';

export interface ILimitedCatalogProps {
  products: TProductInstance[];
  matchedProducts: TProductInstance[];
  catalogType: string;
  isProductsLoaded: boolean;
  isProductsRequestLoading?: boolean;
  productCategory: TProductCategory;
  storeType: TRetailerLocationStoreType;
  locale: string;
  isWishlistEnabled: boolean;
  isFeedbackEnabled?: boolean;
  isEnableLocationMapAddon?: boolean;
  isEnableVusionAddon?: boolean;
  isShowProductLocationAddon?: boolean;
  feedbackData?: IFeedbackData[];
  wishlistProductIds: string[];
  isProductListLoading?: boolean;
  discoveryQuiz: IDiscoveryQuiz;
  handleProductsRequest: (args: IHandleProductsRequestParams) => void;
  handleUpdateWishlistProductList?: (productId: string) => void;
  handleUpdateFeedback: (feedback: TFeedback, productId: string) => void;
}

const LimitedCatalog: FC<ILimitedCatalogProps> = ({
  products,
  isProductsLoaded,
  matchedProducts,
  catalogType,
  productCategory = PRODUCT_CATEGORY_WINE,
  storeType,
  locale,
  isWishlistEnabled,
  isFeedbackEnabled,
  isEnableLocationMapAddon,
  isEnableVusionAddon,
  isShowProductLocationAddon,
  feedbackData = [],
  wishlistProductIds,
  isProductListLoading,
  discoveryQuiz,
  handleProductsRequest,
  handleUpdateWishlistProductList,
  handleUpdateFeedback,
}) => {
  const [isProductsListOpened, setIsProductsListOpened] = useState(true);

  const handleToggleProductsList = () => {
    setIsProductsListOpened(!isProductsListOpened);
  };

  useEffect(() => {
    if (isProductsLoaded && matchedProducts.length) {
      setIsProductsListOpened(false);
    } else {
      setIsProductsListOpened(true);
    }
  }, [isProductsLoaded, matchedProducts]);

  const { productCatalog: productCatalogMessagesWidget } = localeWidget;

  const isMatchedProductsAvailable = !!matchedProducts.length;
  const isProductsAvailable = !!products.length;
  const isBoldProductsListHeadText = !isMatchedProductsAvailable;
  const noProductsAvailable = !isProductsAvailable && !isMatchedProductsAvailable && isProductsLoaded;

  const extraPaddingBottom = isProductsListOpened && matchedProducts.length > 0;

  return (
    <S.ProductCatalogWrapper $isWithExtraPadding={extraPaddingBottom} data-testid="LimitedCatalog">
      {!isProductsLoaded && <GenericSpinner />}
      {noProductsAvailable && <NoProductsAvailable />}

      <>
        {isMatchedProductsAvailable && (
          <MatchedProducts
            catalogType={catalogType}
            discoveryQuiz={discoveryQuiz}
            feedbackData={feedbackData}
            handleProductsRequest={handleProductsRequest}
            handleUpdateFeedback={handleUpdateFeedback}
            handleUpdateWishlistProductList={handleUpdateWishlistProductList}
            isEnableLocationMapAddon={isEnableLocationMapAddon}
            isEnableVusionAddon={isEnableVusionAddon}
            isFeedbackEnabled={isFeedbackEnabled}
            isProductListLoading={isProductListLoading}
            isProductsLoaded={isProductsLoaded}
            isProductsRequestLoading={!isProductsLoaded}
            isShowProductLocationAddon={isShowProductLocationAddon}
            isWishlistEnabled={isWishlistEnabled}
            locale={locale}
            matchedProducts={matchedProducts}
            productCategory={productCategory}
            storeType={storeType}
            wishlistProductIds={wishlistProductIds}
          />
        )}
        {isMatchedProductsAvailable && isProductsAvailable && (
          <SeeAllProductsButton handleClick={handleToggleProductsList} isOpenedState={isProductsListOpened} />
        )}
        {isProductsListOpened && isProductsAvailable && (
          <>
            <S.StyledText
              $isBoldProductsListHeadText={isBoldProductsListHeadText}
              color={STATIC_COLORS.base.black}
              fontFamily={isBoldProductsListHeadText ? 'Fraunces' : 'Montserrat'}
              localeIndex={{ productCategory }}
              size={isBoldProductsListHeadText ? 'h5' : 'body1'}
              text={productCatalogMessagesWidget.allProductsByProximity}
              weight="semibold"
            />

            <ProductListComponent
              disablePagination
              discoveryQuiz={discoveryQuiz}
              feedbackData={feedbackData}
              handleProductsRequest={handleProductsRequest}
              handleUpdateFeedback={handleUpdateFeedback}
              handleUpdateWishlistProductList={handleUpdateWishlistProductList}
              isEnableLocationMapAddon={isEnableLocationMapAddon}
              isEnableVusionAddon={isEnableVusionAddon}
              isFeedbackEnabled={isFeedbackEnabled}
              isProductListLoading={isProductListLoading}
              isProductsRequestLoading={!isProductsLoaded}
              isShowProductLocationAddon={isShowProductLocationAddon}
              isWishlistEnabled={isWishlistEnabled}
              itemsCount={products.length}
              locale={locale}
              products={products}
              storeType={storeType}
              wishlistProductIds={wishlistProductIds}
            />
          </>
        )}
      </>
    </S.ProductCatalogWrapper>
  );
};

export default LimitedCatalog;
