import { css, styled } from 'styled-components';

import { SCREEN_SIZE, STATIC_COLORS, STATIC_GRADIENTS, STATIC_SHADOWS } from '@components/web/src/foundations';
import { ROOT_ELEMENT_KIOSK } from '@components/web/src/foundations/consts';
import { Text } from '@components/web/src/foundations/Text/Text';

export const EventsModalContainer = styled.div<{ $modalType?: string }>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 38px 16px 24px;
  background: ${STATIC_GRADIENTS.warmGradient()};
  background-color: ${STATIC_COLORS.base.white};
  border-radius: 16px;
  box-shadow: 0 10px 20px rgb(0 0 0 / 30%);
  box-shadow: ${STATIC_SHADOWS.xl};

  ${({ $modalType, theme }) => {
    if ($modalType === 'findMeModal') {
      return css`
        padding: 0;
        margin: 0 auto;
        ${theme?.rootElementType === ROOT_ELEMENT_KIOSK && `max-width: 808px!important;`}
      `;
    }

    if ($modalType === 'locationMapModal' && theme?.rootElementType === ROOT_ELEMENT_KIOSK) {
      return css`
        top: -10% !important;
      `;
    }
    return css``;
  }}

  .modal-close {
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
  }

  .product-location-map {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 253px;
      margin: 4px 42px 0;

      @media screen and (min-width: ${SCREEN_SIZE.medium}) {
        width: 365px;
        margin: 0 120.12px 0 120.13px;
      }

      ${({ theme }) =>
        theme?.rootElementType === ROOT_ELEMENT_KIOSK &&
        css`
          width: 562px;
        `}
    }
  }

  .qrcode {
    width: 200px;
    height: 200px;
    margin: 32px auto 0;
  }

  .modal-close-btn {
    margin-top: 24px;
  }

  .content-wrapper {
    width: 100%;

    .btn-wrapper {
      width: 100%;
      padding: 24px 10px 16px;

      .find-it-btn {
        width: 110px;
        padding: 10px 14px;
        margin: 0 auto;
      }
    }
  }

  .modal-icon {
    margin: 0 auto;
    margin-bottom: 4px;

    &.location-icon {
      width: 80px;
      height: 80px;
      margin-bottom: 12px;

      ${({ theme }) =>
        theme?.rootElementType === ROOT_ELEMENT_KIOSK &&
        css`
          width: 120px;
          height: 120px;
        `}
    }

    &.bottles-icon {
      margin-bottom: 32px;
    }
  }
`;

export const CreditTitleText = styled(Text)`
  width: 100%;
  margin-bottom: 8px;
  text-align: left;
`;

export const TitleText = styled(Text)<{ $isLeftAlignedGreen?: boolean; $noMargin?: boolean }>`
  margin-bottom: 8px;
  color: ${STATIC_COLORS.base.black};
  text-align: center;

  ${({ $isLeftAlignedGreen }) =>
    $isLeftAlignedGreen &&
    css`
      width: 100%;
      margin-bottom: 4px;
      color: ${STATIC_COLORS.green['600']};
      text-align: left;
    `}

  ${({ $noMargin }) =>
    $noMargin &&
    css`
      margin-bottom: 0;
    `}
`;

export const DescriptionText = styled(Text)<{ $isLeftAlignedDescription?: boolean; $isLocationMap?: boolean }>`
  width: 100%;
  color: ${STATIC_COLORS.gray['900']};
  text-align: center;

  ${({ $isLeftAlignedDescription }) =>
    $isLeftAlignedDescription &&
    css`
      text-align: left;
    `}

  ${({ $isLocationMap }) =>
    $isLocationMap &&
    css`
      margin-bottom: 8px;
    `}
`;
export const ContentHeaderSection = styled.div`
  position: static !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 16px;
  background-color: #f39c9c;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;

  .icon {
    margin: 10px 24px 0 0;

    svg {
      width: 120px;
      height: 116px;
    }
  }
`;

export const ContentBodySection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 16px 16px 0;
`;

export const BodyTextTitle = styled(Text)`
  margin: 0;
  color: ${STATIC_COLORS.base.black};
  text-align: center;
`;
