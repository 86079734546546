import cn from 'classnames';
import { FC, useState } from 'react';

import { localeApp } from '@lib/tools/locale/source/web/app';
import { VH_VARIANTS } from '@lib/tools/shared/helpers/consts';

import { IModalOption, IToggleOptionChange } from '@components/web/src/app/Modals/modalTypes';
import Button from '@components/web/src/atoms/Buttons/Button';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Text } from '@components/web/src/foundations/Text/Text';
import AppModalHeader from '@components/web/src/templates/Modals/AppModalHeader/AppModalHeader';
import ModalWrapper from '@components/web/src/templates/Modals/ModalWrapper/ModalWrapper';
import RenderModalOptions from '@components/web/src/templates/Modals/RenderModalOptions/RenderModalOptions';

type Props = {
  isModalOpen: boolean;
  hideModal: () => void;
  options: IModalOption;
  handleConfirmButton?: (values: Record<string, boolean>) => void;
  shouldOpenModalsUnderHeader?: boolean;
};

const RegistrationProfilingModal: FC<Props> = ({
  isModalOpen,
  hideModal,
  options,
  handleConfirmButton,
  shouldOpenModalsUnderHeader,
}) => {
  const [toggleOptionsState, setToggleOptionsState] = useState(options);

  const handleOnContinue = () => {
    let finalSelectedValues = {};
    Object.keys(toggleOptionsState).forEach(option => {
      finalSelectedValues = {
        ...finalSelectedValues,
        [option]: toggleOptionsState[option].isEnabled,
      };
    });

    handleConfirmButton(finalSelectedValues);
  };

  const handleOnToggle = ({ optionName }: IToggleOptionChange) => {
    const currentSelectedObjectState = { ...toggleOptionsState };

    const findSelectedObject = currentSelectedObjectState[optionName];
    const finalSelectedObject = {
      ...findSelectedObject,
      isEnabled: !findSelectedObject.isEnabled,
    };
    currentSelectedObjectState[optionName] = finalSelectedObject;

    setToggleOptionsState(currentSelectedObjectState);
  };
  const closeModal = () => {
    setToggleOptionsState(options);
    hideModal();
  };

  const {
    registrationProfilingModal: { headerTitle, btnConfirm, cancelBtn },
  } = localeApp;

  return (
    <ModalWrapper
      hideModal={closeModal}
      isOpen={isModalOpen}
      modalClassName={cn('registration-profiling-modal', { 'under-header': shouldOpenModalsUnderHeader })}
    >
      <AppModalHeader onCrossClick={closeModal} />
      <div className="registration-profiling-container">
        <Text color={STATIC_COLORS.base.black} fontFamily="Fraunces" size="h5" text={headerTitle} />
        <div className="registration-profiling-options">
          <RenderModalOptions flexGap={32} optionType="cookie" options={toggleOptionsState} onToggle={handleOnToggle} />
        </div>
        <div className="registration-profiling-actions">
          <Button
            className="block-btn"
            handleClick={closeModal}
            size="sm"
            text={cancelBtn}
            variant={VH_VARIANTS.LIGHT}
          />
          <Button className="block-btn" handleClick={handleOnContinue} size="sm" text={btnConfirm} />
        </div>
      </div>
    </ModalWrapper>
  );
};

export default RegistrationProfilingModal;
