import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useDeepCompareEffect from 'use-deep-compare-effect';

import { charactersByProductCategory } from '@lib/core/characters/utils';
import { useUserQuiz } from '@lib/core/quizzes/hooks';
import { useRetailer } from '@lib/core/retailers/hooks/retailer';
import { useRetailerLocation } from '@lib/core/retailers/hooks/retailerLocation';
import {
  B2C_USER_PRODUCT_PREFERENCES_TAGS_DATA,
  DESIGN_SET_VINHOOD_APP,
  PRODUCT_CATEGORY_NONE,
  isApplicationKiosk,
} from '@lib/core/service/consts';
import { useApp } from '@lib/core/service/hooks';
import { selectAppAgreements, selectIsClientIpBlocked } from '@lib/core/service/selectors/technical';
import { useUser } from '@lib/core/users/hooks';
import { selectFeedbackData } from '@lib/core/users/selectors/feedback';
import { selectProductListLoading, selectProductListWishlistProductIds } from '@lib/core/users/selectors/productList';
import { selectUserAgreementsArray } from '@lib/core/users/selectors/user';
import { selectIsWishlistLoading, selectWishlistProductIds } from '@lib/core/users/selectors/wishlist';
import initTrackers from '@lib/tools/dat';
import MixpanelTracker, { MixpanelExtras, useDataChangedEffect } from '@lib/tools/dat/mixpanel';
import { USER_PRODUCT_PREFERENCES_FOOD_DATA } from '@lib/tools/shared/helpers/consts';
import { useEffectSkipFirst, useTypedSelector } from '@lib/tools/views/hooks';

/**
 * This HOC can be used to set listeners on required keys in the store.
 */
const DatProvider = ({ children }) => {
  const {
    userCountry: countryCode,
    userCharacters,
    userLastName,
    userFirstName,
    userEmail: email,
    userGroups,
    userId,
    mixpanelUserDidIdentify: mixPanelDidIdentify,
    isUserAuthenticated,
  } = useUser();
  const { productCategory } = useApp();
  const { retailerId } = useRetailer();
  const { retailerDesign } = useRetailerLocation();
  const foodPreferences = useTypedSelector(state => state.productPreferences?.[USER_PRODUCT_PREFERENCES_FOOD_DATA]);
  const isBlackListUserIp = useSelector(selectIsClientIpBlocked);
  const isLoggingEnabled = useTypedSelector(state => state.tracking?.isLoggingEnabled);
  const mixPanelState = useTypedSelector(state => state.tracking?.mixPanelState);
  const anonUserAgreements = useSelector(selectAppAgreements);
  const userAgreements = useSelector(selectUserAgreementsArray);
  const feedbackData = useSelector(selectFeedbackData);

  const isWishlistLoading = useSelector(isApplicationKiosk ? selectProductListLoading : selectIsWishlistLoading);
  const wishlistIds = useSelector(isApplicationKiosk ? selectProductListWishlistProductIds : selectWishlistProductIds);

  const tagsPreferences = useTypedSelector(state => state.productPreferences?.[B2C_USER_PRODUCT_PREFERENCES_TAGS_DATA]);

  const { userQuizType, isUserQuizComplete, userQuizState } = useUserQuiz();

  const shouldTrack = useCallback(
    /**
     * Determines whether a tracking method should be invoked based on 3 factors:
     * - Mixpanel library availability
     * - User identification status
     * - Whether the method should trigger even if the user is not identified
     *
     * @param setSuperPropsIfAnonymous - Whether the tracking method should set super properties regardless of the
     * user's identification status.
     */
    (setSuperPropsIfAnonymous: boolean) => {
      return !!(mixPanelState && (mixPanelDidIdentify || setSuperPropsIfAnonymous));
    },
    [mixPanelState, mixPanelDidIdentify],
  );

  // Store the return value of shouldTrack in a state variable
  const [trackIfAnonymous, setTrackIfAnonymous] = useState(shouldTrack(true));
  const [trackIfIdentified, setTrackIfIdentified] = useState(shouldTrack(false));
  // const wishlistDataChanged = useRef(false);

  useEffect(() => {
    if (!retailerId || (productCategory === PRODUCT_CATEGORY_NONE && retailerDesign !== DESIGN_SET_VINHOOD_APP)) return;
    initTrackers();
  }, [retailerId, productCategory]);

  useEffectSkipFirst(() => {
    if (userQuizType) MixpanelTracker.events.testStarted(userQuizState.data);
  }, [userQuizType]);

  useEffectSkipFirst(() => {
    if (isUserQuizComplete) MixpanelTracker.events.testCompleted(userQuizState.data);
  }, [isUserQuizComplete]);

  useEffect(() => {
    // Update the state variables whenever shouldTrack's return value changes
    setTrackIfAnonymous(shouldTrack(true));
    setTrackIfIdentified(shouldTrack(false));
  }, [shouldTrack]);

  useEffect(() => {
    // Handle mixpanel if user logs out from the app
    if (mixPanelState && !isUserAuthenticated) {
      MixpanelTracker.events.signOut();
    }
  }, [mixPanelState, isUserAuthenticated]);

  useEffect(() => {
    // Handle mixpanel identification of the user when they authenticate or
    // start a new session when already authenticated
    if (mixPanelState && isUserAuthenticated && userId) {
      MixpanelTracker.profile.handleUserIdentity(userId);
    }
  }, [mixPanelState, isUserAuthenticated, userId]);

  useEffect(() => {
    // Anonymous user made a choice for profiling option
    if (trackIfAnonymous) {
      MixpanelTracker.profile.updateAnonymousProfilingOption(anonUserAgreements);
    }
  }, [trackIfAnonymous, anonUserAgreements]);

  useEffect(() => {
    // User privacy settings have been updated
    if (trackIfIdentified) {
      MixpanelTracker.profile.updateUserPrivacyOptions(userAgreements);
    }
  }, [trackIfIdentified, userAgreements]);

  useEffect(() => {
    // User personal info or privacy settings have been updated
    if (trackIfIdentified) {
      MixpanelTracker.profile.updateUserPersonalInfo(email, userFirstName, userLastName, countryCode);
    }
  }, [trackIfIdentified, userAgreements, email, userFirstName, userLastName, countryCode]);

  useDeepCompareEffect(() => {
    // User characters have been updated
    if (trackIfAnonymous) {
      MixpanelTracker.profile.updateUserCharacters(charactersByProductCategory(userCharacters));
    }
  }, [trackIfAnonymous, mixPanelDidIdentify, userCharacters]);

  useDeepCompareEffect(() => {
    // User preferences have been updated
    if (trackIfIdentified) {
      MixpanelTracker.profile.updateUserPreferences(foodPreferences, tagsPreferences);
    }
  }, [trackIfIdentified, foodPreferences, tagsPreferences]);

  // User product wishlist has been updated
  useDataChangedEffect({
    data: wishlistIds,
    deps: [trackIfIdentified, wishlistIds, isWishlistLoading],
    effect: () => MixpanelTracker.profile.updateUserProductWishlist(wishlistIds),
    trackCondition: trackIfIdentified && !isWishlistLoading,
  });

  useDeepCompareEffect(() => {
    // User product feedback list has been updated
    if (trackIfIdentified) {
      MixpanelTracker.profile.updateUserProductFeedback(feedbackData);
    }
  }, [trackIfIdentified, feedbackData]);

  useEffect(() => {
    // Enable or disable tracking based on user IP, DevTools state or user group
    if (mixPanelState) {
      MixpanelExtras.switchTrackingOnOff(isBlackListUserIp || isLoggingEnabled, userGroups);
    }
  }, [mixPanelState, isBlackListUserIp, userGroups, isLoggingEnabled]);

  return children;
};

export default DatProvider;
