import styled from 'styled-components';

export const ModalOptionContainer = styled.div`
  width: 100%;
  padding: 8px 0;
`;

export const ToggleWrapper = styled.div`
  margin: 0 0 8px auto;
`;
