import { useMemo } from 'react';

import { useCharacters } from '@lib/core/characters/hooks';
import { TCharacter } from '@lib/core/characters/types';
import { isCharacterByIdentifiers } from '@lib/core/characters/utils/filters';
import { useQuizzes } from '@lib/core/quizzes/hooks';
import QuizUtils from '@lib/core/quizzes/utils';
import { QUIZ_TYPE_RECIPE } from '@lib/core/quizzes/utils/consts';
import { useRetailerLocation } from '@lib/core/retailers/hooks/retailerLocation';
import { PRODUCT_CATEGORY_NONE } from '@lib/core/service/consts';
import { useApp } from '@lib/core/service/hooks';
import { useUser } from '@lib/core/users/hooks';
import { useAddons } from '@lib/tools/views/hooks';

import {
  HOME_PAGE_VARIANTS,
  THomePageVariants,
} from '@components/web/src/templates/Start&HomePages/Widget/HomePage/HomePage';

export const useHomeData = () => {
  const { locale, productCategory } = useApp();
  const { isDisableRecipeCTAHomeAddon } = useAddons();
  const { retailerLocationProductCategories } = useRetailerLocation();
  const { isQuizzesFetching } = useQuizzes();
  const { characters, isCharactersLoading } = useCharacters();
  const { userCharactersIds, isFetchingUserData, isUserAuthenticated } = useUser();

  const productCategories =
    productCategory === PRODUCT_CATEGORY_NONE ? retailerLocationProductCategories : [productCategory];

  const translatedUserCharacters: Record<string, TCharacter> = useMemo(() => {
    if (!characters.length || !userCharactersIds.length || !locale) return null;

    const filteredCharacters = characters.filter(character => isCharacterByIdentifiers(character, userCharactersIds));

    return productCategories.reduce((acc, category) => {
      acc[category] = filteredCharacters.find(character => character.product_category === category);
      return acc;
    }, {});
  }, [characters, userCharactersIds, locale]);

  const pageVariants: Record<string, THomePageVariants> = useMemo(() => {
    const getPageVariant = isUserHasCharacter => {
      if (isUserAuthenticated) {
        return isUserHasCharacter ? HOME_PAGE_VARIANTS.LOGGED_WITH_CHARACTER : HOME_PAGE_VARIANTS.LOGGED;
      }

      return isUserHasCharacter ? HOME_PAGE_VARIANTS.ANON_WITH_CHARACTER : HOME_PAGE_VARIANTS.ANON;
    };

    return productCategories.reduce((acc, category) => {
      acc[category] = getPageVariant(translatedUserCharacters && translatedUserCharacters[category]);
      return acc;
    }, {});
  }, [translatedUserCharacters, isUserAuthenticated]);

  const isRecipeQuizAvailable =
    !isQuizzesFetching && !!QuizUtils.getQuiz(QUIZ_TYPE_RECIPE) && !isDisableRecipeCTAHomeAddon;

  return {
    isLoading: isFetchingUserData || isQuizzesFetching || isCharactersLoading,
    isRecipeQuizAvailable,
    pageVariants,
    productCategories,
    translatedUserCharacters,
  };
};
