import { FC, Fragment } from 'react';

import { localeCommon } from '@lib/tools/locale/source/web/common';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';
import { VH_VARIANTS } from '@lib/tools/shared/helpers/consts';

import HeaderImage from '@components/web/src/assets/legacy/b2b/widget/taste_test_expert_sketch.png';
import { ReactComponent as ImageBGMask } from '@components/web/src/assets/legacy/b2b/widget/taste_test_vector_mask.svg';
import arrowIcon from '@components/web/src/assets/legacy/icons/arrow_icon.svg';
import Button from '@components/web/src/atoms/Buttons/Button';

interface IProps {
  isDisabledBtn: boolean;
  onHandleClick: () => void;
  characterName: string;
}

const TastePathStartPage: FC<IProps> = ({ isDisabledBtn, onHandleClick, characterName }) => {
  const { tastePathStartPage } = localeCommon;

  const tastePathDiscoverHint = (
    <LocaleFragment message={tastePathStartPage.tastePathDiscoverHint} options={{ characterName }} />
  );
  const tastePathDirectionHint = (
    <LocaleFragment message={tastePathStartPage.tastePathDirectionHint} options={{ characterName }} />
  );
  const tastePathFeedbackHint = (
    <LocaleFragment message={tastePathStartPage.tastePathFeedbackHint} options={{ characterName }} />
  );

  const hintTextList = [tastePathDiscoverHint, tastePathDirectionHint, tastePathFeedbackHint];

  return (
    <div className="taste-path-start-page-container">
      <div className="header-section-wrapper">
        <p className="title">
          <LocaleFragment message={tastePathStartPage.title} />
        </p>
        <div className="img-wrapper">
          <span className="mask-container">
            <ImageBGMask />
          </span>
          <img alt="taste_test" src={HeaderImage} />
        </div>
      </div>
      <div className="content-section-wrapper">
        <p className="subtitle">
          <LocaleFragment message={tastePathStartPage.subTitle} />
        </p>
        <div className="hint-wrapper">
          {hintTextList.map((hint, index) => (
            <Fragment key={index}>
              <div className="hint">
                <p>{hint}</p>
              </div>
              {index !== hintTextList.length - 1 && <img alt="arrow" className="arrow" src={arrowIcon} />}
            </Fragment>
          ))}
        </div>
        <p className="warning-title">
          <LocaleFragment message={tastePathStartPage.footerTitle} />
        </p>
        <p className="warning-description">
          <LocaleFragment message={tastePathStartPage.footerText} />
        </p>
        <Button
          disabled={isDisabledBtn}
          size="sm"
          text={tastePathStartPage.tastePathPageButtonText}
          variant={VH_VARIANTS.PRIMARY}
          onClick={onHandleClick}
        />
      </div>
    </div>
  );
};

export default TastePathStartPage;
