import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { useRetailer } from '@lib/core/retailers/hooks/retailer';
import { selectRetailer3dPartyAgreement } from '@lib/core/retailers/selectors/retailer';
import { selectAppAgreements } from '@lib/core/service/selectors/technical';
import { actionChangeAgreements } from '@lib/core/service/slices/technical/agreements';
import { Agreements } from '@lib/core/service/slices/technical/types';
import { prependBasename } from '@lib/core/service/utils';
import { useUser } from '@lib/core/users/hooks';
import { CHARACTERS_URL_PARAM } from '@lib/tools/shared/helpers/consts';
import { useTypedDispatch } from '@lib/tools/views/hooks/useTypedDispatch';
import { PAGES } from '@lib/tools/views/urls';

import Spinner from '@components/web/src/components/GenericSpinner/GenericSpinner';
import PolicyAcceptancePage from '@components/web/src/pages/widget/PolicyAcceptancePage/PolicyAcceptancePage';

const PolicyAcceptanceContainer = () => {
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();
  const { search } = useLocation();

  const charactersStringFromUrl: string = new URLSearchParams(search).get(CHARACTERS_URL_PARAM) ?? '';
  const thirdPartyAgreementRetailerData = useSelector(selectRetailer3dPartyAgreement);
  const { isAgreementLoading } = useSelector(selectAppAgreements);
  const { isFetchingUserData } = useUser();
  const { retailerTags, retailerName, retailerPrivacyLink } = useRetailer();

  const navigateToProductsPage = () =>
    navigate(prependBasename(PAGES.vinhood.catalog, { [CHARACTERS_URL_PARAM]: charactersStringFromUrl }));

  const submitHandler = data => {
    const { userSelection } = data;
    const userSelectionAsBoolean = userSelection === 'true';
    dispatch(
      actionChangeAgreements({
        [Agreements.isUserAllowedUseThirdParty]: userSelectionAsBoolean,
      }),
    ).then(() => {
      navigateToProductsPage();
    });
  };

  return (
    <>
      {isFetchingUserData || isAgreementLoading ? (
        <Spinner />
      ) : (
        <PolicyAcceptancePage
          logo={retailerTags?.headerLogo}
          privacyOptionTextFromRetailer={thirdPartyAgreementRetailerData?.text}
          privacyUrl={retailerPrivacyLink}
          retailerName={retailerName}
          submitHandler={submitHandler}
        />
      )}
    </>
  );
};

export default PolicyAcceptanceContainer;
