import { createContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { actionChangeAgreements } from '@lib/core/service/slices/technical/agreements';
import { Agreements } from '@lib/core/service/slices/technical/types';
import { prependBasename } from '@lib/core/service/utils';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import { useTypedDispatch } from '@lib/tools/views/hooks/useTypedDispatch';
import { PAGES } from '@lib/tools/views/urls';
import Portal from '@lib/tools/views/web/components/Portal';

import OnBoardingImageTwo from '@components/web/src/assets/legacy/backgrounds/check_image.png';
import OnBoardingImageOne from '@components/web/src/assets/legacy/backgrounds/onboarding_image_one.png';
import OnBoardingImageThree from '@components/web/src/assets/legacy/backgrounds/onboarding_image_three.png';
import OnBoardingPage from '@components/web/src/pages/app/OnBoardingPage/OnBoardingPage';

export const SwiperContext = createContext(null);

export const SwiperProvider = ({ children, swiperRef }) => {
  return <SwiperContext.Provider value={swiperRef}>{children}</SwiperContext.Provider>;
};

const OnBoardingContainer = () => {
  const navigate = useNavigate();
  const dispatch = useTypedDispatch();
  const swiperRef = useRef(null);

  const {
    headingText: headingTextCardOne,
    descriptionText: descriptionTextCardOne,
    btnText: btnTextCardOne,
  } = localeCommon.onBoardingCard.onBoardingCardOne;

  const {
    headingText: headingTextCardTwo,
    descriptionText: descriptionTextCardTwo,
    btnText: btnTextCardTwo,
  } = localeCommon.onBoardingCard.onBoardingCardTwo;

  const {
    headingText: headingTextCardThree,
    descriptionText: descriptionTextCardThree,
    btnText: btnTextCardThree,
  } = localeCommon.onBoardingCard.onBoardingCardThree;

  const onBoardingInfos = [
    {
      backgroundImage: OnBoardingImageOne,
      btnText: btnTextCardOne,
      descriptionText: descriptionTextCardOne,
      titleText: headingTextCardOne,
    },
    {
      backgroundImage: OnBoardingImageTwo,
      btnText: btnTextCardTwo,
      descriptionText: descriptionTextCardTwo,
      titleText: headingTextCardTwo,
    },
    {
      backgroundImage: OnBoardingImageThree,
      btnText: btnTextCardThree,
      descriptionText: descriptionTextCardThree,
      titleText: headingTextCardThree,
    },
  ];

  const handleSkipToHomePage = () => {
    dispatch(
      actionChangeAgreements({
        [Agreements.hasRegisteredUserNotFinishedOnboarding]: false,
      }),
    ).then(() => {
      navigate(prependBasename(PAGES.vinhood.home));
    });
  };

  const handleButtonClick = (skip = false) => {
    if (skip) {
      handleSkipToHomePage();
    } else if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext(300, false);
    }
  };

  return (
    <Portal>
      <SwiperProvider swiperRef={swiperRef}>
        <OnBoardingPage handleCloseClick={handleButtonClick} onBoardingInfos={onBoardingInfos} />
      </SwiperProvider>
    </Portal>
  );
};

export default OnBoardingContainer;
