import { TProductInstance } from '@lib/core/products/types';
import { TQuizAnswerTag } from '@lib/core/quizzes/types';
import { isProductSentia } from '@lib/tools/shared/pmi/products/filters';
import { isQuizAnswerTagContextBlendBase } from '@lib/tools/shared/pmi/quizzes/filters';

export const recommendBlends = (gprls: TProductInstance[], answerTags: TQuizAnswerTag[]): TProductInstance[] => {
  const blendProductList = {};
  const recommendedProducts = [];
  const isSentiaAvailable = gprls.some(isProductSentia);

  /**
   * Group Products by blend bases. Products may belong to more than one blend-base.
   * Retain the order of the products (by character correlation) in these groups.
   */
  gprls.forEach(gprl => {
    const rawProductQuizAnswerTags = gprl.product.attributes?.quiz_answer_tag_association;
    // ! Check: Data team to fix quotes.
    if (rawProductQuizAnswerTags.length) {
      const productQuizAnswerTags = JSON.parse(rawProductQuizAnswerTags.replaceAll("'", '"'));
      const productBlends = productQuizAnswerTags.filter((tag: string) => tag.includes('blend-base-'));
      // Remove the selected product from the result SKUs
      productBlends.forEach((blend: string) => {
        const blendName = blend.replace('blend-base-', '');
        if (!blendProductList[blendName]) blendProductList[blendName] = [];
        blendProductList[blendName].push(gprl);
      });
    }
  });

  const selectedBlends = answerTags.filter(isQuizAnswerTagContextBlendBase);
  let blendsForFilter = [];

  if (selectedBlends.length) {
    const selectedBlend = selectedBlends[0].name;

    const blendBaseTobacco = 'tobacco';
    const blendBaseMenthol = 'menthol';
    const blendBaseAromatic = 'aromatic';
    const blendBaseNegation = 'no';

    switch (selectedBlend) {
      case blendBaseTobacco:
        blendsForFilter = [blendBaseTobacco];
        break;
      case blendBaseMenthol:
        blendsForFilter = [blendBaseMenthol];
        break;
      case blendBaseAromatic:
        blendsForFilter = [`${blendBaseTobacco}-${blendBaseAromatic}`, `${blendBaseMenthol}-${blendBaseAromatic}`];
        break;
      case `${blendBaseNegation}-${blendBaseAromatic}`:
        blendsForFilter = [blendBaseTobacco, blendBaseMenthol];
        break;
      case `${blendBaseNegation}-${blendBaseTobacco}`:
        blendsForFilter = [blendBaseMenthol, `${blendBaseMenthol}-${blendBaseAromatic}`];
        break;
      case `${blendBaseNegation}-${blendBaseMenthol}`:
        blendsForFilter = [blendBaseTobacco, `${blendBaseTobacco}-${blendBaseAromatic}`];
        break;
      default:
        break;
    }

    const blendOneProducts = blendProductList[blendsForFilter[0]];
    const blendTwoProducts = blendProductList[blendsForFilter[1]];

    const correlatedProductsWithoutBlends = [];
    const blendOneProductIdentifiers = [];
    const blendTwoProductIdentifiers = [];

    blendOneProducts?.forEach(blendOneProduct => blendOneProductIdentifiers.push(blendOneProduct.identifier));
    blendTwoProducts?.forEach(blendTwoProduct => blendTwoProductIdentifiers.push(blendTwoProduct.identifier));

    gprls.forEach(gprl => {
      if (
        !blendOneProductIdentifiers.includes(gprl.identifier) &&
        !blendTwoProductIdentifiers.includes(gprl.identifier)
      ) {
        correlatedProductsWithoutBlends.push(gprl);
      }
    });

    if (isSentiaAvailable) {
      const tereaOneProducts = blendOneProducts?.filter(gprl => !isProductSentia(gprl));
      const tereaTwoProducts = blendTwoProducts?.filter(gprl => !isProductSentia(gprl));
      const sentiaOneProducts = blendOneProducts?.filter(gprl => isProductSentia(gprl));
      const sentiaTwoProducts = blendTwoProducts?.filter(gprl => isProductSentia(gprl));

      if (blendsForFilter?.length === 1) {
        if (blendOneProducts?.[0]) {
          recommendedProducts.push(blendOneProducts[0]);
          if (recommendedProducts?.some(isProductSentia)) {
            if (tereaOneProducts?.length) {
              recommendedProducts.push(tereaOneProducts?.[0]);
            } else {
              recommendedProducts.push(correlatedProductsWithoutBlends.filter(gprl => !isProductSentia(gprl))?.[0]);
            }
            recommendedProducts.reverse();
          }

          if (!recommendedProducts?.some(isProductSentia)) {
            if (sentiaOneProducts?.length) {
              recommendedProducts.push(sentiaOneProducts?.[0]);
            } else {
              recommendedProducts.push(correlatedProductsWithoutBlends.filter(gprl => isProductSentia(gprl))?.[0]);
            }
          }
        }
      }

      if (blendsForFilter.length === 2) {
        if (blendOneProducts?.[0]) {
          recommendedProducts.push(blendOneProducts[0]);
          if (recommendedProducts?.some(isProductSentia)) {
            if (tereaTwoProducts?.length) {
              recommendedProducts.push(tereaTwoProducts?.[0]);
            } else {
              recommendedProducts.push(correlatedProductsWithoutBlends.filter(gprl => !isProductSentia(gprl))?.[0]);
            }
            recommendedProducts.reverse();
          }

          if (!recommendedProducts?.some(isProductSentia)) {
            if (sentiaTwoProducts?.length) {
              recommendedProducts.push(sentiaTwoProducts?.[0]);
            } else {
              recommendedProducts.push(correlatedProductsWithoutBlends.filter(gprl => isProductSentia(gprl))?.[0]);
            }
          }
        }
      }
    } else {
      if (blendOneProducts?.[0]) {
        recommendedProducts.push(blendOneProducts[0]);
      } else {
        recommendedProducts.push(correlatedProductsWithoutBlends[0]);
      }

      if (!blendTwoProducts?.length && blendOneProducts?.[1]) {
        recommendedProducts.push(blendOneProducts[1]);
      }

      if (recommendedProducts?.length === 1 && blendTwoProducts?.[0]) {
        recommendedProducts.push(blendTwoProducts[0]);
      } else if (correlatedProductsWithoutBlends[0]?.identifier !== recommendedProducts[0]?.identifier) {
        recommendedProducts.push(correlatedProductsWithoutBlends[0]);
      } else {
        recommendedProducts.push(correlatedProductsWithoutBlends[1]);
      }
    }
  }

  if (!recommendedProducts.length) recommendedProducts.push(gprls.slice(0, 2));

  return [recommendedProducts[0], recommendedProducts[1]];
};
