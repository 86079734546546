import { localeApp } from '@lib/tools/locale/source/web/app';
import { localeCommon } from '@lib/tools/locale/source/web/common';

import ProfileSectionHeader from '@components/web/src/atoms/Headers/ProfileSectionHeader/ProfileSectionHeader';
import BackButton from '@components/web/src/components/Button/BackButton/BackButton';
import { LEGAL_MODAL_TYPES } from '@components/web/src/foundations/consts';
import LegalCard from '@components/web/src/organisms/Cards/LegalCard/LegalCard';

const TasteIdLegalPage = ({ onHandleModal, handleBackBtnClick }) => {
  const {
    legalDescription,
    legalTitle,
    permissionCardButtonText,
    permissionCardDescriptionText,
    permissionCardTitle,
    privacyCardButtonText,
    privacyCardDescriptionText,
    privacyCardTitle,
    cookieCardButtonText,
    cookieCardDescriptionText,
    cookieCardTitle,
    conditionsCardButtonText,
    conditionsCardDescriptionText,
    conditionsCardTitle,
  } = localeApp.legalComponent;

  const { back } = localeCommon.commonMessages;

  const legalCardsProps = [
    {
      buttonText: permissionCardButtonText,
      cardDescription: permissionCardDescriptionText,
      cardTitle: permissionCardTitle,
      onBtnClickHandler: () => onHandleModal(LEGAL_MODAL_TYPES.PREFERENCES),
    },
    {
      buttonText: privacyCardButtonText,
      cardDescription: privacyCardDescriptionText,
      cardTitle: privacyCardTitle,
      onBtnClickHandler: () => onHandleModal(LEGAL_MODAL_TYPES.PRIVACY),
    },
    {
      buttonText: cookieCardButtonText,
      cardDescription: cookieCardDescriptionText,
      cardTitle: cookieCardTitle,
      onBtnClickHandler: () => onHandleModal(LEGAL_MODAL_TYPES.COOKIE),
    },
    {
      buttonText: conditionsCardButtonText,
      cardDescription: conditionsCardDescriptionText,
      cardTitle: conditionsCardTitle,
      onBtnClickHandler: () => onHandleModal(LEGAL_MODAL_TYPES.TERMS_AND_CONDITIONS),
    },
  ];

  return (
    <div className="legal-tab-container">
      <BackButton arrowSize="short" handleClick={handleBackBtnClick} text={back} />
      <ProfileSectionHeader description={legalDescription} title={legalTitle} />
      <div className="legal-cards-container">
        {legalCardsProps.map((cardData, idx) => (
          <LegalCard {...cardData} key={idx} />
        ))}
      </div>
    </div>
  );
};

export default TasteIdLegalPage;
