import { createSlice } from '@reduxjs/toolkit';

import {
  BANNER_POSITION_ARTICLES,
  BANNER_POSITION_CATALOG,
  BANNER_POSITION_CATALOG_BEER,
  BANNER_POSITION_CATALOG_COFFEE,
  BANNER_POSITION_CATALOG_WINE,
  BANNER_POSITION_EDUTAINMENT,
  BANNER_POSITION_EXPERIENCES,
  BANNER_POSITION_HOME,
  BANNER_POSITION_MULTI_PRODUCT_RESULT,
  BANNER_POSITION_PRODUCT_DETAILS,
  BANNER_POSITION_TIP_AND_TRICKS,
} from '@lib/core/banners/consts';
import { selectRetailerSlug } from '@lib/core/retailers/selectors/retailer';
import { selectRetailerLocationSlug } from '@lib/core/retailers/selectors/retailerLocation';
import { createTypedAsyncThunk } from '@lib/core/service/createTypedAsyncThunk';
import backendApiUrls from '@lib/core/service/requests/backend_api_urls';
import request from '@lib/core/service/requests/request';
import { createBackendRetailerLocationSlug, createBackendRetailerUrl } from '@lib/core/service/utils';

import { TBannerCard } from '@components/web/src/atoms/Banner/BannerCard';

const bannersSliceInitialState = {
  [BANNER_POSITION_ARTICLES]: [],
  [BANNER_POSITION_CATALOG]: [],
  [BANNER_POSITION_CATALOG_BEER]: [],
  [BANNER_POSITION_CATALOG_COFFEE]: [],
  [BANNER_POSITION_CATALOG_WINE]: [],
  [BANNER_POSITION_EDUTAINMENT]: [],
  [BANNER_POSITION_EXPERIENCES]: [],
  [BANNER_POSITION_HOME]: [],
  [BANNER_POSITION_MULTI_PRODUCT_RESULT]: [],
  [BANNER_POSITION_PRODUCT_DETAILS]: [],
  [BANNER_POSITION_TIP_AND_TRICKS]: [],
  bannersFetchSuccess: false,
  isLoadingBanners: false,
};

export const fetchBannersLists = createTypedAsyncThunk('b2cHome/fetchBannersLists', async (_payload, { getState }) => {
  const retailerSlugFromRedux = selectRetailerSlug(getState());
  const retailerLocationSlugFromRedux = selectRetailerLocationSlug(getState());

  const requestUrl = createBackendRetailerLocationSlug(
    createBackendRetailerUrl(backendApiUrls.apiUrlB2CBanners, retailerSlugFromRedux),
    retailerLocationSlugFromRedux,
  );

  return await request(requestUrl);
});

export const filterPageSpecificBanners = (bannerData: TBannerCard[]) => {
  const filterBannerPosition = (pagePosition: string) =>
    bannerData
      .map(banner => ({
        ...banner,
        currentPosition: banner.positions?.find(position => position.route === pagePosition),
      }))
      .filter(banner => banner.currentPosition?.route === pagePosition);

  const articlesBanners = filterBannerPosition(BANNER_POSITION_ARTICLES);
  const catalogBanners = filterBannerPosition(BANNER_POSITION_CATALOG);
  const catalogBeerBanners = filterBannerPosition(BANNER_POSITION_CATALOG_BEER);
  const catalogCoffeeBanners = filterBannerPosition(BANNER_POSITION_CATALOG_COFFEE);
  const catalogWineBanners = filterBannerPosition(BANNER_POSITION_CATALOG_WINE);
  const experienceBanners = filterBannerPosition(BANNER_POSITION_EXPERIENCES);
  const homeBanners = filterBannerPosition(BANNER_POSITION_HOME);
  const multiProductResultBanners = filterBannerPosition(BANNER_POSITION_MULTI_PRODUCT_RESULT);
  const productDetailsBanners = filterBannerPosition(BANNER_POSITION_PRODUCT_DETAILS);
  const tipAndTricksBanners = filterBannerPosition(BANNER_POSITION_TIP_AND_TRICKS);
  const edutainmentBanners = filterBannerPosition(BANNER_POSITION_EDUTAINMENT).reverse();
  return {
    articlesBanners,
    catalogBanners,
    catalogBeerBanners,
    catalogCoffeeBanners,
    catalogWineBanners,
    edutainmentBanners,
    experienceBanners,
    homeBanners,
    multiProductResultBanners,
    productDetailsBanners,
    tipAndTricksBanners,
  };
};

export const bannersB2CSlice = createSlice({
  extraReducers: builder => {
    builder.addCase(fetchBannersLists.pending, state => {
      state.isLoadingBanners = true;
    });
    builder.addCase(fetchBannersLists.fulfilled, (state, action) => {
      const {
        articlesBanners,
        catalogBanners,
        catalogBeerBanners,
        catalogCoffeeBanners,
        catalogWineBanners,
        experienceBanners,
        homeBanners,
        multiProductResultBanners,
        productDetailsBanners,
        tipAndTricksBanners,
        edutainmentBanners,
      } = filterPageSpecificBanners(action.payload);

      state[BANNER_POSITION_ARTICLES] = articlesBanners;
      state[BANNER_POSITION_CATALOG] = catalogBanners;
      state[BANNER_POSITION_CATALOG_BEER] = catalogBeerBanners;
      state[BANNER_POSITION_CATALOG_COFFEE] = catalogCoffeeBanners;
      state[BANNER_POSITION_CATALOG_WINE] = catalogWineBanners;
      state[BANNER_POSITION_EXPERIENCES] = experienceBanners;
      state[BANNER_POSITION_HOME] = homeBanners;
      state[BANNER_POSITION_MULTI_PRODUCT_RESULT] = multiProductResultBanners;
      state[BANNER_POSITION_PRODUCT_DETAILS] = productDetailsBanners;
      state[BANNER_POSITION_TIP_AND_TRICKS] = tipAndTricksBanners;
      state[BANNER_POSITION_EDUTAINMENT] = edutainmentBanners;

      state.isLoadingBanners = false;
      state.bannersFetchSuccess = true;
    });
  },
  initialState: bannersSliceInitialState,
  name: 'b2cBanners',
  reducers: {},
});

export default bannersB2CSlice.reducer;
