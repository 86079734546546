import { FC, useMemo } from 'react';

import LocaleFragment, { ILocaleText } from '@lib/tools/locale/views/LocaleFragment';

export interface IIdentityProps {
  identityWarningText: ILocaleText;
  productIdentities: { title: ILocaleText; description: ILocaleText | number }[];
}

const IdentityCard: FC<IIdentityProps> = ({ identityWarningText, productIdentities }) => {
  const filteredProductIdentities = useMemo(
    () => productIdentities.filter(details => details.description),
    [productIdentities],
  );

  return (
    <div className="identity-card-container">
      {filteredProductIdentities?.map(({ title, description }, idx) => {
        const lastIndex = filteredProductIdentities.length - 1;
        return (
          <div key={idx} className="item">
            <span className="title">
              <LocaleFragment message={title} />:{' '}
            </span>
            <span className="description">
              <LocaleFragment message={description as string} />
            </span>
            {idx === lastIndex && (
              <span className="warning">
                <LocaleFragment message={identityWarningText} />
              </span>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default IdentityCard;
