import { FC } from 'react';

import { HARDCODED_AGREEMENT_ID_VINHOOD_PARTNER } from '@lib/core/service/consts';
import { localeApp } from '@lib/tools/locale/source/web/app';

import { IModalOptionItem, IOptionType, IToggleOptionChange } from '@components/web/src/app/Modals/modalTypes';
import ToggleButton from '@components/web/src/atoms/ToggleButton/ToggleButton';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Text } from '@components/web/src/foundations/Text/Text';
import * as S from '@components/web/src/templates/Modals/ModalOptionItem/styles';

interface IProps {
  data: IModalOptionItem;
  optionName?: string;
  optionType: IOptionType;
  isManageModalVariant?: boolean;
  onToggle?: ({ optionName, optionType }: IToggleOptionChange) => void;
  hideToggleText?: boolean;
}

const ModalOptionItem: FC<IProps> = ({
  data,
  optionName,
  isManageModalVariant = false,
  hideToggleText = false,
  optionType,
  onToggle,
}) => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { is_always_active, isEnabled, description, title } = data;
  const checkToggle = is_always_active || isEnabled || false;

  const { modalOptionItem } = localeApp;

  const { toggleEnabledText } = modalOptionItem;
  const { toggleDisabledText } = modalOptionItem;
  const { alwaysActiveText } = modalOptionItem;

  const findToggleTextValue = () => {
    if (is_always_active) return alwaysActiveText;
    return checkToggle ? toggleEnabledText : toggleDisabledText;
  };

  return (
    <S.ModalOptionContainer data-testid="ModalOptionItem">
      <Flexbox direction={isManageModalVariant ? 'row' : 'column'} gap={0}>
        {!!title && <Text size="subtitle1" text={title} weight={isManageModalVariant ? 'bold' : 'semibold'} />}
        {optionName === HARDCODED_AGREEMENT_ID_VINHOOD_PARTNER ? null : (
          <S.ToggleWrapper>
            <ToggleButton
              isVariantModalOption
              isDisabled={!!is_always_active}
              isToggled={checkToggle}
              text={hideToggleText ? null : findToggleTextValue()}
              textPosition="left"
              onToggle={() => onToggle({ optionName, optionType })}
            />
          </S.ToggleWrapper>
        )}
      </Flexbox>
      {!!description && <Text size="body2" text={description} />}
    </S.ModalOptionContainer>
  );
};

export default ModalOptionItem;
