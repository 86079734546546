import React, { FC, useEffect, useRef } from 'react';

import { VH_VARIANTS } from '@lib/tools/shared/helpers/consts';

export type IProgressBarVariant =
  | typeof VH_VARIANTS.PRIMARY
  | typeof VH_VARIANTS.LIGHT
  | typeof VH_VARIANTS.WINE
  | typeof VH_VARIANTS.BEER
  | typeof VH_VARIANTS.COFFEE;

interface Props {
  progressPercentage: number;
  className?: string;
  variant?: IProgressBarVariant;
}

const ProgressBar: FC<Props> = ({ progressPercentage, className = '', variant = VH_VARIANTS.PRIMARY }) => {
  const progressBarRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (progressBarRef.current) {
      progressBarRef.current.classList.add('animated-progress-bar');
      progressBarRef.current.style.width = `${progressPercentage}%`;
    }
  }, [progressBarRef, progressPercentage]);
  return (
    <div className={className}>
      <div className="mp-progress-bar-container">
        <div ref={progressBarRef} className={`progress-bar progress-bar-variant-${variant}`} style={{ width: 0 }} />
      </div>
    </div>
  );
};

export default React.memo(ProgressBar);
